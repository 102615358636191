import React from 'react'
import Autosuggest from 'react-autosuggest';
import theme from "./theme.js";
import InputField from "../InputField";
import { reduxForm, Field } from "redux-form";
import {getCfdiFiltered} from '../../actions/orders';
import { connect } from "react-redux";
import { isRequired } from "../../validations";



const REASONCANCEL = [
    {
        Value: "01",
        Name: "01 - Comprobante emitido con errores con relación",        
    },
    {
        Value: "02",
        Name: "02 - Comprobante emitido con errores sin relación",        
    },
    {
        Value: "03",
        Name: "03 - No se llevó a cabo la operación",        
    },
    {
        Value: "04",
        Name: "04 - Operación nominativa relacionada con una factura global",        
    },
   
]



const renderSuggestion = suggestion => (
    <div>
      {suggestion.Uuid}
    </div>
  );

const getSuggestionValue = suggestion => suggestion.Uuid;



class InvoiceCancelForm extends React.Component {
    constructor() {
		super();
		this.state = {			
            showSustituteCFDI: false,
            CFDISuggestions: [], 
            value: ''
		};
    }

    onSuggestionsFetchRequested = ({ value }) => {
       console.log('valueSSSSS', value);
        // this.props.getCfdiFiltered(value.target.value)
        this.props.getCfdiFiltered(value)
        .then( r => {
            console.log('a ver que traeSSS', r.payload);
            this.setState({ CFDISuggestions: r.payload });
        });
      };

    onSuggestionsClearRequested = () => {
        this.setState({
            CFDISuggestions: []
        });
      };

      onChange = (event, { newValue }) => {
        this.props.setSubstituteCFDI(newValue);
        this.setState({
          value: newValue
        });
      };

    render()  {
        console.log('state', this.state);
        const { value  } = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
        placeholder: 'Type a programming language',
        value,
        onChange: this.onChange
        };

        return (
            <>
       
            <form id="InvoiceCancelForm" >
                    <div className="row mt-2 ml-2 mr-2">
                        
                        <Field
                            label=" Motivo de cancelación"
                            className="form-control"
                            name="reason"
                            required=""
                            type="select"
                            component={InputField}
                            onChange={(e) => {
                                console.log('el value', e.target.value);
                                console.log('this del control', this);
                                this.props.setReason(e.target.value);
                                if(e.target.value === '01')
                                    this.setState({showSustituteCFDI: true});
                                else
                                    this.setState({showSustituteCFDI: false});
                            }}
                            validate={[isRequired]}
                        >
                            <option value="">Selecciona una opción</option>
                            {REASONCANCEL.map(
                                (regime) => (
                                    <option value={regime.Value} key={regime.Value}>
                                        {regime.Name}
                                    </option>
                                )
                            )}
                        </Field>

                        {/* { this.state.showSustituteCFDI &&
                            <Field
                                label=" CFDI que sustituye al cancelado"
                                className="form-control"
                                name="sustituteCFDI"
                                required=""
                                type="text"
                                component={InputField}
                                onChange={ (value) => {
                                    console.log('value', value.target.value);
                                    this.props.getCfdiFiltered(value.target.value)
                                    .then( r => {
                                        console.log('a ver que trae', r.payload);
                                        this.setState({ CFDISuggestions: r.payload });

                                    });
                                    
                                }}
                                // validate={[isRequired]}
                            >                            
                                
                            </Field>
                            
                            
                        }
                        { this.state.CFDISuggestions && this.state.CFDISuggestions.map(
                                (cfdi, i) => (
                                    <div key={i} className='justify suggestion'>{cfdi.Uuid}</div>
                                )
                            ) } */}
                        { this.state.showSustituteCFDI &&
                            <div className=' mt-2'>
                                <label>CFDI que sustituye al cancelado</label>
                                <Autosuggest
                                    suggestions={this.state.CFDISuggestions}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={inputProps}
                                    theme={theme}
                                    // validate={[isRequired]}

                                />	
                            </div>
                         }
                        
                    </div>

                       



                
                
            </form>
            </>
        )
    }
}

InvoiceCancelForm = reduxForm({
	form: "InvoiceCancelForm",
	//enableReinitialize : true,
})(InvoiceCancelForm);

const mapStateToProps = (state) => ({
	resultOrders: getCfdiFiltered(state)
});

export default connect(mapStateToProps, {
	getCfdiFiltered,	
})(InvoiceCancelForm);

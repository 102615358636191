import React from "react";
// import PropTypes from 'prop-types'
import InputField from "../InputField";
import { Field } from "redux-form";
import { Link } from "react-router-dom";
import { isRequired, minLength, maxLength } from "../../validations";

const minLength6 = minLength(6);
// const maxLength13 = maxLength(13);
const maxLength100 = maxLength(100);

const Login = () => {
	return (
		<>
			<p className="h2">Ingresa a Facturama</p>
			<p className="h3">eCommerce</p>
			<br />
			<div className="form-group">
				<label>Nombre de usuario:</label>
				<Field
					type="text"
					className="form-control"
					name="UserName"
					placeholder="Mínimo 6 caracteres, sin espacios"
					component={InputField}
					validate={[isRequired, minLength6, maxLength100]}
				/>
			</div>

			<div className="form-group">
				<label>Contraseña:</label>
				<Field
					type="password"
					className="form-control"
					name="Password"
					placeholder="Mínimo 6 caracteres, solo letras y números"
					component={InputField}
					validate={[isRequired]}
				/>
			</div>

			<div className="row mt-4">
				<div className="col-md-6 text-center">
					<label className="check-container">
						Recordar mis datos
						<input
							data-val="true"
							data-val-required="The Remember me? field is required."
							id="RememberMe"
							name="RememberMe"
							type="checkbox"
							value="true"
						/>
						<input name="RememberMe" type="hidden" value="false" />
						<span className="checkmark"></span>
					</label>
				</div>

				<div className="col-md-6 text-center text-md-right xs-mt-1">
					<Link to="/account/ResetPassword">Olvidé mi contraseña</Link>
				</div>
			</div>
		</>
	);
};

export default Login;

import { handleActions } from "redux-actions";
import {
	GET_ECOMMERCE_SUBSCRIPTION,
	FETCH_SUBSCRIPTION,
	CREATE_SUBSCRIPTION,
	UPDATE_SUBSCRIPTION,
	CANCEL_SUBSCRIPTION,
} from "../constants";

export const subscription = handleActions(
	{
		[GET_ECOMMERCE_SUBSCRIPTION]: (state, action) =>{
			return	action.error ? state
					: {
						...state,
						subscriptionPlanInfo: action.payload,
					  }
		},
		[FETCH_SUBSCRIPTION]: (state, action) =>{
			return action.error ? state 
				:{
					...state,
					subs: action.payload,
				}
				 
		},			
		[CREATE_SUBSCRIPTION]: (state, action) =>
			action.error ? state : { ...state, ...action.payload },
		[UPDATE_SUBSCRIPTION]: (state, action) =>
			action.error ? state 
				: { 
					...state,
					subs:{ ...action.payload }
				},
		[CANCEL_SUBSCRIPTION]: (state, action) =>
			action.error ? state : action.payload,
	},
	[]
);

import React from 'react'
// import React from 'react'
import PropTypes from 'prop-types'
import { useTable} from 'react-table'




const DataTableSimple = ({ columns, data }) => {
    // const [loadingTable, setLoadingTable] = useState(true);
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data: data,
    })
    // console.log('data', data);
    
    // setLoadingTable(false);
    return (
      // If you're curious what props we get as a result of calling our getter functions (getTableProps(), getRowProps())
      // Feel free to use console.log()  This will help you better understand how react table works underhood.
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column =>{
                  return column.hideHeader === true ? null : 
                  ( <th {...column.getHeaderProps()}>{column.render("Header")}</th> )
                }
                )}
            </tr>
          ))}
        </thead>
  
        <tbody {...getTableBodyProps()}>
         
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                })}
              </tr>
            )
          })}
         
        </tbody>
      </table>
    )
  }

DataTableSimple.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    onClickRow: PropTypes.func,
}

export default DataTableSimple

// import React, { useEffect } from "react";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import AppFrame from "../../components/AppFrame";
import CardForm from "../../components/CardForm";
// import { fetchCards, insertCard, deleteCard } from "../../actions/cards";
import { fetchCards, insertCard, deleteCard } from "../../actions/cards";
import { fetchCheckSteps } from "../../actions/profile"
// import { updateSubscriptionWithCard } from "../../actions/subscription";
import { getDefaultCard, getNumberOfCards } from "../../selectors/cards";
import { getSteps } from "../../selectors/profile";

import { getSubscription } from "../../selectors/accounts";
import { Alert } from "react-bootstrap";

let EditCardPage = ({
	card,	
	numberOfCards, 
	steps, 
	fetchCheckSteps, 
	fetchCards,
	// updateSubscriptionWithCard,
	insertCard, 
	deleteCard,
	subscription,
}) => {
	const [showAlertMessage, setShowAlert] = useState(true);
	const [alertVariant, setAlertVariant] = useState( !steps.IsTaxInfo ? "danger" :"primary");
	const [messageAlert, setMessageAlert] = useState( !steps.IsTaxInfo ? 'Debes agregar tu perfil fiscal antes que la tarjeta, recuerda llenar antes tu perfil fiscal con datos reales. Evita llenar campos con información generica.' 
														  			   : 'Antes de agregar tu tarjeta, y para evitar que sea rechazada,  recuerda llenar antes tu perfil fiscal con datos reales. Evita llenar campos con información generica.');

	const [needData, setNeedData] = useState(true);

	useEffect(() => {
		// !card && fetchCards();
		// ( !card  || update ) && fetchCards();
		if(needData) {
			fetchCards();
			fetchCheckSteps();
			setNeedData(false);
		}
	},[ fetchCards, fetchCheckSteps, needData ]);


	
	console.log('card1', card);
	console.log('number of cards1', numberOfCards);
	console.log('steps on cards', steps);



	const AlertMsg = ( msg, variant ) => {		
		setShowAlert(true);
		setAlertVariant(variant);
		setMessageAlert(msg);		
	};

	const handleCloseAlert = () => {	
		setShowAlert(false);			
	};

	// if( steps.IsTaxInfo )
	// 	AlertMsg('Debes agregar tu perfil fiscal antes que la tarjeta', 'success');

	return (
		<AppFrame>
			<div style={{ maxWidth: "600px", margin: "0px auto" }}>
				<div className="card-body">
					<Alert
						show={showAlertMessage}
						variant={alertVariant}
						onClose={handleCloseAlert}
						dismissible
					>
						<p>{messageAlert}</p>						
					</Alert>
					<div className="row">
						<div className="container">
							<h3>Datos de tu tarjeta</h3>
						</div>
					</div>
					<div className="row">
						<div className="container">
							<CardForm
								titleButton="Actualizar tarjeta"
								initialValues={card}
								numberOfCards={numberOfCards}
								// actionName="updateSubscriptionWithCard"
								actionName="updateCard"
								// action={updateSubscriptionWithCard}
								action={insertCard}
								actionWhenUpdated={deleteCard}
								plan={subscription.Plan}
								messageAlert = {AlertMsg}
								disabledButton = { !steps.IsTaxInfo }
							/>
						</div>
					</div>
				</div>
			</div>
		</AppFrame>
	);
};

EditCardPage = connect(
	(state) => ({
		card: getDefaultCard(state),
		numberOfCards: getNumberOfCards(state),
		steps: getSteps(state),
		subscription: getSubscription(state),
	}),
	{ fetchCards, insertCard, deleteCard, fetchCheckSteps }
)(EditCardPage);

export default EditCardPage;

import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import InputField from "../../components/InputField";
import { contactCsdHelpMail } from "../../actions/contactUs";
import { isRequired, maxLength, minLength, number } from "../../validations";
import MessagePage from "./MessagePage";

const maxLength10 = maxLength(10);
const maxLength100 = maxLength(100);
const minLength10 = minLength(10);

let HelpCsdForm = ({
	rfc,
	history,
	handleSubmit,
	contactCsdHelpMail,
	submitSucceeded,
}) => {
	const goBack = () => {
		history.goBack();
	};

	const submit = (data) => {
		contactCsdHelpMail(rfc, data.Name, data.Phone);
	};

	return submitSucceeded ? (
		<MessagePage
			title="Su mensaje fue enviado exitosamente"
			message="Nos pondremos en contacto lo mas pronto posible."
			onOk={() => {
				history.goBack();
			}}
		/>
	) : (
		<div className="pt-5">
			<div className="container mt-5" style={{ maxWidth: "600px" }}>
				<div className="card">
					<div className="card-body">
						<form onSubmit={handleSubmit(submit)}>
							<div className="text-center">
								<h2>¿Ayuda con tus Sellos Digitales?</h2>
								<br />
								<p>
									Lo que acabas de cargar son los archivos de la FIEL ¡No te
									preocupes! Nosotros te ayudamos a solicitar tus Sellos
									Digitales con estos archivos.{" "}
								</p>
							</div>
							<br />
							<div className="form-group">
								<Field
									label="Nombre"
									type="text"
									className="form-control"
									placeholder="Escribe tu nombre"
									name="Name"
									component={InputField}
									validate={[isRequired, maxLength100]}
								/>
							</div>
							<div className="form-group">
								<Field
									label="Celular"
									type="text"
									className="form-control phone"
									placeholder="10 digitos sin espacios"
									name="Phone"
									required=""
									component={InputField}
									validate={[isRequired, minLength10, maxLength10, number]}
								/>
							</div>
							<div className="form-group text-center mt-5">
								<button
									type="button"
									className="btn btn-outline-secondary-cancel mr-3"
									data-dismiss="modal"
									onClick={goBack}
								>
									No gracias
								</button>
								<button type="submit" className="btn btn-outline-primary">
									Si, enviar FIEL
								</button>
							</div>
							<div className="form-group text-center">
								<p className="mb-0">Horario de atención de L-V de 9am a 7pm:</p>
								<h3 className="font-weight-bold">444-8356098 / 444-1510191</h3>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

HelpCsdForm = connect(
	(state) => ({
		rfc: state.profile.Rfc,
	}),
	{
		contactCsdHelpMail,
	}
)(HelpCsdForm);

HelpCsdForm = reduxForm({
	form: "HelpCsdForm",
})(HelpCsdForm);

export default HelpCsdForm;

import React from "react";

export const Pagination = ({
	pageIndex,
	pageSize,
	canPreviousPage,
	canNextPage,
	pageOptions,
	pageCount,
	gotoPage,
	nextPage,
	previousPage,
	setPageSize,
}) => {
	const paginationLength = 5;
	const paginationSize =
		paginationLength < pageCount ? paginationLength : pageCount;
	// const paginationSize = pageIndex < pageCount - 1 ? pageCount : pageIndex;

	return (
		<nav>
			<strong>
				Página {pageIndex + 1} de {pageOptions.length}
			</strong>
			<select
				className="float-right form-control"
				style={{ maxWidth: 150 }}
				value={pageSize}
				onChange={(e) => {
					console.log(e.target.value);
					setPageSize(Number(e.target.value));
				}}
			>
				{[10, 25, 50, 100].map((pageSize) => (
					<option key={pageSize} value={pageSize}>
						Mostrar {pageSize}
					</option>
				))}
			</select>
			<ul className="pagination justify-content-center">
				<li className={canPreviousPage ? "page-item" : "page-item disabled"}>
					<button
						className="page-link leftRadiusZero"
						onClick={() => previousPage()}
					>
						Atrás
					</button>
				</li>
				{canPreviousPage && (
					<li className="page-item disabled">
						<button className="page-link">...</button>
					</li>
				)}
				{Array.from(
					Array(paginationSize),
					(_, i) => i + Math.ceil((pageIndex + 1) / paginationSize)
				).map((index) => (
					<li
						style={{ zIndex: 0 }}
						key={index}
						className={
							index === pageIndex + 1 ? "page-item active " : "page-item"
						}
					>
						<button
							className={
								index === pageIndex + 1 ? "page-link selectecPage" : "page-link"
							}
							onClick={() => gotoPage(index - 1)}
						>
							{index}
						</button>
					</li>
				))}
				{canNextPage && (
					<li className="page-item disabled">
						<button className="page-link">...</button>
					</li>
				)}
				<li className={canNextPage ? "page-item" : "page-item disabled"}>
					<button
						className="page-link rightRadiusZero"
						onClick={() => nextPage()}
					>
						Siguiente
					</button>
				</li>
			</ul>
		</nav>
	);
};

export default Pagination;

import { CONTACT_US } from "./../constants";
import { createAction } from "redux-actions";
import { apiPost, apiPost2 } from "../api";
import { urlContactUsMail } from "../api/urls";

export const contactUsSendMail = createAction(CONTACT_US, (formData) =>
	apiPost2(urlContactUsMail, formData)()
);

export const contactCsdHelpMail = createAction(
	CONTACT_US,
	async (rfc, name, phone) => {
		const data = {
			To: "hramirez@facturama.mx, arochi@facturama.mx, roxana@facturama.mx",
			RFC: rfc,
			Email: "contacto@facturama.mx",
			Subject: "Ayuda con Wizard",
			Message:
				"Necesito ayuda con la carga de mis CSD, he subido la FIEL para recibir su ayuda.",
			Name: name,
			Phone: phone,
			ContactType: 1,
		};
		return await apiPost(urlContactUsMail, data)();
	}
);

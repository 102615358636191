import { handleActions } from "redux-actions";
import {
	FETCH_ORDERS,
	FETCH_ORDERS_WHITHOUT_INVOICE,
	FETCH_ORDERS_WHITH_INVOICE,
	CANCEL_CFDI,
	SET_INVOICE_STATUS,
	GET_CFDI_FILTERED,
} from "../constants";

export const orders = handleActions(
	{
		[FETCH_ORDERS]: (state, action) => {
			let orders = action.payload.Results;
			return {
				...state,
				all: {
					...action.payload,
					Results: orders
				},
			};
		},
		[FETCH_ORDERS_WHITH_INVOICE]: (state, action) => {
			return {
				...state,
				withInvoice: action?.payload,
			};
		},
		[FETCH_ORDERS_WHITHOUT_INVOICE]: (state, action) => {
			return {
				...state,
				withoutInvoice: action?.payload,
			};
		},
		[SET_INVOICE_STATUS]: (state, action) => {
			
			let orderIndex = state.all.Results.findIndex((order) => order.IdInvoice === action.payload.idInvoice );	
			let orderToUpdate = state.all.Results.find((order) => order.IdInvoice === action.payload.idInvoice );	
			let orders = [
				...state.all.Results
			];
		
			orders.splice(orderIndex, 1, {
				...orderToUpdate,
				StatusInvoice: false
			});
			return {
				...state,
				all: {
					...state.all,
					Results: [
						...orders						
					]
				}
			}
		},
	},
	[]
);

export const deleteCFDI = handleActions(
	{
		[CANCEL_CFDI]: (state, action) => {
			return {
				...state,
				cfdiDeleted: action?.payload,
			}
		},
	},
	[]
	
);

export const filteredFDI = handleActions(
	{
		
		[GET_CFDI_FILTERED]: (state, action) => {

			return {
				...state,
				Results1: action?.payload,
			}
		},
	},
	[]
	
);
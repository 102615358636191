import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PaymentStatusSelect } from "./ListInvoicesControls";
import { connect } from "react-redux";
import { getSubscription } from "../../selectors/accounts";

import { PREMIUM, FREE } from "../../constants/plans";


class ListWithoutInvoiceFilter extends React.Component {
	constructor(props) {
		super(props);
		const d = new Date();
		d.setMonth(d.getMonth() - 1);
		this.state = {
			startDate: d,
			orderStatus: [],
			status: "",
			endDate: new Date(),
			suscription: {},
		};


	}

	handleListClick = () => {
		this.props.clickHandler(this.state.startDate, this.state.endDate, this.state.status);
	};
	handleGenararClick = () => {
		this.props.clickUpdateOrderHandler();
	};
	handleStatusChange = (e) => {
		let s = "";
		if (e.target.value === "Completed") s = "1";
		else if (e.target.value === "Held") s = "7";
		else if (e.target.value === "Ordered") s = "8";
		else s = e.target.value;
		this.setState({ status: s });
	};
	componentDidMount = () => {
		this.handleListClick();
	};
	render() {
		let hideButtonInvoice = this.props.hideButtonInvoice || false;
		return (
			<div className="row">
				<div className="col-2">
					<div className="form-group">
						<label>Estado del pago:</label>
							<PaymentStatusSelect
								handleStatus={this.handleStatusChange}
								ordersStatus={this.props.ordersStatus}
								defaultValue={"all"}
							/>
					</div>
				</div>
				<div className="col-2">
					<div className="form-group">
						<label>Fecha inicial:</label>
						<DatePicker
							dateFormat="dd/MM/yyyy"
							className="form-control calendar"
							selected={this.state.startDate}
							onChange={(date) => this.setState({ startDate: date })}
						/>
					</div>
				</div>
				<div className="col-2">
					<div className="form-group">
						<label>Fecha final:</label>
						<DatePicker
							dateFormat="dd/MM/yyyy"
							className="form-control calendar"
							selected={this.state.endDate}
							onChange={(date) => this.setState({ endDate: date })}
						/>
					</div>
				</div>
				<div className="col-2">
					<div className="form-group pt-4">
						<button
							onClick={this.handleListClick}
							className="btn btn-outline-primary"
						>
							Buscar
						</button>
					</div>
				</div>
				<div className="col-4">
					<div className="form-group pt-4">
						{!hideButtonInvoice && (
							<button
								onClick={this.handleGenararClick}
								className="btn btn-outline-primary"
								disabled={ this.props.subscription?.subscriptionPlanInfo?.Plan?.toUpperCase() !== FREE.toUpperCase() && this.props.subscription?.subscriptionPlanInfo?.Plan?.toUpperCase() !== PREMIUM.toUpperCase() }
							>
								Generar factura global
							</button>
						)}
					</div>
				</div>
			</div>
		);
	}
}
// export default ListWithoutInvoiceFilter;
const mapStateToProps = (state) => ({
	subscription: getSubscription(state),
});


export default connect(mapStateToProps)(
	ListWithoutInvoiceFilter
);

export const CFDI_USES = [
    {
        Natural: true,
        Moral: false,
        Name: "Honorarios médicos, dentales y gastos hospitalarios.",
        Value: "D01",
    },
    {
        Natural: true,
        Moral: false,
        Name: "Gastos médicos por incapacidad o discapacidad",
        Value: "D02",
    },
    {
        Natural: true,
        Moral: false,
        Name: "Gastos funerales.",
        Value: "D03",
    },
    {
        Natural: true,
        Moral: false,
        Name: "Donativos.",
        Value: "D04",
    },
    {
        Natural: true,
        Moral: false,
        Name:
            "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
        Value: "D05",
    },
    {
        Natural: true,
        Moral: false,
        Name: "Aportaciones voluntarias al SAR.",
        Value: "D06",
    },
    {
        Natural: true,
        Moral: false,
        Name: "Primas por seguros de gastos médicos.",
        Value: "D07",
    },
    {
        Natural: true,
        Moral: false,
        Name: "Gastos de transportación escolar obligatoria.",
        Value: "D08",
    },
    {
        Natural: true,
        Moral: false,
        Name:
            "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",
        Value: "D09",
    },
    {
        Natural: true,
        Moral: false,
        Name: "Pagos por servicios educativos (colegiaturas)",
        Value: "D10",
    },
    {
        Natural: true,
        Moral: true,
        Name: "Adquisición de mercancias",
        Value: "G01",
    },
    {
        Natural: true,
        Moral: true,
        Name: "Devoluciones, descuentos o bonificaciones",
        Value: "G02",
    },
    {
        Natural: true,
        Moral: true,
        Name: "Gastos en general",
        Value: "G03",
    },
    {
        Natural: true,
        Moral: true,
        Name: "Construcciones",
        Value: "I01",
    },
    {
        Natural: true,
        Moral: true,
        Name: "Mobilario y equipo de oficina por inversiones",
        Value: "I02",
    },
    {
        Natural: true,
        Moral: true,
        Name: "Equipo de transporte",
        Value: "I03",
    },
    {
        Natural: true,
        Moral: true,
        Name: "Equipo de computo y accesorios",
        Value: "I04",
    },
    {
        Natural: true,
        Moral: true,
        Name: "Dados, troqueles, moldes, matrices y herramental",
        Value: "I05",
    },
    {
        Natural: true,
        Moral: true,
        Name: "Comunicaciones telefónicas",
        Value: "I06",
    },
    {
        Natural: true,
        Moral: true,
        Name: "Comunicaciones satelitales",
        Value: "I07",
    },
    {
        Natural: true,
        Moral: true,
        Name: "Otra maquinaria y equipo",
        Value: "I08",
    },
    {
        Natural: true,
        Moral: true,
        Name: "Sin efectos fiscales",
        Value: "S01",
    },
];

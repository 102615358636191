import React from "react";
import { reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { StepOne } from "./StepOne";
import { StepTwo } from "./StepTwo";
import { StepThree } from "./StepThree";

import { getUsername, getToken } from "../../selectors/tokens";
import { setShopName, setToken } from "../../actions/tokens";
import {
	fetchCheckSteps,
	fetchProfile,
	updateProfile,
	updateLogo,
	updateCsd,
	getInfoCSD,
} from "../../actions/profile";
import { insertBranch } from "../../actions/branches";
import { fetchPostalCodes, fetchNeighborhoods } from "../../actions/catalogs";
import { BootBoxAlert } from "../../components/General/bootbox";
import Loader from "../../components/General/loader";

import {
	getProfile,
	getIsNatural,
	getNeighborhoods,
	getSteps,
} from "../../selectors/profile";
// import { createPortal } from "react-dom";
// import { Alert } from "bootstrap";

class WizardForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isUseAddress: true,
			steps: {
				IsTaxInfo: false,
				IsBranch: false,
				IsCertif: false,
			},
			loading: false,
			modalshow: false,
			showBootBoxAlert: false,
			msgConfirm: "",
			actionConfirm: "",
			redirect: false,
			url: "",
			sendedCSD: false,
			responseCsd: {
				Certificate: "",
				ExpirationDate: "",
				SerialNumber: "",
				PrivateKeyPassword: "",
			},
			errorCsd: "",
			succesCsd: false,
		};
	}

	setIsUseAddress = (isUseAddress) => {
		this.setState({ isUseAddress: isUseAddress });
	};

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	submit = (values) => {
		this.setState({ loading: true });
		let actions = [this.props.updateProfile(values)];
		console.log("steps en submit", this.state);
		console.log("values", values);
		if (
			this.state.isUseAddress === true &&
			this.state.steps.IsBranch === false
		) {
			let data = {
				Name: "Principal",
				Description: "Principal",
				Address: values.TaxAddress,
			};
			actions.push(this.props.insertBranch(data));
		}
		return Promise.all(actions)
			.then(() => {
				this.props.history.push(`/invoices/list`);
			})
			.catch((error) => {
				this.setState({ error });
				throw new SubmissionError(error);
			})
			.finally(() => this.setState({ loading: false }));
	};

	validateStepsAndHandleRedirectTo = (steps) => {
		// console.log('steps', steps);

		if (
			steps !== undefined &&
			steps.length !== 0 &&
			steps.success === false &&
			steps.IsBranch === true &&
			steps.IsCertif === true &&
			steps.IsTaxInfo === true
			// && steps.ShowSettingsTaxes === true
		) {
			// console.log("redireccion1");
			this.props.history.push(`/invoices/list`);
			// this.setState({
			//     redirect: true,
			//     //url:`/settings/taxes`
			//     // url:``
			//     url:`/invoices/list`
			//   })
		} else if (
			steps !== undefined &&
			steps.length !== 0 &&
			steps.success === true
		) {
			// console.log("redireccion2");
			this.props.history.push(`/invoices/list`);
			// this.setState({
			//     redirect: true,
			//     url:`/invoices/list`
			//   })
		}
	};

	componentDidMount() {
		// this._isMounted=true;
		// if(this.isMounted){
		// console.log("props", this);
		// console.log("props qwe", WizardWithRouter);
		this.setState({ loading: true });

		Promise.all([
			this.props.fetchCheckSteps(),
			this.props.fetchProfile(),
			// this.props.getInfoCSD()
		])
			.then((response) => {
				if (response[1]) {
					this.props.initialize(this.props.profile);
					this.setState((prevState) => ({
						responseCsd: {
							...prevState.responseCsd,
							PrivateKeyPassword: this.props.profile.Csd.PrivateKeyPassword,
						},
					}));
				}
				// if(response[2]){
				//     if(response[2].payload && response[2].payload.date && response[2].payload.SerialNumber)
				//     {
				//         this.setState(prevState => ({
				//             responseCsd: {
				//                 ...prevState.responseCsd,
				//                 ExpirationDate:response[2].payload.date,
				//                 SerialNumber:response[2].payload.SerialNumber
				//             }
				//         }));
				//         this.setState({sendedCSD:true})
				//     }
				// }
				if (response[0]) {
					this.setState({ steps: response[0].payload });
					console.log("this", this);
					// this.validateStepsAndHandleRedirectTo(response[0].payload);
				}
			})
			.finally(() => this.setState({ loading: false }));
	}

	handleUploadLogo = (logoImageFile) => {
		console.log("Handling logo from the Wizard form");
		let imgData = logoImageFile.split(",");

		const imgContent = imgData.length > 1 ? imgData[1] : "";
		const imgType = imgData[0].split("/")[1].split(";")[0];

		const imgJSON = {
			Image: imgContent,
			Type: imgType,
		};
		this.setState({ loading: true });
		return this.props.updateLogo(imgJSON).then(
			({ payload }) => {
				this.processPayload(payload);
				this.setState({ loading: false });
			},
			(error) => {
				this.setState({
					isLoaded: false,
					error,
				});
			}
		);
	};

	processPayload = (payload) => {
		if (payload.steps !== undefined) {
			console.log("redireccion7");
			this.props.history.push(`/invoices/list`);
			// this.setState({
			//     redirect: true,
			//     //url:`/settings/taxes`
			//     url:`/invoices/list`
			// })
		}
		if (payload.Image !== undefined) {
			this.setState({ showBootBoxAlert: true });
			this.setState({ msgTitle: payload.Message });
			this.setState({ msgDescription: "" });
		}
		if (payload.ExpirationDate !== undefined) {
			this.setState({ showBootBoxAlert: true });
			this.setState({ msgTitle: "Certificado cargado correctamente" });
			this.setState({
				msgDescription: `Fecha de expiración: ${
					payload.ExpirationDate.split("T")[0]
				}`,
			});
			this.setState({ sendedCSD: true });
			let responseCsd = {
				Certificate: payload.Certificate,
				ExpirationDate: payload.ExpirationDate,
				SerialNumber: payload.SerialNumber,
			};
			this.setState({ responseCsd: responseCsd });
		}

		if (payload._error) {
			let complementaryError =
				payload.Type !== undefined ? payload.Type.join("; ") : "";
			if (payload.Message !== undefined) complementaryError += payload.Message;

			this.setState({ showBootBoxAlert: true });
			this.setState({ msgTitle: payload._error });
			this.setState({ msgDescription: complementaryError });
		}
	};

	handleOk = () => {
		this.setState({ showBootBoxAlert: false });
	};

	// CSDs
	handleUploadCertificates = (CSD) => {
		console.log("handleUploadCsd");
		this.setState({ loading: true });
		return this.props.updateCsd(CSD).then(
			({ payload }) => {
				//console.log(payload);
				this.processPayload(payload);
				// alert("El certificado se cargo correctamente.");
				this.setState({
					loading: false,
					succesCsd: true,
					errorCsd: "",
				});
			},
			(error) => {
				//console.log(error);
				//alert(error.Message + (error.Certificate ? ("\n"+ error.Certificate) : "") +  (error.PrivateKey ? ("\n"+ error.PrivateKey) : "") +  (error.PrivateKeyPassword ? ("\n"+ error.PrivateKeyPassword) : "") );
				this.setState({
					isLoaded: true,
					errorCsd:
						error.Message +
						(error.Certificate ? " " + error.Certificate : "") +
						(error.PrivateKey ? ". " + error.PrivateKey : "") +
						(error.PrivateKeyPassword ? ". " + error.PrivateKeyPassword : ""),
					succesCsd: false,
				});
				this.setState({ loading: false });
			}
		);
	};

	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect to={this.state.url} />;
		}
	};

	render() {
		const {
			error,
			handleSubmit,
			submitSucceeded,
			isNatural,
			submitting,
		} = this.props;

		return (
			<div className="container">
				{<Loader show={this.state.loading} />}

				<div className="card">
					<div className="card-body">
						<form onSubmit={handleSubmit(this.submit)}>
							{error && (
								<div className="alert alert-danger" role="alert">
									{error}
								</div>
							)}
							{submitSucceeded && (
								<div className="alert alert-success" role="alert">
									Los cambios se guardaron exitosamente
								</div>
							)}

							<div className="container pt-4">
								<div className="row">
									<div className="col-12">
										<h3>¡Hola {this.props.userName} !</h3>

										<span className="badge badge-pill badge-light mb-5">
											{this.props.profile.Rfc}
										</span>
										<br />
										<p>
											Completa estos 3 pasos para que puedas emitir facturas
											cuanto antes
										</p>
									</div>
								</div>

								{this.state.steps?.IsTaxInfo === false ? (
									<StepOne
										isNatural={isNatural}
										currentLogo={this.props.profile.UrlLogo}
										handleUploadLogo={this.handleUploadLogo}
									/>
								) : (
									""
								)}
								{this.state.steps?.IsTaxInfo === false || this.state.steps?.IsBranch === false ? (
									<StepTwo setIsUseAddress={this.setIsUseAddress} />
								) : (
									""
								)}
								{/* {this.state.loading&&<Loader show={this.state.loading}/>} */}
								{this.state.steps?.IsCertif === false ? (
									<StepThree
										handleUploadCertificates={this.handleUploadCertificates}
										sendedCSD={this.state.sendedCSD}
										responseCsd={this.state.responseCsd}
										errorCsd={this.state.errorCsd}
										succesCsd={this.state.succesCsd}
									/>
								) : (
									""
								)}
								<div className="col-md-12 text-right">
									<Link className="btn btn-link" to="/settings/profile">
										Terminar después
									</Link>
									<button
										type="submit"
										id="btn-finish"
										className="btn btn-outline-primary btn-lg"
										disabled={submitting}
									>
										Finalizar
									</button>
								</div>
							</div>
						</form>
					</div>{" "}
					{/* card-body */}
				</div>
				{
					<BootBoxAlert
						show={this.state.showBootBoxAlert}
						type={"confirm"}
						message={this.state.msgTitle}
						detail={this.state.msgDescription}
						onYesClick={this.handleOk}
					/>
				}
				{/* {this.renderRedirect()} */}
			</div>
		);
	}
}

WizardForm.propTypes = {
	shopName: PropTypes.string,
	token: PropTypes.string,
};

WizardForm = reduxForm({
	form: "wizardForm",
	asyncBlurFields: [] 
})(WizardForm);

WizardForm = connect(
	(state) => {
		return {
			token: getToken(state),
			userName: getUsername(state),
			steps: getSteps(state),
			profile: getProfile(state),
			isNatural: getIsNatural(state),
			neighboorhoods: getNeighborhoods(state),
		};
	},
	{
		updateProfile,
		updateLogo,
		updateCsd,
		fetchCheckSteps,
		fetchProfile,
		getInfoCSD,
		fetchNeighborhoods,
		fetchPostalCodes,
		setShopName,
		setToken,
		insertBranch,
	}
)(WizardForm);

export default WizardForm;

import React from 'react'

const  PaymentFormProvider = ({handleChange,id, name, title, defaultValue, active}) => {
    return (
        <>            
            <div className="row mt-3">
                <div className="col-12">
                    <div className="form-check">
                        <input className="form-check-input" id={`defaultCheck_${id}`} title={title} onChange={handleChange} type="checkbox" name={name} defaultValue={defaultValue}  defaultChecked={active}/>
                        <label className="form-check-label" htmlFor={`defaultCheck_${id}`}>
                            {title}
                        </label>
                    </div>
                </div>                
            </div>
        </>
    )
};
export default PaymentFormProvider;


import { handleActions } from "redux-actions";
import {     
    TOKEN,
    SHOP_NAME    
} from "../constants";

export const tokens = handleActions(
    {                
        [TOKEN]: (state, action) => {             
            return {
                ...state, token : action.payload
            }            
        },
        [SHOP_NAME]: (state, action) => {    
            return {
                ...state, shopName : action.payload
            }            
        }         
    },
    []
);

import { handleActions } from "redux-actions";
import {
	FETCH_PRODUCTS,
	DETAIL_PRODUCT,
	INSERT_PRODUCT,
	UPDATE_PRODUCT,
	DELETE_PRODUCT,
} from "../constants";

export const products = handleActions(
	{
		[FETCH_PRODUCTS]: (state, action) =>
			action.error
				? state
				: [...action.payload].sort((a, b) => (a.Name > b.Name ? 1 : -1)),
		[DETAIL_PRODUCT]: (state, action) =>
			action.error ? state : [...state, action.payload],
		[INSERT_PRODUCT]: (state, action) =>
			action.error
				? state
				: [...state, action.payload].sort((a, b) => (a.Name > b.Name ? 1 : -1)),
		[UPDATE_PRODUCT]: (state, action) =>
			action.error
				? state
				: [
						...state.filter((c) => c.Id !== action.payload.Id),
						action.payload,
				  ].sort((a, b) => (a.Name > b.Name ? 1 : -1)),
		[DELETE_PRODUCT]: (state, action) =>
			action.error ? state : state.filter((c) => c.Id !== action.payload.Id),
	},
	null
);

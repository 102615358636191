import React from 'react'
import { useParams } from 'react-router-dom';
import BranchForm from '../../components/BranchForm'
import AppFrame from '../../components/AppFrame'

export const BranchPage = () => {

    let { id } = useParams();
        
    return (
        <AppFrame title="Sucursal">
            <BranchForm Id={id}/>
        </AppFrame>
    )
}

import store from "../store";

// var myHeaders = new Headers();
// myHeaders.append("Content-Type", "application/json");

// const state = store.getState();

// console.log("------------------------------- Llamada a la API ----------------------------");
// console.log(state.tokens.token)

// if(state.tokens.token !== undefined){
//     myHeaders.append("Authorization", `Basic ${state.tokens.token}` );
// }
// else{
//     myHeaders.append("Authorization", "Basic cHJ1ZWJhczpwcnVlYmFzMjAxMTpGYWN0dXJhbWFFY29tbWVyY2U6Mw==");
// }

//myHeaders.append("Authorization", "Basic UFJVRUJBU0VQT1M4OjEyMzQ1NjpGYWN0dXJhbWFFY29tbWVyY2U6MTQ=") // token de pruebas eposnow
//myHeaders.append("Authorization", "Basic UFJVRUJBU0VQT1MxMDoxMjM0NTY6RmFjdHVyYW1hRWNvbW1lcmNlOjM=")

const getAuthDataHeaders = () => {
	const state = store.getState();

	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	if (state.tokens.token !== undefined) {
		myHeaders.append("Authorization", `Basic ${state.tokens.token}`);
	}

	return myHeaders;
};

const apiSettings = (method, obj) => {
	let myHeaders = getAuthDataHeaders();

	const settings = {
		method: method,
		headers: myHeaders,
	};
	if (obj) {
		settings.body = JSON.stringify(obj);
	}
	return settings;
};

const apiSettingsData = (method, obj) => {
	let myHeaders = getAuthDataHeaders();

	myHeaders.delete("Content-Type");
	//myHeaders.set('Content-Type','multipart/form-data');
	const settings = {
		method: method,
		headers: myHeaders,
	};
	if (obj) {
		settings.body = obj;
	}
	return settings;
};

const handleError = (response) => {
	return response._error ? Promise.reject(response) : response;
};

function handleModelStateError(response) {
	var errors = {
		_error: response.Message || "Error al efectuar la petición",
		_errorDetail: "",
	};
	if (response.ModelState) {
		for (var prop in response.ModelState) {
			var props =
				prop.split(".").length === 1
					? prop.split(".").slice(0)
					: prop.split(".").slice(1);
			var property = errors;
			for (const [index, p] of props.entries()) {
				if (props.length === index + 1) {
					if (!property[p] && prop.split(".").length !== 1)
						property[p] = [...response.ModelState[prop]];
					else errors._error += ` ${response.ModelState[prop].join(", ")}`;
					errors._errorDetail += ` ${response.ModelState[prop].join(", ")}`;
				} else {
					property[p] = property[p] || {};
				}
				property = property[p];
			}
		}
	}
	return errors;
}

const tryToParseJson = (str) => {
	try {
		return JSON.parse(str);
	} catch (e) {
		return {};
	}
};

export const apiGet = (url) => () =>
	fetch(url, apiSettings("GET"))
		.then((response) => Promise.all([response.text(), response.status]))
		.then(([res, status]) =>
			status >= 200 && status <= 206
				? tryToParseJson(res)
				: handleModelStateError(res)
		)
		.then(handleError);

export const apiGetById = (url, id) => () =>
	fetch(`${url}/${id}`, apiSettings("GET"))
		.then((response) => Promise.all([response.text(), response.status]))
		//.then((v) => v.json())
		.then(
			(content) => {
				var json = tryToParseJson(content[0]);
				var status = content[1];
				if (status >= 200 && status <= 206) return json;
				return handleModelStateError(json);
			},
			(error) => {
				this.setState({
					isLoaded: true,
					error,
				});
			}
		)
		.catch((r) => {
			return handleModelStateError(r);
		});

export const apiPut = (url, id, obj) => () =>
	fetch(`${url}/${id}`, apiSettings("PUT", obj))
		.then((response) => Promise.all([response.text(), response.status]))
		.then(([res, status]) => {
			var json = tryToParseJson(res);
			return status >= 200 && status <= 206
				? json
				: handleModelStateError(json);
		})
		.then(handleError);

export const apiPost = (url, obj) => () =>
	fetch(url, apiSettings("POST", obj))
		.then((response) => Promise.all([response.text(), response.status]))
		.then(([res, status]) => {
			var json = tryToParseJson(res);
			return status >= 200 && status <= 206
				? json
				: handleModelStateError(json);
		})
		.then(handleError);

export const apiPostData = (url, obj) => () =>
	fetch(url, apiSettingsData("POST", obj))
		.then((e) => {
			var status = e.status;
			if (status >= 200 && status <= 206) return e.json();
			return e;
		})
		.catch((r) => {
			return handleModelStateError(r);
		});

export const apiPost2 = (url, obj) => () =>
	fetch(url, apiSettings("POST", obj))
		.then((response) => Promise.all([response.text(), response.status]))
		.then((content) => {
			var json = tryToParseJson(content[0]);
			var status = content[1];
			if (status >= 200 && status <= 206) return json;
			return handleModelStateError(json);
		})
		.catch((r) => {
			return handleModelStateError(r);
		});

export const apiDelete = (url, id) => () => {
	return fetch(`${url}/${id}`, apiSettings("DELETE"))
		.then((response) => Promise.all([response.text(), response.status]))
		.then(([res, status]) => {
			var json = tryToParseJson(res);
			return status >= 200 && status <= 206
				? json
				: handleModelStateError(json);
		})
		.then(handleError)
		.catch(handleModelStateError);
};

export const apiDelete2 = (url, id) => () =>
	fetch(`${url}/${id}`, apiSettings("DELETE"))
		.then((v) => v.json())
		.then(handleError);

import React from "react";
import "@fortawesome/fontawesome-free/css/all.css";
import { connect } from "react-redux";

import {
	insertProcess,
	uploadCvs,
	fetchMassiveProcess,
	downloadMassiveCsv,
} from "../../actions/configure_tax";
import DataTableSimpleEditable from "../../components/DataTable/DataTableSimpleEditable";

import { getMassiveProcess } from "../../selectors/configure_tax";
import Loader from "../../components/General/loader";
import { BootBoxAlert } from "../../components/General/bootbox";

const EditableInputCell = ({
	value: initialValue,
	row: { index },
	column: { id },
	updateMyData, // This is a custom function that we supplied to our table instance
  }) => {
	// We need to keep and update the state of the cell normally
	const [value, setValue] = React.useState(initialValue)
  
	const onChange = e => {
	  setValue(e.target.value)
	}
  
	// We'll only update the external data when the input is blurred
	const onBlur = () => {
	  updateMyData(index, id, value)
	}
  
	// If the initialValue is changed external, sync it up with our state
	React.useEffect(() => {
	  setValue(initialValue)
	}, [initialValue])
  
	return <input value={value} onChange={onChange} onBlur={onBlur} className="borderZero" />
  }
  const EditableSelectCell = ({
	value: initialValue,
	row: { index },
	column: { id },
	data: { data },
	updateMyData, // This is a custom function that we supplied to our table instance
  }) => {
	// We need to keep and update the state of the cell normally
	const [value, setValue] = React.useState(initialValue)
  
	const onChange = e => {
	  setValue(e.target.value)
	}
  
	// We'll only update the external data when the input is blurred
	const onBlur = () => {
	  updateMyData(index, id, value)
	}
  
	// If the initialValue is changed external, sync it up with our state
	React.useEffect(() => {
	  setValue(initialValue)
	}, [initialValue])
  
	console.log('data', data);
	
	return 	<select value={value} onChange={onChange} onBlur={onBlur} className="borderZero" >			
				<option value="0">0 %</option>
				<option value="8">8 %</option>
				<option value="16">16 %</option>				
			</select>
  }

class SpecificTaxSettings extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showAlert: false,
			messageAlert: "",
			loading: false,
			modalShow: false,
			email: "",
			emailError: "",
			dataExample: [
				{
					Product: 'product1',
					TotalPrice: '$1,000.00',
					SATProductId: '123415678',
					SATUnitId: 'E48',
					UnitName: 'PIEZA',
					IEPSPercentage: '8.00',
					IEPSPrice: '63.86',
					IVAPercentage: '16.00',
					IVAPrice: '$137.93',
					PricePerUnit: '798.21',
					UseAsCharge: 'no',
					Petition: '12 1234 1234',
					PetitionQty: 1,
					BreakDown:'0'
				},	
				{
					Product: 'product2',
					TotalPrice: '$1,000.00',
					SATProductId: '123415678',
					SATUnitId: 'E48',
					UnitName: 'PIEZA',
					IEPSPercentage: '8.00',
					IEPSPrice: '63.86',
					IVAPercentage: '16.00',
					IVAPrice: '$137.93',
					PricePerUnit: '798.21',
					UseAsCharge: 'no',
					Petition: '12 1234 1234',
					PetitionQty: 1,
					BreakDown:'0'
				},	
				{
					Product: 'product3',
					TotalPrice: '$1,000.00',
					SATProductId: '123415678',
					SATUnitId: 'E48',
					UnitName: 'PIEZA',
					IEPSPercentage: '8.00',
					IEPSPrice: '63.86',
					IVAPercentage: '16.00',
					IVAPrice: '$137.93',
					PricePerUnit: '798.21',
					UseAsCharge: 'no',
					Petition: '12 1234 1234',
					PetitionQty: 1,
					BreakDown:'0'
				},	
				{
					Product: 'product4',
					TotalPrice: '$1,000.00',
					SATProductId: '123415678',
					SATUnitId: 'E48',
					UnitName: 'PIEZA',
					IEPSPercentage: '8.00',
					IEPSPrice: '63.86',
					IVAPercentage: '16.00',
					IVAPrice: '$137.93',
					PricePerUnit: '798.21',
					UseAsCharge: 'no',
					Petition: '12 1234 1234',
					PetitionQty: 1,
					BreakDown:'0'
				},	
				{
					Product: 'product5',
					TotalPrice: '$1,000.00',
					SATProductId: '123415678',
					SATUnitId: 'E48',
					UnitName: 'PIEZA',
					IEPSPercentage: '8.00',
					IEPSPrice: '63.86',
					IVAPercentage: '16.00',
					IVAPrice: '$137.93',
					PricePerUnit: '798.21',
					UseAsCharge: 'no',
					Petition: '12 1234 1234',
					PetitionQty: 1,
					BreakDown:'0'
				},	
				{
					Product: 'product6',
					TotalPrice: '$1,000.00',
					SATProductId: '123415678',
					SATUnitId: 'E48',
					UnitName: 'PIEZA',
					IEPSPercentage: '8.00',
					IEPSPrice: '63.86',
					IVAPercentage: '16.00',
					IVAPrice: '$137.93',
					PricePerUnit: '798.21',
					UseAsCharge: 'no',
					Petition: '12 1234 1234',
					PetitionQty: 1,
					BreakDown:'0'
				},	
			]
		};
	}
	getListMassive = () => {
		const columns = [
			{
				Header: "Productos",
				hideHeader: true,
				columns: [
					{
						Cell: EditableInputCell,
						Header: "+Producto",
						accessor: "Product",
						
					},
					{
						
						Header: "+Precio Total",
						accessor: "TotalPrice",
					},
					{
						Cell: EditableInputCell,
						Header: "Clave Producto SAT",
						accessor: "SATProductId",
					},
					{
						Cell: EditableInputCell,
						Header: "Clave Unidad SAT",
						accessor: "SATUnitId",
					},
					{
						Cell: EditableInputCell,
						Header: "Nombre de la Unidad",
						accessor: "UnitName",
					},
					{
						Cell: EditableSelectCell,
						Header: "IEPS %",
						accessor: "IEPSPercentage",
					},
					{
						Cell: EditableSelectCell,
						Header: "IEPS $",
						accessor: "IEPSPrice",
					},
					{
						Cell: EditableSelectCell,
						Header: "IVA %",
						accessor: "IVAPercentage",
					},
					{
						Cell: EditableSelectCell,
						Header: "IVA $",
						accessor: "IVAPrice",
					},
					{
						Cell: EditableInputCell,
						Header: "Precio Unitario",
						accessor: "PricePerUnit",
					},
					{
						Cell: EditableInputCell,
						Header: "Usar como Cargo",
						accessor: "UseAsCharge",
					},
					{
						Cell: EditableInputCell,
						Header: "Pedimento",
						accessor: "Petition",
					},
					{
						Cell: EditableInputCell,
						Header: "Cant. Pedimento",
						accessor: "PetitionQty",
					},
					{
						Cell: EditableInputCell,
						Header: "Desglozar",
						accessor: "BreakDown",
					},					
				],
			},
		];
		return columns;
	};
	
	
	//param.fetchMassiveProcess()
	showModalConfirm = () => {
		// DownloadStart()
	};
	
	Alert = (msg) => {
		this.setState({ showAlert: true });
		this.setState({ messageAlert: msg });
	};
	handleEmailChange = (e) => {
		this.Emailvalidation(e.target.value);
		this.setState({ email: e.target.value });
	};
	
	componentDidMount = () => {
		//this.props.fetchMassiveProcess();
	};
	handleCloseAlert = () => {
		this.setState({ showAlert: false });
	};
	updateMyData = (rowIndex, columnId, value) => {
		
		let dataNew = this.state.dataExample;
		dataNew[rowIndex][columnId] = value;
		this.setState({dataExample: dataNew});
		

	}
	render() {
		//const{massive_process}=this.props;
		return (
			<>
				<Loader show={this.state.loading} />
				<br />
				<div className="row">
					<div className="col-8">
						<p>
							En este apartado deberas asignar los Impuestos de tus productos. <b className="text-red">Da click en las celdas de las columnas de IVA%, IEPS% para editar la tasa del porcentaje a usar</b>
						</p>
					</div>
					<div className="col-4">
						
					</div>
				</div>

				<div className="row">
					<div className="col-8">
						<label>
							En caso que no conozcas la clave del PRODUCTO/SERVICIO, podras buscarla dando click en le siguiente botón:							
						</label>
					</div>
					<div className="col-4">
						<a
							className=" btn btn-outline-primary btn-block"
							target="_blank"
							rel="noopener noreferrer"
							href="http://200.57.3.46:443/PyS/catPyS.aspx"
						>
							Buscar Clave
						</a>
					</div>
				</div>

				<br />

				<div className="row">
					<div className="col-8">
						<label>
							En caso de que no conozcas la clave de la UNIDAD, podras buscarla dando click en el siguiente botón:							
						</label>
					</div>
					<div className="col-4">
						<a
							className="btn btn-outline-primary btn-block"
							target="_blank"
							rel="noopener noreferrer"
							href="http://200.57.3.46:443/PyS/catUnidades.aspx"
						>
							Buscar Unidad
						</a>
					</div>
				</div>
				<div className="row">
					<div className="col-8">
						<li>Clave Producto SAT: Cambia la CLAVE (max 8 caracteres) del PRODUCTO/SERVICIO solo a aquellos que sea diferente al valor General</li>
						<li>Clave Unidad SAT: Cambia la CLAVE (2 a 3 caracteres) y el NOMBRE (max. 20 caracteres) de la UNIDAD, a aquellos productos donde este es diferente al valor General</li>
					</div>
				</div>
				<br />
				<hr />
				<div className="row">
					<div className="col-8">
						<li>IEPS%: Cambia el valor solo a los que la tasa del producto sea diferente al valor General del IEPS</li>
						<li>IVA%: Cambia el valor soloa  los que la tasa del producto sea diferente al valor General del IVA</li>
						<li>USAR como Cargo: <b className="text-red">(Opcional)</b> Selecciona "si" para los productos que se quieran mostrar como opciones y que el cliente pueda escoger como cargos extra en el carrito de compras</li>
					</div>
				</div>

				<br />
						
				<div className="row">
					<div className="col-8">
						<label>
							<b className="text-red">
								-Información obtenida dirtectamente de TiendaNube															
							</b>
						</label>
						<label>
							<b className="text-red">
								--Para los cálculos se tomo en cuenta que: Los precios ya incluyen Impuestos								
							</b>
						</label>
					</div>
				</div>
				<br />
				
				<br />
				<div className="row">
					<div className="col-12">
						<DataTableSimpleEditable
							columns={this.getListMassive()}
							data={ this.state.dataExample}
							updateMyData={this.updateMyData}
								
						/>
					</div>
				</div>


				

				
				<BootBoxAlert
					show={this.state.showAlert}
					message={this.state.messageAlert}
					onYesClick={this.handleCloseAlert}
				/>
			</>
		);
	}
}
SpecificTaxSettings.defaultProps = {
	massive_process: [],
};
const mapStateToProps = (state) => ({
	massive_process: getMassiveProcess(state),
});

export default connect(mapStateToProps, {
	insertProcess,
	uploadCvs,
	fetchMassiveProcess,
	downloadMassiveCsv,
})(SpecificTaxSettings);

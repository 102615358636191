import React from "react";
import PropTypes from "prop-types";

export const InputField = ({
	input,
	label,
	meta,
	type,
	placeholder,
	children,
	...inputProps
}) => {
	const EntryElement =
		type === "text" ||
		type === "hidden" ||
		type === "number" ||
		type === "file" ||
		type === "email" ||
		type === "password"
			? "input"
			: type;
	return (
		<>
			{label && <label>{label}</label>}
			<EntryElement
				{...input}
				{...inputProps}
				type={!type ? "text" : type}
				placeholder={placeholder}
				className={`form-control ${
					meta.touched
						? meta.touched && meta.error
							? "is-invalid"
							: "is-valid"
						: ""
				}`}
			>
				{children}
			</EntryElement>
			{meta.touched && meta.error && (
				<span className="text-danger">{meta.error}</span>
			)}
		</>
	);
};

InputField.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string,
	meta: PropTypes.object.isRequired,
	type: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
};

export default InputField;

/**
 * AccountResetPwStepTwo
 * 
 * Contenedor de los elementos de Account (ResetPwStepTwo)
 */

import React from "react";

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../../index.css';
import { urlCdnImages } from "../../api/urls"


require('typeface-montserrat');

const ResetPwStepTwoPage = () => {
  
    return (
        <>
        <section className="bg-login-facturama">
            <div className="container">
                <div class="row d-flex justify-content-center">
                    <div class="col-md-auto">
                        <figure class="logo">
                            <picture>
                                {/*<source type="image/webp" srcset="/content/img/facturama-logo.webp"/>
                                <source type="image/png" srcset="/content/img/facturama-logo.png"/>*/}
                                <img src={ `${urlCdnImages}/facturama-freshbooks-logo.png` } alt="Facturación electrónica Facturama" class="img-fluid"/>
                            </picture>
                        </figure>
                    </div>
                </div>				
                <div class="row d-flex justify-content-center mt-1">     
                    <div class="col-md-5">
                        <div class="box pt-5 pb-6 px-5">

                            <figure class="text-center">
                                <img src={ `${urlCdnImages}/resetmail.png` } alt="Reset de mail Facturama" class="img-fluid hh-250"/>
                            </figure>

                            <h1 class="text-center text-blue-primary h3">Listo</h1>
                            <p class="text-center font-weight-semibold">Te hemos enviado un correo a la dirección que registraste. <br/> Sigue las instrucciones para restablecer tu contraseña</p>





                            <div class="text-center">
                                <a  href="https://ecommerce.facturama.mx/account/login" class="font-weight-semibold">
                                    Volver
                                </a>
                            </div>
                        </div>  {/* .box */}

                        <div class="mt-3 text-center small">
                            <a href="tel:4448356098" class="text-gray">444-8356098</a> - <a href="tel:4441510191" class="text-gray">444-1510191</a> | <a class="text-gray" href="mailto:info@facturama.mx">info@facturama.mx</a>
                        </div>
                    </div> { /*.col-md-6 */}
                </div>  {/* col md6 */}            
            </div> {/* container */}
        </section>
        </>
    );    
}


export default ResetPwStepTwoPage;


export const TAX_RATES = [
    {
        index: 0,
        group: "IVA",
        isRetention: false,
        values: [
            { name: "IVA", label: "IVA 16%",            value: 0.160000 },
            { name: "IVA", label: "IVA 8%",             value: 0.080000 },
            { name: "IVA", label: "IVA 0%",             value: 0.000000 },
            { name: "IVA Exento", label: "IVA Exento",  value: 0.000000 },
        ],
    },
    {
        index: 3,
        group: "IEPS",
        isRetention: false,
        values: [
            { name: "IEPS", label: "IEPS 300",      value: 3.000000 },
            { name: "IEPS", label: "IEPS 160",      value: 1.600000 },
            { name: "IEPS", label: "IEPS 53",       value: 0.530000 },
            { name: "IEPS", label: "IEPS 50",       value: 0.500000 },
            { name: "IEPS", label: "IEPS 35",       value: 0.350000 },
            { name: "IEPS", label: "IEPS 30.40%",   value: 0.304000 },
            { name: "IEPS", label: "IEPS 30",       value: 0.300000 },
            { name: "IEPS", label: "IEPS 29.88%",   value: 0.298800 },
            { name: "IEPS", label: "IEPS 26.50%",   value: 0.265000 },
            { name: "IEPS", label: "IEPS 25",       value: 0.250000 },
            { name: "IEPS", label: "IEPS 9",        value: 0.090000 },
            { name: "IEPS", label: "IEPS 8",        value: 0.080000 },
            { name: "IEPS", label: "IEPS 7",        value: 0.070000 },
            { name: "IEPS", label: "IEPS 4",        value: 0.040000 },
            { name: "IEPS", label: "IEPS 5.91%",    value: 0.059100 },
            { name: "IEPS", label: "IEPS 4",        value: 0.040000 },
        ],
    },
    {
        index: 2,
        group: "ISR",
        isRetention: true,
        values: [
            { name: "ISR", label: "ISR 20%",        value: 0.200000 },
            { name: "ISR", label: "ISR 10.666%",    value: 0.106660 },
            { name: "ISR", label: "ISR 10%",        value: 0.100000 },
            { name: "ISR", label: "ISR 5.4%",       value: 0.054000 },
            { name: "ISR", label: "ISR 4%",         value: 0.040000 },
            { name: "ISR", label: "ISR 3%",         value: 0.030000 },
            { name: "ISR", label: "ISR 2%",         value: 0.020000 },
            { name: "ISR", label: "ISR 1.1%",       value: 0.011000 },
            { name: "ISR", label: "ISR 0.9%",       value: 0.009000 },
            { name: "ISR", label: "ISR 0.5%",       value: 0.005000 },
            { name: "ISR", label: "ISR 0.4%",       value: 0.004000 },
            { name: "ISR", label: "ISR 0%",         value: 0.000000 },
        ],
    },
    {
        index: 1,
        group: "IVA RET",
        isRetention: true,
        values: [
            { name: "IVA", label: "IVA Ret 16%",        value: 0.160000 },
            { name: "IVA", label: "IVA Ret 10.6668%",   value: 0.106668 },
            { name: "IVA", label: "IVA Ret 10.6667%",   value: 0.106667 },
            { name: "IVA", label: "IVA Ret 10.6666%",   value: 0.106666 },
            { name: "IVA", label: "IVA Ret 10.67%",     value: 0.106700 },
            { name: "IVA", label: "IVA Ret 10.66%",     value: 0.106600 },
            { name: "IVA", label: "IVA Ret 10.6%",      value: 0.106000 },
            { name: "IVA", label: "IVA Ret 10%",        value: 0.100000 },
            { name: "IVA", label: "IVA Ret 8%",         value: 0.080000 },
            { name: "IVA", label: "IVA Ret 6%",         value: 0.060000 },
            { name: "IVA", label: "IVA Ret 5%",         value: 0.050000 },
            { name: "IVA", label: "IVA Ret 5.3333%",    value: 0.053333 },
            { name: "IVA", label: "IVA Ret 4%",         value: 0.040000 },
            { name: "IVA", label: "IVA Ret 3%",         value: 0.030000 },
            { name: "IVA", label: "IVA Ret 2.5%",       value: 0.025000 },
            { name: "IVA", label: "IVA Ret 2%",         value: 0.020000 },
            { name: "IVA", label: "IVA Ret 0.7%",       value: 0.007000 },
            { name: "IVA", label: "IVA Ret 0.5%",       value: 0.005000 },
            { name: "IVA", label: "IVA Ret 0.2%",       value: 0.002000 },
            { name: "IVA", label: "IVA Ret 0%",         value: 0.000000 },
        ],
    },
];

import React from "react";
// import PropTypes from 'prop-types'
import InputField from "../InputField";
import { Field } from "redux-form";
import {
    isRequired,
    /*minLength,
    maxLength,*/
    mxnPostalCode,
} from "../../validations";
import { FEDERAL_STATES } from "../../constants/federal_states";
/*
const minLength1 = minLength(1);
const maxLength15 = maxLength(15);
const maxLength20 = maxLength(20);
const maxLength100 = maxLength(100);*/
const AddressAux = () => {

    
    const baseNameAux = 'Address'

    return (
        <>
            <div className="form-row">
                <div className={`form-group col-md-4`}>
                    <label>Codigo postal</label>
                    <Field
                        component={InputField}
                        type="text"
                        className="form-control zipcode"
                        name={`${baseNameAux}.ZipCode`}
                        placeholder="Código postal"
                        validate={[mxnPostalCode, isRequired]}
                    />
                </div>
                <div className={`form-group col-md-4`}>
                    <Field
                        label="Estado"
                        component={InputField}
                        type="select"
                        className="form-control"
                        placeholder="Estado"
                        // readOnly                        
                        name={`${baseNameAux}.State`}
                    >
                        {FEDERAL_STATES.map((state) => (
                            <option key={state.Value} value={state.Value}>
                                {state.Name}
                            </option>
                        ))}
                    </Field>
                </div>
                <div className={`form-group col-md-4`}>
                    <Field
                        label="Municipio"
                        component={InputField}
                        type="text"
                        className="form-control"
                        placeholder="Municipio"
                        // readOnly                        
                        name={`${baseNameAux}.Municipality`}
                        validate={[isRequired]}
                    />
                </div>
            </div>
            </>
            );
};
export default AddressAux;
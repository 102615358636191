import React from 'react'
import { useParams } from 'react-router-dom';
import ProductForm from '../../components/ProductForm/ProductForm'
import AppFrame from '../../components/AppFrame'

export const ProductPage = () => {

    let { id } = useParams();
        
    return (
        <AppFrame title="Producto">
            <ProductForm Id={id}/>
        </AppFrame>
    )
}

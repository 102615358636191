// import { render } from '@testing-library/react';
import React from 'react'
// import '../../storeFrontStylesV2.css';

export const FormInvoiceRemote = () => {
    
          
    
    return ( <>
    
        <table>
            <tbody>
                <tr style={{width:"100%"}}><td style={{width:"90%"}}> 
                    <h3 className="fact-title">Factura tu pedido</h3>
                    </td>
                    <td style={{width:"10%"}}>
                        <img className="msgStatus" src="https://app.facturama.mx/Public/img/icons_img/mex1.png" border="0" alt="México" title="Facturación en México" />
                    </td>
                </tr>
            </tbody>
        </table>
        <hr />
        
        <div> 
            <p>Proporciona la siguiente información para generar la Factura con base a tu compra. Esta factura será enviada al correo que proporciones en cuanto se acredite el pago. 
                <br/>
            </p> 
            <p><b>Terminó el tiempo en el que puedes generar tu factura, ponte en contacto con el vendedor.</b></p>
            <br />
            <div id="containerDataClient">    

                <form action="/Shopify/Clients/GetClientStorePartial" id="ClientForm" method="post">
                    <input id="IdClient" name="Id" type="hidden" value="" />    
                    <table>
                    <tbody>
                        <tr>
                            <td style={{width:'40%'}}>
                                <label className="headerClient">Nombre / Razón Social*</label>
                            </td>
                            <td>
                                <input className="inputFact" id="Name" maxLength="200" name="Name" placeholder="Nombre / Razón Social" required="true" type="text" value="" />
                                <span className="field-validation-valid" data-valmsg-for="Name" data-valmsg-replace="true"></span>

                            </td>
                        </tr>
            <tr>
                <td><label className="headerClient">RFC*</label></td>
                <td>
                    <input id="Rfc" className="inputFact" onkeyup="loadCfdiUses(this)" placeholder="RFC" minLength="12" required="" maxLength="13" />
                </td>
            </tr>
            <tr>
                <td><label className="headerClient">Uso de la factura*</label></td>
                <td>
                    <input id="CfdiUse" name="CfdiUse" type="hidden" value="" />
                    <select className="inputFact" id="cfdiUse" required="" style={{width: '100%'}}>
                        <option value="G01">G01 Adquisición de mercancias</option>
                        <option value="G02">G02 Devoluciones, descuentos o bonificaciones</option>
                        <option value="G03">G03 Gastos en general</option>
                        <option value="I01">I01	Construcciones</option>
                        <option value="I02">I02	Mobilario y equipo de oficina por inversiones</option>
                        <option value="I03">I03	Equipo de transporte</option>
                        <option value="I04">I04	Equipo de computo y accesorios</option>
                        <option value="I05">I05	Dados, troqueles, moldes, matrices y herramental</option>
                        <option value="I06">I06	Comunicaciones telefónicas</option>
                        <option value="I07">I07	Comunicaciones satelitales</option>
                        <option value="I08">I08	Otra maquinaria y equipo</option>
                        <option value="D01" style={{display : 'none'}}>D01 Honorarios médicos, dentales y gastos hospitalarios</option>
                        <option value="D02" style={{display : 'none'}}>D02 Gastos médicos por incapacidad o discapacidad</option>
                        <option value="D03" style={{display : 'none'}}>D03 Gastos funerales</option>
                        <option value="D04" style={{display : 'none'}}>D04 Donativos</option>
                        <option value="D05" style={{display : 'none'}}>D05 Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)</option>
                        <option value="D06" style={{display : 'none'}}>D06 Aportaciones voluntarias al SAR</option>
                        <option value="D07" style={{display : 'none'}}>D07 Primas por seguros de gastos médicos</option>
                        <option value="D08" style={{display : 'none'}}>D08 Gastos de transportación escolar obligatoria</option>
                        <option value="D09" style={{display : 'none'}}>D09 Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones</option>
                        <option value="D10" style={{display : 'none'}}>D10 Pagos por servicios educativos (colegiaturas)</option>
                        <option value="S01">S01	Sin efectos fiscales</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td><label>Método de Pago*</label></td>
                <td>
                    <select id="cmbPaymentMethodSho" className="inputFact" style={{width: '100%'}} title="Método de Pago" required="">
                        <option value="">Selecciona una opcion de pago</option>
                                <option value="03">03 Transferencia electrónica de fondos</option>
                                <option value="04">04 Tarjeta de crédito</option>
                                <option value="06">06 Dinero electrónico</option>
                                <option value="28">28 Tarjeta de débito</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td><label>Correo Electrónico*</label></td>
                <td>
                    <input className="inputFact" id="Email" maxLength="80" name="Email" pattern="(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*(;|,)\s*|\s*$))*" placeholder="Correo Electrónico" required="true" type="email" value="norma@facturama.mx" />
                    <span className="field-validation-valid" data-valmsg-for="Email" data-valmsg-replace="true"></span>
                </td>
            </tr>
            <tr>
                <td colspan="2"><hr/></td>
                
            </tr>
            <tr>
                <td><label>Calle</label></td>
                <td>
                    <input className="inputFact" data-val="true" data-val-length="La propiedad Street debe ser por lo menos de 1 caracteres de longitud y menor a 100 caracteres de longitud." data-val-length-max="100" data-val-length-min="1" id="Street" maxLength="100" name="Address.Street" placeholder="Calle" required="true" type="text" value="" />
                    <span className="field-validation-valid" data-valmsg-for="Address.Street" data-valmsg-replace="true"></span>
                </td>
            </tr>
            <tr>
                <td><label>Número Exterior</label></td>
                <td>

                    <input className="inputFact" data-val="true" data-val-length="La propiedad ExteriorNumber debe ser por lo menos de 1 caracteres de longitud y menor a 15 caracteres de longitud." data-val-length-max="15" data-val-length-min="1" id="ExteriorNumber" maxLength="15" name="Address.ExteriorNumber" placeholder="Número Exterior" required="true"  type="text" value="" />
                    <span className="field-validation-valid" data-valmsg-for="Address.ExteriorNumber" data-valmsg-replace="true"></span>
                </td>
            </tr>
            <tr>
                <td><label>Número Interior</label></td>
                <td>

                    <input className="inputFact" data-val="true" 
                    data-val-length="La propiedad InteriorNumber debe ser por lo menos de 1 caracteres de longitud y menor a 20 caracteres de longitud." data-val-length-max="20" data-val-length-min="1" id="InteriorNumber" maxLength="15" name="Address.InteriorNumber" placeholder="Número Interior"
                     type="text" value="" />
                    <span className="field-validation-valid" data-valmsg-for="Address.InteriorNumber" data-valmsg-replace="true"></span>
                </td>
            </tr>
            <tr>
                <td><label>Colonia</label></td>
                <td>

                    <input className="inputFact" data-val="true" data-val-length="La propiedad Neighborhood debe ser por lo menos de 1 caracteres de longitud y menor a 80 caracteres de longitud." data-val-length-max="80" data-val-length-min="1" id="Neighborhood" maxLength="80" name="Address.Neighborhood" placeholder="Colonia" required="true" type="text" value="" />

                    <span className="field-validation-valid" data-valmsg-for="Address.Neighborhood" data-valmsg-replace="true"></span>
                </td>
            </tr>
            <tr>
                <td><label>Código Postal</label></td>
                <td>

                    <input className="inputFact" data-val="true" data-val-length="The ZipCode must be at 0 characters long." data-val-length-max="20" id="Zipcode" maxLength="5" name="Address.ZipCode" placeholder="Código Postal" required="true" type="text" value="78394" />

                    <span className="field-validation-valid" data-valmsg-for="Address.ZipCode" data-valmsg-replace="true"></span>
                </td>
            </tr>
            <tr>
                <td><label>Localidad</label></td>
                <td>

                    <input className="inputFact" data-val="true" data-val-length="La propiedad Locality debe ser por lo menos de 1 caracteres de longitud y menor a 80 caracteres de longitud." data-val-length-max="80" data-val-length-min="1" id="Locality" maxLength="80" name="Address.Locality" placeholder="Localidad" type="text" value="slp" />
                    <span className="field-validation-valid" data-valmsg-for="Address.Locality" data-valmsg-replace="true"></span>
                </td>
            </tr>
            <tr>
                <td><label>Municipio</label></td>
                <td>

                    <input className="inputFact" data-val="true" data-val-length="La propiedad Municipality debe ser por lo menos de 1 caracteres de longitud y menor a 100 caracteres de longitud." data-val-length-max="100" data-val-length-min="1" id="Municipality" maxLength="50" name="Address.Municipality" placeholder="Municipio" required="true" type="text" value="slp" />

                    <span className="field-validation-valid" data-valmsg-for="Address.Municipality" data-valmsg-replace="true"></span>
                </td>
            </tr>
            <tr>
                <td><label>Estado</label></td>
                <td>

                    <input id="State" name="Address.State" type="hidden" value="SAN LUIS POTOSI" />
                    <select id="cmbState" name="State" className="inputFact" style={{width: '100%'}} required={true}>
                        <option value="">Selecciona un estado</option>
                        <option value="" data-code=""></option>
                        <option value="AGUASCALIENTES" data-code="AGS">Aguascalientes</option>
                        <option value="BAJA CALIFORNIA" data-code="BC">Baja California</option>
                        <option value="BAJA CALIFORNIA SUR" data-code="BCS">Baja California Sur</option>
                        <option value="CAMPECHE" data-code="CAMP">Campeche</option>
                        <option value="CHIAPAS" data-code="CHIS">Chiapas</option>
                        <option value="CHIHUAHUA" data-code="CHIH">Chihuahua</option>
                        <option value="COAHUILA" data-code="COAH">Coahuila</option>
                        <option value="COLIMA" data-code="COL">Colima</option>
                        <option value="CIUDAD DE MEXICO">Ciudad de Mexico</option>
                        
                        <option value="DURANGO" data-code="DGO">Durango</option>
                        <option value="GUANAJUATO" data-code="GTO">Guanajuato</option>
                        <option value="GUERRERO" data-code="GRO">Guerrero</option>
                        <option value="HIDALGO" data-code="HGO">Hidalgo</option>
                        <option value="JALISCO" data-code="JAL">Jalisco</option>
                        <option value="ESTADO DE MEXICO" data-code="MEX">Estado de México</option>
                        <option value="MICHOACAN DE OCAMPO" data-code="MICH">Michoacán</option>
                        <option value="MORELOS" data-code="MOR">Morelos</option>
                        <option value="NAYARIT" data-code="NAY">Nayarit</option>
                        <option value="NUEVO LEON" data-code="NL">Nuevo León</option>
                        <option value="OAXACA" data-code="OAx">Oaxaca</option>
                        <option value="PUEBLA" data-code="PUE">Puebla</option>
                        <option value="QUERETARO" data-code="QRO">Querétaro</option>
                        <option value="QUINTANA ROO" data-code="Q ROO">Quintana Roo</option>
                        <option value="SAN LUIS POTOSI" data-code="SLP">San Luis Potosí</option>
                        <option value="SINALOA" data-code="SIN">Sinaloa</option>
                        <option value="SONORA" data-code="SON">Sonora</option>
                        <option value="TABASCO" data-code="TAB">Tabasco</option>
                        <option value="TAMAULIPAS" data-code="TAMPS">Tamaulipas</option>
                        <option value="TLAXCALA" data-code="TLAX">Tlaxcala</option>
                        <option value="VERACRUZ DE IGNACIO DE LA LLAVE" data-code="VER">Veracruz</option>
                        <option value="YUCATAN" data-code="YUC">Yucatán</option>
                        <option value="ZACATECAS" data-code="ZAC">Zacatecas</option>
                    </select>
                </td>
            </tr>

            


        </tbody>
    </table>
    <div id="errors" className="error">
    </div>
    <br />
    <div className="btn btn-primary" style={{padding : '6px 12px'}}>Generar Factura</div>
</form>

            </div>
        </div>

    </>
    )
};




    

export default FormInvoiceRemote;
import React from "react";
import InputField from "../InputField";
import { Field } from "redux-form";
import {    
    email,
    isRequired,
    maxLength

} from "../../validations";

import '../../index.css';

const length10 = maxLength(10);
const maxLength100 = maxLength(100);
const ContactUs = ({mailNeeded, title}) => {
    return (
        <>	           
            <p className="h3 text-blue-primary text-center">{title}</p>

            <div className="form-group">
                <label  >Nombre:</label>
                <Field                        
                    type="text"
                    className="form-control"
                    name="Name"
                    placeholder="Máximo 100 caracteres"
                    component={InputField}     
                    validate={[isRequired, maxLength100]}                        
                />                                        
            </div>

            {mailNeeded === 'true' ?
            <div className="form-group">
                <label  >Correo:</label>
                <Field                        
                    type="email"                    
                    className="form-control"
                    name="Email"
                    placeholder="correo@ejemplo.com"
                    component={InputField}
                    validate={[isRequired, email]}
                />                                        
            </div>
            : null }
            <div className="form-group">       
                <label>Celular:</label>         
                <Field
                    type="number"
                    className="form-control"
                    name="Phone"
                    placeholder=""
                    component={InputField}
                    validate={[length10]}
                    
                />
            </div>

            <div className="form-group">                
                <label>Mensaje:</label>
                <Field
                    type="textarea"
                    className="form-control"    
                    name="Message"                
                    placeholder=""
                    component={InputField}
                    validate={[isRequired]}                    
                />
            </div>

        </>

        
    );
};

export default ContactUs;
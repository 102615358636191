import React, { useEffect } from "react";
import config from "./config.json";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";

import { WelcomePage } from "./pages/WelcomePage";

import { NotFoundPage } from "./pages/NotFoundPage";
import AccountSettingsPage from "./pages/AccountSettings";

import TaxSettingsPage from "./pages/TaxSettings";
import TaxProfilePage from "./pages/TaxProfilePage";
import LoginPage from "./pages/account/LoginPage";
import RegisterPage from "./pages/account/RegisterPage";
import ResetPasswordPage from "./pages/account/ResetPasswordPage";
import RecoveryPasswordPage from "./pages/account/RecoveryPasswordPage";
import ResetPwStepTwoPage from "./pages/account/ResetPwStepTwoPage";

import ThanksRegisterPage from "./pages/account/ThanksRegisterPage";
import CatalogsPage from "./pages/CatalogsPage/CatalogsPage";
import ListInvoicePage from "./pages/Invoices/ListInvoicePage";
import { CustomerPage } from "./pages/CatalogsPage/CustomerPage";
import { ProductPage } from "./pages/CatalogsPage/ProductPage";
import WizardPage from "./pages/account/WizardPage";
import SettingsReports from "./pages/SettingsReports";
import InvoicePageContainer from "./containers/InvoicePageContainer";
import InvoicesGlobalContainer from "./containers/InvoicesGlobalContainer";
import { BranchPage } from "./pages/CatalogsPage/BranchPage";
import SetTokenPage from "./pages/account/SetTokenPage";
import ShoppingPage from "./pages/ShoppingPage";
import PaymentCheckoutPage from "./pages/PaymentCheckoutPage";
import PaymentReceiptPage from "./pages/PaymentReceiptPage";
import PaymentAcusePage from "./pages/PaymentAcusePage";
import FormInvoiceRemote from "./pages/Invoices/FormInvoiceRemote";
import ContactUs from "./pages/ContactUs/ContactUs";
import { getEcommerceAccess } from "./actions/account";
import { useSelector, useDispatch  } from "react-redux";
import PaymentPage from "./pages/account/PaymentPage";
import EditCardPage from "./pages/SubscriptionPage/EditCardPage";
import EditBillingPage from "./pages/SubscriptionPage/EditBillingPage";
import PlansPage from "./pages/SubscriptionPage/PlansPage";
import PrivateRoute from "./helpers/privateRoute";
import SubscribedRoute from "./helpers/subscribedRoute";
import HelpCsdForm from "./pages/ContactUs/HelpCsdForm";




const redirectToWizard = (steps) => {
	let r = true;
	if (
		steps !== undefined 
		&& steps.success === false 
		&& steps.IsBranch === true 
		&& steps.IsCertif === true 
		&& steps.IsTaxInfo === true 
		
	) {
		r = false;
	} else if (steps !== undefined && steps.success === true) {
		r = false;
	}else if(steps !== undefined && steps.length === 0){
		r = false;
	}
	return r;
};

function App() {
	const ecommerceType = useSelector((store) => store.getEcommerceAccess);
	const steps = useSelector((store) => store.steps);
	
	const dispatch = useDispatch();

	
	useEffect(() => {
		dispatch(getEcommerceAccess());
	
	}, [dispatch]); 

	

	return (
		<div>
			
			<Router>
			{redirectToWizard(steps) ? <Redirect to={{ pathname: "/wizard" }} /> : ""}
			{
				<Switch>
			
					<PrivateRoute exact path="/" component={() => <WelcomePage ecommerceType={ecommerceType} />} />
					<PrivateRoute 		path="/settings/profile/:tab?"		component={TaxProfilePage} />
					<PrivateRoute 		path="/settings/account" 			component={AccountSettingsPage} />
					<PrivateRoute 		path="/customers/:id/edit" 			component={CustomerPage} />
					<PrivateRoute 		path="/products/:id/edit" 			component={ProductPage} />
					<PrivateRoute 		path="/branches/:id/edit" 			component={BranchPage} />						
					<PrivateRoute 		path="/wizard" 						component={WizardPage} />
					<PrivateRoute 		path="/help-csd" 					component={HelpCsdForm} />
					<PrivateRoute 		path="/shopping/:tab?" 				component={ShoppingPage}/>
					<PrivateRoute 		path="/payment" 					component={PaymentPage}/>
					<PrivateRoute 		path="/suscription/card" 			component={EditCardPage}/>
					<PrivateRoute 		path="/suscription/billing-data"	component={EditBillingPage}/>
					<PrivateRoute 		path="/suscription/plans" 			component={PlansPage}/>
					<PrivateRoute 		path="/payment-checkout/:id"		component={PaymentCheckoutPage}/>
					<PrivateRoute 		path="/payment-acuse/:id"			component={PaymentAcusePage}/>
					<PrivateRoute 		path="/payment-receipt/:id"			component={PaymentReceiptPage}/>
					<PrivateRoute 		path="/catalogs/list/:tab?" 		component={CatalogsPage} />
					<PrivateRoute 		path="/settings/taxes/:tab?"		component={TaxSettingsPage} />					
					<PrivateRoute 		path="/invoices/list/:tab?" 		component={ListInvoicePage} />
					<PrivateRoute	 	path="/settings/reports" 			component={SettingsReports} /*plan={PREMIUM}*/ />

				
					<SubscribedRoute 	path="/invoices/new/:id" 			component={InvoicePageContainer} />					
				
					<Route 				path="/invoices/global/new/:ids" 	component={InvoicesGlobalContainer} />
					<Route 				path="/account/login/:shopName?" 	component={LoginPage} />
					<Route 				path="/account/register/:shopName?" component={RegisterPage} />
					<Route 				path="/account/register/reset" 		component={RegisterPage} />
					<Route 				path="/account/ResetPassword" 		component={ResetPasswordPage} />
					<Route 				path="/account/RecoveryPassword:t?" component={RecoveryPasswordPage} />
					<Route 				path="/account/ResetPwStepTwo" 		component={ResetPwStepTwoPage} />
					<Route 				path="/account/thanks" 				component={ThanksRegisterPage} />
					<Route 				path="/set-token/:shopName?/:token?"component={SetTokenPage} />
					<Route 				path="/billing-information" 		component={FormInvoiceRemote} />
					<Route 				path="/contact-us" 					component={ContactUs} />
				
					<Route
						path="/web3/createInvoice"
						component={() => {
							config.API_URL === "https://api.facturama.mx"
								? (window.location.href =
										"https://app.facturama.mx/Invoices/Invoices")
								: config.API_URL === "https://apisandbox.facturama.mx"
								? (window.location.href =
										"https://dev.facturama.mx/Invoices/Invoices")
								: (window.location.href =
										"https://dev.facturama.mx/Invoices/Invoices");
							return null;
						}}
					/>

					<Route>
						<NotFoundPage></NotFoundPage>
					</Route>
				</Switch>
			}
		</Router>
		</div>
		
	);
}

export default App;

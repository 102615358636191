export const UNITS_CATALOG = [
    {
        "Name": "Pieza",
        "Value": "H87"
    },
    {
        "Name": "Elemento",
        "Value": "EA"
    },
    {
        "Name": "Unidad de Servicio",
        "Value": "E48"
    },
    {
        "Name": "Actividad",
        "Value": "ACT"
    },
    {
        "Name": "Kilogramo",
        "Value": "KGM"
    },
    {
        "Name": "Trabajo",
        "Value": "E51"
    },
    {
        "Name": "Tarifa",
        "Value": "A9"
    },
    {
        "Name": "Metro",
        "Value": "MTR"
    },
    {
        "Name": "Paquete a granel",
        "Value": "AB"
    },

    {
        "Name": "Caja base",
        "Value": "BB"
    },
    {
        "Name": "Kit",
        "Value": "KT"
    },
    {
        "Name": "Conjunto",
        "Value": "SET"
    },
    {
        "Name": "Litro",
        "Value": "LTR"
    },
    {
        "Name": "Caja",
        "Value": "XBX"
    },
    {
        "Name": "Mes",
        "Value": "MON"
    },
    {
        "Name": "Hora",
        "Value": "HUR"
    },
    {
        "Name": "Metro cuadrado",
        "Value": "MTK"
    },
    {
        "Name": "Equipos",
        "Value": "11"
    },
    {
        "Name": "Miligramo",
        "Value": "MGM"
    },
    {
        "Name": "Paquete",
        "Value": "XPK"
    },
    {
        "Name": "XKI",
        "Value": "Kit (Conjunto de piezas)"
    },
    {
        "Name": "AS",
        "Value": "Variedad"
    },
    {
        "Name": "Gramo",
        "Value": "GRM"
    },
    {
        "Name": "Par",
        "Value": "PR"
    },
    {
        "Name": "Docenas de piezas",
        "Value": "DPC"
    },
    {
        "Name": "Unidad",
        "Value": "xun"
    },
    {
        "Name": "Día",
        "Value": "DAY"
    },
    {
        "Name": "Lote",
        "Value": "XLT"
    },
    {
        "Name": "Grupos",
        "Value": "10"
    },
    {
        "Name": "Mililitro",
        "Value": "MLT"
    },
    {
        "Name": "Viaje",
        "Value": "E54"
    },
];

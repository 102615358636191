import config from "../config.json";

export const urlBase = config.API_URL;

export const urlProductCodes = `${urlBase}/catalogs/productsorservices`;
export const urlPostalCodes = `${urlBase}/catalogs/postalcodes`;
export const urlUnits = `${urlBase}/catalogs/units`;
export const urlCurrencies = `${urlBase}/catalogs/currencies`;
export const urlCountries = `${urlBase}/catalogs/countries`;
export const urlPaymentMethods = `${urlBase}/catalogs/paymentmethods`;
export const urlFederalTaxes = `${urlBase}/catalogs/federaltaxes`;
export const urlCfdiTypes = `${urlBase}/catalogs/cfditypes`;
export const urlFiscalRegimens = `${urlBase}/catalogs/fiscalregimens`;
export const urlCfdiUses = `${urlBase}/catalogs/cfdiuses`;
export const urlRelationTypes = `${urlBase}/catalogs/relationtypes`;
export const urlNeighborhoods = `${urlBase}/catalogs/neighborhoods`;
export const urlMunicipalities = `${urlBase}/catalogs/municipalities`;
export const urlFederalStates = `${urlBase}/catalogs/states`;
export const urlBranchOffices = `${urlBase}/branchoffice`;

export const urlProducts = `${urlBase}/product`;
export const urlCustomers = `${urlBase}/client`;
export const urlCustomersSearch = `${urlBase}/api/clients`;
export const urlProfile = `${urlBase}/taxentity`;
export const urlProfileLogo = `${urlBase}/taxEntity/uploadlogo`;
export const urlProfileCsd = `${urlBase}/taxEntity/uploadcsd`;
export const urlProfileFiel = `${urlBase}/taxEntity/uploadfiel`;

export const urlInvoice = `${urlBase}/api/ecommerce/cfdi40`;
export const urlInvoiceGlobal = `${urlBase}/api/ecommerce/cfdi/global40`;
export const urlOrders = `${urlBase}/api/Ecommerce/orders`;
export const urlProductsEcommerce = `${urlBase}/api/Ecommerce/products/start_download`;
export const urlEcommerceProductsImport = `${urlBase}/api/Ecommerce/products/import`;
export const urlUploadProductsEcommerce = `${urlBase}/api/Ecommerce/products/upload`;
export const urlMassiveProcess = `${urlBase}/api/Ecommerce/products/massive_process`;
export const urlDownloadProductsCsv = `${urlBase}/api/Ecommerce/products/download_csv`;
//export const urlSettingsGeneral = `${urlBase}/api/ecommerce-taxprofile/SettingsGeneral`;
export const urlSettings = `${urlBase}/api/ecommerce-taxprofile`;
export const urlCheckSteps = `${urlBase}/api/ecommerce-taxprofile/CheckSteps`;
export const urlProductsOrServices = `${urlBase}/api/catalogs/ProductsOrServices`;
export const urlCfdi = `${urlBase}/api/cfdi`;
export const urlBranchOfficesSerie = `${urlBase}/api/serie`;

export const urlProcessBatch = `${urlBase}/api/Ecommerce/orders/generate_report`;

export const urlRecoveryAccount = `${urlBase}/api/ecommerce-session/RecoveryPassword`;
export const urlResetAccount = `${urlBase}/api/ecommerce-session/ResetPassword`;
export const urlLoginAccount = `${urlBase}/api/ecommerce-session/login`;
export const urlRegisterAccount = `${urlBase}/api/ecommerce-session/register`;

export const urlFacturamaProducts = `${urlBase}/api/store/products`;
export const urlFacturamaCharges = `${urlBase}/charges`;
export const urlFacturamaDocuments = `${urlBase}/api/documents`;

export const urlSubscriptions = `${urlBase}/subscription`;
export const urlSuscription = `${urlBase}/SuscriptionPlan`;
export const urlGetFolio = `${urlBase}/api/TaxEntity/GetFolio`;
export const urlCards = `${urlBase}/cards`;
export const urlContactUsMail = `${urlBase}/api/mails/contact/send`;
export const urlBillingData = `${urlBase}/taxentity/billing-data`;

export const urlCdnImages = `https://cdnfacturama.azureedge.net/content/img`;

import { handleActions } from "redux-actions";
import { FETCH_INVOICE, FETCH_INVOICE_GLOBAL, INVOICE} from "../constants";

export const invoice = handleActions(
	{
		[FETCH_INVOICE]: (state, action) => {
			return { ...state, invoice: action.payload };
		},
		[FETCH_INVOICE_GLOBAL]: (state, action) => {
			return { ...state, invoice: action.payload };
		},
		[INVOICE]: (state, action) => {
			return {
				...state,
				invoice: action.payload,
			};
		},
		
	},
	[]
);

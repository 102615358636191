import React from "react";
import "@fortawesome/fontawesome-free/css/all.css";
import { CfdiUsageSelect } from "../../components/TaxSettings/TaxSettingsControls";
import {
	saveSetting,
	fetchSettings,
	ImportProducts,
} from "../../actions/configure_tax";
import { connect } from "react-redux";
import {
	getGeneralSettings,
} from "../../selectors/configure_tax";
import PropTypes from "prop-types";
import Bootbox, { BootBoxAlert } from "../../components/General/bootbox";
import Modal from "react-bootstrap/Modal";
import Loader from "../../components/General/loader";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

class TaxGeneralSettings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showConfirm: false,
			showAlert: false,
			modalName: "",
			messageAlert: "",
			loading: false,
			modalShow: false,
			email: "",
			emailError: "",
			SettingData: {
				ShopName: this.props.resultSettings.ShopName ?? "",
				UnitGeneral: this.props.resultSettings.UnitGeneral ?? "",
				ShippingUnit: this.props.resultSettings.ShippingUnit ?? "",
				TaxesInclude: this.props.resultSettings.TaxesInclude ?? "",
				MultipleIva: this.props.resultSettings.MultipleIva ?? "",
				IvaGeneral: this.props.resultSettings.IvaGeneral ?? "",
				UseIeps: this.props.resultSettings.UseIeps ?? "",
				MultipleIeps: this.props.resultSettings.MultipleIeps ?? "",
				IepsGeneral: this.props.resultSettings.IepsGeneral ?? "",
				ShippingTaxesInclude:
					this.props.resultSettings.ShippingTaxesInclude ?? "",
				UseMetafields: this.props.resultSettings.UseMetafields ?? "",
				DiscountTaxesInclude:
					this.props.resultSettings.DiscountTaxesInclude ?? "",
				CodeUnitGeneral: this.props.resultSettings.CodeUnitGeneral ?? "E48",
				CodeUnitShipping: this.props.resultSettings.CodeUnitShipping ?? "SX",
				CodeProdServ: this.props.resultSettings.CodeProdServ ?? "78102200",
				CodeProdServShipping:
					this.props.resultSettings.CodeProdServShipping ?? "78102200",
				CfdiUseGeneral: this.props.resultSettings.CfdiUseGeneral ?? "",
				UseIepsForAll: this.props.resultSettings.UseIepsForAll ?? "",
				ActiveIvaInStore: this.props.resultSettings.ActiveIvaInStore ?? "",
				PedimentRequired: this.props.resultSettings.PedimentRequired ?? "",
				TaxObject: this.props.resultSettings.TaxObject ?? "01",
				AutoInvoicingVersion: '4.0',
			},
		};
	}

	

	handleInputChange = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		let type = event.target.type;

		if (value === " ") return;
		if (value === "true") value = true;
		if (value === "false") value = false;

		this.setState((prevState) => ({
			SettingData: {
				...prevState.SettingData,
				[name]: type !== "radio" ? value.toUpperCase() : value,
			},
		}));
	};

	handleOnPaste = (event) => {
		event.preventDefault();
		let name = event.target.name;
		var text = event?.clipboardData?.getData("Text");
		this.setState({
			SettingData: {
				...this.state.SettingData,
				[name]: text?.replace(/\D/g, "")?.split(" ")?.join(""),
			},
		});
	};

	handleValidation() {
		let msg = "";
		msg +=
			this.state.SettingData.CodeProdServ !== ""
				? ""
				: " Codigo del producto Requerido, ";
		msg +=
			this.state.SettingData.CodeUnitGeneral !== ""
				? ""
				: "Codigo de la unidad Requerido, ";
		msg +=
			this.state.SettingData.UnitGeneral !== ""
				? ""
				: "Nombre de la unidad Requerido, ";
		msg +=
			this.state.SettingData.CodeProdServShipping !== ""
				? ""
				: "Clave para cargo por envio Requerido, ";
		msg +=
			this.state.SettingData.CodeUnitShipping !== ""
				? ""
				: " Codigo del producto para cargo por envío Requerido, ";
		
		msg +=
			this.state.SettingData.ShippingUnit !== ""
				? ""
				: " Nombre de la unidad para cargo por envío Requerido, ";
		if (msg !== "") {
			this.Alert(msg);
		}

		return msg === "";
	}
	Alert = (msg) => {
		this.setState({ showAlert: true });
		this.setState({ messageAlert: msg });
	};
	handleEmailChange = (e) => {
		this.Emailvalidation(e.target.value);
		this.setState({ email: e.target.value });
	};
	Emailvalidation = (value) => {
		let error = "";
		var pattern = new RegExp(
			/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
		);

		if (!value) {
			error = "El correo es requerido.";
		} else if (!pattern.test(value)) {
			error = "Ingresa una dirección de correo valida.";
		}
		this.setState({ emailError: error });
		if (error === "") return true;
		else return false;
	};
	handleSumit = (p) => {
		let dataToSave = {
			...this.state.SettingData
		};
		
		if (this.handleValidation()) {
			this.setState({ loading: true });
			dataToSave.AutoInvoicingVersion = '4.0';
			this.props
				.saveSetting(dataToSave)
				.then((response) => {
					this.setState({ modalName: "" });
					if (response.payload.hasOwnProperty("_error")) {
						this.Alert(response.payload._error);
					} else if (response.payload.hasOwnProperty("Message")) {
						this.Alert(response.payload.Message);
					} else {
						this.setState({ showConfirm: true });
						this.setState({ modalName: "initImportProduct" });
					}
				})
				.catch((r) => {
					this.Alert("Error");
				})
				.finally(() => {
					this.setState({ loading: false });
				});
		}
	};
	componentDidUpdate(oldProps) {
		if (oldProps.resultSettings !== this.props.resultSettings) {
			this.setState({
				SettingData: {
					ShopName: this.props.resultSettings.ShopName,
					UnitGeneral: this.props.resultSettings.UnitGeneral,
					ShippingUnit: this.props.resultSettings.ShippingUnit,
					TaxesInclude: this.props.resultSettings.TaxesInclude,
					MultipleIva: this.props.resultSettings.MultipleIva,
					IvaGeneral: this.props.resultSettings.IvaGeneral,
					UseIeps: this.props.resultSettings.UseIeps,
					MultipleIeps: this.props.resultSettings.MultipleIeps,
					IepsGeneral: this.props.resultSettings.IepsGeneral,
					ShippingTaxesInclude: this.props.resultSettings.ShippingTaxesInclude,
					UseMetafields: this.props.resultSettings.UseMetafields,
					DiscountTaxesInclude: this.props.resultSettings.DiscountTaxesInclude,
					CodeUnitGeneral: this.props.resultSettings.CodeUnitGeneral ?? "",
					CodeUnitShipping: this.props.resultSettings.CodeUnitShipping ?? "",
					CodeProdServ: this.props.resultSettings.CodeProdServ ?? "",
					CodeProdServShipping:
						this.props.resultSettings.CodeProdServShipping ?? "",
					CfdiUseGeneral: this.props.resultSettings.CfdiUseGeneral ?? "",
					UseIepsForAll: this.props.resultSettings.UseIepsForAll,
					ActiveIvaInStore: this.props.resultSettings.ActiveIvaInStore,
					PedimentRequired: this.props.resultSettings.PedimentRequired,
					TaxObject: this.props.resultSettings.TaxObject ?? "01",
					AutoInvoicingVersion: '4.0',
				},
			});			
		}
	}
	componentDidMount() {
		this.setState({ loading: true });
		this.props
			.fetchSettings()
			.then((response) => {
				if (response.payload.hasOwnProperty("_error")) {
					this.Alert(response.payload._error);
				} else if (response.payload.hasOwnProperty("Message")) {
					this.Alert(response.payload.Message);
				} 
				this.setState({ loading: false });
			})
			.catch((r) => {
				this.setState({ loading: false });
				this.Alert("error");
			});
	}
	handleCloseAlert = () => {
		this.setState({ showAlert: false });
	};
	handleCloseConfirm = () => {
		if (this.Emailvalidation(this.state.email)) {
			this.setState({ modalName: "" });
			this.setState({ showConfirm: false });
			if (this.state.modalName === "initImportProduct") {
				this.importProducts(this.state.email);
			}
		}
	};
	importProducts = (email) => {
		this.setState({ modalShow: false });
		this.setState({ loading: true });
		this.props
			.ImportProducts(email)
			.then((response) => {
				if (response.payload.hasOwnProperty("_error")) {
					this.Alert(response.payload._error);
				} else if (response.payload.hasOwnProperty("Message")) {
					this.Alert(response.payload.Message);
				} else {
				}

				this.setState({ loading: false });
			})
			.catch((r) => {
				this.setState({ loading: false });
				this.Alert("error");
			});
	};

	render() {
		const symbolsArr = ["e", "E", "+", "-", "."];
		return (
			<>
				<br />
				<div className="row">
					{/* info : inicio */}
					<div className="col-md-3">
						<p>
							En este apartado deberás indicar que impuestos manejas en tus
							productos, así como indicar si el precio de estos ya incluyen los
							impuestos o no.
							<br />
							<br />
						</p>
					</div>
					{/* info : fin */}

					{/* questions-container : inicio */}
					<div className="col-md-9 questions-container">
						<Loader show={this.state.loading} />

						<div className="form-group" >
							<label className="text-blue font-weight-semibold">
								Versión de Facturación
							</label>
							<div className="row">
								<div className="col-12">
									<label>
										<i className="fas fa-chevron-right text-blue"></i> ¿Que 
										 <b> versión de Facturación</b> deseas usar?
									</label>
								</div>
								<div className="col-12">
									<ul className="ml-4">
										<li>
											<label htmlFor="AutoInvoicingVersion33" className="">
												<input
													type="radio"
													disabled={true}
													id="AutoInvoicingVersion33"
													name="AutoInvoicingVersion"
													defaultValue={'3.3'}
													checked={false}
													onChange={this.handleInputChange}
												/>{" "}
												3.3
											</label>
										</li>
										<li>
											<label htmlFor="AutoInvoicingVersion40" className="">
												<input
													type="radio"
													id="AutoInvoicingVersion40"
													name="AutoInvoicingVersion"
													defaultValue={'4.0'}
													readOnly={true}
													checked={true}
												/>{" "}
												4.0
											</label>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<hr />


						<div className="form-group">
							<label className="text-blue font-weight-semibold">
								Uso del Cfdi
							</label>
							<p>
								<i className="fas fa-chevron-right text-blue"></i> Selecciona la
								opción para el <b>USO DEL CFDI</b> para los clientes que aun no
								lo tengan definido.
							</p>
							<CfdiUsageSelect
								defaultValue={this.state.SettingData.CfdiUseGeneral}
								handleChange={this.handleInputChange}
							/>
						</div>
						<hr />
						<div className="form-group">
							<label className="text-blue font-weight-semibold">
								Asocia tu producto al CATALOGO del SAT
							</label>
							<div className="row">
								<div className="col-xs-12 col-md-8">
									<label>
										<i className="fas fa-chevron-right text-blue"></i> Busca tu
										CLAVE, escríbela en el campo de abajo, espera opciones y
										selecciona una (8 caracteres)
									</label>
								</div>
								<div className="col-xs-12 col-md-4">
									<a
										className="btn btn-outline-primary btn-block"
										target="_blank"
										rel="noopener noreferrer"
										href="http://200.57.3.46:443/PyS/catPyS.aspx"
									>
										Buscar Clave
									</a>
								</div>
							</div>
						</div>
						<br />

						<div className="form-group">
							<div className="row">
								<div className="col-xs-12 col-md-12">
									<label>
										<i className="fas fa-chevron-right text-blue"></i> Escriba
										la CLAVE para todos o la mayoria de tus <b>productos</b>.
									</label>
								</div>
								<div className="col-xs-12 col-md-6">
									<input
										onChange={this.handleInputChange}
										onPaste={this.handleOnPaste}
										type="text"
										className="form-control tt-input"
										name="CodeProdServ"
										required="required"
										placeholder="Clave de Producto ó Servicio"
										maxLength="8"
										value={this.state.SettingData.CodeProdServ}
									/>
								</div>
							</div>
						</div>

						<div className="form-group mt-4">
							<div className="row">
								<div className="col-xs-12 col-md-12">
									<label>
										<i className="fas fa-chevron-right text-blue"></i> Escriba
										la CLAVE para el cargo por envio. (se debe de especificar
										aunque no se use)
									</label>
								</div>
								<div className="col-xs-12 col-md-6">
									<input
										onChange={this.handleInputChange}
										onPaste={this.handleOnPaste}
										type="text"
										className="form-control tt-input"
										name="CodeProdServShipping"
										required="required"
										placeholder="Clave de Producto ó Servicio"
										maxLength="8"
										value={this.state.SettingData.CodeProdServShipping}
									/>
								</div>
							</div>
						</div>

						<hr />

						<div className="form-group">
							<label className="text-blue font-weight-semibold">
								Unidades de medida
							</label>
							<div className="row">
								<div className="col-xs-12 col-md-8">
									<label>
										<i className="fas fa-chevron-right text-blue"></i> Busca tu
										UNIDAD, escríbela en el campode abajo, espera opciones y
										selecciona una (De 2 o 3 caracteres)
									</label>
								</div>
								<div className="col-xs-12 col-md-4">
									<a
										className="btn btn-outline-primary btn-block"
										target="_blank"
										rel="noopener noreferrer"
										href="http://200.57.3.46:443/PyS/catUnidades.aspx"
									>
										Buscar Unidad
									</a>
								</div>
							</div>
						</div>

						<div className="form-group mt-4">
							<div className="row">
								<div className="col-xs-12 col-md-12">
									<label>
										<i className="fas fa-chevron-right text-blue"></i> Escriba
										la <b>unidad de medida</b> para todos o la mayoria de tus{" "}
										<b>productos</b>.
									</label>
								</div>
							</div>
							<div className="row">
								<div className="col-xs-4 col-md-4">
									<label style={{ fontSize: "13px" }}>
										{" "}
										Clave de la unidad.
									</label>
									<input
										onChange={this.handleInputChange}
										type="text"
										className="form-control tt-input"
										name="CodeUnitGeneral"
										required="required"
										placeholder="Clave de Producto ó Servicio"
										maxLength="3"
										value={this.state.SettingData.CodeUnitGeneral}
									/>
								</div>
								<div className="col-xs-8 col-md-8">
									<label style={{ fontSize: "13px" }}>
										Nombre de la unidad(max. 20 caracteres)
									</label>
									<input
										onChange={this.handleInputChange}
										type="text"
										className="form-control tt-input"
										name="UnitGeneral"
										required="required"
										placeholder="Nombre"
										maxLength="20"
										value={this.state.SettingData.UnitGeneral}
									/>
								</div>
							</div>
						</div>

						<div className="form-group mt-4">
							<div className="row">
								<div className="col-xs-12 col-md-12">
									<label>
										<i className="fas fa-chevron-right text-blue"></i> Escriba
										la <b>unidad de medida</b> para el <b>cargo por envío</b>.
										(Se debe especificar aunque no se use)
									</label>
								</div>
							</div>
							<div className="row">
								<div className="col-xs-4 col-md-4">
									<label style={{ fontSize: "13px" }}>
										{" "}
										Clave de la unidad para el cargo por envio.
									</label>
									<input
										onChange={this.handleInputChange}
										onPaste={this.handleOnPaste}
										type="text"
										className="form-control tt-input"
										name="CodeUnitShipping"
										required="required"
										placeholder="Clave de Producto ó Servicio envio"
										maxLength="3"
										value={this.state.SettingData.CodeUnitShipping}
									/>
								</div>
								<div className="col-xs-8 col-md-8">
									<label style={{ fontSize: "13px", marginTop: "17px" }}>
										Nombre de la unidad para el cargo por envio(max. 20
										caracteres)
									</label>
									<input
										onChange={this.handleInputChange}
										type="text"
										className="form-control tt-input"
										name="ShippingUnit"
										required="required"
										placeholder="Nombre de Producto ó Servicio envio"
										maxLength="20"
										value={this.state.SettingData.ShippingUnit}
									/>
								</div>
							</div>
						</div>

						<hr />

						<div className="form-group">
							<div className="row">
								<div className="col-12">
									<label>
										<i className="fas fa-chevron-right text-blue"></i> ¿Los
										precios de tus productos <b>ya incluyen impuestos</b>?
									</label>
								</div>
								<div className="col-12" id="id_del_elemento">
									{
										<ul className="ml-4">
											<li>
												<label htmlFor="TaxesIncludeYes" className="">
													<input
														type="radio"
														id="TaxesIncludeYes"
														name="TaxesInclude"
														value={true}
														checked={
															this.state.SettingData.TaxesInclude === true
														}
														onChange={this.handleInputChange}
													/>{" "}
													SI, (ej. $100 = $86.20 + $13.80)
												</label>
											</li>
											<li>
												<label htmlFor="TaxesIncludeNot" className="">
													<input
														type="radio"
														id="TaxesIncludeNot"
														name="TaxesInclude"
														value={false}
														checked={
															this.state.SettingData.TaxesInclude === false
														}
														onChange={this.handleInputChange}
													/>{" "}
													NO, (ej. $100 = $100 + $16)
												</label>
											</li>
										</ul>
									}
								</div>
							</div>
						</div>

						<hr />

						<div className="form-group">
							<label className="text-blue font-weight-semibold">IVA</label>
							<div className="row">
								<div className="col-12">
									<label>
										<i className="fas fa-chevron-right text-blue"></i> ¿Manejas
										productos con <b>diferentes tasas de IVA</b>?
									</label>
								</div>
								<div className="col-12">
									<ul className="ml-4">
										<li>
											<label htmlFor="multipleIvaYes" className="">
												<input
													type="radio"
													id="multipleIvaYes"
													name="MultipleIva"
													defaultValue={true}
													checked={this.state.SettingData.MultipleIva === true}
													onChange={this.handleInputChange}
												/>{" "}
												SI
											</label>
										</li>
										<li>
											<label htmlFor="multipleIvaNot" className="">
												<input
													type="radio"
													id="multipleIvaNot"
													name="MultipleIva"
													defaultValue={false}
													checked={this.state.SettingData.MultipleIva === false}
													onChange={this.handleInputChange}
												/>{" "}
												NO, <b>todos</b> los productos estan gravados{" "}
												<b>a una sola tasa de IVA</b>
											</label>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className="form-group">
							<div className="row">
								<div className="col-12">
									<label>
										<i className="fas fa-chevron-right text-blue"></i>{" "}
										Selecciona la <b>tasa de IVA</b> que manejan{" "}
										<b>todos ó la mayoría </b>de tus productos.
									</label>
								</div>
								<div className="col-12">
									<ul className="ml-4">
										<li>
											<label htmlFor="ivaGeneral16">
												<input
													type="radio"
													id="ivaGeneral16"
													name="IvaGeneral"
													defaultValue="16"
													checked={
														this.state.SettingData.IvaGeneral === "16"
															? true
															: false
													}
													onChange={this.handleInputChange}
												/>{" "}
												16%
											</label>
										</li>
										<li>
											<label htmlFor="ivaGeneral8">
												<input
													type="radio"
													id="ivaGeneral8"
													name="IvaGeneral"
													defaultValue="8"
													checked={
														this.state.SettingData.IvaGeneral === "8"
															? true
															: false
													}
													onChange={this.handleInputChange}
												/>{" "}
												8%
											</label>
										</li>
										<li>
											<label htmlFor="ivaGeneral0">
												<input
													type="radio"
													id="ivaGeneral0"
													name="IvaGeneral"
													defaultValue="0"
													checked={
														this.state.SettingData.IvaGeneral === "0"
															? true
															: false
													}
													onChange={this.handleInputChange}
												/>{" "}
												0%
											</label>
										</li>
										<li>
											<label htmlFor="ivaGeneral-">
												<input
													type="radio"
													id="ivaGeneral-"
													name="IvaGeneral"
													defaultValue="-"
													checked={
														this.state.SettingData.IvaGeneral === "-"
															? true
															: false
													}
													onChange={this.handleInputChange}
												/>{" "}
												Excento de IVA
											</label>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className="form-group" style={{ display: "none" }}>
							<label className="text-blue font-weight-semibold">IVA 8%</label>
							<div className="row">
								<div className="col-12">
									<label>
										<i className="fas fa-chevron-right text-blue"></i> ¿Deseas
										habilitar en el formulario de autofacturación que aparezca
										la opción del 8% o del 16% de IVA?
									</label>
								</div>
								<div className="col-12">
									<ul className="ml-4">
										<li>
											<label htmlFor="useIva8Yes" className="">
												<input
													type="radio"
													id="useIVA8Yes"
													name="ActiveIvaInStore"
													defaultValue={true}
													checked={
														this.state.SettingData.ActiveIvaInStore === true
													}
													onChange={this.handleInputChange}
												/>{" "}
												SI, el cliente seleccionará el IVA que se aplicará a su
												factura.
											</label>
										</li>
										<li>
											<label htmlFor="useIva8Not" className="">
												<input
													type="radio"
													id="useIVA8Not"
													name="ActiveIvaInStore"
													defaultValue={false}
													checked={
														this.state.SettingData.ActiveIvaInStore === false
													}
													onChange={this.handleInputChange}
												/>{" "}
												NO, se tomará el definido en la configuracion o en los
												productos.
											</label>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<hr />

						<div className="form-group">
							<label className="text-blue font-weight-semibold">IEPS</label>
							<div className="row">
								<div className="col-12">
									<label>
										<i className="fas fa-chevron-right text-blue"></i> ¿Manejas
										productos <b>gravados con IEPS</b>?
									</label>
								</div>
								<div className="col-12">
									<ul className="ml-4">
										<li>
											<label htmlFor="useIepsYes" className="">
												<input
													type="radio"
													id="useIepsYes"
													name="UseIeps"
													defaultValue={true}
													checked={this.state.SettingData.UseIeps === true}
													onChange={this.handleInputChange}
												/>{" "}
												SI
											</label>
										</li>
										<li>
											<label htmlFor="useIepsYes" className="">
												<input
													type="radio"
													id="useIepsNot"
													name="UseIeps"
													defaultValue={false}
													checked={this.state.SettingData.UseIeps === false}
													onChange={this.handleInputChange}
												/>{" "}
												NO
											</label>
										</li>
									</ul>
								</div>
							</div>
						</div>

						{this.state.SettingData.UseIeps &&
						this.state.SettingData.UseIeps === true ? (
							<div className="form-group mt-4">
								<div className="row">
									<div className="col-12">
										<label>
											<i className="fas fa-chevron-right text-blue"></i>{" "}
											¿Cuántas <b>tasas de IEPS</b> manejas?
										</label>
									</div>
									<div className="col-12">
										<ul className="ml-4">
											<li>
												<label htmlFor="multipleIeps">
													<input
														type="radio"
														id="multipleIepsOnlyOne"
														name="MultipleIeps"
														defaultValue={false}
														checked={
															this.state.SettingData.MultipleIeps === false
														}
														onChange={this.handleInputChange}
													/>{" "}
													Solo una del
												</label>
												<div className="input-group input-group-sm">
													<input
														id="iepsGeneral"
														name="IepsGeneral"
														type="number"
														className="form-control"
														maxLength="5"
														autoComplete="off"
														onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
														defaultValue={this.state.SettingData.IepsGeneral}
														onChange={this.handleInputChange}
													/>
													<span className="input-group-addon">%</span>
												</div>{" "}
												{/* input group */}
											</li>
											<li>
												<label htmlFor="multipleIeps2" className="">
													<input
														type="radio"
														id="multipleIeps2"
														name="MultipleIeps"
														defaultValue={true}
														checked={
															this.state.SettingData.MultipleIeps === true
														}
														onChange={this.handleInputChange}
													/>{" "}
													2 ó mas (Es decir, manejas productos con diferentes
													tasas de IEPS)
												</label>
											</li>
										</ul>
									</div>
								</div>

								<div className="row">
									<div className="col-12">
										<label>
											<i className="fas fa-chevron-right text-blue"></i> ¿Deseas
											aplicar el porcentaje de IEPS a todos los productos?
										</label>
									</div>
									<div className="col-12">
										<ul className="ml-4">
											<li>
												<label htmlFor="iepsForAllYes" className="">
													<input
														type="radio"
														id="iepsForAllYes"
														name="UseIepsForAll"
														defaultValue={true}
														checked={
															this.state.SettingData.UseIepsForAll === true
														}
														onChange={this.handleInputChange}
													/>{" "}
													SI
												</label>
											</li>
											<li>
												<label htmlFor="iepsForAllNot" className="">
													<input
														type="radio"
														id="iepsForAllNot"
														name="UseIepsForAll"
														defaultValue={false}
														checked={
															this.state.SettingData.UseIepsForAll === false
														}
														onChange={this.handleInputChange}
													/>{" "}
													NO
												</label>
											</li>
										</ul>
									</div>
								</div>
							</div>
						) : undefined}

						<hr />		

						<div className="form-group">
							<label className="text-blue font-weight-semibold">Objeto Impuesto</label>
							<div className="row">
								<div className="col-12">
									<label>
										<i className="fas fa-chevron-right text-blue"></i> Selecciona el
										<b> Objeto de Impuesto</b> que utilicen todos o la mayoria de tus productos
									</label>
								</div>
								<div className="col-12">
									<ul className="ml-4">										
										<li>
											<label htmlFor="TaxObject" className="">
												<input
													type="radio"
													id="01"
													name="TaxObject"
													defaultValue={'01'}
													checked={this.state.SettingData.TaxObject === '01'}
													onChange={this.handleInputChange}
												/>{" "}
												01 - No objeto de impuesto
											</label>
										</li>
										<li>
											<label htmlFor="TaxObject" className="">
												<input
													type="radio"
													id="02"
													name="TaxObject"
													defaultValue={'02'}
													checked={this.state.SettingData.TaxObject === '02'}
													onChange={this.handleInputChange}
												/>{" "}
												02 - Sí objeto de impuesto
											</label>
										</li>
										<li>
											<label htmlFor="TaxObject" className="">
												<input
													type="radio"
													id="03"
													name="TaxObject"
													defaultValue={'03'}
													checked={this.state.SettingData.TaxObject === '03'}
													onChange={this.handleInputChange}
												/>{" "}
												03 - Sí objeto de impuesto y no obligado al desglose
											</label>
										</li>
										<li>
											<label htmlFor="TaxObject" className="">
												<input
													type="radio"
													id="04"
													name="TaxObject"
													defaultValue={'04'}
													checked={this.state.SettingData.TaxObject === '04'}
													onChange={this.handleInputChange}
												/>{" "}
												04 - Sí objeto de impuesto y no causa impuesto
											</label>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<hr />

						<div className="form-group">
							<label className="text-blue font-weight-semibold">
								Descuentos
							</label>
							<div className="row">
								<div className="col-12">
									<label>
										<i className="fas fa-chevron-right text-blue"></i> ¿El
										descuento <b>ya incluye IVA</b>?
									</label>
								</div>
								<div className="col-12">
									<ul className="ml-4">
										<li>
											<label htmlFor="discountIncludeIvaYes" className="">
												<input
													type="radio"
													id="discountIncludeIvaYes"
													name="DiscountTaxesInclude"
													defaultValue={true}
													checked={
														this.state.SettingData.DiscountTaxesInclude === true
													}
													onChange={this.handleInputChange}
												/>{" "}
												SI incluye, por lo tanto (se desglozará el descuento
												correspondiente en la factura)
											</label>
										</li>
										<li>
											<label htmlFor="discountIncludeIvaNot" className="">
												<input
													type="radio"
													id="discountIncludeIvaNot"
													name="DiscountTaxesInclude"
													defaultValue={false}
													checked={
														this.state.SettingData.DiscountTaxesInclude ===
														false
													}
													onChange={this.handleInputChange}
												/>{" "}
												NO incluye, por lo tanto se aplicara el descuento total
												a los productos
											</label>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<hr />

						<div className="form-group">
							<label className="text-blue font-weight-semibold">
								Cargo por Envio (Shipping)
							</label>
							<div className="row">
								<div className="col-12">
									<label>
										<i className="fas fa-chevron-right text-blue"></i> ¿El cargo
										por el envio <b>ya incluye el 16% de IVA</b>?
									</label>
								</div>
								<div className="col-12">
									<ul className="ml-4">
										<li>
											<label htmlFor="shippingIncludeIvaYes" className="">
												<input
													type="radio"
													id="shippingIncludeIvaYes"
													name="ShippingTaxesInclude"
													defaultValue={true}
													checked={
														this.state.SettingData.ShippingTaxesInclude === true
													}
													onChange={this.handleInputChange}
												/>{" "}
												SI, (se desglozara el iva correspondiente en la factura)
											</label>
										</li>
										<li>
											<label htmlFor="shippingIncludeIvaNot" className="">
												<input
													type="radio"
													id="shippingIncludeIvaNot"
													name="ShippingTaxesInclude"
													defaultValue={false}
													checked={
														this.state.SettingData.ShippingTaxesInclude ===
														false
													}
													onChange={this.handleInputChange}
												/>{" "}
												NO
											</label>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="form-group">
							<div className="row">
								<div className="col-12">
									<button
										className="btn btn-outline-primary btn-block"
										onClick={this.handleSumit}
									>
										Guardar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal
					show={this.state.modalShow}
					onHide={() => this.setState({ modalShow: false })}
				>
					<Modal.Header closeButton>
						<b>
							¿Deseas agregar esta configuración de impuestos a todos tus
							productos? Si es así, déjanos un correo para confirmar cuando el
							proceso haya terminado. Terminado el proceso consulta tus productos 
							en Menú / Administrar catálogos
						</b>
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="col-12">
								<div className="form-group">
									<Form.Group controlId="formBasicEmail">
										<Form.Label>Email</Form.Label>
										<Form.Control
											type="email"
											placeholder="Ingresar email"
											required="required"
											onChange={this.handleEmailChange}
										/>
										<Form.Text className="text-danger">
											{this.state.emailError}
										</Form.Text>
									</Form.Group>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="btn btn-outline-secondary-cancel mr-3"
							onClick={() => {
								this.setState({ modalShow: false });
								this.setState({ showConfirm: false });
							}}
						>
							Cancelar
						</Button>
						<Button
							variant="btn btn-outline-primary"
							onClick={this.handleCloseConfirm}
						>
							Aceptar
						</Button>
					</Modal.Footer>
				</Modal>
				<BootBoxAlert
					show={this.state.showAlert}
					message={this.state.messageAlert}
					onYesClick={this.handleCloseAlert}
				/>
				<Bootbox
					show={this.state.showConfirm}
					message={
						"Tu configuración de impuestos ha sido guardada exitosamente"
					}
					onYesClick={() => {
						this.setState({ modalShow: true });
						this.setState({ email: "" });
					}}
					noClickDisplay={"none"}
					onClose={() => {}}
					onNoClick={() => {}}
				/>
			</>
		);
	}
}

TaxGeneralSettings.propTypes = {
	fetchSettings: PropTypes.func.isRequired,
	resultSettings: PropTypes.object,
};
TaxGeneralSettings.defaultProps = {
	//result:{},
	resultSettings: {},
};
const mapStateToProps = (state) => ({
	//result:getInsertSetting(state),
	resultSettings: getGeneralSettings(state),
});
//saveSetting,
export default connect(mapStateToProps, {
	fetchSettings,
	saveSetting,
	ImportProducts,
})(TaxGeneralSettings);

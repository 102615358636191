import React from "react";
import "@fortawesome/fontawesome-free/css/all.css";
// import { Link } from 'react-router-dom';

import {
	SeriesEditableSelect,
	ExpeditionPlaceSelect,
} from "../../components/AccountSettings/ExpeditionControls";
import SerieNew from "../../components/AccountSettings/SerieNew";
import SerieEdit from "../../components/AccountSettings/SerieEdit";
import PaymentFormProvider from "../../components/AccountSettings/PaymentFormProvider";
//GeneralPublicSelect
import {
	postSetting,
	fetchSettings,
	fetchSerie,
	fetchSeries,
	putSerie,
	postSerie,
} from "../../actions/account";
import { connect } from "react-redux";
import {
	//getInsertSetting,
	getSettings,
	getSeries,
	getSubscription,
} from "../../selectors/accounts";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";

import Loader from "../../components/General/loader";
import { Alert } from "react-bootstrap";
import FeatureContainment from "../../components/FeatureContainment";
import { Link } from "react-router-dom";

class AccountSettings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: false,
			success: false,
			message: "",

			submitting: false,
			isVisible: false,
			modalShow: false,
			isSerieEdit: false,
			loading: true,
			IdBranchOffice: "",
			SerieData: {
				IdBranchOffice: "",
				Name: "",
				Description: "",
				Folio: 0,
			},
			SettingData: {
				ShopName: "",
				IdBranch: "",
				Serie: "",
				Folio: 0,
				AutomaticInvoicing: false,
				AutoInvoicingInStore: false,
				LimitDays: -1,
				AllPaymentsUserEcommerce: [],
			},

			showAlert: false,
			alertVariant: "primary",
			messageAlert: "",
		};
		
		/*
        this.props.fetchSettings().then((response)=>{            
            if(response.payload.hasOwnProperty("_error"))
            {                
                alert(response.payload._error);
            }else if(response.payload.hasOwnProperty("Message"))
            {
                alert(response.payload.Message);                
            }            
        });*/
	}
	updateStates() {
		// console.log("updateStates");
		console.log("this", this);
		this.setState({ loading: true });
		let aux = this.props.settings?.AllPaymentForm.filter(
			(v) =>
				this.props.settings?.AllPaymentsUser.map((r) => r.Value).includes(
					v.Value
				) === true
		).map((res) => {
			return { Code: res.Value, GatewayName: res.Name };
		});
		if (
			this.props.settings?.IdBranch === "" &&
			this.props.settings?.AllBranchs &&
			this.props.settings?.AllBranchs.length > 0
		) {
			this.props.settings.IdBranch = this.props.settings?.AllBranchs[0].Id;
		}
		this.setState({
			SettingData: {
				ShopName: this.props.settings?.ShopName,
				IdBranch: this.props.settings?.IdBranch,
				Serie: this.props.settings?.Serie,
				Folio: this.props.settings?.Folio,
				AutomaticInvoicing: this.props.settings?.AutomaticInvoicing,
				AutoInvoicingInStore: this.props.settings?.AutoInvoicingInStore,
				LimitDays: this.props.settings?.LimitDays,
				AllPaymentsUserEcommerce: aux,
			},
		});
		//this.loadSeries();
		this.setState({ loading: false });
	}
	handleInputChange = (event) => {
		// console.log(event.target.name)
		// console.log(event.target.value)

		let name =
			event.target.name === "InitialFolio" ? "Folio" : event.target.name;
		let value = event.target.value;

		if (name === "AutomaticInvoicing") value = value === "true" ? true : false;
		this.setState((prevState) => ({
			SettingData: {
				...prevState.SettingData,
				[name]: value,
			},
		}));
	};
	optionLimitDaysChange = (event) => {
		let value = event.target.value;
		this.setState((prevState) => ({
			SettingData: {
				...prevState.SettingData,
				LimitDays: value,
			},
		}));
	};
	componentDidUpdate(nextProps, nextState) {
		if (
			this.state.SettingData.IdBranch !== undefined &&
			this.state.SettingData.IdBranch !== nextState.SettingData.IdBranch
		) {
			this.loadSeries();
		}
	}
	handleSubmit = (p) => {
		console.log("data to save", this.state);
		// return;
		this.setState({ loading: true });
		if (this.handleValidation) {
			this.props
				.postSetting(this.state.SettingData)
				.then((response) => {
					if (response.payload.hasOwnProperty("_error")) {
						// alert(response.payload._error);
						this.Alert(response.payload._error, "danger");
						// this.setState({
						// 	error: true,
						// 	success: false,
						// 	message: response.payload._error,
						// });
					} else if (response.payload.hasOwnProperty("Message")) {
						//alert(response.payload.Message);
						this.Alert(response.payload.Message, "danger");
						// this.setState({
						// 	error: true,
						// 	success: false,
						// 	message: response.payload.Message,
						// });
					} else {
						// alert("Se guardo el registro");
						this.Alert("Los cambios se guardaron exitosamente", "success");
						// this.setState({
						// 	error: false,
						// 	success: true,
						// 	message: "Se guardo el registro",
						// });
					}
				})
				.catch((r) => {
					// alert("error");
					console.log("error", r);
					this.Alert("Error: " + r, "danger");
					// this.setState({
					// 	error: false,
					// 	success: true,
					// 	message: "Error: " + r,
					// 	loading: false,
					// });
					// this.setState({ loading: false });
				})
				.finally(() => {
					window.scrollTo(0, 0);
					this.setState({ loading: false });
				});
		}
	};
	handleValidation() {
		//let isValid=false;
		this.setState((prevState) => ({
			SettingData: {
				...prevState.SettingData,
				IdBranch: this.props.settings?.IdBranch,
				Serie: this.props.settings?.Serie,
				Folio: this.props.settings?.Folio,
			},
		}));
		/*
       this.setState({[SettingData.IdBranch]:  this.props.settings?.IdBranch});
        this.setState({[SettingData.Serie]:  this.props.settings?.Serie});
        this.setState({[SettingData.Folio]:  this.props.settings?.Folio});
        */

		let msg = "";
		msg += this.state.SettingData.IdBranch !== "" ? "" : " Branch Requerido, ";
		msg += this.state.SettingData.Serie !== "" ? "" : "Serie Requerido, ";
		msg += this.state.SettingData.Folio !== "" ? "" : "Folio Requerido, ";

		if (msg !== "") {
			this.Alert(msg, "danger");
			// this.setState({
			// 	error: true,
			// 	success: false,
			// 	message: msg,
			// });
		}
		// alert(msg);
		return msg === "";
	}
	handleChangeCheck = (event) => {
		//alert("ok")
		// console.log(event.target.title)
		// console.log(event.target.id)
		// console.log(event.target.value)
		// console.log(event.target.checked)
		if (event.target.checked === true) {
			let o = { Code: event.target.value, GatewayName: event.target.title };
			this.state.SettingData.AllPaymentsUserEcommerce.push(o);
			//this.setState({AllPaymentsUserEcommerce:resins });
		} else {
			let resDel = this.state.SettingData.AllPaymentsUserEcommerce.filter(
				(r) => r.Code !== event.target.value
			);
			//this.setState({AllPaymentsUserEcommerce: resDel});
			this.setState((prevState) => ({
				SettingData: {
					...prevState.SettingData,
					AllPaymentsUserEcommerce: resDel,
				},
			}));
		}
		//this.setState({[event.target.name]:event.target.value});
	};
	componentDidMount() {
		this.props
			.fetchSettings()
			.then((response) => {
				console.log('props', response)
				console.log('state', this.state);
				if (response.payload.hasOwnProperty("_error")) {
					// alert(response.payload._error);
					this.Alert(response.payload._error, "danger");
					// this.setState({
					// 	error: true,
					// 	success: false,
					// 	message: response.payload._error,
					// });
				} else if (response.payload.hasOwnProperty("Message")) {
					// alert(response.payload.Message);
					this.Alert(response.payload.Message, "danger");
					// this.setState({
					// 	error: true,
					// 	success: false,
					// 	message: response.payload.Message,
					// });
				}
				this.updateStates();
			})
			.catch((r) => {
				// alert("error");
				console.log("error", r);
				this.Alert("Error: " + r, "danger");
				// this.setState({
				// 	error: false,
				// 	success: true,
				// 	message: "Error: " + r,
				// });
			});
	}
	handleInputSerieChange = (e) => {
		// console.log(e.target.name)
		// console.log(e.target.value)
		let name = e.target.name;
		let value = e.target.value;
		this.setState((prevState) => ({
			SerieData: {
				...prevState.SerieData,
				[name]: value,
			},
		}));
	};
	onModalSerieNew = () => {
		this.setState({ modalShow: true });
		this.setState({ isSerieEdit: false });
	};
	onModalSerieEdit = () => {
		this.setState({ loading: true });
		this.props
			.fetchSerie(this.state.SettingData.IdBranch, this.state.SettingData.Serie)
			.then((response) => {
				if (response.payload.hasOwnProperty("_error")) {
					this.Alert(response.payload._error, "danger");
					// this.setState({
					// 	error: true,
					// 	success: false,
					// 	message: response.payload._error,
					// });
				} else if (response.payload.hasOwnProperty("Message")) {
					this.Alert(response.payload.Message, "danger");
					// this.setState({
					// 	error: true,
					// 	success: false,
					// 	message: response.payload.Message,
					// });
				} else {
					this.setState({
						SerieData: {
							IdBranchOffice: this.state.SettingData.IdBranch,
							Name: response.payload.Name,
							Folio: response.payload.Folio,
							Description: response.payload.Description,
						},
					});
					this.setState({ modalShow: true });
					this.setState({ isSerieEdit: true });
				}
			})
			.catch((r) => {
				this.Alert("Error: " + r, "danger");
				// this.setState({
				// 	error: false,
				// 	success: true,
				// 	message: "Error: " + r,
				// 	loading: false,
				// });
				// this.setState({ loading: false });
			})
			.finally(() => {
				window.scrollTo(0, 0);
				this.setState({ loading: false });
			});
	};
	onModalHide = () => {
		//putSerie
		this.setState({ modalShow: false });
	};
	onModalSubmit = (values) => {
		return this.state.isSerieEdit === true
			? this.props.putSerie(
					this.state.SettingData.IdBranch,
					this.state.SettingData.Serie,
					{ ...values, IdBranchOffice: this.state.SettingData.IdBranch }
			  )
			: this.props.postSerie(this.state.SettingData.IdBranch, {
					...values,
					IdBranchOffice: this.state.SettingData.IdBranch,
			  });
		/* .then((response)=>{            
            if(response.payload.hasOwnProperty("_error"))
            {                
                alert(response.payload._error);
            }else if(response.payload.hasOwnProperty("Message"))
            {
                alert(response.payload.Message);
            }
            else {
                this.setState({modalShow: false});
                this.setState({isSerieEdit: true});
                this.loadSeries();
                alert("operacion exitosa");
            }
            this.setState({loading: false});
            this.setState({submitting:false});
            
        })
        .catch((r) => {            
            alert("error");
            this.setState({loading: false});
            this.setState({submitting:false});
        });  */
	};
	loadSeries() {
		//this.setState({loading: true});
		this.props.fetchSeries(this.state.SettingData.IdBranch);
		/*.then((response)=>{            
            if(response.payload.hasOwnProperty("_error"))
            {                
                alert(response.payload._error);
            }else if(response.payload.hasOwnProperty("Message"))
            {
                alert(response.payload.Message);
            }
            else {
                alert("operacion exitosa");
            }
            this.setState({loading: false});
        })
        .catch((r) => {            
            alert("error");
            this.setState({loading: false});
        });   */
	}
	handleOnSubmitSuccess = () => {
		this.Alert("Operacion exitosa", "success");
		// this.setState({
		// 	error: false,
		// 	success: true,
		// 	message: "operacion exitosa",
		// 	// modalShow: false
		// });
		this.loadSeries();
		this.setState({ modalShow: false });
		window.scrollTo(0, 0);
	};

	handleCloseAlert = () => {
		this.setState({ showAlert: false });
		if (this.state.redirect) {
			window.location.reload(false);
			this.setState({ redirect: false });
		}
	};
	Alert = (msg, variant) => {
		this.setState({
			showAlert: true,
			messageAlert: msg,
			alertVariant: variant,
		});
	};

	render() {
		// console.log(this.state);
		return (
			<>
				<Loader show={this.state.loading} />
				<div className="row pt-4">
					{/* info : inicio */}
					<div className="col-md-3">
						<p>
							En este apartado se especificará el{" "}
							<b>Lugar de Expedición y la Serie</b> (en caso de existir) que se
							usará en la <b>Autofacturación</b>.
							<br />
							<br />
							<b>¿Qué es la Autofacturación?</b>
							<br />
							<br />
							Cuando un cliente termina de hacer su compra en línea, le
							aparecerá una sección donde se le pedirán sus{" "}
							<b>Datos Fiscales</b>, los cuales al momento de ser guardados se
							le estará generando automaticamente la factura con los datos de la
							compra actual, en caso de que la compra se vaya a pagar por
							tienda, deposito, etc..los datos se guardarán y hasta que se
							confirme el pago se generará la factura.
						</p>
					</div>

					<div className="col-md-9 questions-container">
						{this.state.success ? (
							<div className="alert alert-success text-sm-center ">
								{this.state.message}
							</div>
						) : (
							""
						)}
						{this.state.error ? (
							<div className="alert alert-danger text-sm-center ">
								{this.state.message}
							</div>
						) : (
							""
						)}
						<Alert
							show={this.state.showAlert}
							variant={this.state.alertVariant}
							onClose={this.handleCloseAlert}
							dismissible
						>
							<p>{this.state.messageAlert}</p>
						</Alert>
						<div className="form-group">
							<label className="text-blue font-weight-semibold">
								AUTOFACTURACIÓN
							</label>

							<div className="row">
								<div className="col-md-6 col-sm-6 col-xs-12">
									<div className="form-group">
										<label>Lugar de Expedición:</label>
										<ExpeditionPlaceSelect
											AllBranchs={this.props.settings?.AllBranchs || []}
											//defaultValue={this.props.settings?.IdBranch}
											value={this.state.SettingData.IdBranch}
											handleChange={this.handleInputChange}
										/>
									</div>
								</div>

								<div className="col-md-6 col-sm-6 col-xs-12">
									<div className="form-group">
										<label>Series:</label>
										<SeriesEditableSelect
											AllSeries={this.props.series || []}
											defaultValue={this.state.SettingData.Serie}
											handleChange={this.handleInputChange}
											onModalSerieNew={this.onModalSerieNew}
											onModalSerieEdit={this.onModalSerieEdit}
										/>
									</div>
								</div>
							</div>

							{/* <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Folio Inicial:</label>
                                <input name="InitialFolio" defaultValue={this.props.settings?.Folio} onChange={this.handleInputChange} className="form-control"   placeholder="Folio Inicial" required={true} type="number"  />
                            </div>
                        </div>
                    </div> */}
						</div>

						<hr />
						<div className="form-group my-5">
							<label className="text-blue font-weight-semibold">
								AUTOFACTURACIÓN DESDE HOME
							</label>
							<FeatureContainment
								plan={this.props.subscription?.subscriptionPlanInfo?.Plan}								
								htmlContent={
									<p>
										Para que tus clientes puedan autofacturarse desde un link en
										tu home es necesario contar con la suscripcion PREMIUM -{" "}
										<Link to="/suscription/plans">conoce más</Link>
									</p>
								// 	<p> 
								// 	Para que tus clientes puedan autofacturarse desde un link en
								// 	tu home es necesario seguir este {" "}
								// 	<Link to="../../../public/docs/facturacion-masiva-tiendanube.pdf">manual</Link>
								// </p>
								}
							>
								<div className="row">
									<div className="col-12">
										<label>
											<i className="fas fa-chevron-right text-blue"></i>{" "}
											<b>*Importante:</b> es necesario activar esta opción para
											que tus clientes se autofacturen desde un link en tu home
											en base a su número de orden y total de la compra.
										</label>
									</div>
									<div className="col-12">
										<button
											className="btn btn-outline-secondary my-4"
											onClick={() =>
												window.open(
													"/docs/facturacion-masiva-tiendanube.pdf",
													"_blank"
												)
											}
										>
											Instrucciones autofacturación desde home
										</button>
									</div>
								</div>
							</FeatureContainment>
						</div>
						<div className="form-group">
							<label className="text-blue font-weight-semibold">
								AL FINAL DE LA COMPRA (CHECKOUT)
							</label>
							<div className="row">
								<div className="col-12">
									<label>
										<i className="fas fa-chevron-right text-blue"></i>{" "}
										<b>*Importante:</b> Activa esta opción si quieres que tus
										clientes puedan <b>autofacturarse</b> al termino de su
										compra.
									</label>
								</div>
								<div className="col-12">
									<ul className="ml-4">
										<li>
											<label className="">
												<input
													type="radio"
													id="autofact_si"
													name="AutomaticInvoicing"
													//defaultValue={true}
													value={true}
													// defaultChecked={
													// 	this.props.settings?.AutomaticInvoicing === true
													// }
													checked={
														this.state.SettingData.AutomaticInvoicing === true
													}
													onChange={this.handleInputChange}
												/>{" "}
												Autofacturacion en el checkout
											</label>
										</li>
										<li>
											<label className="">
												<input
													type="radio"
													id="autofact_no"
													name="AutomaticInvoicing"
													value={false}
													checked={
														this.state.SettingData.AutomaticInvoicing === false
													}
													// defaultChecked={
													// 	this.props.settings?.AutomaticInvoicing === false
													// }
													onChange={this.handleInputChange}
												/>{" "}
												Desactivar
											</label>
										</li>
									</ul>
								</div>
							</div>
						</div>
						{/* <div className="form-group">       
                    <label className="text-blue font-weight-semibold">COMO UNA SECCION EN LA TIENDA</label>             
                    <div className="row">
                        <div className="col-12">
                            <label><i className="fas fa-chevron-right text-blue"></i> &nbsp;
                            <b>*Importante: </b> Activa esta opción si quieres que se muestre la seccion de autofacturación en tu tienda. Deberás agregar una nueva pagina donde agregaras 
                            el siguiente contenedor: <b> &lt;div id='facturama'&gt; &lt;/div&gt; </b>
                            </label>
                        </div>        
                        <div className="col-12">                            
                            <ul className="ml-4">
                                <li>
                                    <label className="">
                                        <input type="radio" id="autofact_tienda_si" name="AutoInvoicingInStore" defaultValue={true} defaultChecked={this.props.settings?.AutoInvoicingInStore===true} onChange={this.handleInputChange} /> Autofacturacion en la tienda
                                    </label>
                                </li>
                                <li>
                                    <label className="">
                                        <input type="radio" id="autofact_tienda_no" name="AutoInvoicingInStore" defaultValue={false} defaultChecked={this.props.settings?.AutoInvoicingInStore===false} onChange={this.handleInputChange} /> Desactivar
                                    </label>
                                </li>
                            </ul>
                        </div>                
                    </div>
                   
                </div> */}

						<hr />

						<div className="form-group">
							<label className="text-blue font-weight-semibold">
								RESTRICCIONES
							</label>
							<div className="row">
								<div className="col-12">
									<label>
										<i className="fas fa-chevron-right text-blue"></i> Indica el
										tiempo limite de la generación de la factura (Se generará la
										factura siempre y cuando la orden este como pagada){" "}
									</label>
								</div>
								<div className="col-6">
									<select
										id="optionLimitDays"
										className="form-control"
										onChange={this.optionLimitDaysChange}
										value={
											this.state.SettingData.LimitDays < 9999 && this.state.SettingData.LimitDays > 0
												? "specific"
												: this.state.SettingData.LimitDays
										}
									>
										<option value="-1">Sin limite de Días</option>
										<option value="9999">
											Solo los dias restantes del mes en curso
										</option>
										<option value="specific">Especificar los días</option>
									</select>
									{/* <p>{this.state.SettingData.optionLimitDays}</p> */}
								</div>

								{/* {console.log("specific", this)} */}

								{this.state.SettingData.LimitDays !== undefined &&
								this.state.SettingData.LimitDays !== -1 &&
								this.state.SettingData.LimitDays !== "-1" &&
								this.state.SettingData.LimitDays !== "9999" &&
								this.state.SettingData.LimitDays !== 9999 ? (
									<div className="col-12 mt-3">
										<ul className="ml-4" id="specificContainer">
											<li>
												Escribe cuantos días tiene el cliente para generar la
												factura despues de realizada la compra.
												<input
													type="text"
													id="limitDays"
													className="form-control"
													pattern="[0-9]{3}"
													maxLength="3"
													placeholder="ej. 15"
													name="LimitDays"
													defaultValue={this.props.settings?.LimitDays}
													onChange={this.handleInputChange}
												/>
											</li>
										</ul>
									</div>
								) : undefined}
							</div>
						</div>
						<hr />

						<div className="form-group">
							<div className="row">
								<div className="col-12">
									<label>
										<i className="fas fa-chevron-right text-blue"></i>{" "}
										Selecciona las formas de pago que se mostraran en el
										formulario de la autofacturación.
									</label>
								</div>

								{this.props.settings?.AllPaymentForm !== undefined ? (
									this.props.settings?.AllPaymentForm.map((v) =>
										this.props.settings?.AllPaymentsUser.map(
											(r) => r.Value
										).includes(v.Value) === true
											? { ...v, checked: true }
											: { ...v, checked: false }
									).map((r) => (
										<div className="col-6 " key={r.Value} value={r.checked}>
											<PaymentFormProvider
												handleChange={this.handleChangeCheck}
												id={"paymentform"}
												name={"pamentForm"}
												title={r.Value + " " + r.Name}
												defaultValue={r.Value}
												active={r.checked}
											/>
										</div>
									))
								) : (
									<div className="col-6">
										<PaymentFormProvider title={"02 Cheque nominativo"} />
									</div>
								)}
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<button
									className="btn btn-outline-primary floatRigth"
									onClick={this.handleSubmit}
								>
									Guardar
								</button>
							</div>
						</div>
						<br />
						<br />
					</div>
				</div>
				<Modal show={this.state.modalShow} onHide={this.onModalHide}>
					<Modal.Header closeButton>
						{this.state.isSerieEdit ? "Editar Serie" : "Nueva Serie"}
					</Modal.Header>
					<Modal.Body>
						{!this.state.isSerieEdit ? (
							<SerieNew
								onSubmit={this.onModalSubmit}
								onSubmitSuccess={this.handleOnSubmitSuccess}
							/>
						) : (
							<SerieEdit
								{...this.state.SerieData}
								onSubmit={this.onModalSubmit}
								onSubmitSuccess={this.handleOnSubmitSuccess}
							/>
						)}
					</Modal.Body>
					<Modal.Footer>
						<button
							className="btn btn-outline-secondary-cancel mr-3"
							onClick={this.onModalHide}
						>
							Cancelar
						</button>
						<button
							className="btn btn-outline-primary"
							type="submit"
							form="SerieForm"
							disabled={this.state.submitting}
						>
							Guardar
						</button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}
}
AccountSettings.propTypes = {
	fetchSettings: PropTypes.func.isRequired,
	//settings: PropTypes.object.isRequired,
};
AccountSettings.defaultProps = {
	//result:{},
	settings: {},
	series: [],
};
const mapStateToProps = (state) => ({
	settings: getSettings(state),
	series: getSeries(state),
	subscription: getSubscription(state),
});
//saveSetting,
export default connect(mapStateToProps, {
	fetchSettings,
	postSetting,
	fetchSerie,
	fetchSeries,
	putSerie,
	postSerie,
})(AccountSettings);

import React, { useRef } from "react";
import "@fortawesome/fontawesome-free/css/all.css";
import { Link } from "react-router-dom";

export const PaymentStatusSelect = ({
	defaultValue,
	handleStatus,
	ordersStatus,
}) => {
	defaultValue = defaultValue || "Pending";
	/*
    const paymentStatusCatalog = [
        {                
            "Name": "Activa",
            "Value": "active"
        },
        {                
            "Name": "Pagada",
            "Value": "paid"
        },
        {                
            "Name": "Cancelada",
            "Value": "canceled"
        },
        {                
            "Name": "Reembolso",
            "Value": "refund"
        },
        {                
            "Name": "Pago parcial",
            "Value": "partial"
        },
        {                
            "Name": "Pendiente",
            "Value": "pending"
        },
        {                
            "Name": "Autorizada",
            "Value": "authorized"
        },
        {                
            "Name": "Completada",
            "Value": "Completed"
        },
        {                
            "Name": "Retenida",
            "Value": "Held"
        },
        {                
            "Name": "Ordenada",
            "Value": "Ordered"
        },
    ]*/
	return (
		<>
			<select
				id="payment_status"
				className="form-control"
				title="Estado del pago"
				defaultValue={defaultValue}
				onChange={(e) => handleStatus(e)}
			>
				<option value={"all"}>Todos</option>
				{/* <option value={"invoiced"}>Con Factura</option> */}
				{Array.isArray(ordersStatus) &&
					ordersStatus.map((paymentStatus) => (
						<option key={paymentStatus.Value} value={paymentStatus.Value}>
							{paymentStatus.Name}
						</option>
					))}
			</select>
		</>
	);
};

export const PaymentStatusIcon = ({ status }) => {
	const renderIcon = (Status) => {
		let statusName = Status;
		switch (statusName) {
			case "open":
			case "OPEN":
				return (
					<>
						<i className="fas fa-circle lh-24 active"></i>&nbsp; {statusName}
					</>
				);
			case "active":
			case "ACTIVE":
				return (
					<>
						<i className="fas fa-circle lh-24 active"></i>&nbsp;Activa{" "}
					</>
				);
			case "1":
			case "PAID":
			case "paid":
				return (
					<>
						<i className="fas fa-circle lh-24 paid"></i>&nbsp;Pagada{" "}
					</>
				);

			case "canceled":
			case "CANCELED":
				return (
					<>
						<i className="fas fa-circle lh-24 canceled"></i>&nbsp;Cancelada{" "}
					</>
				);
			case "NOT_PAID":
			case "not_paid":
				return (
					<>
						<i className="fas fa-circle lh-24 canceled"></i>&nbsp;No pagada{" "}
					</>
				);
			case "UNSPECIFIED_PAYMENT_STATUS":
				return (
					<>
						<i className="fas fa-circle lh-24 canceled"></i>&nbsp;Estado de pago
						no especificado{" "}
					</>
				);
			case "FULLY_REFUNDED":
			case "refunded":
				return (
					<>
						<i className="fas fa-circle lh-24 refund"></i>&nbsp;Reembolso{" "}
					</>
				);
			case "PARTIALLY_REFUNDED":
				return (
					<>
						<i className="fas fa-circle lh-24 refund"></i>&nbsp;Reembolso
						parcial{" "}
					</>
				);
			case "partial":
				return (
					<>
						<i className="fas fa-circle lh-24 partial"></i>&nbsp;Pago parcial{" "}
					</>
				);

			case "0":
			case "pending":
			case "PENDING":
				return (
					<>
						<i className="fas fa-circle lh-24 pending"></i>&nbsp;Pendiente{" "}
					</>
				);

			case "authorized":
				return (
					<>
						<i className="fas fa-circle lh-24 authorized"></i>&nbsp;Autorizada{" "}
					</>
				);

			case "Completed":
			case "COMPLETED":
				return (
					<>
						<i className="fas fa-circle lh-24 paid"></i>&nbsp;Completada{" "}
					</>
				);
			case "Held":
				return (
					<>
						<i className="fas fa-circle lh-24 pending"></i>&nbsp;Retenida
					</>
				);
			case "Ordered":
				return (
					<>
						<i className="fas fa-circle lh-24 authorized"></i>&nbsp;Ordenada
					</>
				);

			default:
				return statusName;
		}
	};

	return (
		<>
			<div className="d-inline-flex">{renderIcon(status)}</div>
		</>
	);
};

export const ProcessButton = ({ idOrder, idInvoice, status }) => {
	const renderButton = (IdOrder, IdInvoice, Status) => {
		return Status === "refunded" ? (
			""
		) : IdInvoice && IdInvoice !== "" ? (
			<Link
				to={"/invoices/new/" + IdOrder}
				className="btn btn-outline-secondary alignVertical"
				type="button"
			>
				Refacturar
			</Link>
		) : (
			<Link
				to={"/invoices/new/" + IdOrder}
				className="btn btn-outline-primary alignVertical"
				type="button"
			>
				Facturar
			</Link>
		);
	};
	return (
		<>
			<div className="d-inline-flex">
				{renderButton(idOrder, idInvoice, status)}
			</div>
		</>
	);
};
export const ProcessGlobalInvoice = ({ idOrder }) => {
	const renderButton = (IdOrder) => {
		return (
			<Link
				to={"/invoices/new/" + IdOrder}
				className="btn btn-outline-primary alignVertical"
				type="button"
			>
				Facturar
			</Link>
		);
	};
	return (
		<>
			<div className="d-inline-flex">{renderButton(idOrder)}</div>
		</>
	);
};

export const OptionsDropdown = ({ idInvoice, handleAction, invoicesData }) => {
	const nullref = useRef(null);
	
	// invoicesData[0].StatusInvoice = false;
	// invoicesData[0].StatusInvoice = false;
	return (
		<>
			<div className="dropdown dropleft alignVertical">
				<button
					className="btn btn-options"
					ref={nullref}
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
					href="/"
				>
					<i className="fas fa-ellipsis-h"></i>
				</button>

				<div
					className="dropdown-menu dropleft"
					aria-labelledby="dropdownMenuLink"
				>
					{invoicesData?.length > 1
						? invoicesData.map((invoice, index) => (
								<React.Fragment key={index}>
									<button
										key={index}
										ref={nullref}
										className="dropdown-item"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
										href="/"
									>
										Factura {`${invoice.Folio} ${invoice.Serie}`}
										{( (invoice.IdInvoice && invoice.StatusInvoice === false) 
										 	?<span
												className="fas fa-ban iconRed"
												title="Factura Cancelada"
											></span>
											: ''
										)}
									</button>
									
									<div
										className="dropdown-menu"
										aria-labelledby="dropdownMenuLink"
									>
										{renderActions({
											handleAction,
											idInvoice: invoice.IdInvoice,
										})}
									</div>
									
								</React.Fragment>
						  ))
						: renderActions({ handleAction, idInvoice })}
				</div>
			</div>
		</>
	);
};

const renderActions = ({ handleAction, idInvoice }) => {
	return (
		<>
			<button
				className="dropdown-item"
				name="view"
				value={idInvoice}
				onClick={() => handleAction(1, idInvoice)}
			>
				Ver factura
			</button>
			<button
				className="dropdown-item"
				name="download"
				value={idInvoice}
				onClick={() => handleAction(2, idInvoice)}
			>
				Descargar .zip
			</button>
			<button
				className="dropdown-item"
				name="send"
				value={idInvoice}
				onClick={() => handleAction(3, idInvoice)}
			>
				Enviar por correo
			</button>
			<button
				className="dropdown-item"
				name="cancel"
				value={idInvoice}
				onClick={() => handleAction(4, idInvoice)}
			>
				Cancelar factura
			</button>
		</>
	);
};

import {
	FETCH_CUSTOMERS,
	INSERT_CUSTOMER,
	DETAIL_CUSTOMER,
	UPDATE_CUSTOMER,
	DELETE_CUSTOMER,
	FIND_CUSTOMERS,
} from "./../constants";
import { createAction } from "redux-actions";
import { apiDelete, apiGet, apiGetById, apiPost, apiPut } from "../api";
import { urlCustomers, urlCustomersSearch } from "../api/urls";

export const fetchCustomers = createAction(
	FETCH_CUSTOMERS,
	apiGet(urlCustomers)
);

export const findCustomers = createAction(FIND_CUSTOMERS, (search) => {
	 return apiGet(`${urlCustomersSearch}?search=${search}`)();
});

export const fetchCustomerById = createAction(DETAIL_CUSTOMER, (id) =>
	apiGetById(urlCustomers, id)()
);

export const insertCustomer = createAction(INSERT_CUSTOMER, (customer) =>
	apiPost(urlCustomers, customer)()
);

export const updateCustomer = createAction(
	UPDATE_CUSTOMER,
	async (customer) => {
		await apiPut(urlCustomers, customer.Id, customer)();
		return customer;
	}
);

export const deleteCustomer = createAction(DELETE_CUSTOMER, (Id) =>
	apiDelete(urlCustomers, Id)()
);

import React, { useState } from "react";
import PropTypes from "prop-types";
import AppFrame from "../../components/AppFrame";
import DataTable from "../../components/DataTable";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { fetchCustomers } from "../../actions/customers";
import { getCustomers } from "../../selectors/customers";
import { getProducts } from "../../selectors/products";
import { fetchProducts } from "../../actions/products";
import Loader from "../../components/General/loader";
import { Tabs } from "react-bootstrap";
import { Tab } from "bootstrap";

const columnsCustomers = [
	{
		Header: "Clientes",
		hideHeader: true,
		columns: [
			{
				Header: "Nombre / Razón social",
				accessor: "Name",
			},
			{
				Header: "RFC",
				accessor: "Rfc",
			},
			{
				Header: "Email",
				accessor: "Email",
			},
		],
	},
];

const columnsProducts = [
	{
		Header: "Productos",
		hideHeader: true,
		columns: [
			{
				Header: "Codigo SAT",
				accessor: "CodeProdServ",
			},
			{
				Header: "Nombre",
				accessor: "Name",
			},
			{
				Header: "Descripción",
				accessor: "Description",
			},
			{
				Header: "Código Unidad",
				accessor: "UnitCode",
			},
			{
				Header: "Unidad",
				accessor: "Unit",
			},
			{
				Header: "Precio",
				accessor: "Price",
			},
		],
	},
];

export const CatalogsPage = ({
	customers,
	fetchCustomers,
	products,
	fetchProducts,
	match,
}) => {
	let history = useHistory();

	console.log("productos", products);

	const [isDirtyCustomers, setIsDirtyCustomers] = useState(true);
	const [isDirtyProducts, setIsDirtyProducts] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [defaultTab, setDefaultTab] = useState(undefined);

	const onClickProductHandle = (row) => {
		console.log(row);
		history.push(`/products/${row.original.Id}/edit`);
	};

	const onClickBtnAddProductHandle = (row) => {
		console.log(row);
		history.push(`/products/new/edit`);
	};

	const onClickBtnAddCustomerHandle = (row) => {
		console.log(row);
		history.push(`/customers/new/edit`);
	};

	const onClickCustomerHandle = (row) => {
		console.log(row);
		history.push(`/customers/${row.original.Id}/edit`);
	};

	React.useEffect(() => {
		if (isDirtyCustomers && !customers) {
			setIsLoading(true);
			fetchCustomers().finally(() => {
				setIsLoading(false);
			});
			setIsDirtyCustomers(false);
		}

		if (isDirtyProducts && !products) {
			setIsLoading(true);
			fetchProducts().finally(() => {
				setIsLoading(false);
			});
			setIsDirtyProducts(false);
		}
		if (!defaultTab) {
			setDefaultTab(match.params.tab);
		}
	}, [
		isDirtyCustomers,
		isDirtyProducts,
		defaultTab,
		fetchCustomers,
		fetchProducts,
		match.params.tab,
		customers,
		products,
	]);

	return (
		<AppFrame title="Catálogos">
			<Loader show={isLoading} />
			<Tabs
				id="controlled-catalogTab"
				activeKey={defaultTab}
				onSelect={(tab) => {
					setDefaultTab(tab);
					history.replace(`/catalogs/list/${tab}`);
					
				}}
				className="mb-4"
			>
				<Tab eventKey="customers" title="Clientes">
					<div className="row">
						<div className="col-md-3">
							<p>
								En este apartado podrás agregar los datos de los clientes a
								Facturar
								<br />
								<br />
								Deberás proporcionar:
							</p>

							<ul>
								<li>
									<b>Razón social</b>
								</li>
								<li>
									<b>Domicilio fiscal</b>
								</li>
								<li>
									<b>Régimen en el que tributas</b>
								</li>
								<li>
									<b>Correo Electrónico</b>
								</li>
								<li>
									<b>Teléfono</b>
								</li>
							</ul>
						</div>
						<div className="col-md-9">
							<div className="row">
								<div className="col text-right">
									<div
										className="btn btn-outline-primary"
										onClick={onClickBtnAddCustomerHandle}
									>
										+ Agregar cliente
									</div>
								</div>
							</div>

							<DataTable
								columns={columnsCustomers}
								data={customers || []}
								onClickRow={onClickCustomerHandle}
							/>
						</div>
					</div>
				</Tab>
				<Tab eventKey="products" title="Productos">
					<div className="row">
						<div className="col-md-3">
							<p>
								En este apartado podrás agregar la informacion de los productos.
								<br />
								<br />
								Deberás proporcionar:
							</p>

							<ul>
								<li>
									<b>Nombre del Producto</b>
								</li>
								<li>
									<b>
										Descripción: Esta información es la que se tomará en cuenta
										como concepto de la Factura.
									</b>
								</li>
								<li>
									<b>Código</b>
								</li>
								<li>
									<b>Unidad</b>
								</li>
								<li>
									<b>Precio Unitario(* no incluir IVA)</b>
								</li>
							</ul>
							{/* <p>
                                <b>
                                    Aquí podrás importar tus productos de
                                    Shopify hacia Facturama, déjanos un correo
                                    para notificarte que el proceso ha
                                    terminado:
                                </b>
                            </p>
                            <div>
                                <input
                                    type="email"
                                    id="email_import"
                                    className="form-control"
                                    placeholder="correo para notificar"
                                    required=""
                                />
                                <br />
                                <br />
                                <button
                                    id="btn_import_prod"
                                    className="btn btn-primary pull-left"
                                    title="Importar productos"
                                >
                                    <i className="icon-upload"></i>
                                    &nbsp;Importar Productos
                                </button>
                            </div>
                            <p></p> */}
						</div>
						<div className="col-md-9">
							<div className="row">
								<div className="col text-right">
									<div
										className="btn btn-outline-primary"
										onClick={onClickBtnAddProductHandle}
									>
										+ Agregar producto
									</div>
								</div>
							</div>

							<DataTable
								columns={columnsProducts}
								data={products || []}
								onClickRow={onClickProductHandle}
							/>
						</div>
					</div>
				</Tab>
			</Tabs>
		</AppFrame>
	);
};

CatalogsPage.propTypes = {
	customers: PropTypes.array,
	fetchCustomers: PropTypes.func.isRequired,
	products: PropTypes.array,
	fetchProducts: PropTypes.func.isRequired,
};

CatalogsPage.defaultProps = {
	customers: [],
	products: [],
};

const mapStateToProps = (state) => ({
	customers: getCustomers(state),
	products: getProducts(state),
});

export default connect(mapStateToProps, { fetchCustomers, fetchProducts })(
	CatalogsPage
);

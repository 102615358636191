import {
	FETCH_ORDERS,
	FETCH_INVOICE_PDF,
	FETCH_CFDI_ZIP,
	CANCEL_CFDI,
	SEND_CFDI,
	FETCH_ORDERS_STATUS,
	UPDATE_ORDERS,
	FETCH_ORDERS_WHITHOUT_INVOICE,
	FETCH_ORDERS_WHITH_INVOICE,
	FETCH_ORDERS_FOR_IDS,
	SET_INVOICE_STATUS,
	GET_CFDI_FILTERED
} from "./../constants";
import { createAction } from "redux-actions";
import { apiPost2, apiPost, apiGet, apiDelete2 } from "../api";
import { urlOrders, urlCfdi, urlInvoice } from "../api/urls";


export const fetchOrders = createAction(FETCH_ORDERS, (param) => {
	return apiPost2(
		`${urlOrders}/${param.PaymentStatus === "invoiced" ? "invoices" : "all"}`,
		param
	)();
});
export const fetchOrdersWithoutInvoice = createAction(
	FETCH_ORDERS_WHITHOUT_INVOICE,
	(param) => {
		return apiPost2(`${urlOrders}/withoutinvoice`, param)();
	}
);
export const fetchOrdersWithInvoice = createAction(
	FETCH_ORDERS_WHITH_INVOICE,
	(param) => {
		return apiPost(`${urlOrders}/invoices`, param)();
	}
);
export const fetchOrdersForIds = createAction(FETCH_ORDERS_FOR_IDS, (param) => {
	return apiPost2(`${urlOrders}/ids`, param)();
});
export const GetInvoicePdf = createAction(FETCH_INVOICE_PDF, (idInvoice) => {
	return apiGet(`${urlCfdi}/pdf/issued/${idInvoice}`)();
});
export const getCfdiZip = createAction(FETCH_CFDI_ZIP, (idInvoice) => {
	return apiGet(`${urlCfdi}/zip?id=${idInvoice}&type=issued`)();
});

export const cancelCfdi = createAction(CANCEL_CFDI, (idCfdi, motive, uuidReplacement) => {
	let r;

	if(motive==='01')
		r = apiDelete2(`${urlCfdi}/${idCfdi}?motive=${motive}&uuidReplacement=${uuidReplacement}`)();	
		// {{SERVER}}/Cfdi/qLdDWMXcWOPz8VGhQx6Clg2?motive=01&uuidReplacement=86821964-1cd9-4dad-a47e-dff943adc8cf
	else
		r = apiDelete2(`${urlCfdi}/${idCfdi}?motive=${motive}`)();	
	return r;
});
export const setInvoiceStatus = createAction(SET_INVOICE_STATUS, async (idInvoice, isCanceled) => {
	try {
		await apiPost2(
			`${urlInvoice}/set_invoice_status?idInvoice=${idInvoice}&isCanceled=${isCanceled}`
		)();
		return { idInvoice, isCanceled }
	}
	catch (error) {
		throw error;
	}
});
export const sendCfdi = createAction(SEND_CFDI, (cfdiId, email) => {
	return apiPost2(
		`${urlCfdi}?cfdiType=issued&cfdiId=${cfdiId}&email=${email}`
	)();
});
export const fetchOrdersStatus = createAction(FETCH_ORDERS_STATUS, () => {
	return apiGet(`${urlOrders}/status`)();
});
export const updateOrders = createAction(UPDATE_ORDERS, (param) => {
	return apiPost2(`${urlOrders}/invoice/update_global`, param)();
});

export const getCfdiFiltered = createAction(GET_CFDI_FILTERED, (keyword) => {
	return apiGet(
		`${urlCfdi}?type=issued&keyword=${keyword}`
	)();
});
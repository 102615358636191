import {
	RECOVERY,
	RESET,
	LOGIN,
	LOGOUT,
	REGISTER,
	INSERT_SETTING,
	FETCH_SETTINGS,
	FETCH_SETTINGS_CFDIVERSION,
	FETCH_BRANCHOFFICES_SERIE,
	DETAIL_BRANCHOFFICES_SERIE,
	PUT_BRANCHOFFICES_SERIE,
	INSERT_BRANCHOFFICES_SERIE,
	GET_ECOMMERCE_ACCESS,
	GET_ECOMMERCE_SUBSCRIPTION,
	STORE_SETTINGS,
} from "./../constants";
import { createAction } from "redux-actions";
import { apiGet, apiPost2, apiPut } from "../api";
import {
	urlRecoveryAccount,
	urlResetAccount,
	urlRegisterAccount,
	urlLoginAccount,
	urlSettings,
	urlBranchOfficesSerie,
	urlSuscription,
} from "../api/urls";

export const recoveryAccount = createAction(RECOVERY, (formData) =>
	apiPost2(urlRecoveryAccount, formData)()
);

export const resetAccount = createAction(RESET, (formData) =>
	apiPost2(urlResetAccount, formData)()
);

export const loginAccount = createAction(LOGIN, (formData) =>
	apiPost2(urlLoginAccount, formData)()
);

export const logoutAction = createAction(LOGOUT);

export const registerAccount = createAction(REGISTER, (formData) => {
	return apiPost2(urlRegisterAccount, formData)();
});
export const postSetting = createAction(
	INSERT_SETTING,

	async (formData) => {
		await apiPost2(`${urlSettings}/Settings`, formData)();
		return formData;
	}
);
export const fetchSettings = createAction(FETCH_SETTINGS, () => {
	return apiGet(`${urlSettings}/Settings`)();
});
export const fetchCfdiVersion = createAction(FETCH_SETTINGS_CFDIVERSION, () => {
	return apiGet(`${urlSettings}/cfdiVersion`)();
});
export const fetchSeries = createAction(FETCH_BRANCHOFFICES_SERIE, (idBranch) =>
	apiGet(`${urlBranchOfficesSerie}/${idBranch}`)()
);
export const fetchSerie = createAction(
	DETAIL_BRANCHOFFICES_SERIE,
	(idBranch, serie) => apiGet(`${urlBranchOfficesSerie}/${idBranch}/${serie}`)()
);
export const putSerie = createAction(
	PUT_BRANCHOFFICES_SERIE,
	(idBranch, serie, data) =>
		apiPut(`${urlBranchOfficesSerie}/${idBranch}`, serie, data)()
);
export const postSerie = createAction(
	INSERT_BRANCHOFFICES_SERIE,
	(idBranch, data) => apiPost2(`${urlBranchOfficesSerie}/${idBranch}`, data)()
);
export const getEcommerceAccess = createAction(GET_ECOMMERCE_ACCESS, () =>
	apiGet(`${urlSettings}/ecommerce_type`)()
);
export const getSubscriptionDB = createAction(GET_ECOMMERCE_SUBSCRIPTION, () =>
	apiGet(`${urlSuscription}`)()
);

export const getStoreSettingsAction = createAction(STORE_SETTINGS, () => {
	return apiGet(`${urlSettings}/StoreSettings`)();
});

/*
export const fetchBranchOffice = createAction(
    DETAIL_BRANCHOFFICES, 
    (idBranch,serie) =>  apiGet(`${urlBranchOfficesSerie}/${idBranch}/${serie}`) ()
);*/

import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import config from "../../config.json";

import AppFrame from "../../components/AppFrame/AppFrame";
import InvoiceData from "../../components/PaymentCheckout/InvoiceData";
import PaymentForm from "../../components/PaymentCheckout/PaymentForm";
import PaymentDetails from "../../components/PaymentCheckout/PaymentDetails";
import {
	facturamaCharge,
	facturamaChargePreview,
	facturamaChargeSendReceipt,
	facturamaChargeSendAcuse,
} from "../../actions/charges";
import { fetchSubscription } from "../../actions/subscription";
import { BootBoxAlert } from "../../components/General/bootbox";
import PAYMENT_FORMS from "../../constants/payment_forms";
import Loader from "../../components/General/loader";

class PaymentCheckoutPage extends React.Component {
	constructor(props) {
		super(props);

		const { id } = this.props.match.params;

		this.state = {
			loading: false,
			productId: id,
			paymentType: "card",
			quantity: 100,
			paymentDetails: null,
			promoCode: null,
			cfdiUse: "P01",
			paymentForm: "02",
			tokenId: "fake_token_id",
			op: null,
			mail: "",
			// redirect
			redirect: false,
			url: "",
			// boton de pago
			btnDisabled: false,
			// bootbox
			showBootBoxAlert: false,
			msgTitle: "",
			msgDescription: "",

			isSandboxMode: config.OPENPAY.IS_DEV,
			merchantId: config.OPENPAY.MERCHANT_ID,
			publicKey: config.OPENPAY.PUBLIC_KEY,
		};
	}

	componentDidMount() {
		window.payments = this;
		window.OpenPay.setId(this.state.merchantId);
		window.OpenPay.setApiKey(this.state.publicKey);
		window.OpenPay.setSandboxMode(this.state.isSandboxMode);

		console.log("openpay setId: ", this.state.merchantId);
		console.log("openpay setApiKey: ", this.state.publicKey);
		console.log("openpay setSandboxMode: ", this.state.isSandboxMode);

		var deviceSessionId = window.OpenPay.deviceData.setup(
			"payment-form",
			"deviceIdHiddenFieldName"
		);

		console.log("device:");
		console.log(deviceSessionId);

		this.updateChargePreview();
	}

	getChargeModel() {
		return {
			PaymentType: this.state.paymentType,
			TokenId: this.state.tokenId,
			CustomerId: "",
			Mail: this.state.mail,
			InvoiceData: {
				CfdiUse: this.state.cfdiUse,
				PaymentForm: this.state.paymentForm,
			},
			Items: [
				{
					ProductId: this.state.productId,
					Quantity: this.state.quantity,
					Price: null,
				},
			],
			PromoCode: this.state.promoCode,
		};
	}

	updateChargePreview() {
		let charge = this.getChargeModel();

		this.props.facturamaChargePreview(charge).then((response) => {
			response = response.payload;

			if ("_error" in response) {
				this.setState({ showBootBoxAlert: true });
				this.setState({ msgTitle: "Error" });
				this.setState({ msgDescription: response._error });
			} else {
				console.log(response);
				this.setState({ paymentDetails: response });
			}
		});
	}

	handlePaymentFormOnChange = (value) => {
		this.setState({ paymentForm: value });
	};

	handleCfdiUseOnChange = (value) => {
		this.setState({ cfdiUse: value });
	};
	handleMailOnChange = (value) => {
		this.setState({ mail: value });
	};

	handlePaymentTypeOnChange = (value) => {
		console.log(value);
		let option = PAYMENT_FORMS.find((pForm) =>
			pForm.PayableIn?.includes(value)
		);

		console.log(option.Value);
		this.setState({ paymentType: value });
		this.setState({ paymentForm: option.Value });
	};

	handleQuantityOnChange = (event) => {
		// console.log(event.target.value);
		// let q = 0;
		// if(event.target.value < 100)
		// 	q=100;
		// else
		// 	q = event.target.value
		this.setState({ quantity: event.target.value }, () => {
			this.updateChargePreview();
		});
	};
	handleQuantityOnBlur = (event) => {
		// console.log('event', event.target.value);
		if(event.target.value <= 100){
			event.target.value=100;
			this.setState({ quantity: event.target.value }, () => {
				this.updateChargePreview();
			});
		}
		
	};
	
	handlePromoCodeOnChange = (value) => {
		this.setState({ promoCode: value }, () => {
			this.updateChargePreview();
		});
	};

	handleOkAlert = () => {
		this.setState({ showBootBoxAlert: false });
	};

	handleFinishPurchase = (isTermsChecked) => {
		if (isTermsChecked) {
			console.log("COMPRAR");

			let charge = this.getChargeModel();

			if (!charge.Mail) {
				this.showError("Debes colocar un correo para recibir datos del pago");
			} else {
				this.setState({ btnDisabled: true });

				if (this.state.paymentType === "card") {
					window.OpenPay.token.extractFormAndCreate(
						"payment-form",
						this.successCallback,
						this.errorCallback
					);
				} else {
					this.createFacturamaCharge(charge);
				}
			}
		} else {
			this.showError("Debes aceptar los términos y condiciones");
		}
	};

	// Crea un cargo en el backend
	createFacturamaCharge(charge) {
		this.props
			.facturamaCharge(charge)
			.then((response) => {
				return new Promise((resolve) =>
					// Espera a que el webhook lo procese
					setTimeout(() => {
						this.props.fetchSubscription();
						return resolve(response);
					}, 3000)
				);
			})
			.then((response) => {
				response = response.payload;

				if ("_error" in response) {
					this.showError(response._error);
					window.payments.setState({ btnDisabled: false });
				} else {
					console.log("facturamaCharge", response);

					if (this.state.paymentType === "card") {
						this.props
							.facturamaChargeSendAcuse(
								response.Id,
								this.state.mail,
								this.state.productId
							)
							.then((sendResponse) => {
								sendResponse = sendResponse.payload;
								console.log("facturamaChargeSendAcuse", sendResponse);

								if (sendResponse.success) {
									// Envío correcto
								}
								this.redirect(`/payment-acuse/${response.Id}`);
								window.payments.setState({ btnDisabled: false });
							});
					} else {
						this.props
							.facturamaChargeSendReceipt(response.Id, this.state.mail)
							.then((sendResponse) => {
								sendResponse = sendResponse.payload;

								console.log("facturamaChargeSendReceipt", sendResponse);
								if (sendResponse.success) {
									// Envío correcto
								}
								this.redirect(`/payment-receipt/${response.Id}`);
								window.payments.setState({ btnDisabled: false });
							});
					}
				}
			});
	}

	showError(message) {
		this.setState({ showBootBoxAlert: true });
		this.setState({ msgTitle: "Error" });
		this.setState({ msgDescription: message });
	}

	// Tokenizado correcto de la tarjeta
	successCallback(response) {
		let charge = window.payments.getChargeModel();
		charge.TokenId = response.data.id; // Se agrega el token de la tarjeta a los datos del cargo

		window.payments.createFacturamaCharge(charge);
	}

	// Error al tokenizar la tarjeta
	errorCallback(response) {
		window.payments.showError(
			`${response.message} => ${response.data.description}`
		);
		window.payments.setState({ btnDisabled: false });
	}

	// Redirect
	redirect = (url) => {
		this.setState({
			redirect: true,
			url: url,
		});
	};

	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect to={this.state.url} />;
		}
	};

	render() {
		return (
			<AppFrame title="Detalle de compra">
				<Loader show={this.state.btnDisabled} />
				{this.renderRedirect()}
				<div className="row">
					<div id="payment-form" className="col-md-8">
						<InvoiceData
							paymentType={this.state.paymentType}
							handlePaymentFormOnChange={this.handlePaymentFormOnChange}
							handleCfdiUseOnChange={this.handleCfdiUseOnChange}
							handleMailOnChange={this.handleMailOnChange}
						/>

						{this.state.paymentDetails == null ? (
							<div>Cargando...</div>
						) : (
							<PaymentForm
								handlePaymentTypeOnChange={this.handlePaymentTypeOnChange}
								paymentDetails={this.state.paymentDetails}
							/>
						)}
					</div>

					<div className="col-md-4">
						{this.state.paymentDetails == null ? (
							<div>Cargando...</div>
						) : (
							<PaymentDetails
								paymentDetails={this.state.paymentDetails}
								handleQuantityOnChange={this.handleQuantityOnChange}
								handleQuantityOnBlur={this.handleQuantityOnBlur}
								handlePromoCodeOnChange={this.handlePromoCodeOnChange}
								handleFinishPurchase={this.handleFinishPurchase}
								btnDisabled={this.state.btnDisabled}
							/>
						)}
					</div>
				</div>

				{
					<BootBoxAlert
						show={this.state.showBootBoxAlert}
						type={"confirm"}
						message={this.state.msgTitle}
						detail={this.state.msgDescription}
						onYesClick={this.handleOkAlert}
					/>
				}
			</AppFrame>
		);
	}
}

PaymentCheckoutPage = connect(null, {
	facturamaCharge,
	facturamaChargePreview,
	facturamaChargeSendReceipt,
	facturamaChargeSendAcuse,
	fetchSubscription,
})(PaymentCheckoutPage);

export default PaymentCheckoutPage;

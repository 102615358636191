import React from "react";
import { TAX_RATES } from "../../constants/taxes";
import { Field } from "redux-form";
import InputField from "../InputField";

const FederalTaxes = ({ iepsEnabled }) => {
	const [isQuota, setIsQuota] = React.useState(false);
	const [taxes, setTaxes] = React.useState(TAX_RATES);

	React.useEffect(() => {
		!!iepsEnabled
			? setTaxes(TAX_RATES.filter((t) => t.isRetention === false))
			: setTaxes(TAX_RATES);
	}, [iepsEnabled]);

	return (
		<div className="row mt-2">
			<div className="col-md-12 form-group">
				<h6>Impuestos Federales</h6>
			</div>
			<div className="col-md-12 form-group">
				<small>
					Selecciona únicamente los impuestos que necesite tu producto
				</small>
			</div>
			<div className="container">
				<div className="row">
					{taxes.map((item, index) => (
						<div key={index} className="form-group col-md-6">
							<label>{item.group}</label>
							{item.group === "IEPS" && (
								<label className="float-right">
									TASA{" "}
									<Field
										component="input"
										className="mr-3"
										type="radio"
										name={`Taxes[${index}].IsQuota`}
										value="false"
										checked={!isQuota}
										onChange={() => setIsQuota(false)}
									/>
									CUOTA{" "}
									<Field
										component="input"
										type="radio"
										name={`Taxes[${index}].IsQuota`}
										value="true"
										checked={isQuota}
										onChange={() => setIsQuota(true)}
									/>
								</label>
							)}

							<Field
								component="input"
								type="hidden"
								name={`Taxes[${index}].IsRetention`}
								value={item.isRetention}
							/>
							<Field
								component="input"
								type="hidden"
								name={`Taxes[${index}].Name`}
								value={item.group}
							/>

							{isQuota && item.group === "IEPS" ? (
								<div id="iepsQuotaContainer">
									<Field
										component="input"
										type="hidden"
										name={`Taxes[${index}].IsQuota`}
										value={true}
									/>
									<Field
										component="input"
										type="text"
										className="form-control"
										name={`Taxes[${index}].Rate`}
										placeholder="Ej. 0.005, 0.03"
										min="0"
										max="43.77"
										maxLength="9"
									/>
								</div>
							) : (
								<Field
									name={`Taxes[${index}].Rate`}
									className="form-control"
									type="select"
									component={InputField}
								>
									<option value="">-</option>
									{item.values.map((tax, i) => (
										<option
											key={i}
											value={tax.value}
											// data={tax}
										>
											{tax.label}
										</option>
									))}
								</Field>
							)}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default FederalTaxes;

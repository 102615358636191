import React from "react";
import numeral from "numeral";
import './PaymentCheckout.css'



 const PaymentForm = ({handlePaymentTypeOnChange, paymentDetails}) => {
    return (
        <div id="payment_form" className="mt-3">
            	<div className="section-title">
					<h6>
						<i className="icon-check text-muted"></i> Elige tu forma de pago
					</h6>
				</div>
				<div id="payment_tabs" className="card pt-3">


					<ul className="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
						<li className="nav-item" onClick={() => handlePaymentTypeOnChange('card')}>
							<a className="nav-link active" id="pills-card-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-card" aria-selected="true">
								<i className="far fa-credit-card fa-2x"></i>
								<p>Tarjeta de crédito</p>
							</a>
						</li>
						<li className="nav-item" onClick={() => handlePaymentTypeOnChange('bank')}>
							<a className="nav-link" id="pills-bank-tab" data-toggle="pill" href="#pills-bank" role="tab" aria-controls="pills-bank" aria-selected="false">
								<i className="fas fa-random fa-2x"></i>							
								<p>Transferencia bancaria</p>
							</a>
						</li>
						<li className="nav-item" onClick={() => handlePaymentTypeOnChange('store')}>
							<a className="nav-link" id="pills-store-tab" data-toggle="pill" href="#pills-store" role="tab" aria-controls="pills-store" aria-selected="false">
								<i className="fas fa-store fa-2x"></i>
								<p>Tienda de conveniencia</p>
							</a>
						</li>
						<li className="nav-item" onClick={() => handlePaymentTypeOnChange('oxxo')}>
							<a className="nav-link" id="pills-oxxo-tab" data-toggle="pill" href="#pills-oxxo" role="tab" aria-controls="pills-oxxo" aria-selected="false">
								<span className="block">
									<img src="/images/icons/oxxopay.png" alt="oxxo" className="h-25px mb-2"/>
								</span>
								<p>Pago en efectivo</p>
							</a>
						</li>
					</ul>
					<div className="tab-content" id="pills-tabContent">
					<div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-card-tab">

						<div className="col-xs-12 col-sm-12 col-md-12 pb-5 pr-4 pl-4" >

							<form action="#" method="POST" id="payment-form">
							

								<input type="hidden" name="token_id" id="token_id" />
								<input type="hidden" name="use_card_points" id="use_card_points" value="false"/>
								<input type="hidden" name="deviceIdHiddenFieldName" id="deviceIdHiddenFieldName" />


								<div className="row">
									<div className="col-12 form-group">
										<label>Número de la tarjeta:</label>
										<input id="form_CardNum" data-conekta="card[number]" data-openpay-card="card_number" className="form-control" type="text" name="number"  placeholder="Número de tu tarjeta de crédito (16 dígitos)" maxLength="19" autoComplete="off"  pattern="[0-9]{14,16}" data-msg-pattern="Numero de tarjeta inválido"></input>										
									</div>
									<div className="col-12 form-group">
										<label>Nombre del tarjetahabiente:</label>										
										<input id="form_Name" data-conekta="card[name]" data-openpay-card="holder_name" className="form-control" type="text" name="name" placeholder="Nombre como aparece en tu tarjeta"></input>
									</div>
									<div className="col-4 form-group validity">
										<label>Valida hasta:</label>
										<select data-conekta="card[exp_month]" data-openpay-card="expiration_month" name="expMonth" id="form_month" className="form-control">
											<option value="01">01</option>
											<option value="02">02</option>
											<option value="03">03</option>
											<option value="04">04</option>
											<option value="05">05</option>
											<option value="06">06</option>
											<option value="07">07</option>
											<option value="08">08</option>
											<option value="09">09</option>
											<option value="10">10</option>
											<option value="11">11</option>
											<option value="12">12</option>
										</select>
									</div>
									<div className="col-4 form-group validity">
										<label>Año:</label>										
										<select data-conekta="card[exp_year]" data-openpay-card="expiration_year" name="expYear" id="form_year" className="form-control">
												<option value="20">2020</option>
												<option value="21">2021</option>
												<option value="22">2022</option>
												<option value="23">2023</option>
												<option value="24">2024</option>
												<option value="25">2025</option>
												<option value="26">2026</option>
												<option value="27">2027</option>
												<option value="28">2028</option>
												<option value="29">2029</option>
												<option value="30">2030</option>
												<option value="31">2031</option>
												<option value="32">2032</option>
										</select>
									</div>
									<div className="col-4 form-group">
										<label>Código de seguridad:</label>										
										<input data-conekta="card[cvc]" data-openpay-card="cvv2" id="form_Cvc" className="form-control" type="password" name="ccv2" placeholder="CVC" pattern="[0-9]{3,4}" data-msg-pattern="Solo números" maxLength="4" />
									</div>
								</div>
								
							
							</form>

						</div>

					</div>


					<div className="tab-pane fade" id="pills-bank" role="tabpanel" aria-labelledby="pills-bank-tab">


						<div className="col-xs-12 col-sm-12 col-md-12" >
							<div id="bankForm">
								<div className="row pb-5">

									<div className="col-sm-12">
										<p>Puedes transferir con SPEI de cualquiera de estos bancos:</p>
									</div>

									<div className="col-md-3">
										<img src="/images/icons/bb.jpg" alt="banbnajio"/>
									</div>
									<div className="col-md-3">
										<img src="/images/icons/banamex.jpg" alt="banamex"/>
									</div>
									<div className="col-md-3">
										<img src="/images/icons/famsa.jpg" alt="famsa"/>
									</div>
									<div className="col-md-3">
										<img src="/images/icons/coppel.jpg" alt="coppel"/>
									</div>


									<div className="col-md-3">
										<img src="/images/icons/bajercito.jpg" alt="bajercito"/>
									</div>
									<div className="col-md-3">
										<img src="/images/icons/banorte.jpg" alt="banorte"/>
									</div>
									<div className="col-md-3">
										<img src="/images/icons/banregio.jpg"  alt="banregio"/>
									</div>
									<div className="col-md-3">
										<img src="/images/icons/bancomer.jpg" alt="bancomer"/>
									</div>


									<div className="col-md-3">
										<img src="/images/icons/hsbc.jpg" alt="hsbc"/>
									</div>
									<div className="col-md-3">
										<img src="/images/icons/inbursa.jpg" alt="inbursa"/>
									</div>
									<div className="col-md-3">
										<img src="/images/icons/santander.jpg" alt="santander"/>
									</div>
									<div className="col-md-3">
										<img src="/images/icons/scotiabank.jpg" alt="scotiabank"/>
									</div>
								</div>
							</div>
						</div>


					</div>


					<div className="tab-pane fade" id="pills-store" role="tabpanel" aria-labelledby="pills-store-tab">


						<div className="col-xs-12 col-sm-12 col-md-12" >


							<div id="storeForm">
								<div className="row pb-5">

									<div className="col-sm-12">
										<p>Puedes pagar en cualquiera de estas tiendas:</p>
									</div>


									<div className="col-md-3">
										<img src="/images/icons/walmart.png" alt="walmart"/>
									</div>
									<div className="col-md-3">
										<img src="/images/icons/Superama.png" alt="Superama"/>
									</div>
									<div className="col-md-3">
										<img src="/images/icons/sams.png" alt="sams"/>
									</div>
									<div className="col-md-3">
										<img src="/images/icons/aurrera.png" alt="aurrera"/>
									</div>


									<div className="col-md-3">
										<img src="/images/icons/01.jpg" alt="01"/>
									</div>
									<div className="col-md-3">
										<img src="/images/icons/02.jpg" alt="02"/>
									</div>
									<div className="col-md-3">
										<img src="/images/icons/03.jpg" alt="03"/>
									</div>
									<div className="col-md-3">
										<img src="/images/icons/04.jpg" alt="04"/>
									</div>

								</div>
							</div>
						</div>


					</div>
					<div className="tab-pane fade" id="pills-oxxo" role="tabpanel" aria-labelledby="pills-oxxo-tab">


						<div className="col-xs-12 col-sm-12 col-md-12" >
							<div id="storeForm">

								<div className="opps">
									<div className="opps-header">
										<div className="opps-reminder">Pago en cualquier tienda OXXO</div>
										<div className="opps-info">
											<div className="opps-brand">
												<img src="/images/icons/oxxopay.png" alt="OXXOPay"/>
											</div>
											<div className="opps-ammount">
												<h3>Monto a pagar</h3>
												<h2>
													<span className="details-total">{numeral(paymentDetails.TotalAmount).format("$0,0.00")}</span>
													<sup>MXN</sup>
												</h2>
												<p>OXXO cobrará una comisión adicional al momento de realizar el pago.</p>
											</div>
										</div>

									</div>

								</div>

							</div>
						</div>


					</div>
				</div>

				</div> 

        </div> 
    );
};

export default PaymentForm



import { handleActions } from "redux-actions";
import {
	INSERT_PROCESS,
	FETCH_GENERAL_SETTINGS,
	FETCH_MASSIVEPROCESS,
} from "../constants";

export const insertProcess = handleActions(
	{
		[INSERT_PROCESS]: (state, action) => {
			if (action.hasOwnProperty("payload")) return [...action.payload];
			else alert("No se encontraron ordenes a mostrar.");
		},
	},
	[]
);
export const generalSettings = handleActions(
	{
		[FETCH_GENERAL_SETTINGS]: (state, action) => {
			//if(action.payload !== undefined)
			if (action.hasOwnProperty("payload")) return action.payload;
			else alert("No se encontraron ordenes a mostrar.");
		},
	},
	{}
);

export const massive_process = handleActions(
	{
		[FETCH_MASSIVEPROCESS]: (state, action) => {
			//if(action.payload !== undefined)
			if (action.hasOwnProperty("payload")) return action.payload;
			else alert("No se procesos a mostrar.");
		},
	},
	[]
);

/**
 * Set Token Page
 * 
 * Establece el token cuando el usuario ya está registrado y redirecciona a alguno de los siguentes lugares:
 * - Wizard (ya el wizard se encarga de mandar a donde sea necesario) 
 */

import React, {useEffect, useState} from "react";
import { useHistory, useParams } from "react-router-dom";
import {saveState} from '../../store/localStore'
import { urlCdnImages } from "../../api/urls"

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../../index.css';

import store from "../../store";

require('typeface-montserrat');

const SetTokenPage = () => {

    const history = useHistory();
    let { shopName,token } = useParams();   // Estos parámetros solo se pueden recibir mediane la URL y son obligatorios 
    let state = store.getState(); 
    const [isLoaded, setIsLoaded] = useState(false)   

    useEffect(() => {

        if(! isLoaded ){
            setIsLoaded(true);            
    
            if( shopName !== undefined 
                &&  shopName !== ''
                &&  token !== undefined 
                &&  token !== '' 
                ){
                state.tokens = {};
                state.tokens.shopName = shopName;
                state.tokens.token = token;

                console.log('-- state - mod --');
                console.log(state);
                

                saveState(state);


                setTimeout(function() { //Start the timer                    
                    
                    history.push("/wizard");     
                }, 3000)
                
            }            

        }        
    }, [isLoaded, history, shopName, state, token] );

    

    return (
        <>        

        <section className="bg-triangle-gray pb-3 xs-pb-0">

            <div className="container">

                <div className="row d-flex justify-content-center pt-5 xs-pt-1 xs-pb-0">			
                    <div className="col-md-auto">
                        <figure className="logo xs-img-auto">
                            <picture>
                                <source type="image/png" srcSet = { `${urlCdnImages}/facturama-freshbooks-logo.png` } />
                                <img src={ `${urlCdnImages}/facturama-freshbooks-logo.png` } alt="Facturación electrónica Facturama" className="img-fluid" />
                            </picture>
                        </figure>
                    </div>
                </div>
                <div className="row d-flex justify-content-center mt-1">

                    <div className="col-md-6">
                        <div className="box pt-5 pb-6 px-5">


                            <h1 className="text-center text-blue-primary h3 mt-4">¡Iniciando sesión <br /> en Facturama!</h1>
                            <p className="text-center font-weight-semibold mt-4" style={{fontSize:'16px'}}>
                                Esto tomará muy poco tiempo...
                                <br/>
                                <br/>
                                Te redirigiremos de forma automática cuando estemos listos
                                
                            </p>                            


                        </div>  {/* box */}

                        <div className="mt-5 text-center small">
                            <a href="tel:4448356098" className="text-gray">444-8356098</a> - <a href="tel:4441510191" className="text-gray">444-1510191</a> | <a className="text-gray" href="mailto:info@facturama.mx">info@facturama.mx</a>
                        </div>

                    </div> {/* col-md-6 */}

                </div> {/* row */}
        

            </div> {/* container */}
            

        </section>

                                                
        </>
    );    
}


export default SetTokenPage;


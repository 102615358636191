import {
	FETCH_NEIGHBORHOODS,
	FETCH_POSTALCODES,
	FETCH_FEDERALSTATE,
	FETCH_MUNICIPALITY,
} from "./../constants";
import { createAction } from "redux-actions";
import { apiGet } from "../api";
import {
	urlNeighborhoods,
	urlMunicipalities,
	urlFederalStates,
	urlPostalCodes,	
} from "../api/urls";

export const fetchNeighborhoods = createAction(
	FETCH_NEIGHBORHOODS,
	(postalCode) => apiGet(`${urlNeighborhoods}?postalCode=${postalCode}`)()
);

export const fetchPostalCodes = createAction(
	FETCH_POSTALCODES,
	(postalCode) => {
		return apiGet(`${urlPostalCodes}?keyword=${postalCode}`)().then((data) =>
			Promise.all([
				data[0],
				apiGet(`${urlFederalStates}?keyword=${data[0]?.StateCode}`)(),
				apiGet(`${urlMunicipalities}?stateCode=${data[0]?.StateCode}`)(),
			])
		);
		//return apiGet(`${urlPostalCodes}?keyword=${postalCode}`)()
	}
);

export const fetchFederalState = createAction(
	FETCH_FEDERALSTATE,
	(postalCode) => apiGet(`${urlFederalStates}?keyword=${postalCode}`)()
);

export const fetchMunicipality = createAction(
	FETCH_MUNICIPALITY,
	(postalCode) => apiGet(`${urlMunicipalities}?keyword=${postalCode}`)()
);



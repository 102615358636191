import { handleActions } from "redux-actions";
import {
	FETCH_NEIGHBORHOODS,
	FETCH_POSTALCODES,
	FETCH_PROFILE,
	UPDATE_PROFILE,
	FETCH_CHECK_STEPS,
	FETCH_BILLING_DATA,
	UPDATE_BILLING_DATA,
	FETCH_EXPIRATION_CSD,
} from "../constants";

export const steps = handleActions(
	{
		[FETCH_CHECK_STEPS]: (state, action) => {
			return { ...action.payload };
		},
	},
	[]
);

export const profile = handleActions(
	{
		[FETCH_NEIGHBORHOODS]: (state, action) => {
			return {
				...state,
				neighborhoods: Array.isArray(action.payload) ? [...action.payload] : [],
			};
		},
		[FETCH_POSTALCODES]: (state, action) => {
			const zipCode = action.payload[0];
			const federalStates = action.payload[1] || [];
			const municipalities = action.payload[2] || [];
			if (!zipCode) return state;
			const federalState = federalStates.find(
				(st) => st.Value === zipCode.StateCode
			);
			const municipality = municipalities.find(
				(m) => m.Value === zipCode.MunicipalityCode
			);
			return {
				...state,
				TaxAddress: {
					ZipCode: zipCode.Value,
					...state.TaxAddress,
					State: federalState.Name,
					Municipality: municipality.Name,
				},
			};
		},
		[FETCH_PROFILE]: (state, action) => {
			let neighborhood =
				action.payload !== undefined && action.payload.TaxAddress !== undefined
					? action.payload.TaxAddress.Neighborhood
					: "";

			return {
				...state,
				...action.payload,
				neighborhoods: [
					{
						Name: neighborhood,
						Value: neighborhood,
					},
				],
			};
		},
		[UPDATE_PROFILE]: (state, action) => {
			return { ...state, ...action.payload };
		},
		[FETCH_BILLING_DATA]: (state, action) => ({
			...state,
			billingData: action.payload,
		}),
		[UPDATE_BILLING_DATA]: (state, action) => ({
			...state,
			billingData: action.payload,
		}),
		[FETCH_EXPIRATION_CSD]: (state, action) => ({
			...state,
			Csd: {
				...state.Csd,
				ExpirationDate: action.payload.date,
			},
		}),
	},
	[]
);

// import React from 'react'
import React, { useState } from "react";
import AppFrame from '../../components/AppFrame'
import OrdersNotInvoice from './OrdersNotInvoice';
import { useSelector, useDispatch /*,useDispatch }*/ } from "react-redux";
import { fetchSubscription } from "../../actions/subscription";
import { getSubscriptionDB } from "../../actions/account";
import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";
// import { BootBoxAlert } from "../../components/General/bootbox";
import { useHistory } from 'react-router';
//import GeneralSettings from "./GeneralSettings";
//import SpecificSettings from "./SpecificSettings";
import { PREMIUM, FREE } from "../../constants/plans";



const TaxSettingsPage = ( ) => {
   

    let history = useHistory();

    const dispatch = useDispatch();
    const subscription =  useSelector((store) => store.subscription);

    if(!subscription) {
        dispatch(getSubscriptionDB());
        dispatch(fetchSubscription());
    }

    console.log('suscription on reports', subscription);

    const [showModal, setShowModal] = useState( (subscription?.subscriptionPlanInfo?.Plan?.toUpperCase() !== FREE.toUpperCase() && subscription?.subscriptionPlanInfo?.Plan?.toUpperCase() !== PREMIUM.toUpperCase() ) ? true :  false);
    const [alert, setAlert] = useState((subscription?.subscriptionPlanInfo?.Plan?.toUpperCase() !== FREE.toUpperCase() && subscription?.subscriptionPlanInfo?.Plan?.toUpperCase() !== PREMIUM.toUpperCase() ) ? true  : false);
    const [alertMsg ]= useState((subscription?.subscriptionPlanInfo?.Plan?.toUpperCase() !== FREE.toUpperCase() && subscription?.subscriptionPlanInfo?.Plan?.toUpperCase() !== PREMIUM.toUpperCase() ) ? 'Este módulo solo esta disponible para la suscripicón PREMIUM ' : '');

    // if( !subscription ||  !subscription.subscriptionPlanInfo || subscription.subscriptionPlanInfo.Plan==='pre' )
    // if(subscription?.subscriptionPlanInfo?.Plan !== 'FreeEcommerce' && subscription?.subscriptionPlanInfo?.Plan !== 'PREMIUM_eCommerce'  ){
    //     setAlert(true);
    //     setShowModal(true);
    //     setAlertMsg('Este módulo solo esta disponible para la suscripicón PREMIUM ');
    // }

    return (
        <AppFrame title="Reportes">

            <section id="main-content">
                {/*<script src="/Scripts/source/shopify/MethodsShopify.js"></script>
                 <script>
                    InitializeShopify('pruebasfacturama');
                </script> */}
               <Alert
					show={alert}
					variant="primary"
					onClose={() => { setAlert(false)}}
					dismissible
				>
					<p> 
						
							{alertMsg}
						<button
							className="btn btn-outline-primary ml-3"
							onClick={() => {
								history.push(`/shopping/plans`);												
							}}
						>
							Conocer Mas
						</button>
					</p>
				</Alert>

                <input type="hidden" id="ShopName" value="pruebasfacturama" />
                <input type="hidden" id="api-token" value="cHJ1ZWJhc1Nob3BpZnkzMzoxMjM0NTY=" />
                <article id="settings-general-taxes">
                    <div className="container-fluid">
                        {/* Titulo de la pagina */}
                        <div className="row page-title">
                            <div className="col-xs-12">
                                <h4>Reportes</h4>
                            </div>                            
                        </div> {/* Fin del titulo de la página */}

                        <div className="row">
                            <div className="col-xs-12">

                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">
                                            Reportes ordenes sin factura
                                        </a>
                                                         
                                    </div>
                                </nav>
                                {/* nav-tabContent : inicio */}
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                                        <OrdersNotInvoice subscription={subscription} />
                                    </div>
                                                   
                                </div>
                                {/* nav-tabContent : fin */}

                                 

                            </div>

                        </div>
                        {/* row : fin */}

                    </div>
                </article>
                {
								<Modal show={showModal } onHide={() => { setShowModal(false) }  }>
									<Modal.Header closeButton>Reporte de Ordenes sin Factura</Modal.Header>
									<Modal.Body>
										<div className="row">
											<div className="col-12">
												<div className="form-group">
                                                    {alertMsg}
												</div>
											</div>
										</div>
									</Modal.Body>
									<Modal.Footer>
										<button
											className="btn btn-outline-secondary-cancel mr-3"
											onClick={ () => { setShowModal(false) } }
										>
											Cancelar
										</button>
										<button
											className="btn btn-outline-primary"
											onClick={() => {
												history.push(`/shopping/plans`);												
											}}
										>
											Conocer Mas
										</button>
									</Modal.Footer>
								</Modal>
							}
            </section>
            

           

        </AppFrame>
    )
}


export default TaxSettingsPage;
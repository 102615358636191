import React from 'react'
import { connect } from "react-redux";
import AppFrame from '../../components/AppFrame/AppFrame'
import ProductCard from '../../components/Shopping/ProductCard'
import {fetchFacturamaProducts} from '../../actions/shopping'
import '../../index.css'
import { Tabs } from "react-bootstrap";
import { Tab } from "bootstrap";
import PlansPage from "../SubscriptionPage/PlansPage";


class ShoppingPage extends React.Component {    
  constructor(props) {
    super(props);
        
    this.state = {            
        loading:false,
        products:null,
        defaultTab: 'plans',
    };        
} 

    componentDidMount() {
      let tab = this.props.match.params.tab;
  		if (tab) this.setState({ defaultTab: tab });
      console.log('tab on shop', this.state.defaultTab);
      this.props.fetchFacturamaProducts()
        .then((products) => {          
          console.log(products);
          this.setState({products:products.payload})
      });      
    }
    
  render() {
            

        return (



            <AppFrame title="Tienda">
                
               
                  <Tabs
                    id="controlled-shoppingTab"
                    activeKey={this.state.defaultTab}
                    onSelect={(tab) => {
                      this.setState({ defaultTab: tab });
                      this.props.history.replace(`/shopping/${tab}`);
                    }}
                    className="mb-4"                   
                  >
                    <Tab eventKey="plans" title="Planes">
                      <PlansPage />
                    </Tab>
                    <Tab eventKey="folio" title="Folios">
                      <div className="row mt-25">               
                        {
                          this.state.products &&
                            this.state.products.map((product) => (                                                            
                              <ProductCard product={product} key={product.Id}/>
                              
                          ))                                                                              
                        }      
                      </div>         
                    </Tab>
        
                  </Tabs>
                                          
                 
            </AppFrame>
        )
    }

}


// ShoppingPage = reduxForm({    
//   form: 'shopping_page'
// })(ShoppingPage)


ShoppingPage = connect(
  (state) => {        
      return {
          facturamaProducts: fetchFacturamaProducts(state),          
      };
  },
  {
    fetchFacturamaProducts      
  }
)(ShoppingPage);


export default ShoppingPage;
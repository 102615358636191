import React from "react";

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../../index.css';

const ContactUsInfo = () => {
    return (
        <>
            
            <p className="h3 text-blue-primary text-center">Contacto</p>

            <p className=""> Llamanos o envíanos un mensaje contus dudas y comentarios.</p>
            
            <div>
                <div className="block">
                    <p className="fas fa-envelope iconBlue"></p>
                    <a className="text-gray-bold " href="mailto:info@facturama.mx">info@facturama.mx</a>            
                </div>            
                
                <div className="block">
                    <p className="fas fa-phone iconBlue"></p>
                    <a href="tel:4448356098" className="text-gray-bold ">444-8356098</a>
                    <p>&nbsp;|&nbsp;</p>
                    <a href="tel:4441510191" className="text-gray-bold ">444-1510191</a>
                </div>
                        
                <div className="block">
                    <p className="fas fa-clock iconBlue"></p>       
                    <p className="text-gray-bold ">
                        Horario de atención:<br/>
                        Lunes a viernes<br/>
                        9 a.m. a 7 p.m.
                    </p>
                </div>    
            </div>
            
            
        </>
    );
};

export default ContactUsInfo;
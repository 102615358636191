import React from "react";
import {  reduxForm, SubmissionError } from "redux-form";
import { Link } from 'react-router-dom'
import { resetAccount } from "../../actions/account";
//import { setToken } from "../../actions/tokens";
import ResetPassword from "./ResetPassword";
import { connect } from "react-redux";
//import { getToken, getShopName } from "../../selectors/tokens";


class ResetPasswordForm extends React.Component {    


    submit = (values) => {
        console.log("values:" + values.usuario);
        return this.props.resetAccount({UserName:values.usuario})
        .then(({ payload }) => {            
            if (payload.result) {               
                console.log("result:" + payload.result);    
                window.location.href = '/account/ResetPwStepTwo';           
            }            
            if (payload._error) {
                throw new SubmissionError(payload);
            }
        },
        (error) => {            
            this.setState({
              isLoaded: true,
              error
            });
          });
          
    };

    render() {
        const {
            error,
            handleSubmit,                        
            submitting,            
            submitSucceeded,            
        } = this.props;        

        
		return (
<>
            {                
                
                <div className="box mt-4 xs-mt-0 py-5 px-5 xs-pt-2 xs-pb-4 xs-px-2">	
                    
                    <div className="mt-4 text-center">
						    <span className="h6 text-blue-primary"><strong>Volver a </strong> <Link to="/account/Login">"Inicia sesión"</Link> </span>
					</div>
                    <form onSubmit={handleSubmit(this.submit)} >
                        {error && (
                            <div className="alert alert-danger" role="alert">
                                {error}                                
                            </div>
                        )}
                        {submitSucceeded && (
                            <div className="alert alert-success" role="alert">
                                Se envio el mensaje para completar la recuperacion
                            </div>
                        )}           
                        
                                
                        <ResetPassword/>

                        <div>                    
                            <button
                                className="btn btn-outline-primary mt-4 full-width"
                                type="submit"
                                disabled={submitting}
                            >
                                Enviar
                            </button>
                        </div>

                        <div className="mt-4 text-center">
						    <span><strong>¿Aún no tienes una cuenta?</strong> <Link to="/account/register">Regístrate aquí</Link> </span>
					    </div>
                        
                    </form>

                </div>
                    
            }
            </>
            

            
		);
    
    }
}


    

ResetPasswordForm = reduxForm({    
    form: 'resetPasswordForm'
  })(ResetPasswordForm)


  ResetPasswordForm = connect(
    (state) => {        
        /*return {
            token: getToken(state),
            shopName: getShopName(state),    
        };*/
    },
    {
        resetAccount,
        //setToken        
    }
)(ResetPasswordForm);


export default ResetPasswordForm;
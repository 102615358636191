const CURRENCIES = [ 
    {                
        "Name": "Afghani",
        "Value": "AFN"
    },
    {                
        "Name": "Ariary malgache",
        "Value": "MGA"
    },
    {                
        "Name": "Aruba Florin",
        "Value": "AWG"
    },
    {                
        "Name": "Azerbaijanian Manat",
        "Value": "AZN"
    },
    {                
        "Name": "Baht",
        "Value": "THB"
    },
    {                
        "Name": "Balboa",
        "Value": "PAB"
    },
    {                
        "Name": "Birr etíope",
        "Value": "ETB"
    },
    {                
        "Name": "Bolívar",
        "Value": "VEF"
    },
    {                
        "Name": "Boliviano",
        "Value": "BOB"
    },
    {                
        "Name": "Burundi Franc",
        "Value": "BIF"
    },
    {                
        "Name": "Cabo Verde Escudo",
        "Value": "CVE"
    },
    {                
        "Name": "Cedi de Ghana",
        "Value": "GHS"
    },
    {                
        "Name": "Chelín keniano",
        "Value": "KES"
    },
    {                
        "Name": "Chelín somalí",
        "Value": "SOS"
    },
    {                
        "Name": "Códigos reservados específicamente para propósitos de prueba",
        "Value": "XTS"
    },
    {                
        "Name": "Colón costarricense",
        "Value": "CRC"
    },
    {                
        "Name": "Colon El Salvador",
        "Value": "SVC"
    },
    {                
        "Name": "Convertibles marca",
        "Value": "BAM"
    },
    {                
        "Name": "Córdoba Oro",
        "Value": "NIO"
    },
    {                
        "Name": "Corea del Norte ganó",
        "Value": "KPW"
    },
    {                
        "Name": "Corona checa",
        "Value": "CZK"
    },
    {                
        "Name": "Corona danesa",
        "Value": "DKK"
    },
    {                
        "Name": "Corona islandesa",
        "Value": "ISK"
    },
    {                
        "Name": "Corona noruega",
        "Value": "NOK"
    },
    {                
        "Name": "Corona sueca",
        "Value": "SEK"
    },
    {                
        "Name": "Dalasi",
        "Value": "GMD"
    },
    {                
        "Name": "DEG (Derechos Especiales de Giro)",
        "Value": "XDR"
    },
    {                
        "Name": "Denar",
        "Value": "MKD"
    },
    {                
        "Name": "Dinar argelino",
        "Value": "DZD"
    },
    {                
        "Name": "Dinar de Bahrein",
        "Value": "BHD"
    },
    {                
        "Name": "Dinar iraquí",
        "Value": "IQD"
    },
    {                
        "Name": "Dinar jordano",
        "Value": "JOD"
    },
    {                
        "Name": "Dinar kuwaití",
        "Value": "KWD"
    },
    {                
        "Name": "Dinar libio",
        "Value": "LYD"
    },
    {                
        "Name": "Dinar serbio",
        "Value": "RSD"
    },
    {                
        "Name": "Dinar tunecino",
        "Value": "TND"
    },
    {                
        "Name": "Dirham de EAU",
        "Value": "AED"
    },
    {                
        "Name": "Dirham marroquí",
        "Value": "MAD"
    },
    {                
        "Name": "Dobra",
        "Value": "STD"
    },
    {                
        "Name": "Dolar americano",
        "Value": "USD"
    },
    {                
        "Name": "Dólar Australiano",
        "Value": "AUD"
    },
    {                
        "Name": "Dolar Canadiense",
        "Value": "CAD"
    },
    {                
        "Name": "Dólar de Barbados",
        "Value": "BBD"
    },
    {                
        "Name": "Dólar de Belice",
        "Value": "BZD"
    },
    {                
        "Name": "Dólar de Bermudas",
        "Value": "BMD"
    },
    {                
        "Name": "Dólar de Brunei",
        "Value": "BND"
    },
    {                
        "Name": "Dólar de Fiji",
        "Value": "FJD"
    },
    {                
        "Name": "Dolar De Hong Kong",
        "Value": "HKD"
    },
    {                
        "Name": "Dólar de las Bahamas",
        "Value": "BSD"
    },
    {                
        "Name": "Dólar de las Islas Caimán",
        "Value": "KYD"
    },
    {                
        "Name": "Dólar de las Islas Salomón",
        "Value": "SBD"
    },
    {                
        "Name": "Dólar de Namibia",
        "Value": "NAD"
    },
    {                
        "Name": "Dólar de Nueva Zelanda",
        "Value": "NZD"
    },
    {                
        "Name": "Dolar De Singapur",
        "Value": "SGD"
    },
    {                
        "Name": "Dólar de Suriname",
        "Value": "SRD"
    },
    {                
        "Name": "Dólar de Trinidad y Tobago",
        "Value": "TTD"
    },
    {                
        "Name": "Dólar del Caribe Oriental",
        "Value": "XCD"
    },
    {                
        "Name": "Dólar estadounidense (día siguiente)",
        "Value": "USN"
    },
    {                
        "Name": "Dólar guyanés",
        "Value": "GYD"
    },
    {                
        "Name": "Dólar Jamaiquino",
        "Value": "JMD"
    },
    {                
        "Name": "Dólar liberiano",
        "Value": "LRD"
    },
    {                
        "Name": "Dong",
        "Value": "VND"
    },
    {                
        "Name": "Dram armenio",
        "Value": "AMD"
    },
    {                
        "Name": "Euro",
        "Value": "EUR"
    },
    {                
        "Name": "Florín",
        "Value": "HUF"
    },
    {                
        "Name": "Florín antillano neerlandés",
        "Value": "ANG"
    },
    {                
        "Name": "Franc WIR",
        "Value": "CHW"
    },
    {                
        "Name": "Franco CFA BCEAO",
        "Value": "XOF"
    },
    {                
        "Name": "Franco CFA BEAC",
        "Value": "XAF"
    },
    {                
        "Name": "Franco CFP",
        "Value": "XPF"
    },
    {                
        "Name": "Franco Comoro",
        "Value": "KMF"
    },
    {                
        "Name": "Franco congoleño",
        "Value": "CDF"
    },
    {                
        "Name": "Franco de Djibouti",
        "Value": "DJF"
    },
    {                
        "Name": "Franco guineano",
        "Value": "GNF"
    },
    {                
        "Name": "Franco ruandés",
        "Value": "RWF"
    },
    {                
        "Name": "Franco Suizo",
        "Value": "CHF"
    },
    {                
        "Name": "Gourde",
        "Value": "HTG"
    },
    {                
        "Name": "Guaraní",
        "Value": "PYG"
    },
    {                
        "Name": "Hryvnia",
        "Value": "UAH"
    },
    {                
        "Name": "Kina",
        "Value": "PGK"
    },
    {                
        "Name": "Kip",
        "Value": "LAK"
    },
    {                
        "Name": "Kuna",
        "Value": "HRK"
    },
    {                
        "Name": "Kwacha",
        "Value": "MWK"
    },
    {                
        "Name": "Kwacha zambiano",
        "Value": "ZMW"
    },
    {                
        "Name": "Kwanza",
        "Value": "AOA"
    },
    {                
        "Name": "Kyat",
        "Value": "MMK"
    },
    {                
        "Name": "Lari",
        "Value": "GEL"
    },
    {                
        "Name": "Lek",
        "Value": "ALL"
    },
    {                
        "Name": "Lempira",
        "Value": "HNL"
    },
    {                
        "Name": "Leona",
        "Value": "SLL"
    },
    {                
        "Name": "Leu moldavo",
        "Value": "MDL"
    },
    {                
        "Name": "Leu rumano",
        "Value": "RON"
    },
    {                
        "Name": "Lev búlgaro",
        "Value": "BGN"
    },
    {                
        "Name": "Libra de Gibraltar",
        "Value": "GIP"
    },
    {                
        "Name": "Libra de Santa Helena",
        "Value": "SHP"
    },
    {                
        "Name": "Libra egipcia",
        "Value": "EGP"
    },
    {                
        "Name": "Libra Esterlina",
        "Value": "GBP"
    },
    {                
        "Name": "Libra libanesa",
        "Value": "LBP"
    },
    {                
        "Name": "Libra malvinense",
        "Value": "FKP"
    },
    {                
        "Name": "Libra Siria",
        "Value": "SYP"
    },
    {                
        "Name": "Libra sudanesa",
        "Value": "SDG"
    },
    {                
        "Name": "Libra sudanesa Sur",
        "Value": "SSP"
    },
    {                
        "Name": "Lilangeni",
        "Value": "SZL"
    },
    {                
        "Name": "Lira turca",
        "Value": "TRY"
    },
    {                
        "Name": "Los códigos asignados para las transacciones en que intervenga ninguna moneda",
        "Value": "XXX"
    },
    {                
        "Name": "Loti",
        "Value": "LSL"
    },
    {                
        "Name": "Mercados de Bonos Unidad Europea unidad de cuenta a 17 (UCE-17)",
        "Value": "XBD"
    },
    {                
        "Name": "Mercados de Bonos Unidad Europea unidad de cuenta a 9 (UCE-9)",
        "Value": "XBC"
    },
    {                
        "Name": "México Unidad de Inversión (UDI)",
        "Value": "MXV"
    },
    {                
        "Name": "Mozambique Metical",
        "Value": "MZN"
    },
    {                
        "Name": "Mvdol",
        "Value": "BOV"
    },
    {                
        "Name": "Naira",
        "Value": "NGN"
    },
    {                
        "Name": "Nakfa",
        "Value": "ERN"
    },
    {                
        "Name": "Ngultrum",
        "Value": "BTN"
    },
    {                
        "Name": "Nuevo dólar de Taiwán",
        "Value": "TWD"
    },
    {                
        "Name": "Nuevo Shekel Israelí",
        "Value": "ILS"
    },
    {                
        "Name": "Nuevo Sol",
        "Value": "PEN"
    },
    {                
        "Name": "Oro",
        "Value": "XAU"
    },
    {                
        "Name": "Ouguiya",
        "Value": "MRO"
    },
    {                
        "Name": "Pa anga",
        "Value": "TOP"
    },
    {                
        "Name": "Paladio",
        "Value": "XPD"
    },
    {                
        "Name": "Pataca",
        "Value": "MOP"
    },
    {                
        "Name": "Peso Argentino",
        "Value": "ARS"
    },
    {                
        "Name": "Peso chileno",
        "Value": "CLP"
    },
    {                
        "Name": "Peso Colombiano",
        "Value": "COP"
    },
    {                
        "Name": "Peso Convertible",
        "Value": "CUC"
    },
    {                
        "Name": "Peso Cubano",
        "Value": "CUP"
    },
    {                
        "Name": "Peso Dominicano",
        "Value": "DOP"
    },
    {                
        "Name": "Peso filipino",
        "Value": "PHP"
    },
    {                
        "Name": "Peso Mexicano",
        "Value": "MXN"
    },
    {                
        "Name": "Peso Uruguay en Unidades Indexadas (URUIURUI)",
        "Value": "UYI"
    },
    {                
        "Name": "Peso Uruguayo",
        "Value": "UYU"
    },
    {                
        "Name": "Plata",
        "Value": "XAG"
    },
    {                
        "Name": "Platino",
        "Value": "XPT"
    },
    {                
        "Name": "Pula",
        "Value": "BWP"
    },
    {                
        "Name": "Qatar Rial",
        "Value": "QAR"
    },
    {                
        "Name": "Quetzal",
        "Value": "GTQ"
    },
    {                
        "Name": "Rand",
        "Value": "ZAR"
    },
    {                
        "Name": "Real brasileño",
        "Value": "BRL"
    },
    {                
        "Name": "Rial iraní",
        "Value": "IRR"
    },
    {                
        "Name": "Rial omaní",
        "Value": "OMR"
    },
    {                
        "Name": "Rial yemení",
        "Value": "YER"
    },
    {                
        "Name": "Riel",
        "Value": "KHR"
    },
    {                
        "Name": "Ringgit malayo",
        "Value": "MYR"
    },
    {                
        "Name": "Riyal saudí",
        "Value": "SAR"
    },
    {                
        "Name": "Rublo bielorruso",
        "Value": "BYR"
    },
    {                
        "Name": "Rublo ruso",
        "Value": "RUB"
    },
    {                
        "Name": "Rupia",
        "Value": "MVR"
    },
    {                
        "Name": "Rupia",
        "Value": "IDR"
    },
    {                
        "Name": "Rupia de Mauricio",
        "Value": "MUR"
    },
    {                
        "Name": "Rupia de Pakistán",
        "Value": "PKR"
    },
    {                
        "Name": "Rupia de Seychelles",
        "Value": "SCR"
    },
    {                
        "Name": "Rupia de Sri Lanka",
        "Value": "LKR"
    },
    {                
        "Name": "Rupia india",
        "Value": "INR"
    },
    {                
        "Name": "Rupia nepalí",
        "Value": "NPR"
    },
    {                
        "Name": "Shilling de Uganda",
        "Value": "UGX"
    },
    {                
        "Name": "Shilling tanzano",
        "Value": "TZS"
    },
    {                
        "Name": "Som",
        "Value": "KGS"
    },
    {                
        "Name": "Somoni",
        "Value": "TJS"
    },
    {                
        "Name": "Sucre",
        "Value": "XSU"
    },
    {                
        "Name": "Taka",
        "Value": "BDT"
    },
    {                
        "Name": "Tala",
        "Value": "WST"
    },
    {                
        "Name": "Tenge",
        "Value": "KZT"
    },
    {                
        "Name": "Tugrik",
        "Value": "MNT"
    },
    {                
        "Name": "Turkmenistán nuevo manat",
        "Value": "TMT"
    },
    {                
        "Name": "Unidad ADB de Cuenta",
        "Value": "XUA"
    },
    {                
        "Name": "Unidad de Fomento",
        "Value": "CLF"
    },
    {                
        "Name": "Unidad de Mercados de Bonos Unidad Europea Composite (EURCO)",
        "Value": "XBA"
    },
    {                
        "Name": "Unidad de Valor real",
        "Value": "COU"
    },
    {                
        "Name": "Unidad Monetaria de Bonos de Mercados Unidad Europea (UEM-6)",
        "Value": "XBB"
    },
    {                
        "Name": "Uzbekistán Sum",
        "Value": "UZS"
    },
    {                
        "Name": "Vatu",
        "Value": "VUV"
    },
    {                
        "Name": "WIR Euro",
        "Value": "CHE"
    },
    {                
        "Name": "Won",
        "Value": "KRW"
    },
    {                
        "Name": "Yen",
        "Value": "JPY"
    },
    {                
        "Name": "Yuan Renminbi",
        "Value": "CNY"
    },
    {                
        "Name": "Zimbabwe Dólar",
        "Value": "ZWL"
    },
    {                
        "Name": "Zloty",
        "Value": "PLN"
    }
]

export default CURRENCIES;
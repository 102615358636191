import React from "react";
import { Link } from 'react-router-dom';
import './ProductCard.css';


 const ProductCard = ({product}) => {
    return (
        <div className="col-xs-12 col-sm-8 offset-sm-2 col-md-3 offset-md-0 unlimited">
            <div className="card">
                <div className="card-block  m-top-44 features">
                    <div className="text-center">
                        
                        <i className="far fa-calendar-check fa-2x text-primary"></i>
                        <h5 className="text-primary mt-2">{product.Name}</h5>
                        <strong className="price">
                            {
                                (product.Price > 1)?
                                <span>${(product.Price * 1.16).toFixed(0)}</span>
                                :
                                <span>${(product.Price * 1.16).toFixed(2)}</span>
                            }
                            
                            
                            <small className="normal lgx-max-block"><span className="text-uppercase">MXN</span></small>
                        </strong>
                        
                    </div>
                    <p className="text-center">
                        {product.Description}
                    </p>
                    {/* <ul>                                    
                        <li><i className="icon-check-rounded text-primary"></i> IVA incluido</li>
                    </ul> */}
                    <div className="cta mt-5">
                        <Link className="btn btn-primary btn-block btn-lg" to={`/payment-checkout/${product.Id}`} name="button">
                            <i className="fas fa-check"></i> Comprar
                        </Link>

                    </div>

                </div>
            </div>
        </div>
    );
};

export default ProductCard
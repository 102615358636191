import React, { useEffect } from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import { getStoreSettingsAction, logoutAction, getSubscriptionDB } from "../../actions/account";
import { getInfoCSD } from "../../actions/profile";
import { fetchSubscription } from "../../actions/subscription";
import { getFolios, getStoreSettings } from "../../selectors/accounts";
import { getExpirationDateCSD, getProfile } from "../../selectors/profile";
import { PREMIUM, FREE } from "../../constants/plans";
import "../../reducers/account";
require("typeface-montserrat");

$ && console.log("jQuery initialized");
Popper && console.log("Popper initialized");

let AppFrame = ({
	children,
	title,
	getStoreSettingsAction,
	settingsStore,
	folios,
	csdExpirationDate,
	logoutAction,
	profile,
}) => {
	const dispatch = useDispatch();
	const ecommerceType = useSelector((store) => store.settingsStore.store);
	const subscription =  useSelector((store) => store.subscription);
	
	let days;

	useEffect(() => {
		if (!settingsStore) dispatch(getStoreSettingsAction());
		if(!subscription) {
			dispatch(getSubscriptionDB());
			dispatch(fetchSubscription());
		}
	});
	
	days = Math.round(
		(new Date(csdExpirationDate) - new Date()) / (1000 * 60 * 60 * 24)
	);

	const onLogout = () => {
		logoutAction();
	};

	return (
		<>
			<div className="d-flex flex-column flex-md-row align-items-center px-md-4 mb-3 bg-white border-bottom shadow-sm">
				<div className="borderRight p-2">
					<h3 className="my-0 mr-md-auto font-weight-normal">
						<img
							src="/images/f-logo.jpg"
							className="logoF"
							alt="Facturama Logo"
						/>
					</h3>
				</div>
				<h3 className="p-2 text-gray-bold-title alingSenfCenter">
					{" "}
					{title && ` ${title}`}
				</h3>
				<div className="d-flex flex-column flex-md-row  p-2 ml-auto">
					<div className="px-2 alingSenfCenter">
						<small>Facturas restantes:</small>
						<span className="text-blue-primary font-weight-bold ">
							{ (subscription?.subscriptionPlanInfo?.Plan?.toUpperCase() === FREE.toUpperCase()  ) 
								? 	<span
										className="fas fa-infinity  alignVertical"
										title="Folios Ilimitados"
									></span>
								: (subscription?.subscriptionPlanInfo?.CurrentFolios === '0' 
									? 	<span
											className="fas fa-ban iconRed alignVertical"
											title="0"
										></span>
									: subscription?.subscriptionPlanInfo?.CurrentFolios)
							 }
						</span>
					</div>
					<Link
						to="/invoices/list"
						className="btn btn-outline-secondary mr-3 second-step"
					>
						Consultar
					</Link>
					<div className="dropdown">
						<button
							className="btn btn-outline-primary dropdown-toggle "
							id="dropdownMenuButton"
							data-toggle="dropdown"
						>
							Menu
						</button>
						<div className="dropdown-menu dropdown-menu-right">
							<Link className="dropdown-item" to="/">
								Inicio
							</Link>
							<Link className="dropdown-item " to="/settings/profile">
								Información fiscal
							</Link>
							<Link className="dropdown-item" to="/catalogs/list">
								Administrar catálogos
							</Link>
							<Link className="dropdown-item " to="/settings/taxes">
								Configurar impuestos
							</Link>
							{ecommerceType &&
								ecommerceType.EcommerceTypeName &&
								ecommerceType.EcommerceTypeName.toUpperCase() !== "EPOSNOW" &&
								settingsStore &&
								settingsStore.DisplayAccountSettings && (
									<Link className="dropdown-item" to="/settings/account">
										Ajustes de Autofacturación
									</Link>
								)}
							<Link
								className="dropdown-item"
								target="_blank"
								to="/web3/createInvoice"
							>
								Crear factura
							</Link>
							<Link className="dropdown-item" to="/settings/Reports">
								Reporte
							</Link>
							{settingsStore && settingsStore.DisplayCartFolios && (
								<div>
									<div className="dropdown-divider"></div>
									<Link className="dropdown-item" to="/shopping">
										Carrito de Compras
									</Link>
								</div>
							)}
							<button
								className="dropdown-item closeSession"
								onClick={onLogout}
								type="button"
							>
								Cerrar Sesión
							</button>
						</div>
					</div>
				</div>
			</div>
			{days <= 15 ? (
				days > 0 ? (
					<div className="alert alert-danger alertMargin">
						Tu sello digital <strong>expira </strong> en{" "}
						{" " + days + (days === 1 ? " día " : " días ")}
						<Link to="/settings/profile/certificates" className="alert-link">
							Renovar
						</Link>
					</div>
				) : (
					<div className="alert alert-danger alertMargin">
						Tu sello digital <strong>ha expirado</strong>{" "}
						<Link to="/settings/profile/certificates" className="alert-link">
							Renovar
						</Link>
					</div>
				)
			) : (
				""
			)}
			
			{ new Date(subscription?.subscriptionPlanInfo?.ExpirationDate) <= new Date() 
				? 	<div className="alert alert-danger  alertMargin">
						Tu subscripción <strong>ha expirado </strong>{" "}
							<Link to="/shopping/plans" className="alert-link">
								Renovar
							</Link>
					</div>
				:   (subscription?.subscriptionPlanInfo?.CurrentFolios === '0' && (subscription?.subscriptionPlanInfo?.Plan?.toUpperCase() !== FREE.toUpperCase() && subscription?.subscriptionPlanInfo?.Plan?.toUpperCase() !== PREMIUM.toUpperCase() ) )
					? 	<div className="alert alert-danger  alertMargin">
							Tus folios se <strong>han agotado </strong>{" "}
								<Link to="/shopping/folio" className="alert-link">
									Recargar mas folios
								</Link>
						</div>
					: ''
			

			}
				{/* TODO: revisar el nombre de unrenewed */}
			
			{ subscription?.subscriptionPlanInfo?.Plan === 'PremiunUnRenewed'
				? 	<div className="alert alert-primary  alertMargin">
						Cuentas con una subscripción <strong>sin renovar </strong>{" "}
							<Link to="/shopping/plans" className="alert-link">
								Renovar
							</Link>
					</div>
				:  ''
			

			}
				
			
			<div className="container">{children}</div>
		</>
	);
};

AppFrame.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
};

AppFrame = connect(
	(state) => {
		return {
			settingsStore: getStoreSettings(state),
			folios: getFolios(state),
			csdExpirationDate: getExpirationDateCSD(state),
			profile: getProfile(state),
		};
	},
	{
		getStoreSettingsAction,
		fetchSubscription,
		getSubscriptionDB,
		getInfoCSD,
		logoutAction,
		//  setToken
	}
)(AppFrame);
export default AppFrame;

import { handleActions } from "redux-actions";
import {
	LOGIN,
	REGISTER,
	INSERT_SETTING,
	FETCH_SETTINGS,
	FETCH_SETTINGS_CFDIVERSION,
	FETCH_BRANCHOFFICES_SERIE,
	GET_ECOMMERCE_ACCESS,
	STORE_SETTINGS,
} from "../constants";

export const account = handleActions(
	{
		[LOGIN]: (state, action) => {
			return [...state, action.payload];
		},
		[REGISTER]: (state, action) => {
			return [...state, action.payload];
		},
		[FETCH_BRANCHOFFICES_SERIE]: (state, action) => {
			return [...state, action.payload];
		},
	},
	[]
);
export const settings = handleActions(
	{
		[FETCH_SETTINGS]: (state, action) => {
			if (action.hasOwnProperty("payload")) {
				let paymentsForm = [
					...action.payload.AllPaymentForm
				];
				let index = paymentsForm.findIndex((payment) => payment.Name === 'Por definir' );
				paymentsForm.splice(index, 1);

				return {
					...action.payload,
					AllPaymentForm: paymentsForm
				};
			}
			else alert("No se procesos a mostrar.");
		},
		[FETCH_SETTINGS_CFDIVERSION]: (state, action) => {
			return{
				...action.payload,
			};
		},

		[INSERT_SETTING]: (state, action) => {			
			return {
				...state,
				AllPaymentsUser: action.payload.AllPaymentsUserEcommerce.map((p) => ({
					Value: p.Code,
				})),
			};
		},
	},
	null
);

export const settingsStore = handleActions(
	{
		[STORE_SETTINGS]: (state, action) => {
			return { ...state, store: action.payload };			
		},
	},
	[]
);
export const getSeries = handleActions(
	{
		[FETCH_BRANCHOFFICES_SERIE]: (state, action) => {
			if (action.hasOwnProperty("payload")) return action.payload;
			else alert("No se procesos a mostrar.");
		},
	},
	[]
);

export const getEcommerceAccess = handleActions(
	{
		[GET_ECOMMERCE_ACCESS]: (state, action) => {
			if (action.hasOwnProperty("payload")) return action.payload;
			else alert("No se procesos a mostrar.");
		},
	},
	[]
);

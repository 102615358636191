import {
	FETCH_CARDS,
	DETAIL_CARD,
	INSERT_CARD,
	UPDATE_CARD,
	DELETE_CARD,
} from "./../constants";
import { createAction } from "redux-actions";
import { apiDelete, apiGet, apiGetById, apiPost, apiPut } from "../api";
import { urlCards } from "../api/urls";

export const fetchCards = createAction(FETCH_CARDS, async () => {
	try {
		return await apiGet(urlCards)();
	} catch (e) {
		console.log('error en fetchcards', e);
		return [];
	}
});

export const fetchCardById = createAction(DETAIL_CARD, (id) =>
	apiGetById(urlCards, id)()
);

export const insertCard = createAction(INSERT_CARD, (card) =>{
	// console.log("data card", card);
	return apiPost(urlCards, card)()
});

export const updateCard = createAction(UPDATE_CARD, (card) =>
	apiPut(urlCards, card.Id, card)()
);

export const deleteCard = createAction(DELETE_CARD, (id) =>
	apiDelete(urlCards, id)()
);

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Link } from "react-router-dom";
import InputField from "../InputField";
import { isRequired, number } from "../../validations";
import Loader from "../General/loader";
import { useHistory } from "react-router";

import { connect } from "react-redux";

import { fetchCards } from "../../actions/cards";
// import { getSubscription } from "../../actions/account";
// import { fetchSubscription } from "../../actions/subscription";
import { getsubscriptionPlanInfo } from "../../selectors/accounts";
import { getDefaultCard } from "../../selectors/cards";

const capitalize = (value) => (!value ? value : value.toUpperCase());

let CardForm = ({
	handleSubmit,
	error,
	titleButton,
	submitting,
	action,
	actionWhenUpdated,
	actionName,
	initialValues,
	numberOfCards,
	plan,
	disabledButton
}) => {
	let history = useHistory();
	const now = new Date();
	const years = Array.from({ length: 10 }, (_, i) => i + now.getFullYear());
	const months = [...Array.from({ length: 12 }, (_, i) => i )];
	const cardNumber = initialValues?.Number;
	const nmbrCards = numberOfCards;

	const [alert, setAlert] = useState("");
	const [alertType, setAlertType] = useState("alert-primary");

	const showAlert = (message, alertType) => {
		setAlert(message);
		setAlertType(alertType);
		// return new Promise((resolve) => {
		// 	setTimeout(() => {
		// 		resolve();
		// 		setAlert("");
		// 	}, 4000);
		// });
	};

	const onSubmit = (card) => {

		//  console.log("card", card);
		// return;
		let actionsToDo = [];

		if(actionName === "updateCard" )
			actionsToDo.push(action(card));
		else
			actionsToDo.push(action(plan, card));

		return Promise.all(actionsToDo)
			.then(() => {
				// console.log('return', r);

				if((actionName === "updateCard" ) && nmbrCards > 0)
					return actionWhenUpdated(card.Id)
				if((actionName === "updateCard" ) && nmbrCards === 0)
					return undefined;
				if(actionName === "updateSubscriptionWithCard")
					showAlert("Los cambios se guardaron exitosamente", "alert-success")
				else
					history.goBack();
				return undefined;
			})
			.then(() => {
				// console.log('se va a actualizar....');
				return fetchCards();		
			})
			.then(() => {
				// console.log('directo al mensaje....');
				showAlert("Los cambios se guardaron exitosamente", "alert-success");

			})
			.catch((e) => {
				console.log('error', e);
				const error = { ...e, ...e?.Card };
				throw new SubmissionError(error);
			});
	};

	return (
		<>
			<Loader show={submitting} />
			<form onSubmit={handleSubmit(onSubmit)}>
				{alert && (
					<div className={`alert ${alertType}`} role="alert">
						{alert}
					</div>
				)}
				{error && (
					<div className="alert alert-danger" role="alert">
						{error}
					</div>
				)}
				<div className="row mb-3">
					<div className="col-md-3">
						<small>Tarjetas de Crédito</small>
						<br></br>
						<img
							alt="credit"
							src="/images/credit-cards.png"
							className="img-fluid"
						/>
					</div>
					<div className="col-md-9">
						<small>Tarjetas de Débito</small>
						<br></br>
						<img
							alt="debit"
							src="/images/debit-cards.png"
							className="img-fluid"
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-12 form-group">
						<Field
							type="hidden"
							component={InputField}
							className="form-control"
							placeholder="Nombre como aparece en tu tarjeta"
							name="Id"
						/>
						<Field
							label="Nombre del tarjetahabiente:"
							type="text"
							component={InputField}
							className="form-control"
							placeholder="Nombre como aparece en tu tarjeta"
							name="Name"
							normalize={capitalize}
							validate={[isRequired]}
						/>
					</div>
					<div className="col-12 form-group">
						<Field
							label="Número de la tarjeta:"
							type="number"
							className="form-control"
							name="Number"
							placeholder={
								cardNumber || "Número de tu tarjeta de crédito (16 dígitos)"
							}
							component={InputField}
							maxLength="19"
							validate={[isRequired]}
						/>
					</div>
					<div className="col-md-4 form-group validity">
						<Field
							label="Valida hasta:"
							component={InputField}
							type="select"
							name="ExpirationMonth"
							className="form-control"
							validate={[isRequired]}
						>
							<option value="">Mes</option>
							{months.map((month) => {
								const value = `0${month+1}`.slice(-2);
								return (
									<option value={month} key={month}>
										{value}
									</option>
								);
							})}
						</Field>
					</div>
					<div className="col-md-4 form-group validity">
						<Field
							label="Año:"
							component={InputField}
							type="select"
							name="ExpirationYear"
							className="form-control"
							validate={[isRequired]}
						>
							<option value="">Año</option>
							{years.map((year) => (
								<option value={year - 2000} key={year}>
									{year}
								</option>
							))}
						</Field>
					</div>
					<div className="col-md-4 form-group">
						<Field
							label="CCV:"
							className="form-control"
							type="password"
							name="Cvv2"
							placeholder="CVC"
							data-msg-pattern="Solo números"
							maxLength="4"
							component={InputField}
							validate={[isRequired, number]}
						/>
					</div>
				</div>

				<div className="row mt-3 text-right">
					<div className="col-12 form-group dflex">
						{actionName === "updateSubscriptionWithCard" ? (
							<Link
								to="/settings/profile/subscription"
								className="btn btn-outline-secondary mr-3"
							>
								Regresar
							</Link>
						) : (
							<Link
								to="/settings/profile/profile"
								className="btn btn-outline-secondary mr-3"
							>
								Saltar
							</Link>
						)}
						<button
							// className="btn-outline-primary form-control"
							className="btn btn-outline-primary"
							type="submit"
							disabled={submitting || disabledButton}
						>
							{titleButton ? titleButton : "Guardar tarjeta"}
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

CardForm.propTypes = {
	titleButton: PropTypes.string,
	handleSubmit: PropTypes.func.isRequired,
	submit: PropTypes.func.isRequired,
};

CardForm = reduxForm({
	form: "CardFormSubscription",
})(CardForm);

CardForm = connect(
	(state) => {
		return {
			subsPlanInfo: getsubscriptionPlanInfo(state),
			card: getDefaultCard(state),
		};
	},
	{
		// getSubscription,
		// fetchSubscription,
		fetchCards,
		
	}
)(CardForm);

export default CardForm;

const PAYMENT_FORMS = [
	{
		Name: "Efectivo",
		Value: "01",
		PayableIn: ["oxxo", "store"],
	},
	{
		Name: "Cheque nominativo",
		Value: "02",
		PayableIn: [],
	},
	{
		Name: "Transferencia electrónica de fondos",
		Value: "03",
		PayableIn: ["bank"],
	},
	{
		Name: "Tarjeta de crédito",
		Value: "04",
		PayableIn: ["card"],
	},
	{
		Name: "Monedero electrónico",
		Value: "05",
	},
	{
		Name: "Dinero electrónico",
		Value: "06",
		PayableIn: [],
	},
	{
		Name: "Vales de despensa",
		Value: "08",
	},
	{
		Name: "Dación en pago",
		Value: "12",
		PayableIn: [],
	},
	{
		Name: "Pago por subrogación",
		Value: "13",
	},
	{
		Name: "Pago por consignación",
		Value: "14",
	},
	{
		Name: "Condonación",
		Value: "15",
	},
	{
		Name: "Compensación",
		Value: "17",
	},
	{
		Name: "Novación",
		Value: "23",
	},
	{
		Name: "Confusión",
		Value: "24",
	},
	{
		Name: "Remisión de deuda",
		Value: "25",
	},
	{
		Name: "Prescripción o caducidad",
		Value: "26",
	},
	{
		Name: "A satisfacción del acreedor",
		Value: "27",
		PayableIn: [],
	},
	{
		Name: "Tarjeta de débito",
		Value: "28",
		PayableIn: ["card"],
	},
	{
		Name: "Tarjeta de servicios",
		Value: "29",
	},
	{
		Name: "Aplicación de anticipos",
		Value: "30",
	},
	{
		Name: "Intermediarios",
		Value: "31",
	},
	{
		Name: "Por definir",
		Value: "99",
	},
	
];

export default PAYMENT_FORMS;

import React from 'react'
import { Link } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../index.css';



require('typeface-montserrat');

export const NotFoundPage = () => {

    return (
        <>
        <section className="bg-404">    

            <div className="text-center">                                    
                <img src="/images/404.png" className="img-fluid" alt="Error 404, elemento no encontrado Facturama" />                
            </div>        

            <div className="text-center">
                <h1 className="h3 text-white">Lo sentimos, la página que estás buscando no existe.</h1>
            </div>

            <div className="text-center">            
                <Link to="/" className="btn btn-outline-secondary mr-3 mt-5">Ir al Inicio</Link>
                <Link to="/invoices/list" className="btn btn-outline-secondary mt-5">Ir a Consultar</Link>
            </div>
    
        </section>
                                                
        </>
    );    
}
import {
    INSERT_PROCESS_BATCH,    
} from "./../constants";
import { createAction } from "redux-actions";
import { apiPost2} from "../api";
import {
    urlProcessBatch,
   
} from "../api/urls";
//reporte 
export const insertProcessBatch = createAction(
    INSERT_PROCESS_BATCH,
    (param) => { return apiPost2(`${urlProcessBatch}`,param)();}
);
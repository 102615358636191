import React, { useState } from "react";
// import PropTypes from 'prop-types'
import status from "./verified.png";
import Moment from "moment";

const CertificatesForm = ({
	currentCSD,
	handleUploadCertificates,
	sendedCSD,
	responseCsd,
}) => {
	const [CSD, setCSD] = new useState({
		Certificate: null,
		PrivateKey: null,
		PrivateKeyPassword: null,
	});

	const [labelNameFileCer, setLabelNameFileCer] = new useState("Buscar .CER");
	const [labelNameFileKey, setLabelNameFileKey] = new useState("Buscar .KEY");
	//const [isValidCsd, setIsValidCsd] = new useState("");

	if (currentCSD !== undefined && CSD == null) {
		setCSD(currentCSD);
		//setIsValidCsd("is-valid");
	}

	const setCSDAttribute = (e) => {
		let attribute = e.target?.name;
		let files = null;
		switch (attribute) {
			case "Certificate":
			case "PrivateKey":
				files = e.target.files;
				if (files.length > 0) {
					setBase64Attribute(files[0], attribute);
				}
				attribute === "Certificate"
					? setLabelNameFileCer(files[0]?.name)
					: setLabelNameFileKey(e.target.files[0]?.name);
				setCSD({ ...CSD, PrivateKeyPassword: "" });
				break;
			case "PrivateKeyPassword":
				setCSD({ ...CSD, PrivateKeyPassword: e.target.value });
				break;
			default:
				break;
		}
	};

	const setBase64Attribute = (file, attrName) => {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			let content = reader.result.split(",");
			if (content.length > 1) setCSD({ ...CSD, [attrName]: content[1] });
		};
		reader.onerror = function (error) {
			console.log("Error: ", error);
		};
	};

	return (
		<div className="card">
			<div className="card-body">
				<div className="form-group">
					<span name="Message" id="csd-errors"></span>
				</div>
				<div className="form-row">
					<div className="form-group col-md-11">
						<label>Sube tu archivo .CER</label>
						<div className="mb-0">
							<div className="custom-file">
								<input
									type="file"
									className="custom-file-input "
									required=""
									accept=".cer"
									name="Certificate"
									onChange={(e) => setCSDAttribute(e)}
								/>
								<label className="custom-file-label">{labelNameFileCer}</label>
							</div>
						</div>
					</div>
					{(sendedCSD ||
						(responseCsd &&
							responseCsd.ExpirationDate &&
							responseCsd.ExpirationDate !== "")) && (
						<div className="form-row col-md-1 margin-auto">
							<img src={status} className="csd-success" alt="verified" />
						</div>
					)}
				</div>

				<div className="form-row">
					<div className="form-group col-md-11">
						<label>Sube tu archivo .KEY</label>
						<div className="mb-0">
							<div className="custom-file">
								<input
									{...CSD.PrivateKey}
									type="file"
									className="custom-file-input "
									required=""
									accept=".key"
									name="PrivateKey"
									onChange={(e) => setCSDAttribute(e)}
								/>
								<label className="custom-file-label">{labelNameFileKey}</label>
							</div>
						</div>
					</div>
					{sendedCSD && (
						<div className="form-row col-md-1 margin-auto">
							<img src={status} className="csd-success" alt="verified" />
						</div>
					)}
				</div>
				<div className="form-row">
					<div className="form-group col-md-11">
						<label>Contraseña de los sellos digitales*</label>
						<input
							defaultValue={
								responseCsd &&
								responseCsd.PrivateKeyPassword &&
								responseCsd.PrivateKeyPassword !== "" &&
								responseCsd.PrivateKeyPassword
							}
							type="text"
							className="form-control"
							placeholder="Contraseña"
							required=""
							name="PrivateKeyPassword"
							onChange={(e) => setCSDAttribute(e)}
						/>
					</div>
					{sendedCSD && (
						<div className="form-group col-md-1 margin-auto">
							<img src={status} className="csd-success" alt="verified" />
						</div>
					)}
				</div>

				<div className="form-row">
					<div className="col-xs-12">
						<small className="text-muted">
							Esta contraseña corresponde a los Sellos Digitales según sea el
							caso de los archivos cargados previamente.
						</small>
						<br></br>
						<small className="text-muted">
							En este campo no hay que definir una nueva contraseña ni volver a
							escribir la del acceso a Facturama.
						</small>
					</div>
				</div>
				<div className="form-row">
					<div className="col-xs-12 col-sm-6 col-md-6">
						<label>Número de certificado:</label>
						<b className="text-darker">
							<span id="txtNumCertCSD">
								{responseCsd &&
									responseCsd.SerialNumber &&
									responseCsd.SerialNumber !== "" &&
									responseCsd.SerialNumber}
							</span>
						</b>
					</div>
					<div className="col-xs-12 col-sm-6 col-md-6">
						<label>Vigencia:</label>
						<b className="text-darker">
							<span id="txtVigenciaCertCSD">
								{responseCsd &&
									responseCsd.ExpirationDate &&
									responseCsd.ExpirationDate !== "" &&
									Moment(responseCsd.ExpirationDate).format("YYYY/MM/DD")}
							</span>
						</b>
					</div>
				</div>

				<div className="form-row">
					<div className="form-group col-md-11 d-flex justify-content-end">
						<div
							className="btn btn-outline-primary"
							type="button"
							onClick={() => handleUploadCertificates(CSD)}
						>
							Cargar archivos
						</div>
					</div>
					<div className="form-group col-md-1 margin-auto"></div>
				</div>
			</div>
		</div>
	);
};

// CertificatesForm.propTypes = {

// }

export default CertificatesForm;

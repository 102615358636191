import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PaymentStatusSelect } from "./ListInvoicesControls";

//import { connect } from 'react-redux';
/*import PropTypes from 'prop-types'
import {fetchOrders} from "../../actions/orders";
import { connect } from 'react-redux';
import {
    getOrders,
    
} from "../../selectors/orders";
*/

class ListInvoicesFilter extends React.Component {
	constructor(props) {
		super(props);
		const d = new Date();
		d.setMonth(d.getMonth() - 1);
		this.state = {
			startDate: d,
			endDate: new Date(),
			ordernumber: "",
			status: "",
			NoPos: "",
		};
	}
	handleNumberOrderChange = (e) => {
		this.setState({ ordernumber: e.target.value });
	};
	handleStatusChange = (e) => {
		let s = "";
		if (e.target.value === "Completed") s = "1";
		else if (e.target.value === "Held") s = "7";
		else if (e.target.value === "Ordered") s = "8";
		else s = e.target.value;
		this.setState({ status: s });
	};
	handleNoPosChange = (e) => {
		this.setState({ NoPos: e.target.value });
	};
	handleListClick = () => {
		console.log("buscar:evento en filtro");
		this.props.clickHandler(
			this.state.startDate,
			this.state.endDate,
			this.state.ordernumber,
			this.state.status,
			this.state.NoPos
		);
	};

	componentDidMount = () => {
		/* 
        console.log("inicioliza datos componente")
        fetchOrdersStatus=()=>{    
            
           alert("entra");
        };*/
		this.props.clickHandler(
			this.state.startDate,
			this.state.endDate,
			this.state.ordernumber,
			this.state.status,
			this.state.NoPos
		);
	};
	render() {
		return (
			<div className="row">
				<div className="col-2">
					<div className="form-group">
						<label>Número de orden:</label>
						<input
							type="text"
							className="form-control"
							onChange={this.handleNumberOrderChange}
						/>
					</div>
				</div>

				<div className="col-2">
					<div className="form-group">
						<label>Estado del pago:</label>
						<PaymentStatusSelect
							handleStatus={this.handleStatusChange}
							ordersStatus={this.props.ordersStatus}
							defaultValue={"all"}
						/>
					</div>
				</div>

				<div className="col-2">
					<div className="form-group">
						<label>Fecha inicial:</label>
						<DatePicker
							dateFormat="dd/MM/yyyy"
							className="form-control calendar"
							selected={this.state.startDate}
							onChange={(date) => this.setState({ startDate: date })}
						/>
					</div>
				</div>

				<div className="col-2">
					<div className="form-group">
						<label>Fecha final:</label>
						<DatePicker
							dateFormat="dd/MM/yyyy"
							className="form-control calendar"
							selected={this.state.endDate}
							onChange={(date) => this.setState({ endDate: date })}
						/>
					</div>
				</div>

				{/* <div className="col-2">
                    <div className="form-group">
                        <label>No. POS:</label>
                        <input type="text" className="form-control" onChange={this.handleNoPosChange}/>
                    </div>
                </div> */}

				<div className="col-2">
					<div className="form-group pt-3">
						<button
							onClick={this.handleListClick}
							className="btn btn-outline-primary mt-12"
						>
							Buscar
						</button>
					</div>
				</div>
			</div>
		);
	}
}
export default ListInvoicesFilter;
//export default connect(null,{fetchOrdersStatus,clickHandler})(ListInvoicesFilter)

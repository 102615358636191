import { handleActions } from "redux-actions";
import {
	FETCH_CARDS,
	DETAIL_CARD,
	INSERT_CARD,
	UPDATE_CARD,
	DELETE_CARD,
	UPDATE_SUBSCRIPTION,
} from "../constants";

export const cards = handleActions(
	{
		[UPDATE_SUBSCRIPTION]: (state, action) => [],
		[FETCH_CARDS]: (state, action) =>
			action.error ? state : [...action.payload],
		[DETAIL_CARD]: (state, action) =>
			action.error ? state : [...state, action.payload],
		[INSERT_CARD]: (state, action) =>
			action.error ? state : [...state, action.payload],
		[UPDATE_CARD]: (state, action) =>
			action.error ? state : [...state, action.payload],
		[DELETE_CARD]: (state, action) =>
			action.error ? state : state.filter((c) => c.id !== action.payload),
	},
	[]
);

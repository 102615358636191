import React from 'react'
import numeral from "numeral";


const TotalsBox =  ({ amounts }) => {

    amounts = {...amounts, 
                Total : numeral(amounts !== undefined && amounts.hasOwnProperty("Total") ? amounts.Total : 0).format("$0,0.00"),
                SubTotal : numeral(amounts !== undefined && amounts.Subtotal !== undefined ? amounts.Subtotal : 0).format("$0,0.00"),
                IVA16 : numeral(amounts !== undefined && amounts.IVA16 !== undefined ? amounts.IVA16 : 0).format("$0,0.00"),
                IVA8 : numeral(amounts !== undefined && amounts.IVA8 !== undefined ? amounts.IVA8 : 0).format("$0,0.00"),
                IVA0 : numeral(amounts !== undefined && amounts.IVA0 !== undefined ? amounts.IVA0 : 0).format("$0,0.00"),
                IEPS : numeral(amounts !== undefined && amounts.IEPS !== undefined ? amounts.IEPS : 0).format("$0,0.00"),
                Discount : numeral(amounts !== undefined && amounts.Discount !== undefined ? amounts.Discount : 0).format("$0,0.00"), 
            }    

    return (
        <>

            <div className="row bb-gray py-2">
                <div className="col-12">
                    <label><b>Totales:</b></label>
                </div>
                <div className="col-6">
                    <span>Subtotal</span>
                </div>    
                <div className="col-6">
                    <span><b>{amounts.SubTotal}</b></span>
                </div>    
            </div>    

            <div className="row bb-gray py-2">
                <div className="col-6">
                    <span>Descuento</span>
                </div>    
                <div className="col-6">
                    <span><b>{amounts.Discount}</b></span>
                </div>    
            </div> 

            {amounts.hasOwnProperty("IEPS") ?
            <div className="row bb-gray py-2">
                <div className="col-6">
                    <span>IEPS</span>
                </div>    
                <div className="col-6">
                <span><b>{amounts.IEPS}</b></span>
                </div>    
            </div> 
            : ""}
            <div className="row bb-gray py-2">
                <div className="col-6">
                    <span>IVA 0.16 %</span>
                </div>    
                <div className="col-6">
                <span><b>{amounts.IVA16}</b></span>
                </div>    
            </div>    
            <div className="row bb-gray py-2">
                <div className="col-6">
                    <span>IVA 0.08 %</span>
                </div>    
                <div className="col-6">
                <span><b>{amounts.IVA8}</b></span>
                </div>    
            </div> 
            <div className="row bb-gray py-2">
                <div className="col-6">
                    <span>IVA 0 %</span>
                </div>    
                <div className="col-6">
                <span><b>{amounts.IVA0}</b></span>
                </div>    
            </div> 

            <div className="row bb-gray py-2">
                <div className="col-6">
                    <span>Total</span>
                </div>    
                <div className="col-6">
                <span><b>{amounts.Total}</b></span>
                </div>    
            </div>    
        
        </>
    )
}


export default TotalsBox

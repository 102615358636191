import { combineReducers } from "redux";
import { products } from "./products";
import { customers } from "./customers";
import { profile, steps } from "./profile";
import { orders } from "./orders";
import {
	account,
	settings,
	settingsStore,
	getSeries,
	getEcommerceAccess,
} from "./account";
import { subscription } from "./subscription";
import { cards } from "./cards";
import { tokens } from "./tokens";
import { branchOffices } from "./branch_offices";
import { generalSettings, massive_process } from "./configure_tax";
import { insertProcessBatch } from "./reports";
import { invoice } from "./invoice";

import { reducer as reduxForm } from "redux-form";
import { LOGOUT } from "../constants";

var reducers = combineReducers({
	branchOffices,
	customers,
	profile,
	steps,
	products,
	orders,
	invoice,
	account,
	settings,
	settingsStore,
	tokens,
	massive_process,
	generalSettings,
	getSeries,
	insertProcessBatch,
	getEcommerceAccess,
	subscription,
	cards,
	form: reduxForm,
});

const rootReducer = (state, action) => {
	if (action.type === LOGOUT) {
		return reducers(undefined, action);
	}
	return reducers(state, action);
};

export default rootReducer;

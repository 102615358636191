import React from "react";
import { reduxForm } from "redux-form";
import ContactUs from "./ContactUs";
import { connect } from "react-redux";
import { contactUsSendMail } from "../../actions/contactUs";

class ContactUsForm extends React.Component {
	constructor() {
		super();
		this.state = {
			redirect: false,
			helpNeeded: false,
			url: "",
		};
	}

	submit = (values) => {
		const valuesr = {
			Name: values.Name,
			Email: "contact@facturama.mx",
			//Rfc: "ESO1202108R2",
			Phone: values.Phone,
			Message: values.Message + " celular de contacto: " + values.phone + " email de contacto: " +  values.Email,
			Subject: "Importante / Contactanos",
			// To: values.Email,
			To: "hramirez@facturama.mx, conchita@facturama.mx, norma@facturama.mx, johan@facturama.mx",
			ContactType: 1,
		};

		return this.props.contactUsSendMail(valuesr).then(
			({ r }) => {
				console.log("return", r);
			},
			(error) => {
				this.setState({
					isLoaded: true,
					error,
				});
			}
		);
	};

	// renderRedirect = () => {
	//     if (this.state.redirect) {
	//       return <Redirect to={this.state.url} />
	//     }
	//   }

	render() {
		const { error, handleSubmit, submitting, submitSucceeded } = this.props;

		return (
			<div className="box mt-4 xs-mt-0 py-5 px-5 xs-pt-2 xs-pb-4 xs-px-2">
				{/* {this.renderRedirect()}             */}

				<form onSubmit={handleSubmit(this.submit)}>
					{error && (
						<div className="alert alert-danger" role="alert">
							{error}
						</div>
					)}
					{submitSucceeded && (
						<div className="alert alert-success" role="alert">
							Correo enviado correctamente
						</div>
					)}

					<ContactUs mailNeeded="true" title="Buzón Facturama" />

					<div>
						<button
							className="btn btn-outline-primary mt-4 full-width"
							type="submit"
							disabled={submitting}
						>
							Enviar
						</button>
					</div>

					{/* <button
                        type = "button"
                        className="btn btn-outline-primary mt-4 full-width"
                        onClick={this.needHelp()}
                    >
                    Necesito Ayuda
                    </button> */}
				</form>
			</div>
		);
	}

	// needHelp = () => {
	//     this.state.helpNeeded = !this.state.helpNeeded ;
	//     console.log("necesito ayuda");
	//     console.log(this.state.helpNeeded);
	// };
}

ContactUsForm = connect(
	(state) => {
		return {
			token: "cmgtoken",
			shopName: "cmgShopName",
		};
	},
	{
		contactUsSendMail,
		//  setToken
	}
)(ContactUsForm);

ContactUsForm = reduxForm({
	form: "contactUsForm",
})(ContactUsForm);

export default ContactUsForm;

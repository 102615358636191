import React from "react";
import "@fortawesome/fontawesome-free/css/all.css";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { insertProcessBatch } from "../../actions/reports";
import Loader from "../../components/General/loader";

import { getInsertProcessResponse } from "../../selectors/reports";
import { BootBoxAlert } from "../../components/General/bootbox";
// import FeatureContainment from "../../components/FeatureContainment";
import { getSubscription } from "../../selectors/accounts";
import { PREMIUM, FREE } from "../../constants/plans";

class OrdersNotInvoice extends React.Component {
	constructor(props) {
		super(props);
		const date = new Date();
		this.state = {
			showAlert: false,
			messageAlert: "",
			loading: false,
			InitDate: date,
			EndDate: date,
			Emails: "",
		};
	}

	handleDownloadStart = () => {
		if (this.handleValidation()) {
			this.setState({ loading: true });
			const d1 = `${this.state.InitDate.getFullYear()}-${
				this.state.InitDate.getMonth() + 1
			}-${this.state.InitDate.getDate()}`;
			const d2 = `${this.state.EndDate.getFullYear()}-${
				this.state.EndDate.getMonth() + 1
			}-${this.state.EndDate.getDate()}`;
			this.props
				.insertProcessBatch({
					InitDate: d1,
					EndDate: d2,
					Emails: this.state.Emails,
				})
				.then((response) => {
					if (response.payload.hasOwnProperty("_error")) {
						this.Alert(response.payload._error);
					} else {
						this.Alert(
							"Se iniciara el proceso de descarga, favor de revisar su Email"
						);
					}
					this.setState({ loading: false });
				})
				.catch((r) => {
					this.setState({ loading: false });
					this.Alert("error");
				});
		}
	};
	handleChangeDate = (name, value) => {
		this.setState({ [name]: value === null ? "" : value });
	};
	handleChangeInput = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	handleValidation() {
		let msg = "";
		msg += this.state.InitDate !== "" ? "" : " InitDate Requerido, ";
		msg += this.state.EndDate !== "" ? "" : "EndDate Requerido, ";
		msg += this.state.Emails !== "" ? "" : "Emails Requerido, ";

		if (msg !== "") this.Alert(msg);
		return msg === "";
	}
	componentDidMount() {}
	Alert = (msg) => {
		this.setState({ showAlert: true });
		this.setState({ messageAlert: msg });
	};
	handleCloseAlert = () => {
		this.setState({ showAlert: false });
	};
	render() {

console.log('props en reporte...', this.props);
		return (
			<>
				<Loader show={this.state.loading} />
				<br />
				<div className="row">
					<div className="col-3">
						<p>
							En este apartado podras descargar el reporte de ordenes de
							comptaque no han sido facturadas.
							<br />
							Escoge el periodo de busqueda y proporciona uno o mas correos para
							su envio.
							<br />
							<br />
							<b>Nota:</b> Por la cantidad variable de ordenes el reporte podría
							tardar en generarse. por lo cual el archivo se envia por correo.
							Si el volumen de tus ventas es muy grande, se te recomienda que el
							periodo de busqueda sea por mes.
						</p>
					</div>
					<div className="col-9">
						{/* <FeatureContainment plan={this.props.subscription?.Plan}> */}
							<div className="row">
								<div className="col-3">
									<div className="form-group">
										<label>Fecha inicial:</label>
										<DatePicker
											dateFormat="dd/MM/yyyy"
											className="form-control calendar"
											name="InitDate"
											selected={this.state.InitDate}
											onChange={(date) =>
												this.handleChangeDate("InitDate", date)
											}
										/>
									</div>
								</div>
								<div className="col-3">
									<div className="form-group">
										<label>Fecha final:</label>
										<DatePicker
											dateFormat="dd/MM/yyyy"
											className="form-control calendar"
											name="EndDate"
											selected={this.state.EndDate}
											onChange={(date) =>
												this.handleChangeDate("EndDate", date)
											}
										/>
									</div>
								</div>
								<div className="col-3">
									<div className="form-group">
										<label>Email:</label>
										<input
											type="text"
											className="form-control"
											name="Emails"
											onChange={this.handleChangeInput}
										/>
									</div>
								</div>
								<div className="col-3 mt-28">
									<button
										disabled={(this.props.subscription?.subscriptionPlanInfo?.Plan?.toUpperCase() !== FREE.toUpperCase()  && this.props.subscription?.subscriptionPlanInfo?.Plan?.toUpperCase() !== PREMIUM.toUpperCase() )}
										className="btn btn-outline-primary"
										onClick={this.handleDownloadStart}
									>
										Solicitar
									</button>
								</div>
							</div>
						{/* </FeatureContainment> */}
					</div>
				</div>
				<BootBoxAlert
					show={this.state.showAlert}
					message={this.state.messageAlert}
					onYesClick={this.handleCloseAlert}
				/>
			</>
		);
	}
}
OrdersNotInvoice.defaultProps = {
	insert_process_response: {},
};
const mapStateToProps = (state) => ({
	insert_process_response: getInsertProcessResponse(state),
	subscription: getSubscription(state),
});

export default connect(mapStateToProps, { insertProcessBatch })(
	OrdersNotInvoice
);

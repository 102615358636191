import React, { useState } from "react";
import AppFrame from '../../components/AppFrame'
import { useSelector} from "react-redux";
import GeneralSettings from "./GeneralSettings";
import SpecificSettings from "./SpecificSettings";
import SpecificTaxSettings from "./SpecificTaxSettings";
import { Tabs } from "react-bootstrap";
import { Tab } from "bootstrap";
import { useHistory } from "react-router-dom";
import { PREMIUM, FREE } from "../../constants/plans";


const TaxSettingsPage = ({match}) => {
    console.log('match', match);
    let history = useHistory();
    const [defaultTab, setDefaultTab] = useState(undefined);

    
    const subscription =  useSelector((store) => store.subscription);
    

    React.useEffect(() => {		
		if (!defaultTab) {
			setDefaultTab(match.params.tab);
		}
	}, [		
		defaultTab,		
		match.params.tab		
	]);

    return (
        <AppFrame title="Configurar Impuestos">

            <section id="main-content">
                {/*<script src="/Scripts/source/shopify/MethodsShopify.js"></script>
                 <script>
                    InitializeShopify('pruebasfacturama');
                </script> */}


                <input type="hidden" id="ShopName" value="pruebasfacturama" />
                <input type="hidden" id="api-token" value="cHJ1ZWJhc1Nob3BpZnkzMzoxMjM0NTY=" />
                <article id="settings-general-taxes">
                    <div className="container-fluid">
                        {/* Titulo de la pagina */}
                        <div className="row page-title">
                            <div className="col-xs-12">
                                <h4>Configurar Impuestos</h4>
                            </div>                            
                        </div> {/* Fin del titulo de la página */}

                        <Tabs
                            id="controlled-profileTab"
                            activeKey={defaultTab}
                            onSelect={(tab) => {
                                setDefaultTab( tab );
                                 history.replace(`/settings/taxes/${tab}`);
                            }}
                            className="mb-4"
                        >
                            <Tab eventKey="General" title="Configurar Claves SAT Generales e Impuestos">
                                <GeneralSettings />
                            </Tab>
                            {/* Quitar false para revisar Norma */}
                            { false && (subscription?.subscriptionPlanInfo?.Plan?.toUpperCase() === FREE.toUpperCase() || subscription?.subscriptionPlanInfo?.Plan?.toUpperCase() === PREMIUM.toUpperCase() ) && 
                            <Tab eventKey="Specific" title="Asignacion especifica de Claves SAT e Impuestos">
                                <SpecificTaxSettings />
                            </Tab>}
                            <Tab eventKey="Massive" title="Asignacion Especifica Masiva">
                                <SpecificSettings />
                            </Tab>
                        </Tabs>

                       

                    </div>
                </article>
            </section>
            

           

        </AppFrame>
    )
}


export default TaxSettingsPage;
import React from 'react'


export const  UnitCodeAutocomplete = ({defaultValue,handleChange,Name}) => {
    return (
        <>            
            <input type="text" onChange={handleChange} className="form-control tt-input" name={Name} data-type="typeahead" data-source="catalogs/Units" data-template="#tmpl-data" autoComplete="off" data-display="ShortName" data-target="#UnitCode" required="required" placeholder="Nombre Unidad" maxLength="20" defaultValue={defaultValue} spellCheck="false" dir="auto" />
        </>
    )
};

export const  CfdiUsageSelect = ({defaultValue,handleChange}) => {
    return (
        <>            
            <select value={defaultValue}  onChange={handleChange}  className="form-control" id="CfdiUse" name="CfdiUseGeneral">
                <option value="G01">G01 Adquisición de mercancias</option>
                <option value="G02">G02 Devoluciones, descuentos o bonificaciones</option>
                <option value="G03">G03 Gastos en general</option>
                <option value="I01">I01	Construcciones</option>
                <option value="I02">I02	Mobilario y equipo de oficina por inversiones</option>
                <option value="I03">I03	Equipo de transporte</option>
                <option value="I04">I04	Equipo de computo y accesorios</option>
                <option value="I05">I05	Dados, troqueles, moldes, matrices y herramental</option>
                <option value="I06">I06	Comunicaciones telefónicas</option>
                <option value="I07">I07	Comunicaciones satelitales</option>
                <option value="I08">I08	Otra maquinaria y equipo</option>
                <option value="D01">D01 Honorarios médicos, dentales y gastos hospitalarios</option>
                <option value="D02">D02 Gastos médicos por incapacidad o discapacidad</option>
                <option value="D03">D03 Gastos funerales</option>
                <option value="D04">D04 Donativos</option>
                <option value="D05">D05 Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)</option>
                <option value="D06">D06 Aportaciones voluntarias al SAR</option>
                <option value="D07">D07 Primas por seguros de gastos médicos</option>
                <option value="D08">D08 Gastos de transportación escolar obligatoria</option>
                <option value="D09">D09 Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones</option>
                <option value="D10">D10 Pagos por servicios educativos (colegiaturas)</option>
                <option value="S01">S01 Sin efectos fiscales</option>
            </select>
        </>
    )
};
import React from "react";
import PropTypes from "prop-types";
import {
	useTable,
	usePagination,
	useSortBy,
	useGlobalFilter,
} from "react-table";
import GlobalFilter from "./GlobalFilter";
import Pagination from "./Pagination";
import "../../index.css";

const DataTable = ({ columns, data, onClickRow }) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,

		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize, globalFilter },
		visibleColumns,
		preGlobalFilteredRows,
		setGlobalFilter,
	} = useTable(
		{
			columns,
			data: data,
			initialState: { pageSize: 10 },
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const currencyFormat = (num) => {
		return (
			"$" +
			parseFloat(num)
				.toFixed(2)
				.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
		);
	};

	return (
		<div className="table-responsive">
			<table
				{...getTableProps()}
				className={`table ${onClickRow && `table-hover`}`}
			>
				<thead>
					<tr>
						<th
							colSpan={visibleColumns.length}
							style={{
								textAlign: "left",
							}}
						>
							<GlobalFilter
								preGlobalFilteredRows={preGlobalFilteredRows}
								globalFilter={globalFilter}
								setGlobalFilter={setGlobalFilter}
							/>
						</th>
					</tr>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => {
								return column.hideHeader === true ? null : (
									<th {...column.getHeaderProps(column.getSortByToggleProps())}>
										{column.render("Header")}{" "}
										<span>
											{column.isSorted ? (
												column.isSortedDesc ? (
													<i className="fas fa-chevron-down float-right"></i>
												) : (
													<i className="fas fa-chevron-up float-right"></i>
												)
											) : (
												""
											)}
										</span>
									</th>
								);
							})}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, i) => {
						prepareRow(row);
						return (
							<tr
								key={i}
								{...row.getRowProps()}
								onClick={() => onClickRow && onClickRow(row)}
							>
								{row.cells.map((cell) => {
									// console.log("cell", cell);
									return (
										<td
											{...cell.getCellProps()}
											style={
												cell.column.id === "Price"
													? {
															textAlign: "right",
													  }
													: {
															textAlign: "left",
													  }
											}
											// className = { cell.column.id === 'Price' ? 'align-right': 'align-left' }
										>
											{cell.column.id === "Price"
												? currencyFormat(cell.value)
												: cell.render("Cell")}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
			<Pagination
				pageSize={pageSize}
				pageIndex={pageIndex}
				canPreviousPage={canPreviousPage}
				canNextPage={canNextPage}
				pageOptions={pageOptions}
				pageCount={pageCount}
				gotoPage={gotoPage}
				nextPage={nextPage}
				previousPage={previousPage}
				setPageSize={setPageSize}
			/>
		</div>
	);
};

DataTable.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	onClickRow: PropTypes.func,
};

export default DataTable;

import React from 'react'
import AppFrame from '../../components/AppFrame'
import { useParams } from 'react-router-dom';
import CustomerForm from '../../components/CustomerForm'


export const CustomerPage = () => {

    let { id } = useParams();

    console.log("Customer Id: " + id);

    return (
        <AppFrame title="Cliente">
            <CustomerForm Id={id}  />
        </AppFrame>
    )
}
import React from 'react'
import PropTypes from 'prop-types'
import { Field } from "redux-form";
import InputField from "../InputField";
import { isRequired, maxLength, length } from "../../validations";
import { TAX_REGIMES } from "../../constants/tax_regimes";

const maxLength100 = maxLength(100);
const length10 = length(10);

const CompactTaxData = ({isNatural}) => {
    return (
        <>
            <div className="form-group">                
                <Field
                    className="form-control"
                    label="Razón social"
                    placeholder="Nombre fiscal"
                    name="TaxName"
                    type="text"
                    component={InputField}
                    validate={[isRequired, maxLength100]}
                />
            </div>
            <div className="form-row">
                <div className="form-group col-md-6">
                <Field
                    label="Régimen fiscal"
                    className="form-control"
                    name="FiscalRegime"
                    required=""
                    type="select"
                    component={InputField}
                    validate={[isRequired]}
                >
                    <option value="">Selecciona una opción</option>
                    {TAX_REGIMES.filter(
                        (regime) => regime.Natural === isNatural
                    ).map((regime) => (
                        <option value={regime.Value} key={regime.Value}>
                            {regime.Name}
                        </option>
                    ))}
                </Field>
                </div>
                <div className="form-group col-md-6">                    
                    <Field
                    className="form-control"
                    label="Folio de inicio"
                    placeholder="1"
                    name="FolioCurrent"
                    type="number"
                    component={InputField}
                    // defaultValue={1}
                />
                </div>
            </div>
            <div className="form-group">                
                <Field
                    className="form-control"
                    label="Numero de celular para notificaciones"
                    placeholder="44401010100"
                    name="Phone"
                    type="number"
                    component={InputField}
                    validate={[length10]}
                />
            </div>
        </>
    )
}

CompactTaxData.propTypes = {
    data: PropTypes.object,
}

export default CompactTaxData

import React, { Component } from "react";
import AppFrame from "../components/AppFrame";
import TaxProfileForm from "../components/TaxProfileForm";
import PropTypes from "prop-types";
import CertificatesForm from "../components/CertificatesForm";
import UploadLogoForm from "../containers/UploadLogoForm";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
	fetchProfile,
	updateProfile,
	updateLogo,
	updateCsd,
	updateEFirma,
	getInfoCSD,
	fetchCheckSteps,
} from "../actions/profile";
import { fetchPostalCodes, fetchNeighborhoods } from "../actions/catalogs";
import { fetchBranchOffices } from "../actions/branches";
import { BootBoxAlert } from "../components/General/bootbox";
import Loader from "../components/General/loader";
import { withRouter } from "react-router";

import {
	getProfile,
	getIsNatural,
	getNeighborhoods,
	getExpeditionPlaces,
	getSteps,
} from "../selectors/profile";

import { getStoreSettingsAction } from "../actions/account";
import { getStoreSettings, getSubscription } from "../selectors/accounts";
import "../reducers/account";

import DataTable from "../components/DataTable";
import SubscriptionPage from "./SubscriptionPage";
import { Tabs } from "react-bootstrap";
import { Tab } from "bootstrap";
import { Alert } from "react-bootstrap";

class TaxProfilePage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			defaultTab: "profile",
			loading: false,
			modalshow: false,
			showBootBoxAlert: false,
			msgConfirm: "",
			actionConfirm: "",
			redirect: false,
			url: "",
			sendedCSD: false,
			UrlLogo: "",
			responseCsd: {
				Certificate: "",
				ExpirationDate: "",
				SerialNumber: "",
				PrivateKeyPassword: "",
			},
			errorCsd: "",
			succesCsd: false,

			showAlert: false,
			alertVariant: "primary",
			messageAlert: "",
		};
		//getStoreSettingsAction();
	}

	columnsBranches = [
		{
			Header: "Clientes",
			hideHeader: true,
			columns: [
				{
					Header: "Lugar de expedición",
					accessor: "Name",
				},
				{
					Header: "Descripcion",
					accessor: "Description",
				},
			],
		},
	];

	onClickBtnAddBranchHandle = (row) => {
		console.log(row);
		this.setState({
			redirect: true,
			url: `/branches/new/edit`,
		});
	};

	onClickBranchHandle = (row) => {
		this.setState({
			redirect: true,
			url: `/branches/${row.original.Id}/edit`,
		});
	};

	handleOnSubmitSuccess = () => {
		console.log("submit success");
	};

	handleSubmit = (values) => {
		// this.setState({ loading: true });
		// return this.props.updateTaxProfile(values).then((r) => {
		// 	if (r.error) {
		// 		throw new SubmissionError(r.payload);
		// 	}
		// 	this.setState({ loading: false });
		// });
	};
	responseCsd = () => {};
	componentDidMount() {
		// console.log("entrada");
		let tab = this.props.match.params.tab;
		if (tab) this.setState({ defaultTab: tab });

		this.setState({ loading: true });

		!this.props.steps && fetchCheckSteps();

		!this.props.profile?.Rfc &&
			this.props.fetchProfile().then((prof) => {
				// this.props.initialize(this.props.profile);
				// console.log(prof);

				this.setState({ UrlLogo: this.props.profile.UrlLogo });
				this.setState((prevState) => ({
					responseCsd: {
						...prevState.responseCsd,
						PrivateKeyPassword: this.props.profile.Csd.PrivateKeyPassword,
					},
				}));
				this.setState({ loading: false });
			});
		!this.props.expeditionPlaces?.length && this.props.fetchBranchOffices();

		console.log("branches2", this.props.expeditionPlaces);
		if (this.state.loading === false) this.setState({ loading: true });

		if (this.props.profile?.Csd) {
			this.setState((prevState) => ({
				responseCsd: {
					...prevState.responseCsd,
					ExpirationDate: this.props.profile?.Csd.ExpirationDate,
					SerialNumber: this.props.profile?.Csd.SerialNumber,
				},
			}));
			this.setState({ sendedCSD: true });
			this.setState({ loading: false });
		} else {
			this.props.getInfoCSD().then(
				({ payload }) => {
					this.setState((prevState) => ({
						responseCsd: {
							...prevState.responseCsd,
							ExpirationDate: payload.date,
							SerialNumber: payload.SerialNumber,
						},
					}));
					this.setState({ sendedCSD: true });
					this.setState({ loading: false });
				},
				(error) => {
					this.setState({
						isLoaded: true,
						error,
					});
				}
			);
		}
	}

	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect to={this.state.url} />;
		}
	};

	// ---- Logo ----

	handleUploadLogo = (logoImageFile) => {
		console.log("Handling logo from the Wizard form");
		let imgData = logoImageFile.split(",");

		const imgContent = imgData.length > 1 ? imgData[1] : "";
		const imgType = imgData[0].split("/")[1].split(";")[0];

		const imgJSON = {
			Image: imgContent,
			Type: imgType,
		};
		this.setState({ loading: true });
		return this.props
			.updateLogo(imgJSON)
			.then(({ payload }) => {
				this.processPayload(payload);
			})
			.catch((error) => {
				this.setState({
					isLoaded: true,
					error,
				});
			})
			.finally(() => this.setState({ loading: false }));
	};

	processPayload = (payload) => {
		if (payload.Image !== undefined) {
			this.Alert(payload.Message, "success");
			// this.setState({ showBootBoxAlert: true });
			// this.setState({ msgTitle: payload.Message });
			// this.setState({ msgDescription: "" });
		}

		if (payload.ExpirationDate !== undefined) {
			// this.setState({ showBootBoxAlert: true });
			// this.setState({ msgTitle: "Certificado cargado correctamente" });
			// this.setState({
			// 	msgDescription: `Fecha de expiración: ${
			// 		payload.ExpirationDate.split("T")[0]
			// 	}`,
			// });
			this.Alert(
				`Certificado cargado correctamente. Fecha de expiración: ${
					payload.ExpirationDate.split("T")[0]
				}`,
				"success"
			);
			this.setState({ sendedCSD: true });
			let responseCsd = {
				Certificate: payload.Certificate,
				ExpirationDate: payload.ExpirationDate,
				SerialNumber: payload.SerialNumber,
			};
			this.setState({ responseCsd: responseCsd });
		}

		if (payload._error) {
			let complementaryError =
				payload.Type !== undefined ? payload.Type.join("; ") : "";
			if (payload.Message !== undefined) complementaryError += payload.Message;

			this.Alert(`${payload._error}. ${complementaryError}  `, "danger");
			// this.setState({ showBootBoxAlert: true });
			// this.setState({ msgTitle: payload._error });
			// this.setState({ msgDescription: complementaryError });
		}
	};

	handleOk = () => {
		this.setState({ showBootBoxAlert: false });
	};

	// CSDs
	handleUploadCertificates = (CSD) => {
		this.setState({ loading: true });
		return Promise.allSettled([
			this.props.updateCsd(CSD),
			this.props.updateEFirma(CSD),
		])
			.then(([csdResponse, eFirmaResponse]) => {
				if (eFirmaResponse.status === "fulfilled")
					this.props.history.push("/help-csd");
				if (csdResponse.status === "rejected")
					throw Object.assign(csdResponse?.reason);

				const payload = csdResponse.value?.payload;
				this.processPayload(payload);
				//this.Alert('Sellos digitales actualizados correctamente', 'success');
				// this.setState({
				// 	loading: false,
				// 	succesCsd: true,
				// 	errorCsd: "",
				// });
			})
			.catch((error) => {				

				this.Alert(
					(error.Message ?? error._error) +
						(error.Certificate ? " " + error.Certificate : "") +
						(error.PrivateKey ? ". " + error.PrivateKey : "") +
						(error.PrivateKeyPassword ? ". " + error.PrivateKeyPassword : ""),
					"danger"
				);
				this.setState({
					isLoaded: true,
				});
			})
			.finally(() => this.setState({ loading: false }));
	};

	handleCloseAlert = () => {
		this.setState({ showAlert: false });
		if (this.state.redirect) {
			window.location.reload(false);
			this.setState({ redirect: false });
		}
	};
	Alert = (msg, variant) => {
		this.setState({
			showAlert: true,
			messageAlert: msg,
			alertVariant: variant,
		});
	};

	render = () => {
		console.log("Settings", this.props.settingsStore);
		return (
			<AppFrame>
				{this.renderRedirect()}
				<Tabs
					id="controlled-profileTab"
					activeKey={this.state.defaultTab}
					onSelect={(tab) => {
						this.setState({ defaultTab: tab });
						this.props.history.replace(`/settings/profile/${tab}`);
					}}
					className="mb-4"
				>
					<Tab eventKey="profile" title="Perfil fiscal">
						<div className="row">
							<div className="col-md-3">
								<p>
									En este apartado puedes cargar el{" "}
									<b>Logotipo de tu empresa</b>, el cual aparecerá en el PDF de
									la factura.
									<br />
									<br />
									Se recomienda subir archivos con extensiones{" "}
									<b>.bmp, .jpg, .png</b> con dimensiones de 180x180 px y con un
									peso max. de 1Mb.
									<br />
									<br />
									Ademas deberás llenar tu <b>Perfil Fiscal</b>, esta
									información será la que se utilizará para la generación de tus
									facturas.
									<br />
									<br />
									Deberás proporcionar:
								</p>
								<ul>
									<li>
										<b>Razón social</b>
									</li>
									<li>
										<b>Domicilio fiscal</b>
									</li>
									<li>
										<b>Régimen en el que tributas</b>
									</li>
									<li>
										<b>Correo Electrónico</b>
									</li>
									<li>
										<b>Teléfono</b>
									</li>
								</ul>
							</div>
							<Loader show={this.state.loading} />
							<div className="col-md-9">
								<div className="mb-3">
									<UploadLogoForm
										currentLogo={this.props.profile.UrlLogo}
										handleUploadLogo={this.handleUploadLogo}
									/>
								</div>

								<TaxProfileForm
									onSubmit={this.handleSubmit}
									onSubmitSuccess={this.handleOnSubmitSuccess}
								/>
							</div>
						</div>
					</Tab>
					<Tab eventKey="certificates" title="Sellos digitales">
						<div className="row">
							<div className="col-md-3">
								<p>
									En este apartado debes cargar los archivos <b>.cer y .key</b>{" "}
									de los <b>Sellos Digitales</b> así como proporcionar la
									contraseña de la llave <b>.key</b>, ya que estos archivos son
									necesarios para que se puedan timbrar las Facturas.
								</p>
							</div>
							<div className="col-md-9">
								<Alert
									show={this.state.showAlert}
									variant={this.state.alertVariant}
									onClose={this.handleCloseAlert}
									dismissible
								>
									<p>{this.state.messageAlert}</p>
								</Alert>
								{/* <UploadLogoForm
									currentLogo={this.props.profile.UrlLogo}
									handleUploadLogo={this.handleUploadLogo}
								/> */}
								<Loader show={this.state.loading} />
								<br />

								<CertificatesForm
									handleUploadCertificates={this.handleUploadCertificates}
									sendedCSD={this.state.sendedCSD}
									responseCsd={this.state.responseCsd}
								/>
							</div>
						</div>
					</Tab>
					<Tab eventKey="branchs" title="Sucursales">
						<div className="row">
							<div className="col-md-3">
								<p>
									En este apartado deberas proporcionar el nombre del{" "}
									<b>Lugar de Expedición o Sucursal</b>, una breve descripción y
									la dirección, esta información tambien aparecera en la
									factura.
									<br />
									<br />
									<b>
										{" "}
										Esta información puede ser la misma a la Dirección Fiscal.
									</b>
								</p>
							</div>
							<div className="col-md-9">
								<div className="row">
									<div className="col text-right">
										<div
											className="btn btn-outline-primary"
											onClick={this.onClickBtnAddBranchHandle}
										>
											+ Agregar sucursal
										</div>
									</div>
								</div>

								<DataTable
									columns={this.columnsBranches}
									data={this.props.expeditionPlaces || []}
									onClickRow={this.onClickBranchHandle}
								/>
							</div>
						</div>
					</Tab>

					{Object.keys(this.props.subscription).length &&
					this.props.settingsStore !== undefined &&
					this.props.settingsStore.DisplayCartFolios ? (
						<Tab eventKey="subscription" title="Suscripción">
							<div className="row">
								<div className="col-md-12">
									<SubscriptionPage />
								</div>
							</div>
						</Tab>
					) : undefined}
				</Tabs>
				{
					<BootBoxAlert
						show={this.state.showBootBoxAlert}
						type={"confirm"}
						message={this.state.msgTitle}
						detail={this.state.msgDescription}
						onYesClick={this.handleOk}
					/>
				}
			</AppFrame>
		);
	};
}

TaxProfilePage.propTypes = {
	fetchProfile: PropTypes.func.isRequired,
	updateProfile: PropTypes.func.isRequired,
};

// TaxProfilePage = reduxForm({
// 	form: "taxProfilePage",
// 	//enableReinitialize: true,
// })(TaxProfilePage);

TaxProfilePage = connect(
	(state) => {
		return {
			profile: getProfile(state),
			isNatural: getIsNatural(state),
			neighboorhoods: getNeighborhoods(state),
			expeditionPlaces: getExpeditionPlaces(state),
			settingsStore: getStoreSettings(state),
			steps: getSteps(state),
			subscription: getSubscription(state),
		};
	},
	{
		updateProfile,
		updateLogo,
		updateCsd,
		updateEFirma,
		fetchProfile,
		fetchNeighborhoods,
		fetchPostalCodes,
		fetchBranchOffices,
		fetchCheckSteps,
		getInfoCSD,
		getStoreSettingsAction,
	}
)(TaxProfilePage);

export default withRouter(TaxProfilePage);

import React from 'react';

import ContactUsInfo from '../../components/ContactUs/ContactUsInfo';
import ContactUsForm from '../../components/ContactUs/ContactUsForm';

const ContactUs = () => {

    return (
        <main className="bg-gray pb-3 xs-pb-0">

            <section className="row d-flex pt-5 xs-py-1">
                <section className="col-md-5 contactUsInfo">
                    <ContactUsInfo />
                </section>
                <section className="col-md-7 contactUsForm ">
                    <ContactUsForm />
                </section>
            </section>
            
            
        </main>
    );
}

export default ContactUs;
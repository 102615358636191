import React from "react";
import { Modal } from "react-bootstrap";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { contactUsSendMail } from "../../actions/contactUs";
import ContactUs from "./ContactUs";
import "../../index.css";

import "bootstrap/dist/css/bootstrap.css";

class NeedHelp extends React.Component {
	// constructor() {
	// 	super();
	// }

	submit = (values) => {
		console.log("onSubmit");
		const valuesr = {
			Name: values.Name,
			Email: "contact@facturama.mx",
			//Rfc: "ESO1202108R2",
			Phone: values.Phone,
			Message: values.Message,
			Subject: "Ayuda con Wizard",
			// To: "carlosm@facturama.mx",
			To: "hramirez@facturama.mx, arochi@facturama.mx, claudia@facturama.mx",
			ContactType: 1,
		};

		return this.props.contactUsSendMail(valuesr).then(
			({ r }) => {
				console.log("return", r);
			},
			(error) => {
				this.setState({
					isLoaded: true,
					error,
				});
			}
		);
	};

	render() {
		const { error, submitting, submitSucceeded } = this.props;

		return (
			<div>
				<Modal
					className="bootbox-modal"
					show={this.props.show}
					onHide={this.props.onClose}
				>
					<form onSubmit={this.props.handleSubmit(this.submit)}>
						<Modal.Body>
							{error && (
								<div className="alert alert-danger" role="alert">
									{error}
								</div>
							)}
							{submitSucceeded && (
								<div className="alert alert-success" role="alert">
									Correo enviado correctamente
								</div>
							)}
							<ContactUs mailNeeded="false" title="¿Como te podemos ayudar?" />
						</Modal.Body>
						<Modal.Footer className="bootbox-modal-footer">
							<button
								className="btn btn-outline-secondary-cancel mr-3"
								onClick={() => this.props.onClose()}
							>
								Cancelar
							</button>
							<div>
								<button
									className="btn btn-outline-primary"
									type="submit"
									disabled={submitting}
								>
									Enviar
								</button>
							</div>
							<p className="text-gray-bold text-center">
								Horario de atención de L-V de 9am a 7pm:
							</p>
							<p>444-8356098 / 444-1510191</p>
						</Modal.Footer>
					</form>
				</Modal>
			</div>
		);
	}
}

// BootBox.propTypes = {
//   show: PropTypes.bool.isRequired,
//   onYesClick: PropTypes.func.isRequired,
//   onNoClick: PropTypes.func.isRequired,
//   onClose: PropTypes.func.isRequired,
//   message: PropTypes.string,
// };

NeedHelp = connect(
	(state) => {
		return {
			token: "cmgtoken",
			shopName: "cmgShopName",
		};
	},
	{
		contactUsSendMail,
		//  setToken
	}
)(NeedHelp);

NeedHelp = reduxForm({
	form: "NeedHelp",
})(NeedHelp);

export default NeedHelp;

import { handleActions } from "redux-actions";
import {
	FETCH_CUSTOMERS,
	DETAIL_CUSTOMER,
	INSERT_CUSTOMER,
	UPDATE_CUSTOMER,
	DELETE_CUSTOMER,
} from "../constants";

export const customers = handleActions(
	{
		[FETCH_CUSTOMERS]: (state, action) =>
			action.error
				? state
				: [...action.payload].sort((a, b) => (a.Name > b.Name ? 1 : -1)),
		[DETAIL_CUSTOMER]: (state, action) =>
			action.error ? state : [...state, action.payload],
		[INSERT_CUSTOMER]: (state, action) =>
			action.error
				? state
				: [...state, action.payload].sort((a, b) => (a.Name > b.Name ? 1 : -1)),
		[UPDATE_CUSTOMER]: (state, action) =>
			action.error
				? state
				: [
						...state.filter((c) => c.Id !== action.payload.Id),
						action.payload,
				  ].sort((a, b) => (a.Name > b.Name ? 1 : -1)),
		[DELETE_CUSTOMER]: (state, action) =>
			action.error ? state : state.filter((c) => c.Id !== action.payload.Id),
	},
	null
);

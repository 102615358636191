import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { getSubscription } from "../../selectors/accounts";
import {
	fetchSubscription,
	cancelSubscription,
	createSubscriptionWithCardId,
} from "../../actions/subscription";
import { fetchBillingData } from "../../actions/profile";
import { fetchCards } from "../../actions/cards";
import { getDefaultCard } from "../../selectors/cards";
import Loader from "../../components/General/loader";
import { getBillingData } from "../../selectors/profile";
import { CFDI_USES } from "../../constants/cfdi_uses";
import PAYMENT_FORMS from "../../constants/payment_forms";
// import { PLANS } from "../../constants/plans";
import Bootbox from "../../components/General/bootbox";


// const statusClassNameColor = (status) => {
// 	if (status === "Active") return "text-success";
// 	else if (status === "Cancelled") return "text-danger";
// 	else return "text-warning";
// };

let SubscriptionPage = ({
	subscription,
	billingData,
	cancelSubscription,
	card,
	createSubscriptionWithCardId,
	fetchCards,
	fetchBillingData,
	fetchSubscription,
}) => {
	// const [alert, setAlert] = useState("");
	// const [alertType, setAlertType] = useState("alert-info");

	const [showAlertBox, setshowAlert] = useState(false);
	const [alertVariant, setalertVariant] = useState("primary");
	const [messageAlert, setmessageAlert] = useState("");
	
	const [showConfirmSubscription, setShowConfirmSubscription] = useState(false);
	const [msgConfirmSubscription, setMsgConfirmSubscription] = useState("");


	
	

	const [isLoading, setIsLoading] = useState(false);
	// const statusColor = statusClassNameColor(subscription?.Status);

	// const plan = PLANS.find((p) => p.Id === subscription?.Plan);
	const cfdiUse = CFDI_USES.find((c) => c.Value === billingData?.CfdiUse);
	const paymentForm = PAYMENT_FORMS.find(
		(p) => p.Value === billingData?.PaymentForm
	);

	useEffect(() => {
		!card && fetchCards();
		!billingData && fetchBillingData();
		!subscription && fetchSubscription();
	}, [
		card,
		billingData,
		subscription,
		fetchSubscription,
		fetchBillingData,
		fetchCards,
	]);

	// const showAlert = (message, alertType) => {
	// 	setAlert(message);
	// 	setTimeout(() => setAlert(""), 4000);
	// 	alertType && setAlertType(alertType);
	// };

	const createSubscriptionHandler = () => {
		setIsLoading(true);
		createSubscriptionWithCardId(subscription?.Plan, card?.Id)
			.then(() =>
				// showAlert("La suscripcion se activo exitosamente.", "alert-success")
				AlertActive("La suscripcion se activo exitosamente.", "success")
			)
			// .catch((e) => showAlert(e._error, "alert-danger"))
			.catch((e) => AlertActive(e._error, "danger"))
			.finally(() => setIsLoading(false));
	};

	const cancelSubscriptionHandler = () => {
		setShowConfirmSubscription(true);
		setMsgConfirmSubscription("¿Seguro de que deseas eliminar tu suscripción?");
		
	};
	const handleClose = () => {
		setShowConfirmSubscription(false);
	};

	const handleOk = () => {
		setShowConfirmSubscription(false);
		setIsLoading(true);
			cancelSubscription()
				.then(() =>
					// showAlert("La suscripcion se cancelo exitosamente.", "alert-warning")
					AlertActive("La suscripcion se cancelo exitosamente.", "warning")
				)
				// .catch((e) => showAlert(e._error, "alert-danger"))
				.catch((e) => AlertActive(e._error, "danger"))
				.finally(() => setIsLoading(false));
	};

	const handleCloseAlert = () => {		
		setshowAlert(false);		
	};

	const AlertActive = (msg, variant) => {
		setshowAlert(true);
		setalertVariant(variant);
		setmessageAlert(msg);
		
	};

	console.log('cards222', card);
	return (

		<>
			<Loader show={isLoading} />
			<div className="container">
				{/* {alert && (
					<div className={`alert ${alertType}`} role="alert">
						{alert}
					</div>
				)} */}

				<Alert
					show={showAlertBox}
					variant={alertVariant}
					onClose={handleCloseAlert}
					dismissible
				>
					<p>{messageAlert}</p>
				</Alert>

				<div className="row">
					<div className="col-md-7">
						<h5>Suscripción</h5>
						<span>
							{/* Número de tarjeta: <b>{subscription?.subs?.Card}</b> */}
							Número de tarjeta: <b>{card?.Number}</b>
						</span>
						<br />
						{!!subscription?.subs?.ChargeDate ? (
							<span>
								Tu fecha de cargo esta programado para el{" "}
								{new Date(subscription?.subs?.ChargeDate).toLocaleDateString()}.
							</span>
						) : (
							!!subscription?.subscriptionPlanInfo?.ExpirationDate && (
								<span>
									Tu suscripcion termina el:{" "}
									{new Date(subscription?.subscriptionPlanInfo?.ExpirationDate).toLocaleDateString()}
								</span>
							)
						)}
						<br />
						{new Date(subscription?.subs?.TrialEndDate) > new Date() && (
							<span>
								Tu prueba termina el{" "}
								{new Date(subscription?.subs?.TrialEndDate).toLocaleDateString()}
							</span>
						)}
					</div>
					<div className="col-md-5">
						<div className="d-flex align-items-end flex-column h-100 justify-content-between">
							<div className="btn-toolbar mb-3">
								<div className="btn-group mr-2 my-2">
									<button
										type="button"
										className="btn btn-danger mr-3"
										onClick={cancelSubscriptionHandler}
										disabled={subscription?.Status === "Cancelled"}
									>
										Eliminar
									</button>
								</div>
								<div className="btn-group my-2">
									<button
										type="button"
										className="btn btn-outline-primary"
										onClick={createSubscriptionHandler}
										disabled={subscription?.Status === "Active"}
									>
										Activar
									</button>
								</div>
							</div>
							<Link to="/suscription/card">Administra método de pago</Link>
						</div>
					</div>
				</div>
				<hr className="mb-4" />
				<div className="row">
					<div className="col-md-7">
						<h5>Datos de facturación</h5>
						<span>
							Uso del CFDi: {billingData?.CfdiUse} - {cfdiUse?.Name}
						</span>
						<br />
						<span>
							Método de pago: {billingData?.PaymentForm} - {paymentForm?.Name}
						</span>
						<br />
						<span>Email: {billingData?.Email}</span>
					</div>
					<div className="col-md-5">
						<div className="d-flex align-items-end justify-content-end h-100">
							<Link to="/suscription/billing-data">Cambiar datos</Link>
						</div>
					</div>
				</div>
				<hr className="mb-4" />
				{/* <div className="row">
					<div className="col-md-7">
						<h5>Detalles del plan</h5>
						<span>
							<b className="text-primary">{plan?.Name} </b>
							<b className={statusColor}>({subscription?.Status})</b>{" "}
						</span>
					</div>
					<div className="col-md-5">
						<div className="d-flex align-items-end justify-content-end h-100">
							<Link to="/suscription/plans">Administra tu plan</Link>
						</div>
					</div>
				</div> */}
			
				{<Bootbox
					show={showConfirmSubscription}
					type={"confirm"}
					message={msgConfirmSubscription}
					successLabel={"Si"}
					onYesClick={handleOk}
					onNoClick={handleClose}
					onClose={handleClose}
				/>}
			
				
			</div>
		</>
	);
};

SubscriptionPage = connect(
	(state) => ({
		subscription: getSubscription(state),
		card: getDefaultCard(state),
		billingData: getBillingData(state),
	}),
	{
		cancelSubscription,
		createSubscriptionWithCardId,
		fetchCards,
		fetchSubscription,
		fetchBillingData,
	}
)(SubscriptionPage);

export default SubscriptionPage;

import { handleActions } from "redux-actions";
import {
	DELETE_BRANCHOFFICES,
	FETCH_BRANCHOFFICES,
	INSERT_BRANCHOFFICES,
	UPDATE_BRANCHOFFICES,
} from "../constants";

export const branchOffices = handleActions(
	{
		[FETCH_BRANCHOFFICES]: (state, action) => {
			return action.error ? state : [...action.payload];
		},
		[INSERT_BRANCHOFFICES]: (state, action) =>
			action.error ? state : [...state, action.payload],
		[UPDATE_BRANCHOFFICES]: (state, action) =>
			action.error
				? state
				: [...state.filter((c) => c.Id !== action.payload.Id), action.payload],
		[DELETE_BRANCHOFFICES]: (state, action) =>
			action.error ? state : state.filter((c) => c.Id !== action.payload.Id),
	},
	[]
);

import React from "react";
import "@fortawesome/fontawesome-free/css/all.css";
import { connect } from "react-redux";

import {
	insertProcess,
	uploadCvs,
	fetchMassiveProcess,
	downloadMassiveCsv,
} from "../../actions/configure_tax";
import DataTableSimple from "../../components/DataTable/DataTableSimple";
import Moment from "moment";
import { getMassiveProcess } from "../../selectors/configure_tax";
import Loader from "../../components/General/loader";
import { BootBoxAlert } from "../../components/General/bootbox";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

// import { Link } from 'react-router-dom';
// import DataTable from '../../components/DataTable'

//const TaxSettingsPage = (param) => {
class TaxSettingsPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showAlert: false,
			messageAlert: "",
			loading: false,
			modalShow: false,
			email: "",
			emailError: "",
		};
	}
	getListMassive = () => {
		const columns = [
			{
				Header: "Ordenes",
				hideHeader: true,
				columns: [
					{
						Header: "Estatus",
						accessor: "Status",
					},
					{
						Header: "Mensaje",
						accessor: "Message",
					},
					{
						Header: "Fecha",
						accessor: (d) => {
							return Moment(d.StartTime).local().format("DD/MM/YYYY");
						},
					},
					{
						Header: "Accion",
						accessor: (d) => {
							return d.Type === 7
								? "Envío plantilla de productos"
								: "Subida plantilla de productos";
						},
					},
					{
						Header: "Csv",
						accessor: (d) => {
							return d.OutputFileName ? (
								<button
									className="btn btn-outline-primary btn-block btn-sm"
									onClick={() => this.download(d.IdMassiveProcess)}
								>
									Download
								</button>
							) : (
								<span></span>
							);
						},
					},
				],
			},
		];
		return columns;
	};
	download = (id) => {
		this.setState({ loading: true });
		this.props
			.downloadMassiveCsv(id)
			.then((response) => {
				if (response.payload.hasOwnProperty("_error")) {
					this.Alert(response.payload._error);
				} else if (response.payload.hasOwnProperty("Message")) {
					this.Alert(response.payload.Message);
				} else {
					const linkSource = `data:application/pdf;base64,${response.payload.Content}`;
					const downloadLink = document.createElement("a");
					const fileName = "Productos_Procesados.csv";
					downloadLink.href = linkSource;
					downloadLink.download = fileName;
					downloadLink.click();
				}
				this.setState({ loading: false });
			})
			.catch((r) => {
				this.setState({ loading: false });
				this.Alert("error");
			});
	};
	handleUploadStart = (e) => {
		let files = e.target.files;
		this.setState({ loading: true });
		this.props
			.uploadCvs(files[0])
			.then((response) => {
				if (response.payload.hasOwnProperty("_error")) {
					this.Alert(response.payload._error);
				} else if (
					response.payload.hasOwnProperty("Message") &&
					!response.payload.hasOwnProperty("IdMassiveProcess")
				) {
					this.Alert(response.payload.Message);
				} else if (
					response.payload.status !== undefined &&
					response.payload.status !== 200
				) {
					this.Alert(
						response.payload.statusText !== undefined &&
							response.payload.statusText !== ""
							? response.payload.statusText
							: "error"
					);
				} else {
					this.Alert("Se ha cargado el archivo");
				}
				this.setState({ loading: false });
				//e.target.value = "";
			})
			.catch((r) => {
				this.Alert("error");
				this.setState({ loading: false });
				//e.target.value = "";
			});
		//alert("cargando..")
	};
	//param.fetchMassiveProcess()
	showModalConfirm = () => {
		// DownloadStart()
	};
	handleDownloadStart = () => {
		this.Emailvalidation(this.state.email);
		if (this.state.emailError === "" && this.state.email !== "") {
			this.setState({ loading: true });
			this.props
				.insertProcess(this.state.email)
				.then((response) => {
					if (response.payload.hasOwnProperty("_error")) {
						this.Alert(response.payload._error);
					} else if (
						response.payload.hasOwnProperty("Message") &&
						!response.payload.hasOwnProperty("IdMassiveProcess")
					) {
						this.Alert(response.payload.Message);
					} else {
						this.Alert(
							"Se iniciara el proceso de descarga, favor de revisar su Email"
						);
					}
					this.setState({ loading: false });
					this.setState({ modalShow: false });
				})
				.catch((r) => {
					this.Alert("error");
					this.setState({ loading: false });
				});
		}
	};
	handleListar = () => {
		this.setState({ loading: true });
		this.props
			.fetchMassiveProcess()
			.then((response) => {
				if (response.payload.hasOwnProperty("_error")) {
					this.Alert(response.payload._error);
				} else if (response.payload.hasOwnProperty("Message")) {
					this.Alert(response.payload.Message);
				}
				this.setState({ loading: false });
			})
			.catch((r) => {
				this.Alert("error");
				this.setState({ loading: false });
			});
	};
	Alert = (msg) => {
		this.setState({ showAlert: true });
		this.setState({ messageAlert: msg });
	};
	handleEmailChange = (e) => {
		this.Emailvalidation(e.target.value);
		this.setState({ email: e.target.value });
	};
	Emailvalidation = (value) => {
		let error = "";
		var pattern = new RegExp(
			/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
		);

		if (!value) {
			error = "No puede estar vacio";
		} else if (!pattern.test(value)) {
			error = "Por favor ingresar direccion email valida .";
		}
		this.setState({ emailError: error });
	};
	componentDidMount = () => {
		//this.props.fetchMassiveProcess();
	};
	handleCloseAlert = () => {
		this.setState({ showAlert: false });
	};
	render() {
		//const{massive_process}=this.props;
		return (
			<>
				<Loader show={this.state.loading} />
				<br />
				<div className="row">
					<div className="col-8">
						<p>
							Configura impuestos de manera específica, descarga la plantilla
							con tus productos en <b>INICIAR DESCARGA DE PLANTILLA</b> y asigna
							las tasas de impuesto y las claves del SAT.
						</p>
					</div>
					<div className="col-4">
						<button
							className="btn btn-block btn-outline-secondary"
							onClick={() => {
								this.setState({ modalShow: true });
								this.setState({ email: "" });
							}}
						>
							Iniciar descarga de plantilla
						</button>
					</div>
				</div>

				<div className="row">
					<div className="col-8">
						<label>
							Busca la CLAVE del PRODUCTO/SERVICIO - max. 8 caracteres (ej.
							84111506)
						</label>
					</div>
					<div className="col-4">
						<a
							className=" btn btn-outline-primary btn-block"
							target="_blank"
							rel="noopener noreferrer"
							href="http://200.57.3.46:443/PyS/catPyS.aspx"
						>
							Buscar Clave
						</a>
					</div>
				</div>

				<br />

				<div className="row">
					<div className="col-8">
						<label>
							Busca la clave de la UNIDAD - max 3 caracteres (ej. E48 )
						</label>
					</div>
					<div className="col-4">
						<a
							className="btn btn-outline-primary btn-block"
							target="_blank"
							rel="noopener noreferrer"
							href="http://200.57.3.46:443/PyS/catUnidades.aspx"
						>
							Buscar Unidad
						</a>
					</div>
				</div>
				<br />
				<hr />
				<div className="row">
					<div className="col-8">
						<label>
							<b>
								Una vez llenada la plantilla sube el archivo para que se
								procese, esta acción puede tardar varios minutos.
							</b>
						</label>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="custom-file">
							<input
								type="file"
								className="custom-file-input"
								required=""
								accept=".csv"
								id="csv-file"
								name="Taxes"
								onChange={this.handleUploadStart}
							/>
							<label className="custom-file-label">Seleccionar .csv</label>
						</div>
					</div>
				</div>

				<br />
				<div className="row">
					<div className="col-8">
						<label>
							Revisa el estatus dando click en MOSTRAR ESTATUS DEL PROCESO.
						</label>
					</div>
					<div className="col-4">
						<button
							className="btn btn-block btn-outline-secondary"
							onClick={this.handleListar}
						>
							Mostrar estatus del proceso
						</button>
					</div>
				</div>
				<br />
				<Modal
					show={this.state.modalShow}
					onHide={() => this.setState({ modalShow: false })}
				>
					<Modal.Header closeButton>Iniciar descarga plantilla</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="col-12">
								<div className="form-group">
									<Form.Group controlId="formBasicEmail">
										<Form.Label>Email</Form.Label>
										<Form.Control
											type="email"
											placeholder="Ingresar email"
											onChange={this.handleEmailChange}
										/>
										<Form.Text className="text-danger">
											{this.state.emailError}
										</Form.Text>
									</Form.Group>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="btn btn-outline-secondary-cancel mr-3"
							onClick={() => this.setState({ modalShow: false })}
						>
							Cancelar
						</Button>
						<Button
							variant="btn btn-outline-primary"
							onClick={this.handleDownloadStart}
						>
							Iniciar
						</Button>
					</Modal.Footer>
				</Modal>
				<DataTableSimple
					columns={this.getListMassive()}
					data={
						this.props.massive_process.length > 0
							? this.props.massive_process
							: []
					}
				/>
				<BootBoxAlert
					show={this.state.showAlert}
					message={this.state.messageAlert}
					onYesClick={this.handleCloseAlert}
				/>
			</>
		);
	}
}
TaxSettingsPage.defaultProps = {
	massive_process: [],
};
const mapStateToProps = (state) => ({
	massive_process: getMassiveProcess(state),
});

export default connect(mapStateToProps, {
	insertProcess,
	uploadCvs,
	fetchMassiveProcess,
	downloadMassiveCsv,
})(TaxSettingsPage);

import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Field, reduxForm, SubmissionError } from "redux-form";
import AppFrame from "../../components/AppFrame";
import InputField from "../../components/InputField";
import PAYMENT_FORMS from "../../constants/payment_forms";
import { getBillingData } from "../../selectors/profile";
import { updateBillingData, fetchBillingData } from "../../actions/profile";
import { isRequired } from "../../validations";
import { useEffect, useState } from "react";
import Loader from "../../components/General/loader";
import { Alert } from "react-bootstrap";

let EditBillingPage = ({
	handleSubmit,
	fetchBillingData,
	updateBillingData,
	initialValues,
	history,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	// const [alert, setAlert] = useState("");
	// const [alertType, setAlertType] = useState("alert-primary");

	const [showAlertMessage, setShowAlert] = useState(false);
	const [alertVariant, setAlertVariant] = useState("primary");
	const [messageAlert, setMessageAlert] = useState("");

	// const showAlert = (message, alertType) => {
	// 	setAlert(message);
	// 	setAlertType(alertType);
	// 	// return new Promise((resolve) => {
	// 	// 	setTimeout(() => {
	// 	// 		resolve();
	// 	// 		setAlert("");
	// 	// 	}, 4000);
	// 	// });
	// };

	const submit = (data) => {
		setIsLoading(true);
		return (
			updateBillingData(data)
				.then(() => {
					AlertMsg("Los cambios se guardaron exitosamente", "success");
					// showAlert("Los cambios se guardaron exitosamente", "alert-success");
				})
				// .then(() => {
				// 	history.goBack();
				// })
				.catch((e) => {
					// showAlert(e._error, "alert-danger");
					AlertMsg(e._error, "danger");
					throw new SubmissionError(e);
				})
				.finally(() => setIsLoading(false))
		);
	};

	useEffect(() => {
		!initialValues && fetchBillingData();
	});

	const handleCloseAlert = () => {	
		setShowAlert(false);		
	};
	const AlertMsg = (msg, variant) => {		
		setShowAlert(true);
		setAlertVariant(variant);
		setMessageAlert(msg);		
	};

	return (
		<AppFrame>
			<Loader show={isLoading} />
			
			<Alert
				show={showAlertMessage}
				variant={alertVariant}
				onClose={handleCloseAlert}
				dismissible
			>
				<p>{messageAlert}</p>
			</Alert>
			<form
				style={{ maxWidth: "600px", margin: "0px auto" }}
				onSubmit={handleSubmit(submit)}
			>
				<div>
					<div className="card-body">
						<div className="row">
							<div className="container">
								<h3>Datos para la generación de tu factura</h3>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="form-group">
									<Field
										label="Forma de pago:"
										component={InputField}
										type="select"
										name="PaymentForm"
										className="form-control"
										validate={[isRequired]}
									>
										<option value="">Selecciona una opción</option>
										{PAYMENT_FORMS.filter((pForm) =>
											pForm.PayableIn?.includes("card")
										).map((pForm) => (
											<option value={pForm.Value} key={pForm.Value}>
												{pForm.Value} {pForm.Name}
											</option>
										))}
									</Field>
								</div>
								<div className="form-group">
									<Field
										label="Uso de la factura:"
										component={InputField}
										type="select"
										name="CfdiUse"
										className="form-control"
										validate={[isRequired]}
									>
										<option value="">Selecciona una opción</option>
										<option value="G03">G03 - Gastos en general</option>
									</Field>
								</div>
								<div className="form-group">
									<Field
										label="Correo para recibir datos del pago: *"
										className="form-control"
										type="email"
										name="Email"
										placeholder="E-Mail"
										component={InputField}
										validate={[isRequired]}
									/>
								</div>
								<div className="form-group text-right mt-4">
									<Link
										to="/settings/profile/subscription"
										className="btn btn-outline-secondary mr-3"
									>
										Regresar
									</Link>
									<button type="submit" className="btn btn-outline-primary">
										Guardar cambios
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</AppFrame>
	);
};

EditBillingPage = reduxForm({
	form: "EditBillingPage",
})(EditBillingPage);

EditBillingPage = connect(
	(state) => ({
		initialValues: getBillingData(state),
	}),
	{ updateBillingData, fetchBillingData }
)(EditBillingPage);

export default EditBillingPage;

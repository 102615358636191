import React from 'react';
import { reduxForm, Field,SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { sendInvoice} from "../../actions/invoice";
import Loader from '../../components/General/loader';
import { Alert } from "react-bootstrap";

import { 
    email, 
    isRequired
    } from "../../validations";

const MyField=({input,meta,type,label,name})=>(
    <div className="col-12">
        <label htmlFor={name}>{label}</label>
        <br/>
        <input className="form-control" {...input} type={!type?"text":type}/>
        {
          meta.touched&&meta.error&&<div >{meta.error}</div>
        }
    </div>
);

class SendInvoiceForm extends React.Component {    

    constructor(props) {
        super(props);
        this.state = {
            isLoading:false,
            error:"",
            submitSucceeded:false,
            showAlert: false,
			alertVariant: "primary",
			messageAlert: "",                   
        }
    }
    submit = (values) => {        
        console.log(values.target.ToEmail.value);        
        console.log(values.target.idInvoice.value);
        this.setState({isLoading:true});
        return this.props.sendInvoice("issued",
        values.target.idInvoice.value,
        values.target.ToEmail.value,
        values.target.SubjectEmails.value,
        values.target.CommentsEmails.value)
        .then(({ payload }) => {
            this.setState({error:""}); 
            if (payload&&payload.success) {
                console.log(payload.msg);
                if(payload.success!==true)
                {
                    this.Alert(payload.msg,'danger');
                    throw new SubmissionError(payload.msg);
                }
                else{
                    this.Alert('Se ha enviado el mensaje' ,'success');
                    this.setState({submitSucceeded:true}); 
                }
            }
            if (payload._error) {
                this.Alert(payload._error, 'danger');
                throw new SubmissionError(payload);
            }
            this.setState({isLoading:false});  
        },
        (error) => {            
            this.Alert('Error al intentar enviar: ' + error._error ,'danger');
            this.setState({
              isLoading: false,
              error:"error al intentar enviar"
            });            
          }          
        );
        
    }    

    handleCloseAlert = () => {
		this.setState({ showAlert: false });
		if (this.state.redirect) {
			window.location.reload(false);
			this.setState({ redirect: false });
		}
	};
	Alert = (msg, variant) => {
		this.setState({
			showAlert: true,
			messageAlert: msg,
			alertVariant: variant,
		});
		
	};
    
    render() {
        const {           
            handleSubmit,            
        } = this.props;
        return (
        <>
        <form id="SendForm" onSubmit={e=>{e.preventDefault();handleSubmit(this.submit(e))}}>
            <Loader show={this.state.isLoading}/>
            <div className="row">
                <div className="col-12">
                <Alert
					show={this.state.showAlert}
					variant={this.state.alertVariant}
					onClose={this.handleCloseAlert}
					dismissible
				>
					<p>{this.state.messageAlert}</p>
				</Alert>
                {/* {this.state.error&&this.state.error!=="" && (
                    <div className="alert alert-danger" role="alert">
                        {this.state.error}
                    </div>
                )}
                {this.state.submitSucceeded &&this.state.submitSucceeded===true && (
                    <div className="alert alert-success" role="alert">
                        Se ha enviado el mensaje
                    </div>
                )} */}
                </div>
            </div>
            <div className="row">
                <Field name="ToEmail" component={MyField} 
                validate={[isRequired,email]}
                label={"Enviar a:"}
                ></Field>
                <Field name="SubjectEmails" component={MyField} 
                label={"Asunto(opcional):"}
                ></Field>
                <Field name="CommentsEmails" component={MyField}             
                label={"Mensaje(opcional):"}
                ></Field>
                <Field name="idInvoice" component={MyField} 
                type="hidden"
                validate={[isRequired]}
                ></Field>                
            </div>
        </form>
        </>
        );
    }
}
SendInvoiceForm.defaultProps = {    
};
SendInvoiceForm =  reduxForm({
    form: "SendForm",   
    enableReinitialize : true // this is needed!!
})(SendInvoiceForm);

SendInvoiceForm = connect(
    (state,ownProps) => {
        console.log("state email"+ownProps.email);         
        return {
            initialValues: {
                ToEmail: ownProps.email,
                SubjectEmails: "",
                CommentsEmails: "",
                idInvoice:ownProps.idInvoice
              }
        };
    },
    {
        sendInvoice
    }
)(SendInvoiceForm);

export default SendInvoiceForm;
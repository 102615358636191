import React, { useRef } from "react";
import { Link } from "react-router-dom";
import numeral from "numeral";
import "./PaymentCheckout.css";

const PaymentDetails = ({
	paymentDetails,
	handleQuantityOnChange,
	handlePromoCodeOnChange,
	handleQuantityOnBlur,
	handleFinishPurchase,
	btnDisabled,
}) => {
	const promoCodeInputRef = useRef(null);
	const termsCheckRef = useRef(null);

	return (
		<div className="row" id="payment_details">
			<div className="section-title">
				<h6>
					<i className="icon-receipt text-muted"></i> Detalles de tu compra
				</h6>
			</div>
			<ul className="list-group mb-3 full-width" id="list_amounts">
				{paymentDetails.Items.map((item) => (
					<div key={item.ProductId}>
						<li className="list-group-item d-flex justify-content-between bb-none">
							<div>{item.Description}</div>
						</li>
						<li className="list-group-item d-flex justify-content-between bt-none ">
							<div className="row full-width">
								<div className="col-3 text-right pr-0 mt-8 text-muted">
									<span>{numeral(item.Price).format("$0,0.00")}</span> x
								</div>
								<div className="col-9  pl-5">
									<input
										className="form-control quantity full-width"
										type="number"
										name="quantity"
										min="100" 
										defaultValue="100" 
										onChange={(e) => handleQuantityOnChange(e)}
										onBlur={(e) =>handleQuantityOnBlur(e)}
									/>
								</div>
							</div>
						</li>
					</div>
				))}

				<li className="list-group-item d-flex justify-content-between">
					<span>Subtotal (MXN)</span>
					<span className="text-muted" id="details_subtotal">
						{numeral(paymentDetails.Subtotal).format("$0,0.00")}
					</span>
				</li>
				<li className="list-group-item d-flex justify-content-between">
					<span>IVA (MXN)</span>
					<span className="text-muted" id="details_iva">
						{numeral(paymentDetails.Iva).format("$0,0.00")}
					</span>
				</li>
				<li className="list-group-item d-flex justify-content-between">
					<span>Total (MXN)</span>
					<strong className="details-total" id="details_total">
						{numeral(paymentDetails.TotalAmount).format("$0,0.00")}
					</strong>
				</li>

				<li className="list-group-item d-flex justify-content-between">
					<div className="input-group">
						<input
							type="text"
							className="form-control"
							placeholder="Código de promoción"
							ref={promoCodeInputRef}
							onChange={() => {
								promoCodeInputRef.current.value = promoCodeInputRef.current.value
									?.replace(/\s/g, "")
									?.split(" ")
									?.join("");
							}}
						/>
						<div className="input-group-append">
							<div
								className="btn btn-outline-secondary ml-3 small"
								onClick={() =>
									handlePromoCodeOnChange(promoCodeInputRef.current.value)
								}
							>
								Aplicar
							</div>
						</div>
					</div>
				</li>
			</ul>

			<div className="card p-2 pb-5" id="paid-form">
				<input type="hidden" name="PaymentForm" id="PaymentForm" />
				<div className="form-check">
					<label className="form-check-label">
						<input
							type="checkbox"
							className="form-check-input"
							ref={termsCheckRef}
							defaultChecked={true}
							name="terms"
						/>{" "}
						He leído y acepto los
						<Link
							to={{
								pathname:
									"https://app.facturama.mx/Content/docs/Facturama-terminos-y-condiciones-del-servicio.pdf",
							}}
							target="_blank"
						>
							{" "}
							términos y condiciones del servicio
						</Link>
					</label>
				</div>
				<button
					disabled={btnDisabled}
					className="btn btn-outline-primary btn-lg btn-block mt-3"
					id="pay-button"
					name="pay"
					type="submit"
					onClick={() => handleFinishPurchase(termsCheckRef.current.checked)}
				>
					{btnDisabled ? "Procesando compra" : "Finalizar compra"}
				</button>
			</div>
		</div>
	);
};

export default PaymentDetails;

import React from "react";
import ListWithoutInvoiceFilter from "../../components/ListInvoices/ListWithoutInvoiceFilter";
import DataTableSimple from "../../components/DataTable/DataTableSimple";
import numeral from "numeral";
import Moment from "moment";
import "moment/locale/es";
import BootBox, { BootBoxAlert } from "../../components/General/bootbox";
import Loader from "../../components/General/loader";
import { fetchOrdersWithoutInvoice, updateOrders } from "../../actions/orders";
import { connect } from "react-redux";
import { getOrdersWithoutInvoice } from "../../selectors/orders";
import { withRouter } from "react-router-dom";
import {
	PaymentStatusIcon,
} from "../../components/ListInvoices/ListInvoicesControls";

class ListOrdersWithoutInvoice extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showConfirm: false,
			msgConfirm: "",
			showAlert: false,
			messageAlert: "",
			loading: false,
			modalshow: false,
			checkOrders: [],
			showModalPro2: true,
			page: 0,
		};
	}
	
	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}
	handleCheckChange = (e) => {
		const Value = e.target.value;
		if (e.target.checked === true) {
			this.state.checkOrders.push(Value);
		} else {
			let rescheck = this.state.checkOrders.filter((r) => r !== Value);
			this.setState({ checkOrders: rescheck });
		}
	};
	clickUpdateOrderHandler = () => {
		this.AlertConfirm(
			"Se generaran las facturas globales de las ordenes marcadas"
		);
	};
	UpdateOrders = () => {
		this.setState({ loading: true });
		this.props
			.updateOrders(this.state.checkOrders)
			.then((response) => {
				if (response.payload.hasOwnProperty("_error")) {
					this.Alert(response.payload._error);
				} else if (response.payload.hasOwnProperty("Message")) {
					this.Alert(response.payload.Message);
				} else {
					this.Alert("Se guardo correctamente");
				}
				this.setState({ loading: false });
				this.setState({ showConfirm: false });
			})
			.catch((r) => {
				this.Alert("error");
				this.setState({ loading: false });
				this.setState({ showConfirm: false });
			});
	};
	getColumns() {
		const columns = [
			{
				Header: "Ordenes",
				hideHeader: true,
				columns: [
					{
						Header: "Seleccionar",
						accessor: (d) => {
							return (
								<input
									type="checkbox"
									name={"sel"}
									defaultValue={d.Id}
									defaultChecked={true}
									onChange={this.handleCheckChange}
								/>
							);
						},
					},
					{
						Header: "Estado_Pago",
						accessor: (d) => {
							return (
								<PaymentStatusIcon
									status={d && d.PaymentStatus ? d.PaymentStatus : { Name: "" }}
								/>
							);
						},
					},
					{
						Header: "Número",
						accessor: (d) => {
							return d.Name;
						},
					},
					{
						Header: "Folio",
						accessor: (d) => {
							return d.Folio;
						},
					},
					{
						Header: "Serie",
						accessor: (d) => {
							return d.Serie;
						},
					},
					{
						Header: "Fecha",
						accessor: (d) => {
							return Moment(d.CreatedAt).local().format("DD/MM/YYYY");
						},
					},
					{
						Header: "Cliente",
						accessor: (d) => {
							return d.CustomerInfo && d.CustomerInfo.length > 0
								? d.CustomerInfo[0].Name
								: "Sin definir";
						},
					},
					{
						Header: "Correo",
						accessor: (d) => {
							return d.CustomerInfo && d.CustomerInfo.length > 0 ? (
								<div style={{ whiteSpace: "pre" }}>
									{" "}
									{d.CustomerInfo[0].Email?.endsWith(",")
										? d.CustomerInfo[0].Email?.substr(
												0,
												d.CustomerInfo[0].Email?.length - 1
										  )
												.replaceAll(",,", ",")
												.replaceAll(",", "\n ")
										: d.CustomerInfo[0].Email?.replaceAll(",,", ",").replaceAll(
												",",
												"\n "
										  )}
								</div>
							) : (
								"Sin definir"
							);
						},
					},
					{
						Header: "Total",
						accessor: (d) => {
							return numeral(d.TotalAmount).format("$0,0.00");
						},
					},
				],
			},
		];
		return columns;
	}
	handlerSerchOrder = (startDate, endDate, status) => {

		this.setState({ loading: true });

		const d1 = `${startDate.getFullYear()}-${
			startDate.getMonth() + 1
		}-${startDate.getDate()}`;
		const d2 = `${endDate.getFullYear()}-${
			endDate.getMonth() + 1
		}-${endDate.getDate()}`;
		this.props.fetchOrdersWithoutInvoice({
			Page: this.state.page + 1,
			Records_page: this.state.pageSize,
			PaymentStatus: status === "all" ? "" : status,
			StartDate: d1,
			EndDate: d2,
		});
	};

	componentDidUpdate(oldProps) {
		if (oldProps.resultOrders !== this.props.resultOrders) {
			const ids = [];
			this.props.resultOrders &&
				this.props.resultOrders.Results &&
				this.props.resultOrders.Results.forEach((element) => {
					ids.push(element.Id);
				});
			this.setState({ checkOrders: ids });
			this.setState({ loading: false });
		}
	}
	Alert = (msg) => {
		this.setState({ showAlert: true });
		this.setState({ messageAlert: msg });
	};
	AlertConfirm = (msg) => {
		this.setState({ showConfirm: true });
		this.setState({ msgConfirm: msg });
		this.setState({ actionConfirm: "cancel" });
	};
	handleCloseAlert = () => {
		this.setState({ showAlert: false });
	};
	handleOkConfirm = () => {
		this.props.history.push(
			"/invoices/global/new/" + JSON.stringify(this.state.checkOrders)
		);
	};
	handleCloseConfirm = () => {
		this.setState({ showConfirm: false });
	};

	render() {
		const { resultOrders } = this.props;
		
		return (
			<>
			
					<ListWithoutInvoiceFilter
						clickHandler={this.handlerSerchOrder}
						clickUpdateOrderHandler={this.clickUpdateOrderHandler}
						ordersStatus={this.props.ordersStatus}
					/>
			
				<Loader show={this.state.loading} />
			
					 
				<DataTableSimple
					columns={this.getColumns()}
					data={
						resultOrders.Results ? resultOrders.Results : []
					}
				/>
				<BootBoxAlert
					show={this.state.showAlert}
					message={this.state.messageAlert}
					onYesClick={this.handleCloseAlert}
				/>
				<BootBox
					show={this.state.showConfirm}
					type={"confirm"}
					message={this.state.msgConfirm}
					successLabel={"Si"}
					onYesClick={this.handleOkConfirm}
					onNoClick={this.handleCloseConfirm}
					onClose={this.handleCloseConfirm}
				/>
			</>
		);
	}
}
const mapStateToProps = (state) => ({
	resultOrders: getOrdersWithoutInvoice(state),
	
});
export default withRouter(
	connect(mapStateToProps, { fetchOrdersWithoutInvoice, updateOrders })(
		ListOrdersWithoutInvoice
	)
);

import React from "react";
import { Link } from "react-router-dom";
import { PREMIUM, FREE } from "./../../constants/plans";

const styles = {
	parent: {
		position: "relative",
		width: "100%",
	},
	wrapper: {
		display: "flex",
		width: "100%",
		height: "100%",
		position: "absolute",
		justifyContent: "center",
		zIndex: 900,
	},
	blur: {
		width: "100%",
		height: "100%",
		filter: "blur(2px)",
		top: "0",
		left: "0",
		zIndex: "100",
	},
};

/**
 *
 * @param plan cadena del plan actual contratado
 * @param htmlContent html personalizado con el contenido que se desea remplazar (opcional sino se especifica se aplica un blur)
 * @returns
 */
const FeatureContainment = ({ plan, htmlContent, children }) => {
	return (plan?.toUpperCase() !== PREMIUM.toUpperCase() && plan?.toUpperCase() !== FREE.toUpperCase() ) ? (
		htmlContent || (
			<div>
				<div class="alert alert-info" role="alert">
					Esta es una caracteristica especial{" "}
					<Link to="/suscription/plans">subir a Premium</Link>
				</div>
				<div style={styles.parent}>
					<div style={styles.wrapper}></div>
					<div style={styles.blur}>{children}</div>
				</div>
			</div>
		)
	) : (
		<>{children}</>
	);
};

export default FeatureContainment;

import React from "react";
import { Field } from "redux-form";
import InputField from "../InputField";
import { isRequired, maxLength } from "../../validations";

const maxLength8 = maxLength(8);

export const ProductAndService = () => {
    return (
        <div className="form-group">
            <label>Asocia tu producto al CATALOGO del SAT</label>
            <div className="row">
                <div className="col-xs-12 col-md-8">
                    <small>
                        Busca tu CLAVE, escríbela en el campo de abajo, espera
                        opciones y selecciona una (8 caracteres):
                    </small>
                </div>
                <div className="col-xs-12 col-md-4">
                    <a
                        className="btn btn-block btn-outline-secondary p-2 mb-2"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://200.57.3.46:443/PyS/catPyS.aspx"
                    >
                        Buscar Clave
                    </a>
                </div>
            </div>
            <Field
                type="text"
                name="CodeProdServ"
                component={InputField}
                className="form-control"
                placeholder="Clave de Producto ó Servicio"
                validate={[isRequired, maxLength8]}
            />            
        </div>
    );
};

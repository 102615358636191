import { handleActions } from "redux-actions";
import {
    INSERT_PROCESS_BATCH,
} from "../constants";
export const insertProcessBatch = handleActions(
    {
        
        [INSERT_PROCESS_BATCH]: (state, action) => {            
            if(action.hasOwnProperty("payload"))
                return action.payload;
            else
                alert("No se encontraron ordenes a mostrar.")
        },
              
    },
    []
);
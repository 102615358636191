import { createAction } from "redux-actions";
import { apiGet, apiPut } from "../api";
import {
	urlProfile,
	urlProfileLogo,
	urlProfileCsd,
	urlCheckSteps,
	urlSettings,
	urlBillingData,
	urlProfileFiel,
} from "../api/urls";
import {
	FETCH_PROFILE,
	UPDATE_PROFILE,
	UPDATE_LOGO,
	UPDATE_CSD,
	FETCH_CHECK_STEPS,
	FETCH_EXPIRATION_CSD,
	UPDATE_BILLING_DATA,
	FETCH_BILLING_DATA,
	UPDATE_EFIRMA,
} from "./../constants";

export const fetchCheckSteps = createAction(
	FETCH_CHECK_STEPS,
	apiGet(urlCheckSteps)
);

export const fetchProfile = createAction(FETCH_PROFILE, async () => {
	const profile = await apiGet(urlProfile)();
	try {
		const csdInfo = await apiGet(urlSettings + "/GetInfoCSD")();
		profile.Csd = {
			...profile.Csd,
			ExpirationDate: csdInfo.date,
			SerialNumber: csdInfo.SerialNumber,
			Rfc: csdInfo.Rfc,
			Organization: csdInfo.Organization,
		};
	} catch (e) {
		profile.Csd = {
			...profile.Csd,
			ExpirationDate: undefined,
		};
	}
	return profile;
});

export const getInfoCSD = createAction(FETCH_EXPIRATION_CSD, () =>
	apiGet(urlSettings + "/GetInfoCSD")()
);

export const updateProfile = createAction(UPDATE_PROFILE, async (profile) => {
	await apiPut(urlProfile, "", profile)();
	return profile;
});

export const updateLogo = createAction(UPDATE_LOGO, (logoBase64) => {
	return apiPut(urlProfileLogo, "", logoBase64)();
});

export const updateCsd = createAction(UPDATE_CSD, (csd) => {
	let response = apiPut(urlProfileCsd, "", csd)();

	console.log("createAction");
	console.log(response);

	return response;
});

export const updateEFirma = createAction(UPDATE_EFIRMA, (eFirma) => {
	let response = apiPut(urlProfileFiel, "", eFirma)();
	return response;
});

export const fetchBillingData = createAction(FETCH_BILLING_DATA, () =>
	apiGet(urlBillingData)()
);

export const updateBillingData = createAction(
	UPDATE_BILLING_DATA,
	(billingData) => apiPut(urlBillingData, "", billingData)()
);

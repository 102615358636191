//import { formValueSelector } from "redux-form";
//const selector = formValueSelector("taxProfileForm");
export const getOrders = (state) => state.orders.all || {};

export const getOrdersWithoutInvoice = (state) =>
	state.orders.withoutInvoice || [];

export const getOrdersWithInvoice = (state) =>
	state.orders.withInvoice?.Results || [];

export const cancelCFDIslctr = (state) => state.orders.cfdiDeleted || [];

export const getFDIsFilteredCtrl = (state) => state.orders.Results1 || [];
// import React from "react";
import React, { useEffect, useState } from "react";
// import { useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// import AppFrame from "../../components/AppFrame";
import { getSubscription } from "../../selectors/accounts";
import { getSubscriptionDB } from "../../actions/account";
import { fetchSubscription, updateSubscription, createSubscriptionWithCardId, cancelSubscription} from "../../actions/subscription";
// import { getsubscriptionPlanInfo } from "../../selectors/accounts";
import { fetchCards } from "../../actions/cards";
import "../../styles/plans.css";
import Loader from "../../components/General/loader";
import { getDefaultCard } from "../../selectors/cards";
import { PREMIUM, PRO } from "../../constants/plans";
import { Alert } from "react-bootstrap";
import  BootBox from "../../components/General/bootbox";
import { useDispatch } from "react-redux";

let PlansPage = ({ subscription, card, updateSubscription, fetchCards, history }) => {
	const [currentPlan, setCurrentPlan] = useState(subscription?.Plan);
	const [isLoading, setIsLoading] = useState(false);
	// const [alert, setAlert] = useState("");
	// const [alertType, setAlertType] = useState("alert-primary");

	
	const [showAlertMessage, setShowAlert] = useState(false);
	const [alertVariant, setAlertVariant] = useState("primary");
	const [messageAlert, setMessageAlert] = useState("");
	const [messageLink, setMessageLink] = useState("");
	const [toLink, setToLink] = useState("");
	const [showAlertLink, setShowAlertLink] = useState(false);
	const [showBox, setShowBox] = useState(false);
	const [messageBox, setMessageBox] = useState("");
	const [needSubscription, setNeedSubscription] = useState(true);
	

	const dispatch = useDispatch();
	useEffect(() => {
		!card && fetchCards();
		// !billingData && fetchBillingData();
		// !subscription && fetchSubscription();
		if(needSubscription){			
			dispatch(getSubscriptionDB());
			dispatch(fetchSubscription());
			setNeedSubscription(false);
		}
	}, [
		card,
		// billingData,
		subscription,
		// fetchSubscription,
		// fetchBillingData,
		fetchCards,
		needSubscription, 
		dispatch
	]);
	
	
	const cards =  useSelector((store) => store.cards);
	



	const AlertMsg = (msg, variant, showLink, msgLink, toLnk) => {		
		setShowAlert(true);
		setAlertVariant(variant);
		setMessageAlert(msg);
		setShowAlertLink(showLink);
		if(showLink){			
			setMessageLink(msgLink);
			setToLink(toLnk);
		}		
	};

	const AlertBox = (msg) => {		
		setShowBox(true);
		setMessageBox(msg);		
	};

	// const showAlert = (message, alertType) => {
	// 	setAlert(message);
	// 	setAlertType(alertType);
	// 	// return new Promise((resolve) => {
	// 	// 	setTimeout(() => {
	// 	// 		resolve();
	// 	// 		setAlert("");
	// 	// 	}, 4000);
	// 	// });
	// };

	const handleCloseAlert = () => {	
		setShowAlert(false);	
		setShowAlertLink(false);	
	};

	const handleCloseBox = () => {	
		setShowBox(false);			
	};

	const verifyToChangePlan = (plan) => {

		setCurrentPlan(plan);

		if ( subscription.subs !== undefined  &&  subscription.subs.Status.toUpperCase() !== 'CANCELLED'){
			if( plan.toUpperCase() === subscription.subscriptionPlanInfo?.Plan?.toUpperCase() ) 
				AlertMsg('Ya cuentas con un Plan ' + ( plan.toUpperCase() === PRO.toUpperCase() ? 'PRO' : 'PREMIUM'), 'primary', false, '', '')
			else {
				const diasRestantes = Math.round(
					((new Date(subscription.subs.ExpirationDate)) - new Date()) / (1000 * 60 * 60 * 24)
				);
			
				console.log('suscripcion a cancelar', subscription);
				console.log('dias restantes', diasRestantes)
				AlertBox('Aún te ' + ( diasRestantes === 1 ? ('queda 1 día ' ) : ('quedan '+ diasRestantes + ' días ')  ) + ' de tu plan actual, si cambias de plan ahora se perderan y se te realizará en este momento el cobro del plan seleccionado');
			}
		}
		else
			ChangePlan(plan)

	}

	function ChangePlan  (value) {
		let actionsToDo = [];
		setIsLoading(true);
		handleCloseBox();

		
		if(cards.length === 0){			
			AlertMsg("No cuenta con una tarjeta, por favor agregue alguna y regrese aqui ", "danger", true, 'Agregar Tarjeta', '/suscription/card');
			setIsLoading(false);
		}
		else{
			// console.log('subs', subscription);
			// console.log('subscripcion', value);
			
			if ( subscription.subs !== undefined  &&  subscription.subs.Status.toUpperCase() !== 'CANCELLED'){
				actionsToDo.push(  cancelSubscription() );								
				AlertMsg("Espera un poco, estamos cancelando tu plan actual", "primary", false, '', '');
			}
			else
				// actionsToDo =  () => { return createSubscriptionWithCardId(value, cards[0].Id);}
				actionsToDo.push( undefined );
			
				console.log('sctions to do', actionsToDo);
			Promise.all(actionsToDo)			
			.then( (r) => { //regreso de cancelacion

				let actionsCancel = [];
				if(r[0]?.type === "CANCEL_SUBSCRIPTION")
					actionsCancel.push(r[0].payload);
				console.log('cancelar suscripcion', r);
				return Promise.all(actionsCancel);
			}) 
			.then((r) => {
				let actionToCreate = [createSubscriptionWithCardId( value, cards[0].Id)];
				AlertMsg("Estamos creando tu nuevo plan", "primary", false, '', '');
				return Promise.all(actionToCreate);
			})
			.then((r) => {
				let actionsCreate = [];
				if(r[0]?.type === "CREATE_SUBSCRIPTION")
					actionsCreate.push(r[0].payload);
				console.log('crear suscripcion', r);
				return Promise.all(actionsCreate);
			})
			.then((r) => { 

				AlertMsg("Estamos validando tu compra", "primary", false, '', '');
				var promise = new Promise ((resolve, reject) => {
					setTimeout(() => {
						console.log("10 Segundos esperando")
						let actions = [
							getSubscriptionDB(),
							fetchSubscription()
						];						
						
						
						
		
						resolve( Promise.all(actions) );
					  }, 20000);
				} );

				return promise;
			})					
			.then((r)=> {
				let actionsSubs = [];
				if(r[0]?.type === "GET_ECOMMERCE_SUBSCRIPTION"){
					actionsSubs.push(r[0].payload);
					actionsSubs.push(r[1].payload);
				}
				console.log('regreso de suscripcion', r);
				return Promise.all(actionsSubs);
				// console.log('resultados a', r );
				// AlertMsg("Los cambios se guardaron exitosamente", "success", false, '', '');
			})	
			.then((r) => {
				console.log('resultados a', r );
				if(r[1].Status === 'Active')
					AlertMsg("Los cambios se guardaron exitosamente", "success", false, '', '');
				else
					AlertMsg("Hubo un error al generar tu suscripción, status: " + r[1].Status, "danger", false, '', '');
			})				
			.catch((e) => { 						
				console.log('error en plan', e);
				AlertMsg(e._error + ' ' + e._errorDetail, "danger", false, '', '');
				// const error = { ...e, ...e?._error };
				// throw new Error(error);
			})
			.finally(() => { 	
				setNeedSubscription(true);
				setIsLoading(false);
			});
		}
	};
	
	

	return (
		// <AppFrame>
		<div>
			<Loader show={isLoading} />
			<div style={{ maxWidth: "800px", margin: "0px auto" }}>
				<div className="container">
					<div className="text-center mb-4 fontMontserrat">
						<h1>Administra tu plan</h1> 
						<span>
							Selecciona el plan que mas se adecúe a tus necesidades
						</span>
						{/* {alert && (
							<div className={`alert ${alertType}`} role="alert">
								{alert}
							</div>
						)} */}
						<Alert
							show={showAlertMessage}
							variant={alertVariant}
							onClose={handleCloseAlert}
							dismissible
						>
							<p>{messageAlert}</p>
							{ showAlertLink && <Link to={toLink} className='alert-link' >{messageLink}</Link>}
						</Alert>
					</div>
					<div className="row">
						<div className="col-md-6">
							<div
								// className={"card pro" + (currentPlan === PRO ? " card-active" : "")}
								className={"card pro fontMontserrat"}
								// onClick={() => setCurrentPlan(PRO)}
							>
								<div className="card-header pro textIdent">PRO</div>
								<div className="card-body">
									<div className="textIdent2">
										<strong className="price">$150 MXN</strong>
										<strong className="month"> / MES *</strong><br/>
										<strong className="tinyText fontMontserrat" >* Precios más IVA. Pago mensual no incluye folios</strong>
									</div>
									<div className="black2 my-4">
										<ul className="features-list fontMontserrat">
											<li>Configuración impuestos</li>
											<li>Catálogos de productos y clientes</li>
											<li>Auto-facturación al finalizar la compra </li>
											<li>Facturación a partir de una OC</li>
											<li>Información de las OC y sus facturas</li>
											<li>Envía, descarga o cancela facturas</li>
											<li>Acceso a facturama.mx</li>
											<li className="feature-not-included fontMontserrat">
												Módulo facturación global{" "}
											</li>
											<li className="feature-not-included fontMontserrat">
												Reporte de OC sin factura{" "}
											</li>
											<li className="feature-not-included fontMontserrat">
												Facturación desde HOME
											</li>
										</ul>
									</div>
									<div className="text-center">
									<button
										className="btn btn-outline-primary buttonWidth"
										// onClick={() => changePlan(PRO)}
										onClick={() => verifyToChangePlan(PRO)}	
										disabled={false}
									>
										Contratar
									</button>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div
								className={ "card premium fontMontserrat"  }
								// className={ "card premium" + (currentPlan === PREMIUM ? " card-active" : "") }
								// onClick={() => setCurrentPlan(PREMIUM)}
							>
								<div className="card-header premium textIdent">
									PREMIUM
								</div>
								<div className="card-body">
									<div className="textIdent2">
										<strong className="price white">$200 MXN</strong>
										<strong className="month white"> / MES *</strong><br/>
										<strong className="tinyText fontMontserrat white" >* Precios más IVA. Pago mensual no incluye folios</strong>
									</div>
									<div className="white my-4">
										<ul className="features-list fontMontserrat">
											<li>Configuración impuestos</li>
											<li>Catálogos de productos y clientes</li>
											<li>Auto-facturación al finalizar la compra </li>
											<li>Facturación a partir de una OC</li>
											<li>Información de las OC y sus facturas</li>
											<li>Envía, descarga o cancela facturas</li>
											<li>Acceso a facturama.mx</li>
											<li>Módulo facturación global </li>
											<li>Reporte de OC sin factura </li>
											<li>Facturación desde HOME</li>
										</ul>
									</div>
									<button
										className="btn btn-outline-primary buttonWidth buttonWhite"										
										// onClick={() => changePlan(PREMIUM)}
										onClick={() => verifyToChangePlan(PREMIUM)}										
										disabled={false}
									>
										Contratar
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="row fontMontserrat">
						{/* <strong>Consideraciones:</strong> */}
						<small>
							El pago mensual incluye el uso de la app de Facturama en
							TiendaNube, almacenamiento de facturas, asesoría y soporte (vía
							mail o chat), actualizaciones del SAT en materia de facturación
							electrónica y mejoras continuas en la app. - Para emitir facturas
							es necesario contratar folios (independiente al plan). El costo
							por folios es de $.50c IVA incluido y puede disminuir según el
							volumen de contratación. - Los folios tendrán una vigencia de 1
							año.
						</small>
					</div>
					<br></br>
					<div className="text-center mb-3">
						{/* <Link
							to="/settings/profile/subscription"
							className="btn btn-outline-secondary mr-3"
						>
							Regresar
						</Link> */}
					
					</div>
					<BootBox
						show={showBox}
						type={"confirm"}
						message={messageBox}
						successLabel={"Si"}
						onYesClick={ () => ChangePlan(currentPlan)}						
						onNoClick={handleCloseBox}
						onClose={handleCloseBox}
					/>
				</div>
			</div>
		</div>
		//</AppFrame> 
	);
};

PlansPage = connect(
	(state) => ({
		subscription: getSubscription(state),
		// subsPlanInfo: getsubscriptionPlanInfo(state),		
		card: getDefaultCard(state),
	}),
	{
		updateSubscription,
		getSubscription,
		fetchSubscription,
		fetchCards,
	}
)(PlansPage);

export default PlansPage;


// LoginForm = reduxForm({
// 	form: "loginForm",
// })(LoginForm);

// LoginForm = connect(
// 	(state) => {
// 		return {
// 			token: getToken(state),
// 			shopName: getShopName(state),
// 			subsPlanInfo: getsubscriptionPlanInfo(state),
// 		};
// 	},
// 	{
// 		loginAccount,
// 		getSubscriptionDB,
// 		fetchSubscription,
// 		fetchCheckSteps,
// 		fetchProfile,
// 		setToken,
// 		getEcommerceAccess,
// 	}
// )(LoginForm);

// export default LoginForm;
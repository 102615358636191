import React from "react";
import { reduxForm, SubmissionError, change } from "redux-form";
import PropTypes from "prop-types";
import TaxData from "../TaxData";
import Address from "../Address";
import { connect } from "react-redux";
import { fetchProfile, updateProfile } from "../../actions/profile";
import { fetchPostalCodes, fetchNeighborhoods } from "../../actions/catalogs";
import { getUsername } from "../../selectors/tokens";
// import UploadLogoForm from "../../containers/UploadLogoForm";
import {
	getProfile,
	getIsNatural,
	getNeighborhoods,
} from "../../selectors/profile";
import { Alert } from "react-bootstrap";


class TaxProfileForm extends React.Component {

	constructor() {
		super();
		this.state = {
			showAlert: false,
			alertVariant: "primary",
			messageAlert: "",
		};
	}


	submit = (values) => {
		return this.props.updateProfile(values)
		.then( () => {
			this.Alert('Los cambios se guardaron exitosamente', 'success');
		})
		.catch((e) => {
			this.Alert(e, 'danger');
			throw new SubmissionError(e);
			
		});
	};

	onPostalCodeChange = (input, value) => {
		this.props.fetchNeighborhoods(value);
		this.props.fetchPostalCodes(value);
	};

	componentDidMount() {
		!this.props.profile && this.props.fetchProfile();
		console.log("this on profile", this);
	}


	handleCloseAlert = () => {
		this.setState({ showAlert: false });
		
	};
	Alert = (msg, variant) => {
		this.setState({
			showAlert: true,
			messageAlert: msg,
			alertVariant: variant,
		});
	};

	render() {
		const {
			// error,
			handleSubmit,
			pristine,
			//reset,
			submitting,
			isNatural,
			neighboorhoods,
			// submitSucceeded,
			change,
		} = this.props;

		return (
			<form onSubmit={handleSubmit(this.submit)}>

				<Alert
					show={this.state.showAlert}
					variant={this.state.alertVariant}
					onClose={this.handleCloseAlert}
					dismissible
				>
					<p>{this.state.messageAlert}</p>
				</Alert>


				{/* {error && (
					<div className="alert alert-danger" role="alert">
						{error}
					</div>
				)}
				{submitSucceeded && (
					<div className="alert alert-success" role="alert">
						Se actualizo correctamente
					</div>
				)} */}
				<p>
					<b>Usuario: </b>
					{this.props.userName}{" "}
				</p>
				
						{/* <UploadLogoForm
							currentLogo={this.props.profile.UrlLogo}
							handleUploadLogo={this.handleUploadLogo}
						/> */}
					
						<TaxData isNatural={isNatural} onRfcChange={change} />
					
				
				
				
				<Address
					neighboorhoods={neighboorhoods}
					onPostalCodeChange={this.onPostalCodeChange}
				/>
				<br></br>
				<div className="d-flex flex-row-reverse">
					<button
						className="btn btn-outline-primary"
						type="submit"
						disabled={submitting}
					>
						Aceptar
					</button>
					<button
						className="btn btn-outline-secondary-cancel mr-3"
						type="button"
						disabled={pristine || submitting}
						//onClick={reset}
					>
						Cancelar
					</button>
				</div>
			</form>
		);
	}
}

TaxProfileForm.propTypes = {
	fetchProfile: PropTypes.func.isRequired,
	updateProfile: PropTypes.func.isRequired,
};

TaxProfileForm = reduxForm({
	form: "taxProfileForm",
	//enableReinitialize: true,
})(TaxProfileForm);

TaxProfileForm = connect(
	(state) => {
		return {
			initialValues: getProfile(state),
			profile: getProfile(state),
			isNatural: getIsNatural(state),
			userName: getUsername(state),
			neighboorhoods: getNeighborhoods(state),
		};
	},
	{
		updateProfile,
		fetchProfile,
		fetchNeighborhoods,
		fetchPostalCodes,
		change,
	}
)(TaxProfileForm);

export default TaxProfileForm;

import React from "react";
import InputField from "../InputField";
import { Field } from "redux-form";


import {
    isRequired,
    minLength,
   
} from "../../validations";
const minLength6 = minLength(6);
const RecoveryPassword = () => {
   
    return (
        <>
            <p className="h3 text-blue-primary">Restablecer contraseña</p>    
            <label className="h6">Ingresa tu nueva contraseña</label>            
            <div className="form-group">
                <label  >Nueva Contraseña:</label>
                <Field                        
                    type="password"
                    className="form-control"
                    name="PasswordNew"
                    placeholder="Mínimo 6 caracteres, solo letras y números"
                    component={InputField}
                    validate={[isRequired,minLength6]}
                />                                        
            </div>
            <div className="form-group">
                <label  >Confirmar Contraseña:</label>
                <Field                        
                    type="password"
                    className="form-control"
                    name="PasswordConfirm"
                    placeholder="Mínimo 6 caracteres, solo letras y números"
                    component={InputField}
                    validate={[isRequired,minLength6]}
                />                                        
            </div>           
        </>
    );
};

export default RecoveryPassword;
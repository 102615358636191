import { createStore, compose, applyMiddleware } from "redux";
import promiseMiddleware from "redux-promise";
import reducers from "../reducers";
import {loadState, saveState} from './localStore'

const composeEnhancers =
    (typeof window !== "undefined" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;


const initialData = loadState() || {}

const store = createStore(
    reducers,
    initialData,
    composeEnhancers(applyMiddleware(promiseMiddleware))
);



store.subscribe( function () {    
    saveState(store.getState())
  });

export default store
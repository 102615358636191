import React from 'react'
// import React from 'react'
import PropTypes from 'prop-types'
import { useTable} from 'react-table'


const DataTableSimpleEditable = ({ columns, data, updateMyData, skipPageReset}) => {
    // const [loadingTable, setLoadingTable] = useState(true);

    const EditableInputCell = ({
      value: initialValue,
      row: { index },
      column: { id },
      updateMyData, // This is a custom function that we supplied to our table instance
    }) => {
      // We need to keep and update the state of the cell normally
      const [value, setValue] = React.useState(initialValue)
    
      const onChange = e => {
        setValue(e.target.value)
      }
    
      // We'll only update the external data when the input is blurred
      const onBlur = () => {
        updateMyData(index, id, value)
      }
    
      // If the initialValue is changed external, sync it up with our state
      React.useEffect(() => {
        setValue(initialValue)
      }, [initialValue])
    
      return <input value={value} onChange={onChange} onBlur={onBlur} className="borderZero" />
    }

    const defaultColumn = {
      Cell: EditableInputCell,
    }
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data,
      defaultColumn,
      updateMyData
    })

    
    // setLoadingTable(false);
    return (
      // If you're curious what props we get as a result of calling our getter functions (getTableProps(), getRowProps())
      // Feel free to use console.log()  This will help you better understand how react table works underhood.
      <table {...getTableProps()} className="table-responsive">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column =>{
                  return column.hideHeader === true ? null : 
                  ( <th {...column.getHeaderProps()}>{column.render("Header")}</th> )
                }
                )}
            </tr>
          ))}
        </thead>
  
        <tbody {...getTableBodyProps()}>
         
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                })}
              </tr>
            )
          })}
         
        </tbody>
      </table>
    )
  }

DataTableSimpleEditable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    onClickRow: PropTypes.func,
}

export default DataTableSimpleEditable

import React from "react";
import InputField from "../InputField";
import { Field } from "redux-form";
import { maxLength, isRequired, predialAccount } from "../../validations";

const maxLength15 = maxLength(15);
const maxLength50 = maxLength(50);
const maxLength150 = maxLength(150);
const maxLength1000 = maxLength(1000);

export const ProductData = () => {
    return (
        <>
            <div className="form-group">
                <Field
                    label="Nombre Interno"
                    component={InputField}
                    type="text"
                    name="Name"
                    placeholder="Nombre Interno"
                    validate={[isRequired, maxLength50]}
                />
            </div>
            <div className="form-group">
                <Field
                    label="No Identificación"
                    component={InputField}
                    type="text"
                    name="IdentificationNumber"
                    placeholder="Código Interno"
                    validate={maxLength50}
                />
            </div>
            <div className="form-group">
                <Field
                    label="Descripción"
                    component={InputField}
                    type="textarea"
                    name="Description"
                    placeholder="Descripción"
                    validate={[isRequired, maxLength1000]}
                />
            </div>
            <div className="form-group">
                <Field
                    label="Precio Unitario"
                    component={InputField}
                    type="number"
                    name="Price"                   
                    placeholder="Precio"
                    validate={[isRequired, maxLength15]}
                />
            </div>
            <div className="form-group">
                <Field
                    label="Cuenta Predial"
                    component={InputField}
                    type="text"
                    name="CuentaPredial"
                    placeholder="Cuenta Predial (Solo para arrendamiento)"
                    validate={[predialAccount, maxLength150]}
                />
            </div>
        </>
    );
};

import React from "react";

import "../../styles/success.css";

const MessagePage = ({ title, message, onOk }) => {
	return (
		<div className="container pt-5">
			<div className="card mt-5">
				<div className="card-header primary text-center">
					<h4>{title}</h4>
				</div>
				<div className="card-body text-center">
					<p>{message}</p>
					<div
						className="swal2-icon swal2-success swal2-animate-success-icon"
						style={{ display: "flex" }}
					>
						<div className="swal2-success-circular-line-left"></div>
						<span className="swal2-success-line-tip"></span>
						<span className="swal2-success-line-long"></span>
						<div className="swal2-success-ring"></div>
						<div className="swal2-success-fix"></div>
						<div className="swal2-success-circular-line-right"></div>
					</div>

					{/* Fail icon */}
					{/* <div
				className="swal2-icon swal2-error swal2-animate-error-icon"
				style={{ display: "flex" }}
			>
				<span className="swal2-x-mark">
					<span className="swal2-x-mark-line-left"></span>
					<span className="swal2-x-mark-line-right"></span>
				</span>
			</div> */}
				</div>
				<div className="card-body text-center">
					{!!onOk && (
						<button className="btn btn-outline-primary" onClick={onOk}>
							Aceptar
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default MessagePage;

export const FEDERAL_STATES = [
    {
        Value: "AGUASCALIENTES",
        Name: "Aguascalientes",
    },
    {
        Value: "BAJA CALIFORNIA",
        Name: "Baja California",
    },
    {
        Value: "BAJA CALIFORNIA SUR",
        Name: "Baja California Sur",
    },
    {
        Value: "CAMPECHE",
        Name: "Campeche",
    },
    {
        Value: "CHIAPAS",
        Name: "Chiapas",
    },
    {
        Value: "CHIHUAHUA",
        Name: "Chihuahua",
    },
    {
        Value: "COAHUILA",
        Name: "Coahuila",
    },
    {
        Value: "COLIMA",
        Name: "Colima",
    },
    {
        Value: "CIUDAD DE MEXICO",
        Name: "Ciudad de Mexico",
    },
    {
        Value: "DURANGO",
        Name: "Durango",
    },
    {
        Value: "GUANAJUATO",
        Name: "Guanajuato",
    },
    {
        Value: "GUERRERO",
        Name: "Guerrero",
    },
    {
        Value: "HIDALGO",
        Name: "Hidalgo",
    },
    {
        Value: "JALISCO",
        Name: "Jalisco",
    },
    {
        Value: "ESTADO DE MEXICO",
        Name: "México",
    },
    {
        Value: "MICHOACAN DE OCAMPO",
        Name: "Michoacán",
    },
    {
        Value: "MORELOS",
        Name: "Morelos",
    },
    {
        Value: "NAYARIT",
        Name: "Nayarit",
    },
    {
        Value: "NUEVO LEON",
        Name: "Nuevo León",
    },
    {
        Value: "OAXACA",
        Name: "Oaxaca",
    },
    {
        Value: "PUEBLA",
        Name: "Puebla",
    },
    {
        Value: "QUERETARO",
        Name: "Querétaro",
    },
    {
        Value: "QUINTANA ROO",
        Name: "Quintana Roo",
    },
    {
        Value: "SAN LUIS POTOSI",
        Name: "San Luis Potosí",
    },
    {
        Value: "SINALOA",
        Name: "Sinaloa",
    },
    {
        Value: "SONORA",
        Name: "Sonora",
    },
    {
        Value: "TABASCO",
        Name: "Tabasco",
    },
    {
        Value: "TAMAULIPAS",
        Name: "Tamaulipas",
    },
    {
        Value: "TLAXCALA",
        Name: "Tlaxcala",
    },
    {
        Value: "VERACRUZ DE IGNACIO DE LA LLAVE",
        Name: "Veracruz",
    },
    {
        Value: "YUCATAN",
        Name: "Yucatán",
    },
    {
        Value: "ZACATECAS",
        Name: "Zacatecas",
    },
];

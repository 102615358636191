/**
 * AccountRegister
 * 
 * Contenedor de los elementos de Account (Login y Register)
 */

import React from "react";
import { useParams } from 'react-router-dom';

import RegisterForm from "../../components/Account/RegisterForm";
import { urlCdnImages } from "../../api/urls"

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../../index.css';
import store from "../../store";

require('typeface-montserrat');


const RegisterPage = () => {    


    let { shopName } = useParams();
    let state = store.getState();

    if(state.tokens === undefined)
        state.tokens = {};  

    if(shopName !== undefined && shopName !== ''){
        state.tokens.shopName = shopName;
    }

    if(shopName === 'reset'){        
        state.tokens.token = '';
    }
    
    return (
        <>
        
        <section className="bg-gray pb-3 xs-pb-0">

            <div className="container">

                <div className="row d-flex justify-content-center pt-5 xs-pt-1 xs-pb-0">			
                    <div className="col-md-auto">
                        <figure className="logo xs-img-auto">
                            <picture>
                                <source type="image/png" srcSet = { `${urlCdnImages}/facturama-freshbooks-logo.png` } />
                                <img src={ `${urlCdnImages}/facturama-freshbooks-logo.png` } alt="Facturación electrónica Facturama" className="img-fluid" />
                            </picture>
                        </figure>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">

                    <div className="col-md-7 xs-full-col">						

                        <RegisterForm shopName={shopName} />                        

                    <div className="mt-3 text-center small xs-display-content-none">
                        <a href="tel:4448356098" className="text-gray">444-8356098</a> - <a href="tel:4441510191" className="text-gray">444-1510191</a> | <a className="text-gray" href="mailto:info@facturama.mx">info@facturama.mx</a>
                    </div>

                </div>  {/* col md6 */}
            

        </div>  {/* row */}
        

    </div> {/* container */}
    

</section>

                                                
        </>
    );    
}


export default RegisterPage;


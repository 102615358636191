import React from "react";
import { connect } from "react-redux";

import AppFrame from "../../components/AppFrame/AppFrame";
import { documentsPaymentReceipt } from "../../actions/documents";

class PaymentReceiptPage extends React.Component {
	constructor(props) {
		super(props);

		const { id } = this.props.match.params;

		this.state = {
			loading: false,
			chargeId: id,
			htmlContent: "",
		};
	}

	componentDidMount() {
		this.updateReceiptPreview();
	}

	b64ToUtf8(str) {
		str = str.replace(/\s/g, "");
		return decodeURIComponent(escape(window.atob(str)));
	}

	updateReceiptPreview() {
		console.log("cargando lav vista");

		this.props.documentsPaymentReceipt(this.state.chargeId).then((response) => {
			response = response.payload;
			if ("_error" in response) {
				console.log("error:", response);
			} else {
				console.log(response);
				let html = this.b64ToUtf8(response.Content);
				this.setState({ htmlContent: html });
			}
		});
	}

	render() {
		return (
			<AppFrame title="Comprobante para pago">
				<div className="row">
					<div id="payment-form" className="col-md-12">
						<div
							dangerouslySetInnerHTML={{ __html: this.state.htmlContent }}
						></div>
					</div>
				</div>
			</AppFrame>
		);
	}
}

PaymentReceiptPage = connect(null, {
	documentsPaymentReceipt,
})(PaymentReceiptPage);

export default PaymentReceiptPage;

import React from "react";
import AddressWithoutZipCode from "../Address/AddressWithoutZipCode";
import InputField from "../InputField";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
	rfc,
	email,
	isRequired,
	emailNotRequired,
	minLength,
	maxLength,
	mxnPostalCode,
} from "../../validations";
import { CFDI_USES } from "../../constants/cfdi_uses";
import { TAX_REGIMES } from "../../constants/tax_regimes";
import {
	fetchCustomerById,
	insertCustomer,
	updateCustomer,
	deleteCustomer,
} from "../../actions/customers";
import Bootbox, { BootBoxAlert } from "../../components/General/bootbox";
import Loader from "../../components/General/loader";
import { getCustomerById } from "../../selectors/customers";


const minLength12 = minLength(12);
const maxLength13 = maxLength(13);

class CustomerForm extends React.Component 
{
	constructor() 
	{
		super();
		this.state = {
			includeAddress: false,
			redirect: false,
			url: "",
			hiddenDeleteButton: false,
			showBootBoxAlert: false,
			modalshow: false,
			email: "",
			rfcIsNatural: false,
			showConfirm: false,
			msgConfirm: "",
			isLoading: false,
		};
	}

	submit = (values) => 
	{
		values.Name = values.Name.toUpperCase().trim();
		if (values.Address && !this.state.includeAddress) 
		{
				delete values.Address.State;
				delete values.Address.Municipality;
				delete values.Address.Neighborhood;
				delete values.Address.Street;
				delete values.Address.ExteriorNumber;
				delete values.Address.InteriorNumber;
		}
		this.setState({ isLoading: true });
		let submitFuncion = values.Id
			? this.props.updateCustomer
			: this.props.insertCustomer;
		return submitFuncion(values).then(
			({ payload }) => {				
				if (payload._error) 
				{
					throw new SubmissionError(payload);
				}
				if (this.props.setId !== undefined) this.props.setId(payload.Id, payload.Name);
					this.setState({ isLoading: false });
			},
			(error) => 
			{
				this.setState({
					isLoaded: true,
					error,
				});
				this.setState({ isLoading: false });
			}
		);
	};

	componentDidMount() {
		if (this.props.Id === "new" || this.props.Id === "0") {
			return;
		}
		this.setState({ rfcIsNatural : ( this.props.initialValues?.Rfc?.length === 13 ) });
		if (this.props.initialValues?.Address && this.props.initialValues?.Address.State ) {
			this.setState({ includeAddress: true });
		}
	}

	handleCancel() {
		this.setState({
			redirect: true,
			url: `/catalogs/list/customers`,
		});
	}

	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect to={this.state.url} />;
		}
	};

	handleDelete() {
		this.setState({ showConfirm: true });
		this.setState({ msgConfirm: "¿Deseas eliminar este cliente?" });
	}

	handleClose = () => {
		this.setState({ showConfirm: false });
	};
	handleOk = () => {
		this.setState({ showConfirm: false });
		this.setState({ isLoading: true });
		return this.props.deleteCustomer(this.props.Id).then(
			({ payload }) => {
				if (payload) {
					this.setState({ showBootBoxAlert: true });
					this.setState({ msgTitle: "Cliente eliminado correctamente" });
					this.setState({ msgDescription: "" });
				}
				if (payload._error) {
					throw new SubmissionError(payload);
				}
				this.setState({ isLoading: false });
			},
			(error) => {
				this.setState({
					error,
				});
				this.setState({ isLoading: false });
			}
		);
	};

	handleOkAlert = () => {
		this.setState({
			redirect: true,
			url: `/catalogs/list/customers`,
		});
	};
	componentDidUpdate(nextProps, nextState) {}

	render() {
		const { error, handleSubmit, submitSucceeded, IsInvoice } = this.props;

		return (
			<>
				<Loader show={this.state.isLoading} />
				<form id="CustumerForm" onSubmit={handleSubmit(this.submit)}>
					{!IsInvoice && this.renderRedirect()}

					{!IsInvoice && (
						<div className="row">
							<div className="col text-right">
								<button
									className="btn btn-danger"
									type="button"
									hidden={this.state.hiddenDeleteButton}
									onClick={() => this.handleDelete()}
								>
									Eliminar
								</button>
							</div>
						</div>
					)}
					{error && (
						<div className="alert alert-danger" role="alert">
							{error}
						</div>
					)}
					{submitSucceeded && (
						<div className="alert alert-success" role="alert">
							Datos de cliente guardados correctamente
						</div>
					)}

					<div className="form-row">
						<div className="form-group col-md-4">
							<Field
								label="RFC"
								component={InputField}
								type="text"
								name="Rfc"
								normalize={(value) => value.toUpperCase()}
								onChange={ (value) => { 
									this.setState({ rfcIsNatural : ( value.target.value.length === 13 ) });
								} }
								placeholder="RFC"
								validate={[isRequired, minLength12, maxLength13, rfc]}
							/>
						</div>
						<div className="form-group col-md-4">
							<Field
								label="Nombre"
								component={InputField}
								type="text"
								name="Name"
								placeholder="Nombre o razón social"
								validate={[isRequired]}
								normalize={(value) => value.toUpperCase()}
							/>
						</div>
						<div className="form-group col-md-4">
							
							<Field
								label="Uso del Cfdi"
								component={InputField}
								type="select"
								name="CfdiUse"
								placeholder="Nombre o razón social"
								validate={isRequired}
							>
								<option value="">Seleccione una opción</option>
								{CFDI_USES.map((cfdiUse) => (
									<option
										key={cfdiUse.Value}
										value={cfdiUse.Value}
									>{`${cfdiUse.Value} - ${cfdiUse.Name}`}</option>
								))}
							</Field>
						</div>
					</div>
					<div className="form-row">
						<div className="form-group col-md-12">

							<label>Régimen fiscal&nbsp;</label>
								<span className="fa fa-info-circle color-secondary-static" title="El campo Régimen fiscal será obligatorio para los receptores a partir de julio 2022" ></span>
							<Field
								label=""
								className="form-control"
								name="FiscalRegime"
								required=""
								type="select"
								component={InputField}
							>
								<option value="">Selecciona una opción</option>
								{
									this.state.rfcIsNatural ? (
										TAX_REGIMES.filter((regime) => regime.Natural === true).map(
											(regime) => (
												<option value={regime.Value} key={regime.Value}>
													{regime.Name}
												</option>
											)
										))
									:(
										TAX_REGIMES.filter((regime) => regime.Moral === true).map(
											(regime) => (
												<option value={regime.Value} key={regime.Value}>
													{regime.Name}
												</option>
											)
										))
								}
							</Field>					
						</div>
							
					</div>
					<div className="form-row">
						<div className="form-group col-md-4">
							<Field
								label="Email 1"
								component={InputField}
								type="email"
								name="Email"
								placeholder="Email"
								validate={[isRequired, email]}
							/>
						</div>
						<div className="form-group col-md-4">
							<Field
								label="Email 2"
								component={InputField}
								type="email"
								name="EmailOp1"
								placeholder="Email"
								validate={[emailNotRequired]}
							/>

						</div>
						<div className={`form-group col-md-4`}>
							<label>Codigo postal&nbsp;</label>
							<span className="fa fa-info-circle color-secondary-static" title="El campo CP será obligatorio para los receptores a partir de julio 2022" ></span>
							
							<Field
								component={InputField}
								type="text"
								className="form-control zipcode"
								name="Address.ZipCode"								
								placeholder="Código postal"
								normalize={value => value.replace(/[^\d]/g, '')}
								validate={[mxnPostalCode ]}
							/>
						</div>
						<div className="col-md-12 d-flex align-items-end mb-2">
							<div className="form-group form-check">
								<input
									type="checkbox"
									className="form-check-input"
									id="includeAddress"
									onChange={() =>
										this.setState({
											includeAddress: !this.state.includeAddress,
										})
									}
									checked={this.state.includeAddress}
								/>
								<label className="form-check-label" htmlFor="includeAddress">
									Incluir dirección
								</label>
							</div>
						</div>
					</div>
					{this.state.includeAddress && (
						<>
							<div className="form-row">
								<div className="col-md-12">
									<h4>Dirección</h4>
									<hr className="my-2"></hr>
								</div>
							</div>
							<AddressWithoutZipCode baseName="Address" />
						</>
					)}

					{!IsInvoice && (
						<div className="row text-right">
							<div className="col pt-4">
								<button
									className="btn btn-outline-secondary mr-3"
									type="button"
									onClick={() => this.handleCancel()}
								>
									<i className="fas fa-chevron-left"></i> Ir a catálogos
								</button>
								<button className="btn btn-outline-primary " type="submit">
									Aceptar
								</button>
							</div>
						</div>
					)}

					{
						<Bootbox
							show={this.state.showConfirm}
							type={"confirm"}
							message={this.state.msgConfirm}
							successLabel={"Si"}
							onYesClick={this.handleOk}
							onNoClick={this.handleClose}
							onClose={this.handleClose}
						/>
					}

					{
						<BootBoxAlert
							show={this.state.showBootBoxAlert}
							type={"confirm"}
							message={this.state.msgTitle}
							detail={this.state.msgDescription}
							onYesClick={this.handleOkAlert}
						/>
					}
				</form>
			</>
		);
	}
}

CustomerForm.defaultProps = {
	customer: null,
};

CustomerForm = reduxForm({
	form: "CustomerForm",
})(CustomerForm);

CustomerForm = connect(
	(state, props) => {
		return {
			initialValues: getCustomerById(state, props),
		};
	},
	{
		fetchCustomerById,
		updateCustomer,
		insertCustomer,
		deleteCustomer,
	}
)(CustomerForm);

export default CustomerForm;

/**
 * PaymentPage
 *
 * Pagina que indica la forma en que se pagará la suscripción
 */

import React from "react";
import { connect } from "react-redux";
import { createSubscriptionWithCard } from "../../actions/subscription";
import CardForm from "../../components/CardForm";
import { PRO } from "../../constants/plans";

let PaymentPage = ({ createSubscriptionWithCard }) => {
	return (
		<section className="bg-gray">
			<div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm">
				<h3 className="my-0 mr-md-auto font-weight-normal">
					<img
						src="/images/facturama-logo.png"
						className="logo"
						alt="Facturama Logo"
					/>
				</h3>
			</div>
			<div
				className="container"
				style={{ maxWidth: "600px", margin: "0px auto" }}
			>
				<div className="card">
					<div className="pt-5 px-5">
						<h1 className="text-center">Define tu método de pago</h1>
						<p className="text-center">
							Al ser un servicio por suscripción mensual es necesario agregar
							una tarjeta de crédito. Te recordamos que cuentas con un periodo
							de 7 días totalmente gratis. Recuerda que podrás cancelar tu
							suscripción en cualquier momento.
						</p>
					</div>
					<div className="card-body">
						<div className="container">
							<CardForm
								titleButton="Iniciar Suscripción"
								action={createSubscriptionWithCard}
								plan={PRO}
							/>
						</div>
					</div>
					<div className="card-footer">
						<div className="mt-3">
							<small className="text-center text-muted mt-3">
								Al hacer clic en el botón "Iniciar suscripción" se aceptan los
								términos de uso y aviso de privacidad, que quién contrata es
								mayor de 18 años y que Facturama continuará con el cargo mensual
								recurrente del plan PRO (actualmente MXN$150 mas IVA) a su
								método de pago hasta que este sea cancelado. La suscripción se
								cancela desde perfil fiscal y de clic en "cancelar suscripción".
								.
							</small>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

PaymentPage = connect(null, {
	createSubscriptionWithCard,
})(PaymentPage);

export default PaymentPage;

import { SHOP_NAME, TOKEN } from "./../constants";
import { createAction } from "redux-actions";

export const setShopName = createAction(SHOP_NAME, 
    (shopName) => {            
        return shopName;
     }     
);


export const setToken = createAction(TOKEN, 
    (token) => {            
        return token;
     }     
);

import React from "react";

export const WelcomeEposNow = () => {
	return (
		<div>
			Gracias por interesarte en nuestra app en <b>EPOSNOW</b>, adem&aacute;s de
			facturar ten acceso a las siguientes herramientas:
			<br />
			<br />
			<ul>
				<li>
					Asociación de las claves SAT para productos y unidades de forma
					masiva.
				</li>
				<li>Emitir facturas a partir de las ventas realizadas.</li>
				<li>Reporte de las ventas no facturadas.</li>
			</ul>
			<br />
			Aqu&iacute; encontrar&aacute;s nuestros manuales de uso:
			<br />
			<br />
			<a
				href="https://cdnfacturama.azureedge.net/content/docs/Eposnow_instalacion.pdf"
				target="_blank"
				rel="noopener noreferrer"
			>
				Manual de Instalaci&oacute;n
			</a>
			<br />
			<a
				href="https://cdnfacturama.azureedge.net/content/docs/Eposnow_configuracion.pdf"
				target="_blank"
				rel="noopener noreferrer"
			>
				Manual de Configuraci&oacute;n
			</a>
		</div>
	);
};

import { FACTURAMA_DOCUMENTS} from './../constants';
import { createAction } from 'redux-actions';
import { apiGet } from '../api';
import { urlFacturamaDocuments } from '../api/urls';

/**
 * Obtiene el documento del pago en HTML (un recibo para que puedan pagar en una de las tiendas)
 */
export const documentsPaymentReceipt = createAction(
    FACTURAMA_DOCUMENTS,     
    (chargeId) => apiGet(`${urlFacturamaDocuments}/payments/receipts/${chargeId}/html`) ()
    
);

/**
 * Obtiene el acuse de pago (un pago ya confirmado) en HTML
 */
export const documentsPaymentAcuse = createAction(
    FACTURAMA_DOCUMENTS,     
    (chargeId) => apiGet(`${urlFacturamaDocuments}/payments/acuses/${chargeId}/html`) ()
    
);
//Request URL: https://apisandbox.facturama.mx/api/documents/payments/receipts/trkfr6lkygt32qtbjoje/html




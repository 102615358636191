import React from "react";
// import PropTypes from 'prop-types'
import InputField from "../InputField";
import { Field } from "redux-form";
import { Link } from 'react-router-dom'
import {
    anyCharButSpace,
    email,
    isRequired,
    minLength,
    maxLength,
    password,
    rfc
} from "../../validations";

const minLength6 = minLength(6);
const minLength12 = minLength(12);
const maxLength13 = maxLength(13);
const maxLength100 = maxLength(100);

const onlyLettersNumbers = value => {    
    const validCharacters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    let returnString = '';
    for (var i=0; i<value.length; i++)
        if (validCharacters.indexOf(value.charAt(i)) !== -1) 
            returnString += value.charAt(i);
    return  returnString;
}


const Register = () => {


    return (
        <>

                <p className="h3 text-blue-primary text-center">Inicia tu prueba</p>

                <div className="form-group">
                    <label  >Nombre de usuario:</label>
                    <Field                        
                        type="text"
                        className="form-control"
                        name="UserName"
                        placeholder="Mínimo 6 caracteres, sin espacios"
                        component={InputField}     
                        normalize={ value => value.replace(/\s/g, '') }                   
                        validate={[isRequired, minLength6,maxLength100, anyCharButSpace]}                        
                    />                                        
                </div>

                <div className="form-group">
                    <label  >Correo:</label>
                    <Field                        
                        type="email"
                        className="form-control"
                        name="Email"
                        placeholder="correo@ejemplo.com"
                        component={InputField}
                        validate={[isRequired, email]}
                    />                                        
                </div>

                <div className="form-group">
                    <label  >Confirmar Correo:</label>
                    <Field                        
                        type="email"
                        className="form-control"
                        name="ConfirmEmail"
                        placeholder="correo@ejemplo.com"
                        component={InputField}
                        validate={[isRequired, email]}
                    />                                        
                </div>

                <div className="form-group">
                    <label  >Contraseña:</label>
                    <Field                        
                        type="password"
                        className="form-control"
                        name="Password"
                        placeholder="Mínimo 6 caracteres, solo letras y números"
                        normalize = { onlyLettersNumbers }   
                        component={InputField}
                        validate={[isRequired, password]}
                    />                                        
                </div>

                <div className="form-group">
                    <label  >RFC:</label>
                    <Field                        
                        type="text"
                        className="form-control"
                        name="Rfc"
                        placeholder="Clave con 12 o 13 caracteres"
                        component={InputField}
                        normalize={ value => value.toUpperCase()}
                        validate={[isRequired, minLength12, maxLength13, rfc]}
                    />                                        
                </div>

                
                <div className="text-center mt-4">
                    <Link to={{pathname: "https://app.facturama.mx/Content/docs/Facturama-terminos-y-condiciones-del-servicio.pdf"}} target="_blank" rel="nofollow" className="text-blue-primary font-weight-semibold xs-font-12 align-center">
                        Al registrarte en Facturama estas aceptando nuestros Términos y Condiciones
                    </Link>
                </div>

            </>
    );
};

export default Register;

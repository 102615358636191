/**
 * Wizard Page
 * 
 * Página del wizard
 */

import React from "react";
import { reduxForm } from "redux-form";
import NeedHelp from "../../components/ContactUs/NeedHelp";
import WizardForm from "../../components/Wizard";
import { connect } from "react-redux";
import { contactUsSendMail } from "../../actions/contactUs";
import { urlCdnImages } from "../../api/urls"

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../../index.css';


require('typeface-montserrat');


class WizardPage extends React.Component {

    constructor() {
        super();
       
        this.state = {
            showNeedHelp: false
        }
      
    }

    handleOpenNeedHelp=() => {
        this.setState({showNeedHelp:true});
    }
    handleCloseNeedHelp=() => {
        this.setState({showNeedHelp:false});
    }

    render() {
        // console.log("props h", this);
        return (
            <>
            <section className="bg-gray">            
    
                <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm">
                    <h3 className="my-0 mr-md-auto font-weight-normal"><img src={ `${urlCdnImages}/facturama-freshbooks-logo.png` } className="logo" alt="Facturama Logo"/></h3>
                    
                    <button type="button" className="btn btn-outline-primary" data-toggle="modal" data-target="#helpModal" onClick={() => {this.handleOpenNeedHelp() } }>Necesito ayuda</button>
                     { <NeedHelp
                        show={ this.state.showNeedHelp } 
                        type={"confirm"}                          
                        successLabel = {"Si"}
                        onClose = {this.handleCloseNeedHelp }
                       
                    /> }
                </div>
    
                <div className="container">
    
                    <WizardForm {...this.props}/>              
    
                </div> {/* container */}
        
    
            </section>
                                                    
            </>
        );    
    }

}

// const WizardPage = () => {

    
// }

WizardPage = connect(
    (state) => {        
         return {
            //  token: getToken(state),
            
         };
    },
    {
        contactUsSendMail,
    //  setToken        
    }
)(WizardPage);

WizardPage = reduxForm({
    form: 'wizardPage'
})(WizardPage)

export default WizardPage;


import { SEND_INVOICE,INSERT_INVOICE_GLOBAL,INSERT_INVOICE, FETCH_INVOICE, FETCH_INVOICE_GLOBAL } from "./../constants";
import { createAction } from "redux-actions";
import { apiGet, apiPost } from "../api";
import { urlInvoice,urlCfdi, urlInvoiceGlobal } from "../api/urls";
export const fetchInvoice = createAction(
	FETCH_INVOICE, 
	(id) => { 
		return apiGet(`${urlInvoice}/${id}`)();
});
export const fetchInvoiceGlobal = createAction(
	FETCH_INVOICE_GLOBAL, 
	(ids) => { 
		return apiGet(`${urlInvoiceGlobal}?strIds=${ids}`)();
});

export const insertInvoice = createAction(
	INSERT_INVOICE, 
	(invoice, id) => {
		return apiPost(`${urlInvoice}/${id}`, invoice)();
	 }
);
export const insertInvoiceGlobal = createAction(
	INSERT_INVOICE_GLOBAL, 
	(invoice, ids) => {
		return apiPost(`${urlInvoiceGlobal}?ids=${ids}`, invoice)()
	}
);
export const sendInvoice = createAction(
	SEND_INVOICE,
	(cfdiType,cfdiId,email,subject,comments) => { 
			return apiPost(`${urlCfdi}?cfdiType=${cfdiType}&cfdiId=${cfdiId}&email=${email}&subject=${subject}&comments=${comments}`)();
		});


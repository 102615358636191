import React from 'react';
import { reduxForm, Field } from "redux-form";
import {setPropsAsInitial} from "../../helpers/setPropsAsInitial";
const isRequired=value=>(
!value&&"este campo es requerido"
);
const MyField=({input,meta,type,label,name})=>(
    <div className="col-6">
        <label htmlFor={name}>{label}</label>
        <input {...input} type={!type?"text":type}/>
        {
          meta.touched&&meta.error&&<div >{meta.error}</div>
        }
    </div>
);
const isNumber=value=>(
    isNaN(Number(value))&&"El campo debe ser numerico"
);
const validate=values=>{
    const error={};
    if(!values.Name){
        error.Name="el campo Nombre es requerido";
    }
    if(!values.Description){
        error.Description="el campo Descripcion es requerido";
    }
    return error;
};
const toNumber=value=>value && Number(value)
const onlyGrow=(value,previousValue,values)=>value&&(value>=0?value:1)//value&&previousValue&&(value>previousValue?value:previousValue);

const  SerieNew = ({handleSubmit}) => {
    return(        
        <form id="SerieForm" onSubmit={handleSubmit}>
            <div className="row">
                <Field name="Name" component={MyField}                
                label={"Nombre"}
                ></Field>
                <Field name="Description" component={MyField}                
                label={"Descripcion"}
                ></Field>
                <Field name="Folio" component={MyField} type="number"
                validate={[isRequired,isNumber]}
                parse={toNumber}
                normalize={onlyGrow}
                label={"Folio de Inicio"}
                ></Field>
            </div>
        </form>
    );
}
const SerieNewForm=reduxForm(
    {
        form:"SerieNew",
        validate
    })(SerieNew);
export default setPropsAsInitial(SerieNewForm);
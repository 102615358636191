import React from "react";
import { Field } from "redux-form";
import InputField from "../InputField";
// import { isRequired } from "../../validations";



export const TaxObject = () => {
    return (
        <div className="form-group">
            <label>Objeto Impuesto</label>
            
            <Field
                type="select"
                name="ObjetoImp"
                component={InputField}
                className="form-control"
                placeholder="Objeto Impuesto"
                // validate={[isRequired]}
            >       
                <option key="" value="">Selecciona una opción</option>     
                <option key="01" value="01">01 - No objeto de impuesto</option>     
                <option key="02" value="02">02 - Sí objeto de impuesto</option>     
                <option key="03" value="03">03 - Sí objeto de impuesto y no obligado al desglose</option>  
                <option key="04" value="04">04 - Sí objeto de impuesto y no causa impuesto</option>   
            </Field>
        </div>
    );
};

import {
	INSERT_PROCESS,
	INSERT_PROCESS_UPLOAD,
	INSERT_TAX_SETTING,
	FETCH_PRODUCTS_SERVICES,
	FETCH_GENERAL_SETTINGS,
	FETCH_MASSIVEPROCESS,
	DOWNLOAD_PRODUCTS_CSV,
	IMPORT_INSERT_PRODUCTS,
} from "./../constants";
import { createAction } from "redux-actions";
import { apiPost, apiPostData, apiGet } from "../api";
import {
	urlProductsEcommerce,
	urlUploadProductsEcommerce,
	urlSettings,
	urlProductsOrServices,
	urlMassiveProcess,
	urlDownloadProductsCsv,
	urlEcommerceProductsImport,
	//urlSettings,
} from "../api/urls";
export const insertProcess = createAction(INSERT_PROCESS, (param) => {
	return apiPost(`${urlProductsEcommerce}`, param)();
});
export const uploadCvs = createAction(INSERT_PROCESS_UPLOAD, (param) => {
	return apiPostData(`${urlUploadProductsEcommerce}`, param)();
});
export const saveSetting = createAction(INSERT_TAX_SETTING, (param) => {
	return apiPost(`${urlSettings}/SettingsGeneral`, param)();
});
export const fetchProductsOrServices = createAction(
	FETCH_PRODUCTS_SERVICES,
	(search) => {
		return apiGet(`${urlProductsOrServices}?keyword=${search}`)();
	}
);
export const fetchSettings = createAction(FETCH_GENERAL_SETTINGS, () => {
	return apiGet(`${urlSettings}/SettingsGeneral`)();
});
export const fetchMassiveProcess = createAction(FETCH_MASSIVEPROCESS, () => {
	return apiGet(`${urlMassiveProcess}`)();
});
export const downloadMassiveCsv = createAction(DOWNLOAD_PRODUCTS_CSV, (id) => {
	return apiGet(`${urlDownloadProductsCsv}?idMassive=${id}`)();
});
export const ImportProducts = createAction(IMPORT_INSERT_PRODUCTS, (email) => {
	return apiPost(`${urlEcommerceProductsImport}?email=${email}`)();
});

import React, { useState, useEffect } from "react";

import DataTableSimple from "../../components/DataTable/DataTableSimple";
import TotalsBox from "../../components/Invoice/TotalsBox";
import { reduxForm, Field } from "redux-form";
import InputField from "../../components/InputField";
import CURRENCIES from "../../constants/currencies";
import PAYMENT_FORMS from "../../constants/payment_forms";
import PERIODICITY from "../../constants/periodicity";
import MONTHS from "../../constants/months";
import { isRequired, maxLength } from "../../validations";
import theme from "./theme.js";
import { findCustomers } from "../../actions/customers"
import { getCustomerBySearch } from "../../selectors/customers";
import Autosuggest from 'react-autosuggest';
import { connect } from "react-redux";

const maxLength30 = maxLength(30);
let AddInvoicePage = (props) => {
	const [idCustomer, setIdCustomer] = useState("");
	const [optionsMonths, setOptionsMonths] = useState([]);
	const [clientSugestions, setClientSugestions] = useState([]);
	const [value, setValue] = useState('');
	const [errorOnClient, setErrorOnClient] = useState(false);
	const {
		branchOffices,
		invoice,
		series,
		fillSeries,
		onCustomersModalShow,
		informationGlobalNode,
		onChangePaymentType,
		onChangeClient,
		clientName,
		clearClientName,
		clientError,
		clearErrorOnClient
	} = props;
		useEffect(() => {
		if(clientName !== ''){
			setValue(clientName);
			clearClientName();
		}
		if(clientError){
			theme.input = {
				width: '340px',
				height: '38px',
				padding: '10px 15px',
				border: '1px solid',
				'border-color': '#dc3545',
				'background-image': "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e\")",
				'background-repeat': 'no-repeat',
				'background-position': 'right calc(0.375em + 0.1875rem) center',
				'background-size': 'calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)'
			};
			onChangeClient('');
			setErrorOnClient(true);
			clearErrorOnClient();
		}
	  },[clientName, clientError, clearClientName, onChangeClient, clearErrorOnClient] );
	

	const changePeriodicity = (e) => {
		var options = [];

		MONTHS.map((month) => {
			if(month.AvailableFor.includes(e.target.value))
				options.push(month);		
			return null;
		})
		setOptionsMonths(options);
	};

	const  onSuggestionsFetchRequested = ({ value }) => {
		findCustomers(value).payload
		.then( r => {
			setClientSugestions(r.data);
		});
	   };
 

	const onSuggestionSelected = (e, s) => {

		theme.input = {
			width: '340px',
			height: '38px',
			padding: '10px 15px',
			border: '1px solid #ced4da'
		};
		setErrorOnClient(false);
	}

	const onSuggestionsClearRequested = () => {
		setClientSugestions([]);
	};
	const getSuggestionValue = (suggestion) => {
		setIdCustomer(suggestion.Id);
		onChangeClient(suggestion.Id);
		return suggestion.Name;
	}

	const renderSuggestion = suggestion => (
		<div>
		  {suggestion.Name}
		</div>
	  );

	const onChange = (event, { newValue }) => {

		if( newValue === undefined || newValue === "") {
			theme.input = {
				width: '340px',
				height: '38px',
				padding: '10px 15px',
				border: '1px solid',
				'border-color': '#dc3545',
				'background-image': "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e\")",
				'background-repeat': 'no-repeat',
				'background-position': 'right calc(0.375em + 0.1875rem) center',
				'background-size': 'calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)'
			};
			onChangeClient('');
		}
		setErrorOnClient(true);
		findCustomers(newValue);
		setValue(newValue);
    };
	  
	  const inputProps = {
        placeholder: 'Selecciona el cliente',
        value,
        onChange: onChange		
    };

	const columnsProducts = [
		{
			Header: "Productos",
			hideHeader: true,
			columns: [
				{
					Header: "Cantidad",
					accessor: "Quantity",
				},
				{
					Header: "Clave Prod SAT",
					accessor: "ProductCode",
				},
				{
					Header: "Clave Unidad SAT",
					accessor: "UnitCode",
				},
				{
					Header: "Unidad",
					accessor: "Unit",
				},
				{
					Header: "Descripción",
					accessor: "Description",
				},
				{
					Header: "NoIdentificacion",
					accessor: "IdentificationNumber",
				},
				{
					Header: "SKU",
					accessor: "SkU",
				},
				{
					Header: (invoice.AutoInvoicingVersion === '4.0') ? "Objeto Impuesto" : " ",
					accessor: (d) => {
						return ( 
						( invoice.AutoInvoicingVersion === '4.0' ) ? <div>
							{d.TaxObject === '01' ? '01-No objeto de impuesto'
							: (d.TaxObject === '02' ? '02-Sí objeto de impuesto' 
							: (d.TaxObject === '03' ? '03-Sí objeto de impuesto y no obligado a desglose'
							: (d.TaxObject === '04' ? '04-Sí objeto de impuesto y no causa impuesto'
							: '')))}
						</div>
						:''
						)

					},
				},
				{
					Header: "Precio U.",
					accessor: "UnitPrice",
				},
				{
					Header: "Importe",
					accessor: "Subtotal",
				},
			],
		},
	];


	
	return (
		<>
			<div className="row">
				<div className="col-10">
					<h1>Nueva factura</h1>
				</div>

				<div className="col-2">{/* <InvoiceModal BtnName="Facturar"/> */}</div>
			</div>

			<div className="row mt-4">
				<div className="col-12">
					<div className="card padd-1">
						<div className="row">
							<div className="col-4">
								<label>Cliente:</label>								
								<Autosuggest
                                    suggestions={clientSugestions}
                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                                    getSuggestionValue={getSuggestionValue}									
                                    renderSuggestion={renderSuggestion}
									onSuggestionSelected={onSuggestionSelected}
                                    inputProps={inputProps}
                                    theme={theme}
                                />
								{errorOnClient && <span className="text-danger">Este campo es requerido</span>}
								{/* } */}
								
								
							</div>
							<div className="col-2" style={{ paddingTop: "30px" }}>
								<button
									className="mr-4"
									title="Editar cliente seleccionado"
									onClick={(e) => {
										e.preventDefault();
										if (idCustomer !== "") {
											onCustomersModalShow(true, true, idCustomer);
										}
									}}
								>
									<i className="far fa-edit"></i>
								</button>

								<button
									className="ml-2"
									title="Agregar nuevo cliente"
									onClick={(e) => {
										e.preventDefault();
										onCustomersModalShow(true, false, "");
									}}
								>
									<i className="far fa-plus-square"></i>
								</button>
							</div>

							<div className="col-3">
								<label>Lugar de expedición:</label>
								<Field
									component={InputField}
									type="select"
									name="IdBrachOffice"
									placeholder="Lugar de expedición"
									validate={isRequired}
									onChange={fillSeries}
								>
									{branchOffices.map((branch) => (
										<option
											key={branch.Id}
											value={branch.Id}
										>{`${branch.Name}`}</option>
									))}
								</Field>
							</div>

							<div className="col-3">
								<div className="row">
									{series && (
										<div className="col-8">
											<label>Serie:</label>
											<Field
												component={InputField}
												type="select"
												name="Serial"
												placeholder="Serie"
											>
												<option key="0" value="">
													Serie
												</option>
												{series.map((branch) => (
													<option
														key={branch.Id}
														value={branch.Id}
													>{`${branch.Name}`}</option>
												))}
											</Field>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-12">
					<div className="card padd-1">
						<div className="row">
							<div className="col-4">
								<label>General:</label>
							</div>
							<div className="col-4">
								<label>Opciones de pago:</label>
							</div>

							<div className="col-4">
								<label>Observaciones:</label>
							</div>
						</div>

						<div className="row">
							<div className="col-4">
								<Field
									type="text"
									className="form-control"
									placeholder="Número de pedido"
									name="OrderNumber"
									component={InputField}
									validate={[maxLength30]}

								/>

								<div className="mt-3">
									<Field
										component={InputField}
										type="select"
										name="DaysBack"
										placeholder="Fecha de emisión"
										validate={isRequired}
									>
										<option value="0">Fecha de emisión actual</option>
										<option value="1">1 día antes</option>
										<option value="2">2 días antes</option>
									</Field>
								</div>

								<div className="mt-3">
									<Field
										component={InputField}
										type="select"
										name="PaymentType"
										placeholder="PaymentType"
										validate={isRequired}
										onChange={onChangePaymentType}
									>
										<option value="">Seleccionar método de pago</option>
										<option value="PUE">PUE Pago en una sola exhibición</option>
										<option value="PPD">
											PPD Pago en parcialidades o diferido
										</option>
									</Field>
								</div>
							</div>
							<div className="col-4">
								<Field
									component={InputField}
									type="select"
									name="Currency"
									placeholder="Moneda"
									validate={isRequired}
									value="MXN"
								>
									{CURRENCIES.map((currency) => (
										<option
											key={currency.Value}
											value={currency.Value}
										>{`${currency.Name}`}</option>
									))}
								</Field>

								<div className="mt-3">
									<Field
										type="text"
										className="form-control"
										placeholder="Tipo de Cambio(monto)"
										name="ExchangeRate"
										component={InputField}
									/>
								</div>

								<div className="mt-3">
									<Field
										component={InputField}
										type="select"
										name="PaymentMethod"
										placeholder="Forma de pago"
										validate={isRequired}
										// onChange={handleChangePaymentMethod}
									>
										<option value="">Seleccionar forma de pago</option>
										{PAYMENT_FORMS.map((pf) => (
											<option
												key={pf.Value}
												value={pf.Value}
											>{`${pf.Name}`}</option>
										))}
									</Field>
									
								</div>
							</div>
							<div className="col-4">
								<Field
									type="textarea"
									className="form-control"
									placeholder="Las observaciones no son datos fiscales"
									name="Observations"
									component={InputField}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{ informationGlobalNode ?
				<div className="row mt-3">
					<div className="col-12">
						<div className="card padd-1">
							<div className="row">
								<div className="col-4">
									<label>Periodicidad:</label>
								</div>
								<div className="col-4">
									<label>Meses:</label>
								</div>

								<div className="col-4">
									<label>Año:</label>
								</div>
							</div>

							<div className="row">
								<div className="col-4">
									<Field
										component={InputField}
										type="select"
										name="Periodicity"
										placeholder="Periodicidad"
										validate={isRequired}
										onChange={changePeriodicity}
										value=""
									>
										<option value="">Seleccionar Periodicidad</option>
										{ PERIODICITY.map((period) => (
											<option
												key={period.Value}
												value={period.Value}
											>{`${period.Name}`}</option>
										))}
									</Field>
								</div>
								<div className="col-4">
									<Field
										component={InputField}
										type="select"
										name="Months"
										placeholder="Meses"
										validate={isRequired}
										value=""
									>
										<option value="">Seleccionar Mes</option>
										{ optionsMonths.map((month) => (
											<option
												key={month.Value}
												value={month.Value}
											>{`${month.Name}`}</option>
										))}
									</Field>
								</div>
								<div className="col-4">
									<Field
										type="text"
										className="form-control"
										placeholder="correspondiente a la información del comprobante"
										name="Year"
										component={InputField}
										validate={isRequired}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			: ''}



			<div className="row mt-3">
				<div className="col-12">
					<div className="card padd-1">
						<div className="row">
							<div className="col-12">
								<DataTableSimple
									columns={columnsProducts}
									data={
										invoice !== undefined &&
										invoice.hasOwnProperty("ItemsAndTaxesData")
											? invoice.ItemsAndTaxesData.Items
											: []
									}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row mt-3">
				<div className="col-12">
					<div className="card padd-1">
						<div className="row">
							<div className="col-8"></div>
							<div className="col-4">
								<TotalsBox
									amounts={invoice !== undefined ? invoice.Totals : []}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

AddInvoicePage = reduxForm({
	form: "AddInvoicePage",
})(AddInvoicePage);

AddInvoicePage = connect(
	(state) => ({
		clientSugestions: getCustomerBySearch(state),
	}),
	{ findCustomers }
)(AddInvoicePage);

export default AddInvoicePage;

/**
 * AccountLogin
 *
 * Contenedor de los elementos de Account (Login y Register)
 */

import React from "react";
import { useHistory, useParams } from "react-router-dom";
import store from "../../store";

import LoginForm from "../../components/Account/LoginForm";
import { urlCdnImages } from "../../api/urls"

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../../index.css";

require("typeface-montserrat");

const LoginPage = () => {
    const history = useHistory();

    let { shopName } = useParams();
    let state = store.getState();

    if (state.tokens === undefined) state.tokens = {};

    if (shopName !== undefined && shopName !== "") {
        state.tokens.shopName = shopName;
    }
    // console.log("state", state);
    // if (state.tokens.token !== undefined && state.tokens.token !== "") {
    //     history.push("/invoices/list");
    // }

    return (
        <>
            <section className="bg-login-facturama">
                <div className="container">
                    <div className="row d-flex pt-5 xs-py-1">
                        <div className="col-md-6 offset-md-1 xs-full-col">
                            <figure className="logo xs-img-auto xs-figure-mg">
                                <picture>                                    
                                    <source
                                        type="image/png"
                                        srcSet = { `${urlCdnImages}/facturama-freshbooks-logo.png` }
                                    />
                                    <img
                                        src = { `${urlCdnImages}/facturama-freshbooks-logo.png` }
                                        alt="Facturación electrónica Facturama"
                                        className="img-fluid"
                                    />
                                </picture>
                            </figure>

                            <LoginForm history={history} />
                        </div>{" "}
                        {/* col md6 */}
                    </div>{" "}
                    {/* row */}
                </div>{" "}
                {/* container */}
            </section>
        </>
    );
};

export default LoginPage;

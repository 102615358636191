import React from "react";
// import PropTypes from 'prop-types'
import InputField from "../InputField";
import { Field } from "redux-form";
import {
    isRequired,
    minLength,
    maxLength,
    mxnPostalCode,
} from "../../validations";
import { FEDERAL_STATES } from "../../constants/federal_states";

const minLength1 = minLength(1);
const maxLength15 = maxLength(15);
const maxLength20 = maxLength(20);
const maxLength100 = maxLength(100);

const Address = ({ neighboorhoods , baseName }) => {

    if(!baseName)
        baseName = 'TaxAddress'

    return (
        <>
            <div className="form-row">
                <div className={`form-group col-md-4`}>
                    <label>Codigo postal*</label>
                    <Field
                        component={InputField}
                        type="text"
                        className="form-control zipcode"
                        name={`${baseName}.ZipCode`}
                        placeholder="Código postal"
                        normalize={value => value.replace(/[^\d]/g, '')}
                        validate={[mxnPostalCode, isRequired]}
                    />
                </div>
                <div className={`form-group col-md-4`}>
                    <Field
                        label="Estado*"
                        component={InputField}
                        type="select"
                        className="form-control"
                        placeholder="Estado"
                        // readOnly                        
                        validate={[isRequired]}
                        name={`${baseName}.State`}
                    >
                        <option value="">Selecciona una opción</option>
                        {FEDERAL_STATES.map((state) => (
                            <option key={state.Value} value={state.Value}>
                                {state.Name}
                            </option>
                        ))}
                        
                    </Field>
                </div>
                <div className={`form-group col-md-4`}>
                    <Field
                        label="Municipio*"
                        component={InputField}
                        type="text"
                        className="form-control"
                        placeholder="Municipio"
                        // readOnly                      
                        name={`${baseName}.Municipality`}
                        validate={[isRequired]}
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <Field
                        label="Colonia*"
                        type="text"
                        className="form-control"                        
                        name={`${baseName}.Neighborhood`}
                        component={InputField}
                        validate={[isRequired]}
                    />
                </div>
                <div className="form-group col-md-4">
                    <Field
                        label="Calle*"
                        type="text"
                        className="form-control"
                        placeholder="Calle"                        
                        name={`${baseName}.Street`}
                        required=""
                        component={InputField}
                        validate={[isRequired, minLength1, maxLength100]}
                    />
                </div>
                <div className="form-group col-md-2">
                    <Field
                        label="No. exterior*"
                        type="text"
                        className="form-control"
                        placeholder="No. exterior"                        
                        name={`${baseName}.ExteriorNumber`}
                        component={InputField}
                        validate={[isRequired, minLength1, maxLength15]}
                    />
                </div>
                <div className="form-group col-md-2">
                    <Field
                        label="No. Interior"
                        type="text"
                        className="form-control"
                        placeholder="No. interior"                        
                        name={`${baseName}.InteriorNumber`}
                        component={InputField}
                        validate={[maxLength20]}
                    />
                </div>
            </div>
        </>
    );
};

// Address.propTypes = {

// }

export default Address;



import React,{useState} from "react";
//import { Form, reduxForm } from "redux-form";
import LogoProfile from "../components/LogoProfile/LogoProfile";



let UploadLogoForm = ({currentLogo, handleUploadLogo}) => {
    
    const [logoImageFile, setLogoImageFile] = new useState('/images/img-default.png');        
    
    // Verify if the currentLogo can be a valid logo
    if( typeof currentLogo === 'string' && currentLogo.length > 100 && logoImageFile.length < 100){
        console.log('estableciendo logo')
        setLogoImageFile(currentLogo);
    }



    return (
        <div className="card">
            <div className="card-body">                
                    <div className="row">
                        <div className="col-xl-5">
                            <LogoProfile    logoImageFile = {logoImageFile} 
                                            setLogoImageFile = {setLogoImageFile} />
                        </div>
                        <div className="col-xl-7">
                            <h5>
                                Agrega tu logotipo y personaliza tus facturas
                            </h5>
                            <small>
                                Formato PNG o JPG 180 x 180 px de resolución y
                                menos de 1MB de tamaño
                            </small>
                            <br />
                            <br />
                            <div                                
                                className="btn btn-outline-primary"                                
                                onClick={() => handleUploadLogo(logoImageFile)}
                            >
                                Guardar logo
                            </div>
                            { false && (
                                <img alt="logo-loaded-verified" />
                            )}
                        </div>
                    </div>                
            </div>
        </div>
    );
};


export default UploadLogoForm;

import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.css";

function BootBox(props) {
	return (
		<Modal className="bootbox-modal" show={props.show} onHide={props.onClose}>
			<Modal.Body>
				<h5 className="bootBox-message">
					{props.message ? props.message : "Are you sure you want to proceed"}
				</h5>
			</Modal.Body>
			<Modal.Footer className="bootbox-modal-footer">
				<button
					className="btn btn-outline-secondary-cancel mr-3"
					style={{ display: props.noClickDisplay !== undefined ? props.noClickDisplay : "block" }}
					onClick={() => props.onNoClick()}
				>
					Cancelar
				</button>
				<button
					className="btn btn-outline-primary"
					onClick={() => props.onYesClick()}
				>
					Aceptar
				</button>
			</Modal.Footer>
		</Modal>
	);
}

export function BootBoxAlert(props) {
	return (
		<Modal className="bootbox-modal" show={props.show}>
			<Modal.Body>
				<h5 className="bootBox-message">
					{props.message ? props.message : "Are you sure you want to proceed"}
				</h5>
				<p className="bootBox-message">{props.detail ? props.detail : ""}</p>
			</Modal.Body>
			<Modal.Footer className="bootbox-modal-footer">
				<button
					className="btn btn-outline-primary"
					onClick={() => props.onYesClick()}
				>
					Aceptar
				</button>
			</Modal.Footer>
		</Modal>
	);
}

BootBox.propTypes = {
	show: PropTypes.bool.isRequired,
	onYesClick: PropTypes.func.isRequired,
	onNoClick: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	message: PropTypes.string,
};

export default BootBox;

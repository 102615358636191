import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { getToken } from "../selectors/tokens";

let PrivateRoute = ({ component: Component, token, location, ...rest }) => {
	const isLoggedIn = !!token;
	
	return (
		<Route
			{...rest}
			render={(props) =>
				
				!isLoggedIn ? (
					<Redirect
						to={{ pathname: "/account/login", state: { from: props.location } }}
					/>
				) : (
					<Component {...props} />
				)
				
			}
		/>
	);
};

PrivateRoute = connect((state) => ({
	token: getToken(state),
}))(PrivateRoute);

export default PrivateRoute;

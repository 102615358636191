import React from "react";
// import PropTypes from 'prop-types'
import FederalTaxes from "./FederalTaxes";
import { Redirect } from "react-router-dom";
import { ProductAndService } from "./ProductAndService";
import { TaxObject } from "./TaxObject";
import { UnitCode } from "./UnitCode";
import { ProductData } from "./ProductData";
import { reduxForm } from "redux-form";
import { SubmissionError } from "redux-form";
import { connect } from "react-redux";
import {
	fetchProductById,
	insertProduct,
	updateProduct,
	deleteProduct,
} from "../../actions/products";
import Bootbox, { BootBoxAlert } from "../../components/General/bootbox";
import { UNITS_CATALOG } from "../../constants/units_catalog";
import Loader from "../../components/General/loader";
import {
	getProductsById,
	getTaxFormProductForm,
} from "../../selectors/products";
import { Alert } from "react-bootstrap";

class ProductForm extends React.Component {
	constructor() {
		super();
		this.state = {
			includeAddress: false,
			// redirect
			redirect: false,
			url: "",
			// delete
			hiddenDeleteButton: false,
			// bootbox
			showBootBoxAlert: false,
			modalshow: false,
			email: "",
			showConfirm: false,
			msgConfirm: "",
			unitsCatalog: UNITS_CATALOG,
			isLoading: false,

			showAlert: false,
			alertVariant: "primary",
			messageAlert: "",
		};
	}

	submit = (values) => {
		// console.log('values producto', values);
		// return;
		values.CuentaPredial =
			values.CuentaPredial === "" ? null : values.CuentaPredial;
		values.Unit = UNITS_CATALOG.find(
			(element) => element.Value === values.UnitCode
		).Name;

		var submitFuncion = values.Id
			? this.props.updateProduct
			: this.props.insertProduct;
		this.setState({ isLoading: true });

		const { iva, isr, ieps, ivaR } = this.props;
		const data = {
			...values,
			Taxes: (ieps ? [iva, ieps] : [iva, isr, ivaR]).filter((t) => t),
		};
		return submitFuncion(data)
			.then(() =>
				this.Alert("Datos del producto guardados correctamente", "success")
			)
			.catch((error) => {
				this.Alert(error._error, "danger");
				throw new SubmissionError(error);
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	componentDidMount() {
		if (this.props.Id === "new" || this.props.Id === "0") {
			return;
		}

		// this.setState({ isLoading: true });
		// this.props.fetchProductById(this.props.Id).then(
		// 	(response) => {
		// 		console.log(response.payload);

		// 		response.payload = {
		// 			...response.payload,
		// 			Taxes: ["IVA", "IEPS", "ISR", "IVA RET"].map((tax) => {
		// 				var taxObj = response.payload.Taxes.find((t) => t.Name === tax) || {
		// 					Name: tax,
		// 					Rate: 0,
		// 					IsRetention: false,
		// 					IsFederalTax: false,
		// 				};
		// 				return { Name: tax, ...taxObj };
		// 			}),
		// 		};

		// 		this.props.initialize(response.payload);

		// 		this.setState({ isLoading: false });
		// 	},
		// 	(error_) => {
		// 		console.log(error_);
		// 		this.setState({ isLoading: false });
		// 	}
		// );
	}

	handleCancel() {
		console.log("Cancelar e ir para atras");
		this.setState({
			redirect: true,
			url: `/catalogs/list/products`,
		});
	}

	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect to={this.state.url} />;
		}
	};

	handleDelete() {
		this.setState({ showConfirm: true });
		this.setState({ msgConfirm: "¿Deseas eliminar este producto?" });
	}

	handleClose = () => {
		this.setState({ showConfirm: false });
	};
	handleOk = () => {
		console.log("Eliminar product Id: " + this.props.Id);
		this.setState({ showConfirm: false });
		this.setState({ isLoading: true });
		return this.props
			.deleteProduct(this.props.Id)
			.then(({ payload }) => {
				if (payload) {
					console.log(payload);
					this.Alert("Producto eliminado correctamente", "danger");
					// this.setState({ showBootBoxAlert: true });
					// this.setState({ msgTitle: "Producto eliminado correctamente" });
					// this.setState({ msgDescription: "" });
				}
				if (payload._error) {
					this.Alert(payload._error, "danger");
					throw new SubmissionError(payload);
				}
			})
			.catch((error) => {
				// console.log('error', error);
				this.Alert(error._error, "danger");
				this.setState({
					error,
				});
			})
			.finally(() => this.setState({ isLoading: false }));
	};

	handleOkAlert = () => {
		console.log("handleOkAlert");
		this.setState({
			redirect: true,
			url: `/catalogs/list/products`,
		});
	};

	handleCloseAlert = () => {
		this.setState({ showAlert: false });
		if (this.state.redirect) {
			window.location.reload(false);
			this.setState({ redirect: false });
		}
	};
	Alert = (msg, variant) => {
		this.setState({
			showAlert: true,
			messageAlert: msg,
			alertVariant: variant,
		});
	};

	render() {
		const { handleSubmit, submitting } = this.props;

		return (
			<>
				<Loader show={this.state.isLoading} />

				<form onSubmit={handleSubmit(this.submit)}>
					{this.renderRedirect()}

					<div className="row">
						<div className="col text-right">
							<button
								className="btn btn-danger"
								type="button"
								hidden={this.state.hiddenDeleteButton}
								onClick={() => this.handleDelete()}
							>
								Eliminar
							</button>
						</div>
					</div>

					{/* {error && (
						<div className="alert alert-danger" role="alert">
							{error}
						</div>
					)}
					{submitSucceeded && (
						<div className="alert alert-success" role="alert">
							Datos del producto guardados correctamente
						</div>
					)} */}
					<Alert
						show={this.state.showAlert}
						variant={this.state.alertVariant}
						onClose={this.handleCloseAlert}
						dismissible
					>
						<p>{this.state.messageAlert}</p>
					</Alert>
					<div className="row">
						<div className="col-xs-12 col-sm-6 col-md-6">
							<div className="form-group">
								<h6>Datos del producto</h6>
							</div>
							<ProductData />
							<FederalTaxes iepsEnabled={this.props.includeIeps} />
						</div>
						<div className="col-xs-12 col-sm-6 col-md-6">
							<div className="form-group">
								<h6>Cambios obligatorios CFDI 3.3 SAT</h6>
							</div>
							<ProductAndService />
							{UNITS_CATALOG && <UnitCode unitsCatalog={UNITS_CATALOG} />}


							<div className="form-group mt-4">
								<h6>Cambios obligatorios CFDI 4.0 SAT</h6>
							</div>
							<TaxObject />							

						</div>
						
						<div id="errors" className="error"></div>
					</div>

					<div className="row text-right">
						<div className="col pt-4 mb-3">
							<button
								className="btn btn-outline-secondary mr-3"
								type="button"
								onClick={() => this.handleCancel()}
							>
								<i className="fas fa-chevron-left"></i> Ir a catálogos
							</button>
							<button
								className="btn btn-outline-primary"
								type="submit"
								disabled={submitting}
							>
								Aceptar
							</button>
						</div>
					</div>

					{
						<Bootbox
							show={this.state.showConfirm}
							type={"confirm"}
							message={this.state.msgConfirm}
							successLabel={"Si"}
							onYesClick={this.handleOk}
							onNoClick={this.handleClose}
							onClose={this.handleClose}
						/>
					}

					{
						<BootBoxAlert
							show={this.state.showBootBoxAlert}
							type={"confirm"}
							message={this.state.msgTitle}
							detail={this.state.msgDescription}
							onYesClick={this.handleOkAlert}
						/>
					}
				</form>
			</>
		);
	}
}

// ProductForm.propTypes = {

// }

ProductForm = reduxForm({
	form: "ProductForm",
	// initialValues: {
	// 	Taxes: [
	// 		{ IsRetention: false, Name: "IVA" },
	// 		{ IsRetention: false, Name: "IEPS" },
	// 		{ IsRetention: true, Name: "ISR" },
	// 		{ IsRetention: true, Name: "IVA RET" },
	// 	],
	// },
})(ProductForm);

ProductForm = connect(
	(state, props) => {
		var product = getProductsById(state, props);
		return {
			includeIeps: !!getTaxFormProductForm(state, "IEPS"),
			iva: getTaxFormProductForm(state, "IVA"),
			isr: getTaxFormProductForm(state, "ISR"),
			ieps: getTaxFormProductForm(state, "IEPS"),
			ivaR: getTaxFormProductForm(state, "IVA RET"),
			initialValues: {
				...product,
				Taxes: [
					{
						IsRetention: false,
						Name: "IVA",
						Rate: "0.16",
						...product?.Taxes.find((t) => t.Name === "IVA"),
					},
					{
						IsRetention: false,
						Name: "IEPS",
						Rate: "",
						...product?.Taxes.find((t) => t.Name === "IEPS"),
					},
					{
						IsRetention: true,
						Name: "ISR",
						Rate: "",
						...product?.Taxes.find((t) => t.Name === "ISR"),
					},
					{
						IsRetention: true,
						Name: "IVA RET",
						Rate: "",
						...product?.Taxes.find((t) => t.Name === "IVA RET"),
					},
				],
			},
		};
	},
	{
		fetchProductById,
		insertProduct,
		updateProduct,
		deleteProduct,
	}
)(ProductForm);

export default ProductForm;

const PERIODICITY = [
    {
        Name: '01-Diario',
        Value: '01',
    },
    {
        Name: '02-Semanal',
        Value: '02',
    },
    {
        Name: '03-Quincenal',
        Value: '03',
    },
    {
        Name: '04-Mensual',
        Value: '04',
    },
    {
        Name: '05-Bimestral',
        Value: '05'
    },
];

export default PERIODICITY;
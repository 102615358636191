import React from 'react'
import CompactTaxData from '../CompactTaxData'
import UploadLogoForm from "../../containers/UploadLogoForm";

export const StepOne = ({isNatural, currentLogo, handleUploadLogo }) => {
    return (
        <div className="row mb-4">
            <div className="col-12">
                <h3 className="pb-3">Paso 1. Información personal</h3>
            </div>
            <div className="col-md-6">
                <UploadLogoForm currentLogo={currentLogo}  handleUploadLogo={handleUploadLogo} />
            </div>
            <div className="col-md-6">
                <CompactTaxData isNatural={isNatural} />
            </div>
        </div>	  
    )
}

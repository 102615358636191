import React from "react";
// import PropTypes from 'prop-types'
import InputField from "../InputField";
import { Field } from "redux-form";
//import { Link } from 'react-router-dom'
import {
    isRequired,
    minLength,
    maxLength,    
} from "../../validations";

const minLength12 = minLength(12);
const maxLength13 = maxLength(13);

const ResetPassword = () => {


    return (
        <>
            <p className="h3 text-blue-primary">Restablecer contraseña</p>    
            <p><b>Ingresa el RFC con el que te registraste en Facturama
y te enviarémos un link para que cambies tu contraseña.</b></p>            
            <div className="form-group">
                <label  >RFC:</label>
                <Field                        
                    type="text"
                    className="form-control"
                    name="usuario"
                    placeholder="RFC de 12 o 13 caracteres"
                    component={InputField}
                    validate={[isRequired, minLength12,maxLength13]}                        
                />                                        
            </div>
            <div className="row mt-4">
            </div>
        </>
    );
};

export default ResetPassword;
import { createAction } from "redux-actions";
import {
	FETCH_SUBSCRIPTION,
	UPDATE_SUBSCRIPTION,
	CREATE_SUBSCRIPTION,
	CANCEL_SUBSCRIPTION,
} from "../constants";
import { apiGet, apiPost, apiPut, apiDelete } from "../api";
import { urlSubscriptions, urlSuscription } from "../api/urls";

export const fetchSubscription = createAction(FETCH_SUBSCRIPTION, async () => {
	try {
		var subscription = await apiGet(urlSubscriptions)();
		var suscriptionPlan = await apiGet(urlSuscription)();
		return { ...suscriptionPlan, ...subscription };
	} catch (e) {
		return ;
	}
});

export const createSubscriptionWithCard = createAction(
	CREATE_SUBSCRIPTION,
	(plan, card) => apiPost(urlSubscriptions, { plan, card })()
);

export const updateSubscriptionWithCard = createAction(
	UPDATE_SUBSCRIPTION,
	(plan, card) => apiPut(urlSubscriptions, "", { plan, card })()
);


// export const insertCard = createAction(INSERT_CARD, (card) =>{
// 	// console.log("data card", card);
// 	return apiPost(urlCards, card)()
// });


// export const createSubscriptionWithCardId = createAction(
// 	CREATE_SUBSCRIPTION,
// 	async (planName, cardId) => 	{
// 		try {
// 			var subscription = await apiPost(urlSubscriptions, {
// 				cardId: cardId,
// 				plan: planName,
// 			})();
// 			return subscription;
// 		} catch (e) {			
// 			console.log('entro al catch', e);
// 			 throw e
			
// 		}		
// 	}
// );


export const createSubscriptionWithCardId = createAction(
	CREATE_SUBSCRIPTION, (planName, cardId) => {
		 return apiPost(urlSubscriptions, {
					cardId: cardId,
					plan: planName,
				})()		
	}
);

export const updateSubscription = createAction(
	UPDATE_SUBSCRIPTION,
	(plan, cardId) => apiPut(urlSubscriptions, "", { plan, cardId })()	 
);

export const cancelSubscription = createAction(
	CANCEL_SUBSCRIPTION,
	async () => {
		try {
			await apiDelete(urlSubscriptions, "")();
			var subscription = await apiGet(urlSubscriptions)();
			var suscriptionPlan = await apiGet(urlSuscription)();
			return { ...suscriptionPlan, ...subscription };
		} catch (e) {
			return e;
		}
	}
);

import React from 'react'
import CertificatesForm from '../CertificatesForm/CertificatesForm'

export const StepThree = ({handleUploadCertificates, sendedCSD,responseCsd, errorCsd, succesCsd}) => {
    return (
        
        <div className="row mb-4">
            {/* {console.log("a ver que viene", responseCsd)} */}
            <div className="col-12">
                <h3 className="pb-3">Paso 3. Sellos digitales</h3>
                <p className="text-bold">
                    Es importante que subas tus sellos digitales, ya que sin ellos no podrás emitir facturas.
                </p>
            </div>
            { errorCsd !== '' && (
                <div className="alert alert-danger text-sm-center alertMargin" role="alert">
                    {errorCsd}
                </div>
            )}
            {succesCsd && (
                <div className="alert alert-success text-sm-center alertMargin" role="alert">
                    Sellos digitales actualizados correctamente
                </div>
            )}
            <div className="col-md-12">
                <CertificatesForm handleUploadCertificates={handleUploadCertificates} sendedCSD={sendedCSD} responseCsd={responseCsd} />
            </div>
            
        </div>
    )
}

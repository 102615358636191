
import React, {useState} from 'react'
import Address from '../Address'
import AddressAux from '../Address/AddressAux'


export const StepTwo = ({setIsUseAddress}) => {
    const [useAddress, setUseAddress] = useState(false);
    //setUseAddress(true);
    //setUseAddress(false);
    const handleChange=(e)=>
    {
        if(e.target.checked===false){
            setUseAddress(true);
            setIsUseAddress(true);
        }else{
            setUseAddress(false);
            setIsUseAddress(false)
        }       
    }
    return (
        <div className="row mb-4">
          
            <div className="col-12">
                <h3 className="pb-3">Paso 2. Dirección fiscal</h3>
            </div>
            <div className="col-12">
                <Address />
            </div>
            <div className="col-md-12">
                <div className="form-group form-check text-right">
                 <input type="checkbox" className="form-check-input" defaultChecked={true} id="sameExpeditionPlace" onChange={handleChange} />
                    <label labelfor="sameExpeditionPlace" className="form-check-label">Usar esta dirección como mi lugar de expedición.</label>
                </div>
            </div>
            <div className="col-12">
            {useAddress &&<AddressAux/>}
            </div>
        </div> 
    )
}

const theme = {
    container: {
      position: 'relative'
    },
    
    input: {
      width: '340px',
      height: '38px',
      padding: '10px 15px',
      border: '1px solid #ced4da',

    },
    
    inputFocused: {
      outline: 'none'
    },
    
    inputOpen: {
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0'
    },
    
    suggestionsContainer: {
      display: 'none'
    },
    
    suggestionsContainerOpen: {
      display: 'block',
      position: 'absolute',   
      width: '340px',
      border: '1px solid #aaa',
      backgroundColor: '#fff',
      fontFamily: 'Helvetica, sans-serif',
      fontWeight: '300',
      fontSize: '16px',
      zIndex: '2'
    },
    
    suggestionsList: {
      margin: '0',
      padding: '0',
      listStyleType: 'none',
    },
    
    suggestion: {
      cursor: 'pointer',
      padding: '10px 20px'
    },
    
    suggestionHighlighted: {
      backgroundColor: '#ddd'
    }
  }
  
  export default theme;
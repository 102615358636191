import { createSelector } from "reselect";

export const getCustomers = (state) => state.customers;

export const getCustomerByRfc = createSelector(
	(state, props) => state.customers.find((c) => c.rfc === props.rfc),
	(customer) => customer
);

export const getCustomerById = createSelector(
	(state, props) => state.customers.find((c) => c.Id === props.Id),
	(customer) => customer
);

export const getCustomerBySearch = createSelector(
	(state, props) => state.customers.find((c) => { 
		return c;
	}),
	(customer) => customer
);
export const isRequired = (value) => !value && "Este campo es requerido";
export const maxLength = (max) => (value) =>
	value && value.length > max
		? `Debe contener máximo ${max} caracteres`
		: undefined;

export const minLength = (min) => (value) =>
	value && value.length < min
		? `Debe contener mínimo ${min} caracteres`
		: undefined;

export const length = (length) => (value) =>
	value && value.length !== length
		? `Debe contener ${length} caracteres`
		: undefined;

export const predialAccount = (value) =>
	value && !/^[0-9]{1,150}/i.test(value)
		? "Formato de cuenta predial inválido"
		: undefined;

export const rfc = (value) =>
	value &&
	!/^[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]/.test(
		value
	)
		? "El RFC no cumple con el formato correcto"
		: undefined;

export const email = (value) =>
	!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
		? "El formato del correo es incorrecto"
		: undefined;

export const emailNotRequired = (value) =>
	value
		? !/^([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})?$/i.test(value)
			? "El formato del correo es incorrecto"
			: undefined
		: undefined;

export const mxnPostalCode = (value) =>
 	!/^([0-9]{5})$/.test(value) && (value !== '')
		? "El formato del Código postal es incorrecto"
		: undefined;

export const anyCharButSpace = (value) =>
	!/^[^\s]+$/.test(value) ? "No debe contener espacios" : undefined;

export const password = (value) =>
	!/^[\w|\d]+$/.test(value) ? "Solo debe contener letras y números" : undefined;

export const number = (value) =>
	value && isNaN(Number(value)) ? "Debe ser númerico" : undefined;

export const alphaNumeric = (value) =>
	value && /[^a-zA-Z0-9 ]/i.test(value)
		? "Only alphanumeric characters"
		: undefined;

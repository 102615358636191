import {
	FETCH_PRODUCTS,
	DETAIL_PRODUCT,
	INSERT_PRODUCT,
	UPDATE_PRODUCT,
	DELETE_PRODUCT,
} from "./../constants";
import { createAction } from "redux-actions";
import { apiDelete, apiGet, apiGetById, apiPost, apiPut } from "../api";
import { urlProducts } from "../api/urls";

export const fetchProducts = createAction(FETCH_PRODUCTS, apiGet(urlProducts));

export const fetchProductById = createAction(DETAIL_PRODUCT, (id) =>
	apiGetById(urlProducts, id)()
);

export const insertProduct = createAction(INSERT_PRODUCT, (product) =>
	apiPost(urlProducts, product)()
);

export const updateProduct = createAction(UPDATE_PRODUCT, async (product) => {
	await apiPut(urlProducts, product.Id, product)();
	return product;
});

export const deleteProduct = createAction(DELETE_PRODUCT, (Id) =>
	apiDelete(urlProducts, Id)()
);

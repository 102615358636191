import { createSelector } from "reselect";

export const getProducts = (state) => state.products;

export const getProductsByCode = createSelector(
	(state, props) => state.products.find((c) => c.Code === props.Code),
	(product) => product
);

export const getProductsById = createSelector(
	(state, props) => state.products.find((c) => c.Id === props.Id),
	(product) => product
);

export const getTaxFormProductForm = (state, taxName) => {
	return state.form.ProductForm?.values?.Taxes?.find(
		(t) => t.Rate !== "" && t.Name === taxName
	);
};

import React from "react";

import AppFrame from "../../components/AppFrame";
import DataTableSimple from "../../components/DataTable/DataTableSimple";
import { Pagination } from "../../components/DataTable/Pagination";
import numeral from "numeral";
import Moment from "moment";
import "moment/locale/es";
import ListInvoicesFilter from "../../components/ListInvoices/ListInvoicesFilter";
import InvoiceCancelForm from "../../components/Invoice/InvoiceCancelForm"
import ListOrdersWithoutInvoice from "./ListOrdersWithoutInvoice";
import {
	PaymentStatusIcon,
	ProcessButton,
	OptionsDropdown,
} from "../../components/ListInvoices/ListInvoicesControls";
import {
	fetchOrders,
	GetInvoicePdf,
	getCfdiZip,
	cancelCfdi,
	sendCfdi,
	fetchOrdersStatus,
	setInvoiceStatus,
} from "../../actions/orders";
import { getSubscription } from "../../selectors/accounts";
// import { getsubscriptionPlanInfo } from "../../selectors/accounts";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getOrders, cancelCFDIslctr } from "../../selectors/orders";
// import { useSelector } from "react-redux";

import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { PREMIUM, FREE } from "../../constants/plans";

import BootBox from "../../components/General/bootbox";
import Loader from "../../components/General/loader";
import ListOrdersWithInvoice from "./ListOrdersWithInvoice";
import { Alert } from "react-bootstrap";
import { Tabs } from "react-bootstrap";
import { Tab } from "bootstrap";



class ListInvoicePage extends React.Component {
	constructor(props) {
		super(props);
		this.loadingTable = false;
		const d = new Date();
		
		d.setMonth(d.getMonth() - 1);
		this.state = {
			hasAccess: true,
			ordersStatus: [],
			showAlert: false,
			messageAlert: "",
			page: 0,
			pageSize: 25,
			pageCount: 1,
			loading: false,
			modalshow: false,
			email: "",
			idCfdi: "",
			showConfirm: false,
			msgConfirm: "",
			actionConfirm: "",
			redirect: false,
			alertVariant: "primary",
			showModalPro: false,
			showCancelModal: false,
			showAlertPro: false,
			defaultTab: "all",
			reasonToCancel: "",
			substituteCFDI: ""
		};
	}
	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}
	getColumns() {
		const columns = [
			{
				Header: "Ordenes",
				hideHeader: true,
				columns: [
					{
						Header: "Estado_Pago",
						accessor: (d) => {
							return (
								<PaymentStatusIcon
									status={d && d.PaymentStatus ? d.PaymentStatus : { Name: "" }}
								/>
							);
						},
					},
					{
						Header: "Número",
						accessor: (d) => {
							return d && d.Name;
						},
					},
					{
						Header: "Folio",
						accessor: (d) => {
							return d.Folio;
						},
					},
					{
						Header: "Serie",
						accessor: (d) => {
							return d.Serie;
						},
					},
					{
						Header: "Fecha",
						accessor: (d) => {
							return Moment(d.CreatedAt).local().format("DD/MM/YYYY");
						},
					},
					{
						Header: "Cliente",
						accessor: (d) => {
							return d.CustomerInfo && d.CustomerInfo.length > 0
								? d.CustomerInfo[0].Name
								: "Sin definir";
						},
					},
					{
						Header: "Correo",
						accessor: (d) => {
							return d.CustomerInfo && d.CustomerInfo.length > 0 ? (
								<div style={{ whiteSpace: "pre" }}>
									{" "}
									{d.CustomerInfo[0].Email?.endsWith(",")
										? d.CustomerInfo[0].Email?.substr(
												0,
												d.CustomerInfo[0].Email?.length - 1
										  )
												.replaceAll(",,", ",")
												.replaceAll(",", "\n ")
										: d.CustomerInfo[0].Email?.replaceAll(",,", ",").replaceAll(
												",",
												"\n "
										  )}
								</div>
							) : (
								"Sin definir"
							);
						},
					},
					{
						Header: "Total",
						accessor: "TotalAmount",
						Cell: (props) => numeral(props.value).format("$0,0.00"),
					},
					{
						Header: " ",
						accessor: (d) => {
							return (
								d.TotalAmount >= 0 && (
									<ProcessButton
										idOrder={d.Id}
										idInvoice={d.IdInvoice}
										status={d.PaymentStatus}
									/>
								)
							);
						},
					},
					{
						Header: "  ",
						accessor: (d) => {
							return (d.IdInvoice ) ? (
								<OptionsDropdown
									idInvoice={d.IdInvoice}
									invoicesData={d.Invoices}
									handleAction={this.onClickIvoiceHandle}
								/>
							) : (
								""
							);
						},
					},
					{
						Header: "   ",
						accessor: (d) => {
							return (d.Invoices?.length === 1 && d.IdInvoice && d.StatusInvoice === false ) ? (
								<div>
									<span
										className="fas fa-ban iconRed alignVertical"
										title="Factura Cancelada"
									></span>
								</div>
							) : (
								""
							);
						},
					},
				],
			},
		];
		return columns;
	}

	onClickIvoiceHandle = (a, b) => {
		
		switch (a) {
			case 1:
				this.setState({ loading: true });
				this.props
					.GetInvoicePdf(b)
					.then((response) => {
						if (response.payload.hasOwnProperty("_error")) {
							this.Alert(response.payload._error, 'danger');
						} else if (response.payload.hasOwnProperty("Message")) {
							this.Alert(response.payload.Message);
						} else {
							this.Download(
								`data:application/pdf;base64,${response.payload.Content}`,
								"cfdi.pdf"
							);
						}
						this.setState({ loading: false });
					})
					.catch((r) => {
						this.setState({ loading: false });
						this.Alert("Error: " + r, 'danger');
					});
				break;
			case 2:
				this.setState({ loading: true });
				this.props
					.getCfdiZip(b)
					.then((response) => {
						if (response.payload.hasOwnProperty("_error")) {
							this.Alert(response.response.payload._error, 'danger');
						} else if (response.payload.hasOwnProperty("Message")) {
							this.Alert(response.payload.Message);
						} else {
							this.Download(
								`data:application/zip;base64,${response.payload.Content}`,
								"cfdi.zip"
							);
						}
						this.setState({ loading: false });
					})
					.catch((r) => {
						this.setState({ loading: false });
						this.Alert("Error: " + r, 'danger');
					});
				break;
			case 3:
				this.setState({ modalshow: true });
				this.setState({ idCfdi: b });
				//(modalSend)
				break;
			case 4:
				this.setState({ showCancelModal: true });
				// this.setState({ showConfirm: true });
				// this.setState({
				// 	msgConfirm: "¿Esta seguro de que desea cancelar esta factura?",
				// });
				this.setState({ actionConfirm: "cancel" });
				this.setState({ idCfdi: b });

				break;
			default:
				this.Alert("Sin acción");
		}
	};
	Download(linkSource, fileName) {
		const downloadLink = document.createElement("a");
		downloadLink.href = linkSource;
		downloadLink.download = fileName;
		downloadLink.click();
	}
	actionCancelCfdi = () => {
		this.setState({ loading: true });
		this.props
			.cancelCfdi(this.state.idCfdi, this.state.reasonToCancel, this.state.substituteCFDI)
			.then((response) => {
				if (response.payload.hasOwnProperty("_error")) {
					this.Alert(response.payload._error, 'danger');
				} else {
					this.props
						.setInvoiceStatus(this.state.idCfdi, response.payload.Status)
						.then((response) => {
							
							if (response.payload.hasOwnProperty("_error")) {
								this.Alert(response.payload._error, 'danger');
							} else if (response.payload.hasOwnProperty("Message")) {
								this.Alert(response.payload.Message);
							} else if (response.payload) {								

								
								this.setState({ redirect: true });
								this.Alert("Factura cancelada", 'success');							
							}
							
						})
						.catch((r) => {
							this.Alert("Error: " + r, 'danger');
						})
						.finally(() => {
							window.scrollTo(0,0);
							this.setState({ 
								loading: false,
								showConfirm: false,
								idCfdi: ""
							 });

						});
				}
				// }
				// this.setState({ showConfirm: false });
				// this.setState({ loading: false });
				// this.setState({ idCfdi: "" });
			})
			.catch((r) => {
				this.setState({ showConfirm: false });
				this.setState({ loading: false });
				this.setState({ idCfdi: "" });
				this.Alert("Error: " + r, 'danger');
			});
	};
	handleAction() {
		alert("Entra");
	}
	handlerSerchOrder = (startDate, endDate, ordernumber, status, NoPos) => {
		this.setState({ loading: true });
		const d1 = `${startDate.getFullYear()}-${
			startDate.getMonth() + 1
		}-${startDate.getDate()}`;
		const d2 = `${endDate.getFullYear()}-${
			endDate.getMonth() + 1
		}-${endDate.getDate()}`;
		this.props
			.fetchOrders({
				Page: this.state.page + 1,
				Records_page: this.state.pageSize,
				NumberOrder: ordernumber,
				PaymentStatus: status === "all" ? "" : status,
				StartDate: d1,
				EndDate: d2,
			})
			.then((response) => {
				if (response.payload.hasOwnProperty("_error")) {
					this.Alert(response.payload._error, 'danger');
				} else if (response.payload.hasOwnProperty("Message")) {
					this.Alert(response.payload.Message);
				} else if (response.payload.Results.length === 0) {
					this.Alert("No cuentas con ordenes de compra de este periodo");
				} else {
					this.setState({
						pageCount:
							this.props.resultOrders.TotalPages &&
							this.props.resultOrders.TotalPages < 1
								? 1
								: this.props.resultOrders.TotalPages,
					});
				}
				this.setState({ loading: false });
			})
			.catch((r) => {
				this.Alert("Error: " + r, 'danger');
				this.setState({ loading: false });
			});
	};
	gotoPage = (page) => {
		this.setState({ page: page });
	};
	setPageSize = (pageSize) => {
		this.setState({ pageSize: pageSize });
	};
	nextPage = () => {
		this.setState({ page: this.state.page + 1 });
	};
	previousPage = () => {
		this.setState({ page: this.state.page - 1 });
	};
	componentDidMount() {
		this.loadingTable = true;
		let tab = this.props.match.params.tab;
		if (tab) this.setState({ defaultTab: tab });
	
		if(tab === 'withoutInvoice' && ( this.props.subscription?.subscriptionPlanInfo?.Plan?.toUpperCase() !== FREE.toUpperCase() && this.props.subscription?.subscriptionPlanInfo?.Plan?.toUpperCase() !== PREMIUM ) ) {
			this.setState({showModalPro: true});
			this.setState({showAlertPro: true});			
		}
		this.props.fetchOrdersStatus().then((response) => {
			this.setState({ ordersStatus: response.payload });
		});

		if (
			this.props.ecommerceType &&
			this.props.ecommerceType.Name &&
			(this.props.ecommerceType.Name.toUpperCase() === "WIX" ||
				this.props.ecommerceType.Name.toUpperCase() === "TIENDANUBE")
		) {
			this.setState({ hasAccess: true });
		}
	}
	componentDidUpdate(nextProps, nextState) {
		if (
			nextState.pageSize !== this.state.pageSize ||
			nextState.page !== this.state.page
		) {
			this.refs.child.handleListClick();
			this.loadingTable = false;
			return true;
		}
		this.loadingTable = false;
		return false;
	}
	onHide = () => {
		this.setState({ modalshow: false });
		this.setState({ idCfdi: "" });
	};
	onHideModalPro = () => {
		this.setState({ showModalPro: false });
	};
	onHideModalCancelInvoice = () => {
		this.setState({ showCancelModal: false });
	};
	onSend = (e) => {
		debugger;
		if (this.state.idCfdi !== "" && this.state.email !== "") {
			this.setState({ loading: true });
			this.setState({ modalshow: false });
			this.props
				.sendCfdi(this.state.idCfdi, this.state.email)
				.then((response) => {
					if (response.payload.hasOwnProperty("_error")) {
						this.Alert(response.payload._error, 'danger');
					} else if (response.payload.hasOwnProperty("Message")) {
						this.Alert(response.payload.Message);
					}					
				})
				.catch((r) => {
					this.Alert("Error: " + r, 'danger');					
				})
				.finally(() => {
					window.scrollTo(0,0);
					this.setState({ loading: false });
					this.setState({ idCfdi: "" });
				});
		} else {
			this.Alert("Email requerido", 'danger');
		}
	};
	handleEmailChange = (e) => {
		this.setState({ email: e.target.value });
	};
	handleClose = () => {
		this.setState({ showConfirm: false });
	};
	handleOk = () => {
		if (this.state.actionConfirm === "cancel") {
			this.actionCancelCfdi();
		} else if (this.state.actionConfirm === "rebill") {
		}
	};
	handleCancel = () => {
		if(this.state.reasonToCancel==='')
			this.Alert('Debe seleccionar un  motivo de cancelación', 'danger');
		else if(this.state.reasonToCancel==='01' && (!this.state.substituteCFDI  || this.state.substituteCFDI === ''))
			this.Alert('Debe seleccionar un CFDI que sustituya al cancelado', 'danger');
		else if (this.state.actionConfirm === "cancel") {
			this.setState({ showAlert: false });
			this.setState({ showCancelModal: false });
			this.actionCancelCfdi();
		} 
	};
	handleReason = (value) => {
		this.setState({reasonToCancel: value});
	};
	handleSubstituteCFDI = (value) => {
		this.setState({substituteCFDI: value});
	};
	handleCloseAlert = () => {
		this.setState({ showAlert: false });
		if (this.state.redirect) {
			window.location.reload(false);
			this.setState({ redirect: false });
		}
	};
	Alert = (msg, type = 'primary') => {
		this.setState({ showAlert: true,
						messageAlert: msg,
						alertVariant: type
		 });
	};
	render() {
		const { resultOrders } = this.props;
		
		return (
			<AppFrame title="Consultar">
				{/* <h1>Órdenes de compra </h1> */}
				<Alert
					show={this.state.showAlert}
					variant={this.state.alertVariant}
					onClose={this.handleCloseAlert}
					dismissible
				>
					<p>{this.state.messageAlert}</p>
				</Alert>
				<Alert
					show={this.state.showAlertPro}
					variant="primary"
					onClose={() => { this.setState({ showAlertPro: false })}}
					dismissible
				>
					<p> 
						
							Este módulo solo esta disponible para la suscripicón PREMIUM 						
						<button
							className="btn btn-outline-primary ml-3"
							onClick={() => {
								this.props.history.push(`/shopping/plans`);												
							}}
						>
							Conocer Mas
						</button>
					</p>
				</Alert>
				<Tabs
					id="controlled-catalogTab"
					activeKey={this.state.defaultTab}
					onSelect={ (tab) => {
						this.setState({defaultTab: tab});
						this.props.history.replace(`/invoices/list/${tab}`);
						// if(tab === 'withoutInvoice') {
						if(tab === 'withoutInvoice' && ( this.props.subscription?.subscriptionPlanInfo?.Plan?.toUpperCase() !== FREE.toUpperCase() && this.props.subscription?.subscriptionPlanInfo?.Plan?.toUpperCase() !== PREMIUM.toUpperCase() ) ) {
							this.setState({showModalPro: true});
							this.setState({showAlertPro: true});							
						}
						else
							this.setState({showAlertPro: false});							
						
					 } }
					className="mb-4"
				>
					<Tab eventKey="all" title="Todas">
						<ListInvoicesFilter
							ref="child"
							clickHandler={this.handlerSerchOrder}
							ordersStatus={this.state.ordersStatus}
						/>

						<Loader show={this.state.loading || this.loadingTable} />

						{ resultOrders.hasOwnProperty("Results")  &&
							 
							
							<DataTableSimple
								columns={this.getColumns()}
							
								data={
									resultOrders.Results
										? resultOrders.Results
										: []
								}
								onClickRow={this.handleAction}
							/> }
							
							<Pagination
								pageIndex={this.state.page}
								pageSize={this.state.pageSize}
								canPreviousPage={this.state.page > 0}
								canNextPage={this.state.page + 1 < this.state.pageCount}
								pageOptions={Array(this.state.pageCount)}
								// pageOptions={[10, 25, 50, 100]}
								pageCount={this.state.pageCount}
								gotoPage={this.gotoPage}
								nextPage={this.nextPage}
								previousPage={this.previousPage}
								setPageSize={this.setPageSize}
							/>
							

						
						{
							<Modal show={this.state.modalshow} onHide={this.onHide}>
								<Modal.Header closeButton>Enviar factura</Modal.Header>
								<Modal.Body>
									<div className="row">
										<div className="col-12">
											<div className="form-group">
												<Form.Group controlId="formBasicEmail">
													<Form.Label>Email</Form.Label>
													<Form.Control
														type="email"
														placeholder="Enter email"
														onChange={this.handleEmailChange}
													/>
												</Form.Group>
											</div>
										</div>
									</div>
								</Modal.Body>
								<Modal.Footer>
									<button
										className="btn btn-outline-secondary-cancel mr-3"
										onClick={this.onHide}
									>
										Cancelar
									</button>
									<button
										className="btn btn-outline-primary"
										onClick={this.onSend}
									>
										Enviar
									</button>
								</Modal.Footer>
							</Modal>
						}
						{
							<BootBox
								show={this.state.showConfirm}
								type={"confirm"}
								message={this.state.msgConfirm}
								successLabel={"Si"}
								onYesClick={this.handleOk}
								onNoClick={this.handleClose}
								onClose={this.handleClose}
							/>
						}
						{/* <BootBoxAlert
							show={this.state.showAlert}
							message={this.state.messageAlert}
							onYesClick={this.handleCloseAlert}
						/> */}
					</Tab>
					{this.state.hasAccess && ( 
						<Tab eventKey="withoutInvoice" title="Sin Factura">
							<ListOrdersWithoutInvoice ordersStatus={this.state.ordersStatus} showModalPro={this.state.showModalPro}  />
						</Tab> 
					)}
					{false && this.state.hasAccess && ( 
						<Tab eventKey="withInvoice" title="Con Factura">
							<ListOrdersWithInvoice />
						</Tab>
					)}
				</Tabs>

				{
					<Modal show={this.state.showModalPro} onHide={this.onHideModalPro}>
						<Modal.Header closeButton>Generar Factura Global</Modal.Header>
						<Modal.Body>
							<div className="row">
								<div className="col-12">
									<div className="form-group">
										Este módulo solo esta disponible para la suscripicón PREMIUM
									</div>
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<button
								className="btn btn-outline-secondary-cancel mr-3"
								onClick={this.onHideModalPro}
							>
								Cancelar
							</button>
							<button
								className="btn btn-outline-primary"
								onClick={() => {
									this.props.history.push(`/shopping/plans`);												
								}}
							>
								Conocer Mas
							</button>
						</Modal.Footer>
					</Modal>
				}
				{
					<Modal show={this.state.showCancelModal} onHide={this.onHideModalCancelInvoice}>
						<Modal.Header closeButton>¿Esta seguro que desea cancelar esta factura?</Modal.Header>
						<Modal.Body>
							<div className="row">
								<div className="col-12">
									<div className="form-group">
									
										<InvoiceCancelForm
											setReason={this.handleReason}
											setSubstituteCFDI={this.handleSubstituteCFDI}
										/>
									
									</div>
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<button
								className="btn btn-outline-secondary-cancel mr-3"
								onClick={this.onHideModalCancelInvoice}
							>
								No
							</button>
							<button
								className="btn btn-outline-primary"
								onClick={this.handleCancel}
							>
								Sí
							</button>
						</Modal.Footer>
					</Modal>
				}
			</AppFrame>
		);
	}
}

ListInvoicePage.propTypes = {
	//handlerSerchOrder:PropTypes.func.isRequired,
	resultOrders: PropTypes.object.isRequired,
};
ListInvoicePage.defaultProps = {
	resultOrders: {},
};
const mapStateToProps = (state) => ({
	resultOrders: getOrders(state),
	canceledCFDI: cancelCFDIslctr(state),
	subscription: getSubscription(state),
});
export default connect(mapStateToProps, {
	fetchOrders,
	GetInvoicePdf,
	getCfdiZip,
	cancelCfdi,
	sendCfdi,
	fetchOrdersStatus,
	setInvoiceStatus,
})(ListInvoicePage);

import React from "react";
import { reduxForm } from "redux-form";
import { loginAccount, getEcommerceAccess, getSubscriptionDB } from "../../actions/account";
import { fetchSubscription } from "../../actions/subscription";
import { fetchCheckSteps, fetchProfile} from "../../actions/profile"
import { setToken } from "../../actions/tokens";
import Login from "./Login";
import { connect } from "react-redux";
import { getToken, getShopName } from "../../selectors/tokens";
import { getsubscriptionPlanInfo } from "../../selectors/accounts";
import { Alert } from "react-bootstrap";

import store from "../../store";

class LoginForm extends React.Component {

	constructor(props) {
		super(props);	
		this.state = {		
			showAlert: false,
			messageAlert: "",			
			loading: false,			
			alertVariant: "primary",			
		};
	}
	

	submit = (values) => {
		values.Id = this.props.shopName;
		const { getSubscriptionDB, fetchSubscription, fetchCheckSteps, fetchProfile, getEcommerceAccess} = this.props;
		
		return this.props
			.loginAccount(values)
			.then(({ payload }) => {
				if (payload.token) {
					this.props.setToken(payload.token);

					return Promise.all([
						getSubscriptionDB(),
						fetchSubscription(),
						fetchCheckSteps(),
						fetchProfile(),
						getEcommerceAccess(),
					]);						
						
				}
				if (payload._error) {
					this.Alert(payload._error, 'danger');
					console.log('_error', payload);		
				}
				return undefined;
			})
			.catch((error) => {
				this.setState({
					isLoaded: true,
					error,
				});
				console.log('error', error);
				this.Alert(error, 'danger');
			})
			 .then((subscription) => {

				if(subscription){
				
					this.Alert('Ingreso de usuario correcto', 'danger');
					
				
					if( subscription[2].payload !== undefined 
						&& subscription[2].payload.success === false  //false
						&& subscription[2].payload.IsBranch === true  //false
						&& subscription[2].payload.IsCertif === true  //true
						&& subscription[2].payload.IsTaxInfo === true  //true
					){       
							this.props.history.push("/invoices/list");
					}else if( subscription[1].payload !== undefined && 
						subscription[1].payload.success === true ){       
						this.props.history.push("/invoices/list");
					} 
					else {
						this.props.history.push(`/wizard`);					
					}
				}

			})
			.catch((error) => {
				this.setState({
					isLoaded: true,
					error,
				});
				console.log('error', error);
				this.Alert(error, 'danger');
			})
			.finally((error) => {
				this.setState({
					isLoaded: true,
					error,
				});
				if(error)
					this.Alert(error, 'danger');
			});
	};
	componentDidMount() {         		
		let state = store.getState();
		if( state.profile && state.profile.length !== 0) {
			this.props.history.push("/invoices/list");
		}
			
	}

	componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

	handleCloseAlert = () => {
		this.setState({ showAlert: false });
	};

	Alert = (msg, type = 'primary') => {
		this.setState({ showAlert: true,
						messageAlert: msg,
						alertVariant: type
		 });
	};

	render() {
		const { handleSubmit, submitting } = this.props;
	
		return (
			<>
				{
					<div className="box mt-4 xs-mt-0 py-5 px-5 xs-pt-2 xs-pb-4 xs-px-2">
						<form onSubmit={handleSubmit(this.submit)}>
							<Alert
								show={this.state.showAlert}
								variant={this.state.alertVariant}
								onClose={this.handleCloseAlert}
								dismissible
							>
								<p>{this.state.messageAlert}</p>
							</Alert>
							<Login />
							<div>
								<button
									className="btn btn-outline-primary mt-4 full-width"
									type="submit"
									disabled={submitting}
								>
									Entrar a Facturama
								</button>
							</div>
						</form>
					</div>
				}
			</>
		);
	}
}

LoginForm = reduxForm({
	form: "loginForm",
})(LoginForm);

LoginForm = connect(
	(state) => {
		return {
			token: getToken(state),
			shopName: getShopName(state),
			subsPlanInfo: getsubscriptionPlanInfo(state),
		};
	},
	{
		loginAccount,
		getSubscriptionDB,
		fetchSubscription,
		fetchCheckSteps,
		fetchProfile,
		setToken,
		getEcommerceAccess,
	}
)(LoginForm);

export default LoginForm;

import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { getStoreSettings, getSubscription } from "../selectors/accounts";
import { fetchSubscription } from "../actions/subscription";
import { useEffect } from "react";
import { PRO, PREMIUM, FREE } from "../constants/plans";

let SubscribedRoute = ({
	component: Component,
	subscription,
	settingsStore,
	location,
	plan,
	...rest
}) => {
	useEffect(() => {
		!subscription && fetchSubscription();
	}, [subscription]);

	const subscriptionExpired =
		new Date(subscription?.subscriptionPlanInfo?.ExpirationDate) <= new Date(); //Suscripción vencida
	// const noSubscriptionCreated = subscription?.subs?.Status === undefined; //No tiene ninguna suscripcion ni cancelada ni nada	
	const notValidSubscription = ( subscription?.subscriptionPlanInfo?.Plan.toUpperCase() !== PRO.toUpperCase() && subscription?.subscriptionPlanInfo?.Plan.toUpperCase() !== PREMIUM.toUpperCase() && subscription?.subscriptionPlanInfo?.Plan.toUpperCase() !== FREE.toUpperCase() )
	const zeroFolios = subscription?.subscriptionPlanInfo?.CurrentFolios === "0"; //No tiene folios
	const planName = subscription?.subscriptionPlanInfo?.Plan;


	return !!settingsStore?.DisplayCartFolios ? (
		<Route
			{...rest}
			render={(props) =>
				notValidSubscription ? (
					<Redirect
						to={{
							pathname: "/shopping/plans",
							state: { from: props.location },
						}}
					/>
				) : subscriptionExpired ? (
					<Redirect
						to={{
							pathname: "/settings/profile/subscription",
							state: { from: props.location },
						}}
					/>
				) : zeroFolios ? (
					<Redirect
						to={{
							pathname: "/shopping/folio",
							state: { from: props.location },
						}}
					/>
				) : plan && plan !== planName ? (
					<Redirect
						to={{
							pathname: "/shopping/plans",
							state: { from: props.location },
						}}
					/>
				) : (
					<Component {...props} />
				)
			}
		/>
	) : (
		<Route {...rest} render={(props) => <Component {...props} />} />
	);
};

SubscribedRoute = connect(
	(state) => ({
		subscription: getSubscription(state),
		settingsStore: getStoreSettings(state),
	}),
	{
		fetchSubscription,
	}
)(SubscribedRoute);

export default SubscribedRoute;

import React, { Component } from "react";
import { getFormValues, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import AppFrame from "../components/AppFrame";
import AddInvoicePage from "../pages/Invoices/AddInvoicePage";
import { fetchCustomers } from "../actions/customers";
import { getCustomers } from "../selectors/customers";
import { fetchBranchOffices } from "../actions/branches";
import { getExpeditionPlaces } from "../selectors/profile";
import { fetchInvoiceGlobal, insertInvoiceGlobal } from "../actions/invoice";
import Loader from "../components/General/loader";
import { fetchSeries } from "../actions/account";
import Modal from "react-bootstrap/Modal";
import CustomerForm from "../components/CustomerForm";

class InvoicesGlobalContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			modalShow: false,
			isCustomerEdit: false,
			showBootBoxAlert: false,
			msgConfirm: "",
			actionConfirm: "",
			redirect: false,
			url: "",
			isFirstLoad: true,
			customers: undefined,
			customer: "",
			branchOffices: undefined,
			invoice: [],
			paramIds: [],
			series: null,
			idCustomer: "",
			cfdiVersion: "4.0",
			clientName: '',
			clientError: false
		};
	}

	componentDidMount() {
		if (this.state.isFirstLoad) {
			this.setState({ loading: true })
			this.setState({ isFirstLoad: false });
			const { ids } = this.props.match.params;
			this.setState({ paramIds: ids });
			
			Promise.all([
				this.props.fetchInvoiceGlobal( JSON.parse(ids)) , 
				this.props.fetchCustomers(),
				this.props.fetchBranchOffices(),
			
			])
			.then( (responses) => {
				if (responses[0].payload) {
					this.setState({ invoice: responses[0].payload });
					if (responses[0].payload.hasOwnProperty("ItemsAndTaxesData")) {
						this.props.initialize({
							OrderNumber: responses[0].payload.ItemsAndTaxesData.OrderNumber,
							Currency: "MXN",
							PaymentType: "PUE",
							PaymentMethod: "04",
							DaysBack: "0",
						});
					}
				}
				if (responses[1].payload){
					this.setState({ customers: responses[1].payload });
				}
				if (responses[2].payload){
					if(responses[2].payload.length > 0)
						this.props.initialize({
							...this.props.formValues,
							IdBrachOffice: responses[2].payload[0].Id
						});
					this.setState({ branchOffices: responses[2].payload });
				}			
			} )
			.catch( (error) => {
				this.Alert(error._error, 'danger');
				console.log('error on did mount', error);
			})
			.finally( () => this.setState({ loading: false }) );

		}
	}
	clearClientName = () =>{
		this.setState({clientName: ''});
	}
	clearErrorOnClient = () =>{
		this.setState({clientError: false});
	}
	onHandleUpdateCustomers = () => {
		this.props.fetchCustomers().then((response) => {
			if (response.payload) this.setState({ customers: response.payload });
			this.setState({ loading: false });
		});
	};
	onhandleSubmitSend = (e) => {
		this.Alert('Factura generada correctamente, Correo enviado correctamente', 'success');
	};
	submit = (value,ids) => {

		if(!value.IdClient){
			this.setState({clientError: true});
			window.scrollTo(0,0);
			return;
		}
		
		this.setState({ loading: true });
		return this.props
			.insertInvoiceGlobal(value, JSON.parse(this.state.paramIds))
			.then(({ payload }) => {
				if (payload.success === true) {
					this.setState({ sendInvoiceShow: true });
					this.setState({ email: payload.email });
					this.setState({ idInvoice: payload.idInvoice });
					this.Alert('Factura generada correctamente', 'success');
				} else {
					const errorMessage = payload.hasOwnProperty("message")
						? payload.message
						: "Ha ocurrido un error, favor recargar la página y volver a intentarlo";
					this.Alert(errorMessage, 'danger');
					throw new SubmissionError({ _error: errorMessage });
				}
				if (payload._error) {
					this.Alert(payload._error, 'danger');
					throw new SubmissionError(payload);
				}
			})
			.catch((error) => {
				console.log('error', error);
				error._error += ` ${error._errorDetail}`;
				this.Alert(error._error, 'danger');
				throw new SubmissionError(error);
			})
			.finally(() => {
				window.scrollTo(0,0);
				this.setState({ loading: false });
			});
	};

	handleOnSubmitSuccess = () => {
		this.Alert('Factura generada correctamente', 'success');
		console.log("submit success");
	};
	fillSeries = (event) => {
		this.props.fetchSeries(event.target.value).then((response) => {
			if (response.payload) {
				const seriesValue =
					response.payload.length > 0 ? response.payload : null;
				this.setState({ series: seriesValue });
			}
		});
	};
	onCustomersModalShow = (isShow, isEdit, idCustomer) => {
		this.setState({ modalShow: isShow });
		this.setState({ isCustomerEdit: isEdit });
		this.setState({ idCustomer: idCustomer });
	};

	onChangeClient = (idClient) => {		
		this.props.initialize({
			...this.props.formValues,
			IdClient: idClient,
		});		
	}

	onChangePaymentType = (e) => {
		let paymentMethod = "04";
		if (e.target.value === "PPD") {
			paymentMethod = "99";
		}
		this.props.initialize({
			...this.props.formValues,
			PaymentMethod: paymentMethod,
		});		
	}

	handleCloseAlert = () => {
		this.setState({ showAlert: false });
		if (this.state.redirect) {
			window.location.reload(false);
			this.setState({ redirect: false });
		}
	};
	Alert = (msg, variant) => {
		this.setState({
			showAlert: true,
			messageAlert: msg,
			alertVariant: variant,
		});
		
	};

	render = () => {
		const { error, handleSubmit, submitting, submitSucceeded, formValues } = this.props;

		return (
			<>	
				<AppFrame title={ this.state.cfdiVersion === '4.0' ? 'Nueva Factura global 4.0': 'Nueva Factura global 3.3' }>					
					{this.state.loading ||
					this.state.customers === undefined ||
					this.state.branchOffices === undefined ||
					this.state.invoice === undefined ? (
						<Loader show={true} />
					) : (
						<form id="invoiceGlobalForm" onSubmit={handleSubmit(this.submit)}>
							<Loader show={this.state.loading} />
							{error && (
								<div className="alert alert-danger" role="alert">
									{error}
								</div>
							)}
							{submitSucceeded && (
								<div className="alert alert-success" role="alert">
									Factura generada correctamente
								</div>
							)}

							<AddInvoicePage
								customers={this.state.customers}
								branchOffices={this.state.branchOffices}
								invoice={this.state.invoice}
								series={this.state.series}
								fillSeries={this.fillSeries}
								onCustomersModalShow={this.onCustomersModalShow}
								cfdiVersion={this.state.cfdiVersion}
								informationGlobalNode={ true }
								form="invoiceGlobalForm"
								onChangePaymentType={this.onChangePaymentType}
								onChangeClient={this.onChangeClient}
								clientName={this.state.clientName}
								clearClientName={this.clearClientName}
								clientError={this.state.clientError}
								clearErrorOnClient={this.clearErrorOnClient}
							/>
							
							<div className="row mt-3 mb-5">
								<div className="col-12 text-right">
									<button
										type="submit"
										className="btn btn-primary"
										disabled={submitting}
										form="invoiceGlobalForm"
									>
										Generar factura
									</button>
								</div>
							</div>
						</form>
					)}
					<Modal
						dialogClassName="modal-xl"
						show={this.state.modalShow}
						onHide={() => {
							this.setState({ modalShow: false });
						}}
					>
						<Modal.Header closeButton>
							{this.state.isCustomerEdit ? "Editar Cliente" : "Nuevo Cliente"}
						</Modal.Header>
						<Modal.Body>
							{
								<CustomerForm
									Id={this.state.isCustomerEdit ? this.state.idCustomer : 0}
									IsInvoice={true}
									onSubmitSuccess={this.onHandleUpdateCustomers}
									setId={(id, name) => {
										this.setState({clientName: name})
										this.props.initialize({
											...formValues,
											IdClient: id,
										});
									}}
								/>
							}
						</Modal.Body>
						<Modal.Footer>
							<button
								className="btn btn-outline-secondary-cancel mr-3"
								onClick={() => {
									this.setState({ modalShow: false });
								}}
							>
								Cancelar
							</button>
							<button
								className="btn btn-outline-primary"
								type="submit"
								form="CustumerForm"
							>
								Guardar
							</button>
						</Modal.Footer>
					</Modal>
				</AppFrame>
			</>
		);
	};
}

InvoicesGlobalContainer = reduxForm({
	form: "invoiceGlobalForm",
})(InvoicesGlobalContainer);

InvoicesGlobalContainer = connect(
	(state) => {
		return {
			formValues: getFormValues("invoiceGlobalForm")(state),
			customers: getCustomers(state),
			branchOffices: getExpeditionPlaces(state)
		};
	},
	{
		fetchCustomers,
		fetchBranchOffices,
		fetchInvoiceGlobal,
		insertInvoiceGlobal,
		fetchSeries
	}
)(InvoicesGlobalContainer);

export default InvoicesGlobalContainer;

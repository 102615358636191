// import { formValueSelector } from "redux-form";
// const selector = formValueSelector("taxProfileForm");

export const getSteps = (state) => {
	return state.steps;
};

export const getProfile = (state) => {
	return state.profile;
};

export const getIsNatural = (state) => {
	// const rfc = selector(state, "Rfc");
	const rfc = state.profile.Rfc;
	//return rfc?.length === 13 ? true : rfc?.length === 12 ? false : null;
	return rfc?.length === 13 ? true : false;
};

export const getNeighborhoods = (state) => {
	return state.profile.neighborhoods || [];
};

export const getExpeditionPlaces = (state) => {
	return state.branchOffices;
};

export const getExpeditionPlaceById = (state, props) => {
	return state.branchOffices.find((b) => b.Id === props.Id);
};

export const getBillingData = (state) => state.profile.billingData;

export const getExpirationDateCSD = (state) =>
	state.profile?.Csd?.ExpirationDate;

import React from "react";
import ListWithoutInvoiceFilter from "../../components/ListInvoices/ListWithoutInvoiceFilter";
import DataTableSimple from "../../components/DataTable/DataTableSimple";
import numeral from "numeral";
//import Moment from "moment";
import "moment/locale/es";
import BootBox, { BootBoxAlert } from "../../components/General/bootbox";
import Loader from "../../components/General/loader";
import {
	fetchOrdersWithInvoice,
	updateOrders,
	fetchOrders,
	GetInvoicePdf,
	getCfdiZip,
	cancelCfdi,
	sendCfdi,
	fetchOrdersStatus,
} from "../../actions/orders";
import { connect } from "react-redux";
import { getOrdersWithInvoice } from "../../selectors/orders";
import { withRouter } from "react-router-dom";
import {
	ProcessButton,
	OptionsDropdown,
} from "../../components/ListInvoices/ListInvoicesControls";

class ListOrdersWithInvoice extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showConfirm: false,
			msgConfirm: "",
			showAlert: false,
			messageAlert: "",
			loading: false,
			loadingTable: false,
			modalshow: false,
			checkOrders: [],
		};
	}
	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}
	handleCheckChange = (e) => {
		const Value = e.target.value;
		if (e.target.checked === true) {
			this.state.checkOrders.push(Value);
		} else {
			let rescheck = this.state.checkOrders.filter((r) => r !== Value);
			this.setState({ checkOrders: rescheck });
		}
	};
	clickUpdateOrderHandler = () => {
		this.AlertConfirm(
			"Se generaran las facturas globales de las ordenes marcadas"
		);
	};
	UpdateOrders = () => {
		this.setState({ loading: true });
		this.props
			.updateOrders(this.state.checkOrders)
			.then((response) => {
				if (response.payload.hasOwnProperty("_error")) {
					this.Alert(response.payload._error);
				} else if (response.payload.hasOwnProperty("Message")) {
					this.Alert(response.payload.Message);
				} else {
					this.Alert("Se guardo correctamente");
				}
				this.setState({ loading: false });
				this.setState({ showConfirm: false });
			})
			.catch((r) => {
				this.Alert("error");
				this.setState({ loading: false });
				this.setState({ showConfirm: false });
			});
	};
	getColumns() {
		const columns = [
			{
				Header: "Ordenes",
				hideHeader: true,
				columns: [					
					{
						Header: "Número orden",
						accessor: (d) => {
							return d.Name;
						},
					},
					{
						Header: "Folio",
						accessor: (d) => {
							return d.Folio;
						},
					},
					{
						Header: "Serie",
						accessor: (d) => {
							return d.Serie;
						},
					},
					{
						Header: "Cliente",
						accessor: (d) => {
							return d.CustomerInfo.map((c) => c.Name).join(", ");
						},
					},
					{
						Header: "Fecha",
						accessor: (d) => {
							return d.InvoiceDate;
							//return Moment(d.CreatedAt).local().format("DD/MM/YYYY");
						},
					},
					{
						Header: "Total",
						accessor: (d) => {
							return numeral(d.TotalAmount).format("$0,0.00");
						},
					},
					{
						Header: " ",
						accessor: (d) => {
							return (
								d.TotalAmount >= 0 && (
									<ProcessButton
										idOrder={d.Id}
										idInvoice={d.IdInvoice}
										status={d.PaymentStatus}
									/>
								)
							);
						},
					},
					{
						Header: "  ",
						accessor: (d) => {
							return d.IdInvoice ? (
								<OptionsDropdown
									idInvoice={d.IdInvoice}
									handleAction={this.onClickIvoiceHandle}
								/>
							) : (
								""
							);
						},
					},
				],
			},
		];
		return columns;
	}
	onClickIvoiceHandle = (a, b) => {
		switch (a) {
			case 1:
				this.setState({ loading: true });
				this.props
					.GetInvoicePdf(b)
					.then((response) => {
						if (response.payload.hasOwnProperty("_error")) {
							this.Alert(response.payload._error);
						} else if (response.payload.hasOwnProperty("Message")) {
							this.Alert(response.payload.Message);
						} else {
							this.Download(
								`data:application/pdf;base64,${response.payload.Content}`,
								"cfdi.pdf"
							);
						}
						this.setState({ loading: false });
					})
					.catch((r) => {
						this.setState({ loading: false });
						this.Alert("error");
					});
				break;
			case 2:
				this.setState({ loading: true });
				this.props
					.getCfdiZip(b)
					.then((response) => {
						if (response.payload.hasOwnProperty("_error")) {
							this.Alert(response.response.payload._error);
						} else if (response.payload.hasOwnProperty("Message")) {
							this.Alert(response.payload.Message);
						} else {
							this.Download(
								`data:application/zip;base64,${response.payload.Content}`,
								"cfdi.zip"
							);
						}
						this.setState({ loading: false });
					})
					.catch((r) => {
						this.setState({ loading: false });
						this.Alert("error");
					});
				break;
			case 3:
				this.setState({ modalshow: true });
				this.setState({ idCfdi: b });
				//(modalSend)
				break;
			case 4:
				this.setState({ showConfirm: true });
				this.setState({
					msgConfirm: "¿Esta seguro de que desea cancelar esta factura?",
				});
				this.setState({ actionConfirm: "cancel" });
				this.setState({ idCfdi: b });

				break;
			default:
				this.Alert("SIN ACCION");
		}
	};
	Download(linkSource, fileName) {
		const downloadLink = document.createElement("a");
		downloadLink.href = linkSource;
		downloadLink.download = fileName;
		downloadLink.click();
	}
	actionCancelCfdi = () => {
		this.setState({ loading: true });
		this.props
			.cancelCfdi(this.state.idCfdi)
			.then((response) => {
				if (response.payload.hasOwnProperty("_error")) {
					this.Alert(response.payload._error);
				} else if (response.payload.hasOwnProperty("Message")) {
					this.Alert(response.payload.Message);
				} else if (response.payload) {
					this.Alert("Factura cancelada");
				}
				this.setState({ loading: false });
				this.setState({ idCfdi: "" });
			})
			.catch((r) => {
				this.setState({ loading: false });
				this.setState({ idCfdi: "" });
				this.Alert("error");
			});
	};
	handleAction() {
		alert("entra");
	}
	handlerSerchOrder = (startDate, endDate) => {
		this.setState({ loading: true });
		const d1 = `${startDate.getFullYear()}-${
			startDate.getMonth() + 1
		}-${startDate.getDate()}`;
		const d2 = `${endDate.getFullYear()}-${
			endDate.getMonth() + 1
		}-${endDate.getDate()}`;
		this.props.fetchOrdersWithInvoice({
			Page: 1,
			Records_page: 100,
			PaymentStatus: "withInvoice",
			StartDate: d1,
			EndDate: d2,
		});
	};

	componentDidUpdate(oldProps) {
		if (oldProps.resultOrders !== this.props.resultOrders) {
			const ids = [];
			this.props.resultOrders &&
				this.props.resultOrders.forEach((element) => {
					ids.push(element.IdOrder);
				});
			this.setState({ checkOrders: ids });
			this.setState({ loading: false });
		}
	}
	Alert = (msg) => {
		this.setState({ showAlert: true });
		this.setState({ messageAlert: msg });
	};
	AlertConfirm = (msg) => {
		this.setState({ showConfirm: true });
		this.setState({ msgConfirm: msg });
		this.setState({ actionConfirm: "cancel" });
	};
	handleCloseAlert = () => {
		this.setState({ showAlert: false });
	};
	handleOkConfirm = () => {
		this.props.history.push(
			"/invoices/global/new/" + JSON.stringify(this.state.checkOrders)
		);
	};
	handleCloseConfirm = () => {
		this.setState({ showConfirm: false });
	};

	//de aqui
	onHide = () => {
		this.setState({ modalshow: false });
		this.setState({ idCfdi: "" });
	};
	onSend = (e) => {
		if (this.state.idCfdi !== "" && this.state.email !== "") {
			this.setState({ loading: true });
			this.setState({ modalshow: false });
			this.props
				.sendCfdi(this.state.idCfdi, this.state.email)
				.then((response) => {
					if (response.payload.hasOwnProperty("_error")) {
						this.Alert(response.payload._error);
					} else if (response.payload.hasOwnProperty("Message")) {
						this.Alert(response.payload.Message);
					}
					this.setState({ loading: false });

					this.setState({ idCfdi: "" });
				})
				.catch((r) => {
					this.Alert("error");
					this.setState({ loading: false });

					this.setState({ idCfdi: "" });
				});
		} else {
			this.Alert("Email requerido");
		}
	};
	handleEmailChange = (e) => {
		this.setState({ email: e.target.value });
	};
	handleClose = () => {
		this.setState({ showConfirm: false });
	};
	handleOk = () => {
		if (this.state.actionConfirm === "cancel") {
			this.actionCancelCfdi();
		} else if (this.state.actionConfirm === "rebill") {
		}
	};

	componentDidMount() {
		this.setState({ loading: true });
		this.props.fetchOrdersStatus().then((response) => {
			this.setState({ ordersStatus: response.payload });
			this.setState({ loading: false });
		});

		if (
			this.props.ecommerceType &&
			this.props.ecommerceType.Name &&
			(this.props.ecommerceType.Name.toUpperCase() === "WIX" ||
				this.props.ecommerceType.Name.toUpperCase() === "TIENDANUBE")
		) {
			this.setState({ hasAccess: true });
		}
	}

	render() {
		const { resultOrders } = this.props;
		return (
			<>
				<ListWithoutInvoiceFilter
					ref="child2"
					hideButtonInvoice={true}
					clickHandler={this.handlerSerchOrder}
					clickUpdateOrderHandler={this.clickUpdateOrderHandler}
				/>
				<Loader show={this.state.loading || this.state.loadingTable} />
				<DataTableSimple
					columns={this.getColumns()}
					data={(Object.keys(resultOrders).length !== 0 && resultOrders) || []}
					setLoadingTable={(value) => this.setState({ loadingTable: value })}
				/>
				<BootBoxAlert
					show={this.state.showAlert}
					message={this.state.messageAlert}
					onYesClick={this.handleCloseAlert}
				/>
				<BootBox
					show={this.state.showConfirm}
					type={"confirm"}
					message={this.state.msgConfirm}
					successLabel={"Si"}
					onYesClick={this.handleOkConfirm}
					onNoClick={this.handleCloseConfirm}
					onClose={this.handleCloseConfirm}
				/>
			</>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		resultOrders: getOrdersWithInvoice(state),
	};
};
export default withRouter(
	connect(mapStateToProps, {
		fetchOrdersWithInvoice,
		updateOrders,
		fetchOrders,
		GetInvoicePdf,
		getCfdiZip,
		cancelCfdi,
		sendCfdi,
		fetchOrdersStatus,
	})(ListOrdersWithInvoice)
);


export const getToken = (state) => {    
    return state.tokens.token;
};


export const getShopName = (state) => {          
    return state.tokens.shopName;
};

export const getUsername = (state) => {
    let token = atob( state.tokens.token );
    return token.split(":")[0];   
}
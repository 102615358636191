import { createAction } from "redux-actions";
import {
	DETAIL_BRANCHOFFICES,
	FETCH_BRANCHOFFICES,
	INSERT_BRANCHOFFICES,
	UPDATE_BRANCHOFFICES,
	DELETE_BRANCHOFFICES,
} from "./../constants";
import { apiGet, apiGetById, apiPost, apiPut, apiDelete } from "../api";
import { urlBranchOffices } from "../api/urls";

export const fetchBranchOffices = createAction(FETCH_BRANCHOFFICES, () =>
	apiGet(`${urlBranchOffices}`)()
);

export const fetchBranchById = createAction(DETAIL_BRANCHOFFICES, (id) =>
	apiGetById(urlBranchOffices, id)()
);

export const insertBranch = createAction(INSERT_BRANCHOFFICES, (branch) =>
	apiPost(urlBranchOffices, branch)()
);

export const updateBranch = createAction(
	UPDATE_BRANCHOFFICES,
	async (branch) => {
		await apiPut(urlBranchOffices, branch.Id, branch)();
		return branch;
	}
);

export const deleteBranch = createAction(DELETE_BRANCHOFFICES, (Id) =>
	apiDelete(urlBranchOffices, Id)()
);

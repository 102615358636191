import React from "react";
import AppFrame from "../../components/AppFrame";
import AccountSettings from "./AccountSettings";

// import ExtraChargesSettings from "./ExtraChargesSettings";

const AccountSettingsPage = () => {
	
	return (
		<AppFrame title="Ajustes de Autofacturación">
			<section id="main-content">
				{/*<script src="/Scripts/source/shopify/MethodsShopify.js"></script>
                 <script>
                    InitializeShopify('pruebasfacturama');
                </script> */}

				<input type="hidden" id="ShopName" value="pruebasfacturama" />
				<input
					type="hidden"
					id="api-token"
					value="cHJ1ZWJhc1Nob3BpZnkzMzoxMjM0NTY="
				/>
				<article id="settings-general-taxes">
					<div className="container-fluid">
						{/* Titulo de la pagina */}
						{/* <div className="row page-title">
							<div className="col-xs-12">
								<h4>Configuraciones</h4>
							</div>
						</div>{" "} */}
						{/* Fin del titulo de la página */}
						<div className="row">
							<div className="col-xs-12">
								<nav>
									<div className="nav nav-tabs" id="nav-tab" role="tablist">
										<a
											className="nav-item nav-link active"
											id="nav-home-tab"
											data-toggle="tab"
											href="#nav-home"
											role="tab"
											aria-controls="nav-home"
											aria-selected="true"
										>
											Ajustes de Autofacturación
										</a>
										{/* <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">
                                            Configurar Cargos Extra (Opcional)
                                        </a>                     */}
									</div>
								</nav>
								{/* nav-tabContent : inicio */}
								<div className="tab-content" id="nav-tabContent">
									<div
										className="tab-pane fade show active"
										id="nav-home"
										role="tabpanel"
										aria-labelledby="nav-home-tab"
									>
										<AccountSettings />
									</div>
									{/* <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                        <ExtraChargesSettings />
                                    </div>                 */}
								</div>
								{/* nav-tabContent : fin */}
							</div>
						</div>
						{/* row : fin */}
					</div>
				</article>
			</section>
		</AppFrame>
	);
};

export default AccountSettingsPage;

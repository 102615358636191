import React from "react";
import {  reduxForm, SubmissionError } from "redux-form";
import { Link } from 'react-router-dom'
import { recoveryAccount } from "../../actions/account";
import RecoveryPassword from "./RecoveryPassword";
import { connect } from "react-redux";
import { setToken } from "../../actions/tokens";
//import { getToken, getShopName } from "../../selectors/tokens";


class RecoveryPasswordForm extends React.Component {    
    constructor(props) {
        super(props);
        this.state = {
            isLoaded:true,
            token:""
        } 
    }
    submit = (values) => {       
        let objValues={PasswordNew:values.PasswordNew,PasswordConfirm:values.PasswordConfirm,Token:this.props.token};
        return this.props.recoveryAccount(objValues)
        .then(({ payload }) => {
            if (payload.token) {                
                console.log("result:" + payload.token);
                //this.props.setToken(payload.token);
                window.location.href = '/account/login';
               
            }            
            if (payload._error) {
                throw new SubmissionError(payload);
            }
        },
        (error) => {
            
            this.setState({
              isLoaded: true,
              error
            });
          });
          
    };

    render() {
        const {
            error,
            handleSubmit,                        
            submitting,            
            submitSucceeded,            
        } = this.props;        

        
		return (
<>
            {                
                
                <div className="box mt-4 xs-mt-0 py-5 px-5 xs-pt-2 xs-pb-4 xs-px-2">	
                    
                    <form onSubmit={handleSubmit(this.submit)} >
                        {error && (
                            <div className="alert alert-danger" role="alert">
                                {error}                                
                            </div>
                        )}
                        {submitSucceeded && (
                            <div className="alert alert-success" role="alert">
                                Se ha cambiado la contraseña
                            </div>
                        )}           
                        
                                
                        <RecoveryPassword />

                        <div>                    
                            <button
                                className="btn btn-outline-primary mt-4 full-width"
                                type="submit"
                                disabled={submitting}
                            >
                                Restablecer contraseña
                            </button>
                        </div>

                        <div className="mt-4 text-center">
						    <span><strong>¿Aún no tienes una cuenta?</strong> <Link to="/account/register">Regístrate aquí</Link> </span>
					    </div>
                    </form>

                </div>
                    
            }
            </>
            

            
		);
    
    }
}


    

RecoveryPasswordForm = reduxForm({    
    form: 'recoveryPasswordForm'
  })(RecoveryPasswordForm)


  RecoveryPasswordForm = connect(
    (state) => {        
        /*return {
            token: getToken(state),
            shopName: getShopName(state),    
        };*/
    },
    {
        recoveryAccount,        
        setToken        
    }
)(RecoveryPasswordForm);


export default RecoveryPasswordForm;
import React from 'react'
import { useAsyncDebounce } from 'react-table'

export const GlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) => {
      
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)
  
    return (
      <span>
        Buscar:{' '}
        <input
          value={value || ""}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`En ${count} registros...`}
          style={{
            fontSize: '0.8rem',
            border: '0',
          }}
        />
      </span>
    )
}

export default GlobalFilter
const MONTHS = [
    {
        Name: '01 - Enero',
        Value: '01',
        AvailableFor:'01,02,03,04',
    },
    {
        Name: '02 - Febrero',
        Value: '02',
        AvailableFor:'01,02,03,04',
    },
    {
        Name: '03 - Marzo',
        Value: '03',
        AvailableFor:'01,02,03,04',
    },
    {
        Name: '04 - Abril',
        Value: '04',
        AvailableFor:'01,02,03,04',
    },
    {
        Name: '05 - Mayo',
        Value: '05',
        AvailableFor:'01,02,03,04',
    },
    {
        Name: '06 - Junio',
        Value: '06',
        AvailableFor:'01,02,03,04',
    },
    {
        Name: '07 - Julio',
        Value: '07',
        AvailableFor:'01,02,03,04',
    },
    {
        Name: '08 - Agosto',
        Value: '08',
        AvailableFor:'01,02,03,04',
    },
    {
        Name: '09 - Septiembre',
        Value: '09',
        AvailableFor:'01,02,03,04',
    },
    {
        Name: '10 - Octubre',
        Value: '10',
        AvailableFor:'01,02,03,04',
    },
    {
        Name: '11 - Noviembre',
        Value: '11',
        AvailableFor:'01,02,03,04',
    },
    {
        Name: '12 - Diciembre',
        Value: '12',
        AvailableFor:'01,02,03,04',
    },
    {
        Name: '13 - Enero-Febrero',
        Value: '13',
        AvailableFor:'05',
    },
    {
        Name: '14 - Marzo-Abril',
        Value: '14',
        AvailableFor:'05',
    },
    {
        Name: '15 - Mayo-Junio',
        Value: '15',
        AvailableFor:'05',
    },
    {
        Name: '16 - Julio-Agosto',
        Value: '16',
        AvailableFor:'05',
    },
    {
        Name: '17 - Septiembre-Octubre',
        Value: '17',
        AvailableFor:'05',
    },
    {
        Name: '18 - Noviembre-Diciembre',
        Value: '18',
        AvailableFor:'05',
    },
    
];

export default MONTHS;
// export const PRO = "PRO_eCommerce";
export const PRO = "Pro_ecommerce";
// export const PREMIUM = "PREMIUM_eCommerce";
export const PREMIUM = "Premium_ecommerce";

export const FREE = "FreeEcommerce";
export const UNRENEWED = "PremiunUnRenewed";

export const PLANS = [
	{
		Name: "PRO",
		Id: PRO,
	},
	{
		Name: "PREMIUM",
		Id: PREMIUM,
	},
];

import { FETCH_FACTURAMA_PRODUCTS} from './../constants';
import { createAction } from 'redux-actions';
import { apiGet } from '../api';
import { urlFacturamaProducts } from '../api/urls';


export const fetchFacturamaProducts = createAction(
    FETCH_FACTURAMA_PRODUCTS, 
    apiGet(`${urlFacturamaProducts}?keyword=Ecommerce` )
    
);



export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const DETAIL_PRODUCT = "DETAIL_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const INSERT_PRODUCT = "INSERT_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";

export const FETCH_CUSTOMERS = "FETCH_CUSTOMERS";
export const FIND_CUSTOMERS = "FIND_CUSTOMERS";
export const DETAIL_CUSTOMER = "DETAIL_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const INSERT_CUSTOMER = "INSERT_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";

export const FETCH_BRANCHOFFICES = "FETCH_BRANCHOFFICES";
export const DETAIL_BRANCHOFFICES = "DETAIL_BRANCHOFFICES";
export const INSERT_BRANCHOFFICES = "INSERT_BRANCHOFFICES";
export const UPDATE_BRANCHOFFICES = "UPDATE_BRANCHOFFICES";
export const DELETE_BRANCHOFFICES = "DELETE_BRANCHOFFICES";

export const FETCH_PROFILE = "FETCH_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_LOGO = "UPDATE_LOGO";
export const UPDATE_CSD = "UPDATE_CSD";
export const UPDATE_EFIRMA = "UPDATE_EFIRMA";
export const FETCH_EXPIRATION_CSD = "FETCH_EXPIRATION_CSD";

export const FETCH_NEIGHBORHOODS = "FETCH_NEIGHBORHOODS";
export const FETCH_POSTALCODES = "FETCH_POSTALCODES";
export const FETCH_FEDERALSTATE = "FETCH_FEDERALSTATE";
export const FETCH_MUNICIPALITY = "FETCH_MUNICIPALTY";

export const SEND_INVOICE = "SEND_INVOICE";
export const INSERT_INVOICE = "INSERT_INVOICE";
export const INSERT_INVOICE_GLOBAL = "INSERT_INVOICE_GLOBAL";
export const FETCH_INVOICE = "FETCH_INVOICE";
export const FETCH_INVOICE_GLOBAL = "FETCH_INVOICE_GLOBAL";
export const INVOICE = "INVOICE";

export const FETCH_ORDERS = "FETCH_ORDERS";
export const FETCH_ORDERS_STATUS = "FETCH_ORDERS_STATUS";
export const UPDATE_ORDERS = "UPDATE_ORDERS";
export const FETCH_ORDERS_WHITHOUT_INVOICE = "FETCH_ORDERS_WHITHOUT_INVOICE";
export const FETCH_ORDERS_WHITH_INVOICE = "FETCH_ORDERS_WHITH_INVOICE";
export const FETCH_ORDERS_FOR_IDS = "FETCH_ORDERS_FOR_IDS";

export const INSERT_PROCESS = "INSERT_PROCESS";
export const INSERT_PROCESS_UPLOAD = "INSERT_PROCESS_UPLOAD";
export const FETCH_MASSIVEPROCESS = "FETCH_MASSIVEPROCESS";
export const FETCH_INVOICE_PDF = "FETCH_INVOICE_PDF";
export const FETCH_CFDI_ZIP = "FETCH_CFDI_ZIP";
export const CANCEL_CFDI = "CANCEL_CFDI";
export const SEND_CFDI = "SEND_CFDI";
export const DOWNLOAD_PRODUCTS_CSV = "DOWNLOAD_PRODUCTS_CSV";
export const GET_CFDI_FILTERED = "GET_CFDI_FILTERED";

export const INSERT_PROCESS_BATCH = "INSERT_PROCESS_BATCH";

export const INSERT_SETTING = "INSERT_SETTING";
export const INSERT_TAX_SETTING = "INSERT_TAX_SETTING";
export const FETCH_SETTINGS = "FETCH_SETTINGS";
export const FETCH_SETTINGS_CFDIVERSION = "FETCH_SETTINGS_CFDIVERSION";

export const FETCH_CHECK_STEPS = "FETCH_CHECK_STEPS";
export const INSERT_SETTING_GENERAL = "INSERT_SETTING_GENERAL";
export const FETCH_GENERAL_SETTINGS = "FETCH_GENERAL_SETTINGS";
export const FETCH_BRANCHOFFICES_SERIE = "FETCH_BRANCHOFFICES_SERIE";
export const DETAIL_BRANCHOFFICES_SERIE = "DETAIL_BRANCHOFFICES_SERIE";
export const PUT_BRANCHOFFICES_SERIE = "PUT_BRANCHOFFICES_SERIE";
export const INSERT_BRANCHOFFICES_SERIE = "INSERT_BRANCHOFFICES_SERIE";
export const GET_ECOMMERCE_ACCESS = "GET_ECOMMERCE_ACCESS";
export const GET_ECOMMERCE_SUBSCRIPTION = "GET_ECOMMERCE_SUBSCRIPTION";
export const IMPORT_INSERT_PRODUCTS = "IMPORT_INSERT_PRODUCTS";

export const FETCH_PRODUCTS_SERVICES = "FETCH_PRODUCTS_SERVICES";

export const RECOVERY = "RECOVERY";
export const RESET = "RESET";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";

export const SHOP_NAME = "SHOP_NAME";
export const TOKEN = "TOKEN";

export const FETCH_FACTURAMA_PRODUCTS = "FETCH_FACTURAMA_PRODUCTS";
export const FACTURAMA_CHARGE = "FACTURAMA_CHARGE";

export const FACTURAMA_DOCUMENTS = "FACTURAMA_DOCUMENTS";

export const CONTACT_US = "CONTACT_US";

export const STORE_SETTINGS = "STORE_SETTINGS";
export const SET_INVOICE_STATUS = "SET_INVOICE_STATUS";

export const FETCH_SUBSCRIPTION = "FETCH_SUBSCRIPTION";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";

export const FETCH_CARDS = "FETCH_CARDS";
export const DETAIL_CARD = "DETAIL_CARD";
export const UPDATE_CARD = "UPDATE_CARD";
export const INSERT_CARD = "INSERT_CARD";
export const DELETE_CARD = "DELETE_CARD";

export const UPDATE_BILLING_DATA = "UPDATE_BILLING_DATA";
export const FETCH_BILLING_DATA = "FETCH_BILLING_DATA";

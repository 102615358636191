import React from "react";
export const WelcomeTiendaNube = () => {
	return (
		<div>
			Gracias por interesarte en nuestra app en <b>TIENDA NUBE</b>,
			adem&aacute;s de facturar ten acceso a las siguientes herramientas:
			<br />
			<br />
			<ul>
				<li>
					Asociación de las claves SAT para productos y unidades de forma
					masiva.
				</li>
				<li>Emitir facturas a partir de las ventas realizadas.</li>
				<li>Reporte de las ventas no facturadas.</li>
			</ul>
			<br />
			Aqu&iacute; encontrar&aacute;s nuestros manuales de uso:
			<br />
			<br />
			<a
				href="https://cdnfacturama.blob.core.windows.net/content/docs/Manual_Instalacion_Tienda_Nube.pdf"
				target="_blank"
				rel="noopener noreferrer"
			>
				Manual de Instalaci&oacute;n
			</a>
			<br />
			<a
				href="https://cdnfacturama.blob.core.windows.net/content/docs/Manual_Configuracion_Tienda_Nube.pdf"
				target="_blank"
				rel="noopener noreferrer"
			>
				Manual de Configuraci&oacute;n
			</a>
		</div>
	);
};

import React from 'react';
import { reduxForm, Field } from "redux-form";
import {setPropsAsInitial} from "../../helpers/setPropsAsInitial";
import PropTypes from 'prop-types';
/*const isRequired=value=>(
!value&&"este campo es requerido"
);*/
const MyField=({input,meta,type,readonly,label,name})=>(
    <div className="col-6">
        <label htmlFor={name}>{label}</label>
        <input {...readonly===true?"readonly":""} {...input} type={!type?"text":type}/>
        {
          meta.touched&&meta.error&&<div color={"red"}>{meta.error}</div>
        }
    </div>
);
/*
const isNumber=value=>(
    isNaN(Number(value))&&"El campo debe ser numerico"
);*/
const validate=values=>{
    const error={};
    if(!values.Name){
        error.Name="el campo Nombre es requerido";
    }
    if(!values.Description){
        error.Description="el campo Descripcion es requerido";
    }
    return error;
};
//const toNumber=value=>value && Number(value)
//const onlyGrow=(value,previousValue,values)=>value&&(value>0?value:1)//value&&previousValue&&(value>previousValue?value:previousValue);

const  SerieEdit = ({Name,Description,Folio,handleSubmit}) => {
    return(        
        <form id="SerieForm" onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-6">
                    <label >Nombre</label>
                    <input value={Name} disabled type="text"/>
                </div>
                <Field name="Description" component={MyField} type="text"                
                label={"Descripcion"}
                ></Field>
                <div className="col-6">
                    <label >Folio de Inicio</label>
                    <input value={Folio} disabled type="text"/>
                </div>
            </div>
        </form>
    );
};
SerieEdit.propTypes={
    Name:PropTypes.string,
    Description:PropTypes.string,
    Folio:PropTypes.number
};
const SerieEditForm=reduxForm(
    {
        form:"SerieEdit",
        validate
    })(SerieEdit);
export default setPropsAsInitial(SerieEditForm);
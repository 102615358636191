import React, { useState } from "react";
import { Field } from "redux-form";
import InputField from "../InputField";
import {
	isRequired,
	maxLength,
	minLength,
	rfc,
	length,
	email,
} from "../../validations";
import { TAX_REGIMES } from "../../constants/tax_regimes";

const maxLength100 = maxLength(100);
const minLength12 = minLength(12);
const maxLength13 = maxLength(13);
const length10 = length(10);

export const TaxData = ({ isNatural, onRfcChange }) => {
	const [rfcIsNatural, setRfcIsNatural] = useState(isNatural);

	const handleOnChangeRfc = ({ target: { value } }) => {
		setRfcIsNatural(value.length === 13);
		onRfcChange && onRfcChange("FiscalRegime", "", false, false);
	};

	return (
		<div className="form-row">
			<div className="form-group col-md-6">
				<Field
					className="form-control"
					label="Nombre fiscal"
					placeholder="Nombre fiscal"
					name="TaxName"
					type="text"
					component={InputField}
					validate={[isRequired, maxLength100]}
				/>
			</div>
			<div className="form-group col-md-6">
				<Field
					label="Nombre comercial"
					type="text"
					className="form-control"
					placeholder="Nombre comercial"
					name="ComercialName"
					component={InputField}
					validate={[maxLength100]}
				/>
			</div>
			<div className="form-group col-md-3">
				<Field
					label="RFC"
					type="text"
					className="form-control"
					placeholder="RFC"
					normalize={(value) => value.toUpperCase()}
					name="Rfc"
					onChange={handleOnChangeRfc}
					component={InputField}
					validate={[isRequired, minLength12, maxLength13, rfc]}
				/>
			</div>
			<div className="form-group col-md-3">
				<Field
					label="Email"
					type="text"
					className="form-control"
					placeholder="Email"
					name="Email"
					component={InputField}
					validate={[email, isRequired]}
				/>
			</div>
			<div className="form-group col-md-3">
				<Field
					label="Teléfono"
					type="text"
					className="form-control"
					placeholder="Teléfono"
					name="Phone"
					normalize={(value) => value.replace(/[^\d]/g, "")}
					component={InputField}
					validate={[length10]}
				/>
			</div>
			<div className="form-group col-md-3">
				<Field
					label="Régimen fiscal"
					className="form-control"
					name="FiscalRegime"
					required=""
					type="select"
					component={InputField}
					validate={[isRequired]}
				>
					<option value="">Selecciona una opción</option>
					{TAX_REGIMES.filter((regime) => regime.Natural === rfcIsNatural).map(
						(regime) => (
							<option value={regime.Value} key={regime.Value}>
								{regime.Name}
							</option>
						)
					)}
				</Field>
			</div>
		</div>
	);
};

export default TaxData;

import React from "react";
import { reduxForm, SubmissionError } from "redux-form";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { registerAccount, getSubscriptionDB } from "../../actions/account";
import { fetchSubscription } from "../../actions/subscription";

import { setShopName, setToken } from "../../actions/tokens";
import Register from "./Register";
import { connect } from "react-redux";
import { getShopName, getToken } from "../../selectors/tokens";

class RegisterForm extends React.Component {
    constructor() {
        super();
        this.state = {
            redirect: false,
            url: "",
        };
    }

    submit = (values) => {
        values.Id =
            this.props.shopName !== "reset"
                ? this.props.shopName
                : this.props.currentShopName;

        return this.props
            .registerAccount(values)
            .then(({ payload }) => {
                if (payload.token) {
                    this.props.setToken(payload.token);
                    return Promise.all([
                        getSubscriptionDB(),
                        fetchSubscription(),
                    ]);
                }
                if (payload._error) {
                    throw new SubmissionError(payload);
                }
            })
            .then(() => {
                setTimeout(
                    function () {
                        //Start the timer
                        this.setState({
                            redirect: true,
                            url: `/account/thanks`,
                        });
                    }.bind(this),
                    1000
                );
            });
    };

    renderRedirect = () => {
        if (this.state.redirect === true) {
            return <Redirect to={this.state.url} />;
        }
    };

    render() {
        const { error, handleSubmit, submitting, submitSucceeded } = this.props;

        return (
            <div className="box mt-4 xs-mt-0 py-5 px-5 xs-pt-2 xs-pb-4 xs-px-2">
                {this.renderRedirect()}

                <form onSubmit={handleSubmit(this.submit)}>
                    {error && (
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    )}
                    {submitSucceeded && (
                        <div className="alert alert-success" role="alert">
                            Usuario registrado correctamente
                        </div>
                    )}

                    <Register />

                    <div>
                        <button
                            className="btn btn-outline-primary mt-4 full-width"
                            type="submit"
                            disabled={submitting}
                        >
                            Registrar
                        </button>
                    </div>

                    <div className="mt-4 text-center">
                        <span>
                            <strong>¿Ya tienes una cuenta?</strong>{" "}
                            <Link to="/account/login">Ingresa aquí</Link>{" "}
                        </span>
                    </div>
                </form>
            </div>
        );
    }
}

RegisterForm.propTypes = {
    shopName: PropTypes.string,
};

RegisterForm = reduxForm({
    form: "registerForm",
})(RegisterForm);

RegisterForm = connect(
    (state) => {
        return {
            token: getToken(state),
            currentShopName: getShopName(state),
        };
    },
    {
        registerAccount,
        setShopName,
        setToken,
        getSubscriptionDB,
        fetchSubscription,
    }
)(RegisterForm);

export default RegisterForm;

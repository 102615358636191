import React from "react";
// import Spinner from 'react-bootstrap/Spinner'
import "./loader.css";

function Loader({ show }) {
	return show ? (
		<div
			style={{
				width: "100%",
				height: "100%",
				background: "rgba(255, 255, 255, 0.6)",
				position: "fixed",
				top: "0",
				left: "0",
				zIndex: "100",
				
			}}
		>
			<div
				style={{
					zIndex: 1000,
					display: "flex",
					left: "43%",
					top: "25%",
					position: "absolute",
					alignItems: "center",
					height: "50vh",
				}}
			>
				{/* <Spinner animation="grow" variant="info" />&nbsp;<Spinner animation="grow" variant="info" />&nbsp;<Spinner animation="grow" variant="info" /> */}
				<div className="lds-ellipsis">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		</div>
	) : (
		""
	);
}
export default Loader;

/* 

.container { ... }
.input { ... }
.suggestionsContainer { ... }
.suggestion { ... }
.suggestionHighlighted { ... }
... */

const theme = {
  container: {
    position: 'relative'
  },
  
  input: {
    width: '449px',
    height: '39px',
    padding: '10px 20px',
    // 'font-family': 'Helvetica, sans-serif',
    // 'font-weight': '300',
    // 'font-size': '16px',
    // border: '1px solid #aaa',
    border: '1px solid',
    // 'border-radius': '4px'
  },
  
  inputFocused: {
    outline: 'none'
  },
  
  inputOpen: {
    // 'border-bottom-left-radius': '0',
    borderBottomLeftRadius: '0',
    // 'border-bottom-right-radius': '0'
    borderBottomRightRadius: '0'
  },
  
  suggestionsContainer: {
    display: 'none'
  },
  
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',   
    width: '449px',
    border: '1px solid #aaa',
    // 'background-color': '#fff',
    backgroundColor: '#fff',
    // 'font-family': 'Helvetica, sans-serif',
    fontFamily: 'Helvetica, sans-serif',
    // 'font-weight': '300',
    fontWeight: '300',
    // 'font-size': '16px',
    fontSize: '16px',
    // 'border-radius': '0 0 4px 4px',
    // 'z-index': '2'
    zIndex: '2'
  },
  
  suggestionsList: {
    margin: '0',
    padding: '0',
    // 'list-style-type': 'none',
    listStyleType: 'none',
  },
  
  suggestion: {
    cursor: 'pointer',
    padding: '10px 20px'
  },
  
  suggestionHighlighted: {
    // 'background-color': '#ddd'
    backgroundColor: '#ddd'
  }
}

export default theme;
import React from "react";
import { Link } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.css";

export const ClientsSelect = ({ defaultValue, customers }) => {
	return (
		<>
			{customers ? (
				<select
					className="form-control"
					title="Clientes"
					defaultValue={defaultValue}
				>
					{customers.slice(0, 1000).map((state) => (
						<option key={state.Id} value={state.Id}>
							{state.Name}
						</option>
					))}
				</select>
			) : (
				<p>Por favor agrega por lo menos un cliente</p>
			)}
		</>
	);
};

export const ClientsEditableSelect = ({ defaultValue, customers }) => {
	return (
		<>
			<div className="row">
				<div className="col-8">
					<ClientsSelect customers={customers} />
				</div>
				<div className="col-2">
					<Link to="" title="Editar cliente seleccionado">
						<i className="far fa-edit"></i>
					</Link>
				</div>
				<div className="col-2">
					<Link to="" title="Agregar nuevo cliente">
						<i className="far fa-plus-square"></i>
					</Link>
				</div>
			</div>
		</>
	);
};

export const ExpeditionPlaceSelect = ({
	AllBranchs,
	defaultValue,
	handleChange,
	value,
}) => {
	if (defaultValue === "" && AllBranchs.length > 0) {
		defaultValue = AllBranchs[0].Id;
	}

	return (
		<>
			<select
				id="expedition_place_select"
				className="form-control"
				name="IdBranch"
				defaultValue={defaultValue}
				onChange={handleChange}
				value={value}
			>
				<option>Selecciona un lugar de expedición</option>
				{AllBranchs &&
					AllBranchs.map((state) => (
						<option key={state.Id} value={state.Id}>
							{state.Name}
						</option>
					))}
			</select>
		</>
	);
};

export const SeriesSelect = ({ AllSeries, defaultValue, handleChange }) => {
	return (
		<>
			<select
				id="serie_select"
				className="form-control"
				title="Serie del folio"
				name="Serie"
				value={defaultValue}
				onChange={handleChange}
			>
				<option value="">Sin Serie</option>
				{AllSeries &&
					AllSeries.map((state) => (
						<option key={state.Name} value={state.Name}>
							{state.Name}
						</option>
					))}
			</select>
		</>
	);
};

export const SeriesEditableSelect = ({
	AllSeries,
	defaultValue,
	handleChange,
	onModalSerieNew,
	onModalSerieEdit,
}) => {
	return (
		<>
			<div className="row">
				<div className="col-8">
					<SeriesSelect
						AllSeries={AllSeries}
						defaultValue={defaultValue}
						handleChange={handleChange}
					/>
				</div>
				<div className="col-2">
					<button
						disabled={!defaultValue}
						title="Editar serie seleccionada"
						onClick={onModalSerieEdit}
					>
						<i className="far fa-edit"></i>
					</button>
				</div>
				<div className="col-2">
					<button title="Agregar nueva serie" onClick={onModalSerieNew}>
						<i className="far fa-plus-square"></i>
					</button>
				</div>
			</div>
		</>
	);
};

export const GeneralPublicSelect = ({ id, defaultValue }) => {
	return (
		<>
			<select
				id="{id}"
				className="form-control"
				title="Cliente al que se le expide la factura"
				defaultValue={defaultValue}
			>
				<option value="0">Seleccionar Cliente</option>
				<option value="0a3iWRZ0PoZjllHip6rJcQ2">CLIENTE EXTRANJERO</option>
				<option value="3Z5OV8w8dWztgdo8vYwD7g2">PUBLICO EN GENERAL</option>
			</select>
		</>
	);
};

import React from "react";
// import { Link } from 'react-router-dom';
import AppFrame from "../components/AppFrame/AppFrame";
import { WelcomeEposNow } from "./WelcomeEposNow";
import { WelcomeWix } from "./WelcomeWix";
import { WelcomeTiendaNube } from "./WelcomeTiendaNube";
import { urlCdnImages } from "../api/urls"

export const WelcomePage = ({ ecommerceType }) => {
	return (
		<AppFrame>
			<section className="bg-triangle-gray pb-3 xs-pb-0">
				<div className="container">
					<div className="row d-flex justify-content-center pt-5 xs-pt-1 xs-pb-0">
						<div className="col-md-auto">
							<figure className="logo xs-img-auto">
								<picture>								
								<source
                                        type="image/png"
                                        srcSet = { `${urlCdnImages}/facturama-freshbooks-logo.png` }
                                    />
                                    <img
                                        src = { `${urlCdnImages}/facturama-freshbooks-logo.png` }
                                        alt="Facturación electrónica Facturama"
                                        className="img-fluid"
                                    />
								</picture>
							</figure>
						</div>
					</div>
					<div className="row d-flex justify-content-center mt-1">
						<div className="col-md-6">
							<div className="box pt-5 px-5">
								<h1 className="text-center text-blue-primary h3 mt-4">
									¡Gracias por unirte <br /> a Facturama!
								</h1>
								{ecommerceType &&
									ecommerceType.Name &&
									((ecommerceType.Name.toUpperCase() === "EPOSNOW" && (
										<WelcomeEposNow />
									)) ||
										(ecommerceType.Name.toUpperCase() === "WIX" && (
											<WelcomeWix />
										)) ||
										(ecommerceType.Name.toUpperCase() === "TIENDANUBE" && (
											<WelcomeTiendaNube />
										)))}
							</div>{" "}
							{/* box */}
							<div className="mt-5 text-center small">
								<a href="tel:4448356098" className="text-gray">
									444-8356098
								</a>{" "}
								-{" "}
								<a href="tel:4441510191" className="text-gray">
									444-1510191
								</a>{" "}
								|{" "}
								<a className="text-gray" href="mailto:info@facturama.mx">
									info@facturama.mx
								</a>
							</div>
						</div>{" "}
						{/* col-md-6 */}
					</div>{" "}
					{/* row */}
				</div>{" "}
				{/* container */}
			</section>
		</AppFrame>
	);
};

import { FACTURAMA_CHARGE} from './../constants';
import { createAction } from 'redux-actions';
import { apiPost2 } from '../api';
import { urlFacturamaCharges } from '../api/urls';


export const facturamaCharge = createAction(
    FACTURAMA_CHARGE,     
    (charge) => apiPost2(`${urlFacturamaCharges}`, charge ) ()
    
);

export const facturamaChargePreview = createAction(
    FACTURAMA_CHARGE, 
    (charge) => apiPost2(`${urlFacturamaCharges}/preview`, charge ) ()
    
);


export const facturamaChargeSendReceipt = createAction(
    FACTURAMA_CHARGE, 
    (chargeId, email) => apiPost2(`${urlFacturamaCharges}/receipts?chargeId=${chargeId}&email=${email}`, null ) ()    
);

export const facturamaChargeSendAcuse = createAction(
    FACTURAMA_CHARGE, 
    (chargeId, email, idProduct) => apiPost2(`${urlFacturamaCharges}/acuses?chargeId=${chargeId}&email=${email}&idProduct=${idProduct}`, null ) ()    
);




import React, { useRef } from "react";
import PropTypes from "prop-types";
import "./LogoProfile.css";

const LogoProfile = ({ logoImageFile, setLogoImageFile }) => {
	const fileInputRef = useRef(null);

	// Mustra el control de seleccionar archivo
	const handleImagePreviewClick = () => {
		fileInputRef.current.click();
	};

	const logoPreviewShow = (e) => {
		let files = e.target.files;
		if (files.length > 0) getBase64(files[0], setLogoImageFile);
	};

	const getBase64 = (file, cb) => {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			cb(reader.result);
		};
		reader.onerror = function (error) {
			console.log("Error: ", error);
		};
	};

	return (
		<div className="avatar-upload">
			<div className="avatar-edit">
				<input
					type="file"
					ref={fileInputRef}
					accept=".png, .jpg, .jpeg"
					name="LogoImage"
					onChange={(e) => logoPreviewShow(e)}
				/>
				<label htmlFor="imageUpload" onClick={handleImagePreviewClick}></label>
			</div>
			<div className="avatar-preview">
				<div
					id="imagePreview"
					style={{
						backgroundImage: `url(${logoImageFile})`,
					}}
				></div>
			</div>
		</div>
	);
};

LogoProfile.propTypes = {
	urlLogo: PropTypes.string,
};

export default LogoProfile;

import React from "react";
import { Field } from "redux-form";
import InputField from "../InputField";
import { maxLength, isRequired } from "../../validations";

const maxLength20 = maxLength(20);

export const UnitCode = ({ unitsCatalog }) => {
	return (
		<div className="form-group">
			<label>Define la UNIDAD de tu producto</label>
			<div className="row">
				<div className="col-xs-12 col-md-8">
					<small>Busca tu UNIDAD, y seleccónala del campo de abajo</small>
				</div>
				<div className="col-xs-12 col-md-4">
					<a
						className="btn btn-block btn-outline-secondary p-2"
						target="_blank"
						rel="noopener noreferrer"
						href="http://200.57.3.46:443/PyS/catUnidades.aspx"
					>
						Buscar Unidad
					</a>
				</div>
			</div>
			<Field
				component={InputField}
				type="select"
				className="form-control"
				name="UnitCode"
				placeholder="Unidad"
				validate={[maxLength20, isRequired]}
			>
				{unitsCatalog.map((unit) => (
					<option key={unit.Value} value={unit.Value}>
						{unit.Value} - {unit.Name}
					</option>
				))}
			</Field>
		</div>
	);
};

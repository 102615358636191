import React from "react";
import Address from "../Address";
import InputField from "../InputField";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { isRequired } from "../../validations";
import {
	fetchBranchById,
	insertBranch,
	updateBranch,
	deleteBranch,
} from "../../actions/branches";
import { Redirect } from "react-router-dom";
import Bootbox, { BootBoxAlert } from "../../components/General/bootbox";
import Loader from "../../components/General/loader";
import { getExpeditionPlaceById } from "../../selectors/profile";
import { Alert } from "react-bootstrap";

class BranchForm extends React.Component {
	constructor() {
		super();
		this.state = {
			includeAddress: false,
			// redirect
			redirect: false,
			url: "",
			// delete
			hiddenDeleteButton: false,
			// bootbox
			showBootBoxAlert: false,
			modalshow: false,
			email: "",
			showConfirm: false,
			msgConfirm: "",
			isLoading: false,

			showAlert: false,
			alertVariant: "primary",
			messageAlert: "",
		};
	}

	submit = (values) => {
		// console.log("submit");
		// console.log(values);

		if (!values.Address.Country) values.Address.Country = "México";

		if (!values.Address.State) values.Address.State = "AGUASCALIENTES";

		let submitFuncion = values.Id
			? this.props.updateBranch
			: this.props.insertBranch;

		return submitFuncion(values)
			.then(({ payload }) => {
				if (payload) {
					console.log(payload);
					this.Alert("Datos de la sucursal guardados correctamente", "success");
				}
				if (payload._error) {
				}
			})
			.catch((error) => {
				console.log("error", error);
				this.setState({ error });
				this.Alert(error._error, "danger");
				throw new SubmissionError(error);
			})
			.finally(() => {
				this.setState({
					isLoaded: true,
				});
			});
	};

	componentDidMount() {
		if (this.props.Id === "new" || this.props.Id === "0") {
			this.setState({ hiddenDeleteButton: true });
			return;
		}

		// console.log(this.props.Id);

		// this.setState({isLoading:true});
		// this.props.fetchBranchById(this.props.Id)
		// .then((response) => {
		//     console.log(response.payload);
		//     this.props.initialize(response.payload);
		//     this.setState({isLoading:false});
		// },
		// (error_) => {
		//     console.log(error_);
		//     this.setState({isLoading:false});
		// }
		// );
	}

	handleCancel() {
		console.log("Cancelar e ir para atras");
		this.setState({
			redirect: true,
			url: `/settings/profile/branchs`,
		});
	}

	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect to={this.state.url} />;
		}
	};

	handleDelete() {
		this.setState({ showConfirm: true });
		this.setState({ msgConfirm: "¿Deseas eliminar esta sucursal?" });
	}

	handleClose = () => {
		this.setState({ showConfirm: false });
	};
	handleOk = () => {
		console.log("Eliminar sucursal Id: " + this.props.Id);
		this.setState({ showConfirm: false });
		this.setState({ isLoading: true });
		return this.props
			.deleteBranch(this.props.Id)
			.then(({ payload }) => {
				if (payload) {
					console.log(payload);
					this.setState({ showBootBoxAlert: true });
					this.setState({ msgTitle: "Sucursal eliminada correctamente" });
					this.setState({ msgDescription: "" });
				}
			})
			.catch((error) => {
				this.setState({
					error,
				});
				this.Alert(error._error, "danger");
				throw new SubmissionError(error);
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	handleOkAlert = () => {
		console.log("handleOkAlert");
		this.setState({
			redirect: true,
			url: `/settings/profile`,
		});
	};
	handleCloseAlert = () => {
		this.setState({ showAlert: false });
		if (this.state.redirect) {
			window.location.reload(false);
			this.setState({ redirect: false });
		}
	};
	Alert = (msg, variant) => {
		this.setState({
			showAlert: true,
			messageAlert: msg,
			alertVariant: variant,
		});
	};

	render() {
		const { handleSubmit } = this.props;

		return (
			<>
				{this.state.isLoading && <Loader show={this.state.isLoading} />}
				<form onSubmit={handleSubmit(this.submit)}>
					{this.renderRedirect()}

					{/* {error && ( */}
					<Alert
						show={this.state.showAlert}
						variant={this.state.alertVariant}
						onClose={this.handleCloseAlert}
						dismissible
					>
						<p>{this.state.messageAlert}</p>
					</Alert>

					{/* // <div className="alert alert-danger" role="alert">
						// 	{error}
						// </div> */}
					{/* )} */}
					{/* {submitSucceeded && (
						<div className="alert alert-success" role="alert">
							Datos de la sucursal guardados correctamente
						</div>
					)} */}

					<div className="row">
						<div className="col text-right">
							<button
								className="btn btn-danger"
								type="button"
								hidden={this.state.hiddenDeleteButton}
								onClick={() => this.handleDelete()}
							>
								Eliminar
							</button>
						</div>
					</div>

					<div className="form-row">
						<div className="form-group col-md-4">
							<Field
								label="Nombre del Lugar de Expedición:*"
								component={InputField}
								type="text"
								name="Name"
								placeholder=""
								validate={[isRequired]}
							/>
						</div>
						<div className="form-group col-md-4">
							<Field
								label="Descripción:*"
								component={InputField}
								type="text"
								name="Description"
								placeholder=""
								validate={[isRequired]}
							/>
						</div>
					</div>

					<div className="form-row">
						<div className="col-md-12 pt-3">
							<h4>Dirección</h4>
							<hr className="my-2"></hr>
						</div>
					</div>

					<Address baseName="Address" />

					<div className="row text-right">
						<div className="col pt-4">
							<button
								className="btn btn-outline-secondary mr-3"
								type="button"
								onClick={() => this.handleCancel()}
							>
								<i className="fas fa-chevron-left"></i> Ir a sucursales
							</button>
							<button className="btn btn-outline-primary " type="submit">
								Aceptar
							</button>
						</div>
					</div>

					{
						<Bootbox
							show={this.state.showConfirm}
							type={"confirm"}
							message={this.state.msgConfirm}
							successLabel={"Si"}
							onYesClick={this.handleOk}
							onNoClick={this.handleClose}
							onClose={this.handleClose}
						/>
					}

					{
						<BootBoxAlert
							show={this.state.showBootBoxAlert}
							type={"confirm"}
							message={this.state.msgTitle}
							detail={this.state.msgDescription}
							onYesClick={this.handleOkAlert}
						/>
					}
				</form>
			</>
		);
	}
}

BranchForm.defaultProps = {
	customer: null,
};

BranchForm = reduxForm({
	form: "BranchForm",
})(BranchForm);

BranchForm = connect(
	(state, props) => {
		return {
			initialValues: getExpeditionPlaceById(state, props),
		};
	},
	{
		fetchBranchById,
		updateBranch,
		insertBranch,
		deleteBranch,
	}
)(BranchForm);

export default BranchForm;

import React from "react";
import PAYMENT_FORMS from "../../constants/payment_forms";

const InvoiceData = ({
	paymentType,
	handlePaymentFormOnChange,
	handleCfdiUseOnChange,
	handleMailOnChange,
}) => {
	return (
		<div id="invoice_data">
			<div className="section-title">
				<h6>
					<i className="icon-check text-muted"></i> Datos para tu factura
				</h6>
			</div>

			<div className="card p-2 mb-2">
				<div className="row pb-1">
					<div className="col-6">
						<label>Forma de pago:</label>
						<select
							className="form-control"
							title="Método de Pago"
							onChange={(event) =>
								handlePaymentFormOnChange(event.target.value)
							}
						>
							{PAYMENT_FORMS.filter((pForm) =>
								pForm.PayableIn?.includes(paymentType)
							).map((pForm) => (
								<option value={pForm.Value} key={pForm.Value}>
									{pForm.Value} {pForm.Name}
								</option>
							))}
						</select>
					</div>
					<div className="col-6">
						<label>Uso de la factura:</label>
						<select
							id="cfdiUseStore"
							className="form-control"
							defaultValue="P01"
							onChange={(event) => handleCfdiUseOnChange(event.target.value)}
						>
							<option value="G03">G03 - Gastos en general</option>
						</select>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<label>Correo para recibir datos del pago: *</label>
						<input
							className="form-control"
							type="email"
							placeholder="E-Mail"
							onChange={(event) => handleMailOnChange(event.target.value)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default InvoiceData;
